import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import Confirm from '../Confirm';
import Close from '../Close';

// helpers
import {ls} from '../../Globals/Localstorage';

// globals
import {API} from '../../Globals/Constants';

// styles
import styles from './Menu.module.css';

export default class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAdmin:props.isAdmin,
			alertShow:false
		};
	}
	logoff = (e) => {
		e.preventDefault();
		this.setState({alertShow:true});
	}
	confim = () => {
		const version = ls('version');
		ls();
		ls('version', version);
		this.close();
		window.location.href='/login';
	}
	close = () => this.setState({alertShow:false});
	render() {
		return (
			<div className={this.state.isAdmin?styles.sidemenuside:styles.sidemenu}>
				{!this.state.isAdmin && <div className={styles.inner}>
					<div className={styles.logo}>
						<img src={require('../../Images/nobug.logo.svg')} alt="No Bug. Управление общепитом" />
					</div>
					{!this.state.isAdmin && <Close close={this.props.onClose} />}
					<div>
						Комплексное решение для автоматизации кафе и ресторанов.<br/>
						Система администрирования
						<span>версия {API.version}</span>
					</div>
				</div>}
				<div className={styles.menucontainer}>
					{this.state.isAdmin ?
						<>
							<img src={require('../../Images/nobug.logo.svg')} alt="NoBug | Управление общепитом" />
							<ul>
								<li><Link to={'/license'}>Лицензия</Link></li>
							</ul>
							<ul>
								<li><b>Диспетчерская</b></li>
								<li><Link to={'/products'}>Позиции меню</Link></li>
								<li><Link to={'/productrelations'}>Связанные позиции</Link></li>
								<li><Link to={'/orders'}>Заказы</Link></li>
								<li><Link to={'/orderssearch'}>Поиск заказов</Link></li>
								<li><Link to={'/clients'}>Клиенты</Link></li>
								<li><Link to={'/clientranks'}>Клиентские статусы</Link></li>
								<li><Link to={'/clientssearch'}>Поиск клиентов</Link></li>
								<li><Link to={'/ratings'}>Отзывы</Link></li>
								<li><Link to={'/feedbacks'}>Обращения</Link></li>
							</ul>
							<ul>
								<li><Link to={'/loyalty/alerts'}><b>Программы лояльности</b></Link></li>
							</ul>
							<ul>
								<li><Link to={'/store'}><b>Складской учет</b></Link></li>
							</ul>
							<ul>
								<li><b>Контент</b></li>
								<li><Link to={'/pages'}>Страницы</Link></li>
								<li><Link to={'/ads'}>Рекламные блоки</Link></li>
								<li><Link to={'/contents'}>Контент</Link></li>
								<li><Link to={'/stories'}>Акции-истории</Link></li>
								<li><Link to={'/clientscripts'}>Клиентские сценарии</Link></li>
								<li><Link to={'/clientscriptproducts'}>Комплекты</Link></li>
							</ul>
							<ul>
								<li><b>Справочники</b></li>
								<li><Link to={'/sections'}>Разделы</Link></li>
								<li><Link to={'/categories'}>Категории</Link></li>
							</ul>
							<ul>
								<li><b>Статистика и отчеты</b></li>
								<li><Link to={'/statistics'}>Статистика</Link></li>
								<li><Link to={'/dayshifts'}>Отчет по сменам</Link></li>
								<li><Link to={'/statistic-products'}>Позиции по сменам</Link></li>
								<li><Link to={'/statistic-sections'}>Отчет по станциям</Link></li>
								<li><Link to={'/statistic-casher'}>Отчет по кассе</Link></li>
							</ul>
							<ul>
								<li><b>Реферальная программа</b></li>
								<li><Link to={'/refusers'}>Пользователи</Link></li>
								<li><Link to={'/refrequests'}>Заявки на вывод</Link></li>
								<li><Link to={'/refpromos'}>Промо</Link></li>
							</ul>
							<ul>
								<li><b>Администрирование</b></li>
								<li><Link to={'/transactions'}>Транзакции</Link></li>
								<li><Link to={'/events'}>События</Link></li>
								<li><Link to={'/users'}>Пользователи</Link></li>
								<li><Link to={'/couriers'}>Курьеры</Link></li>
								<li><Link to={'/settings'}>Настройки</Link></li>
								<li><Link to={'/areas'}>Точки</Link></li>
								<li><Link to={'/deliveryareas'}>Зоны доставки</Link></li>
							</ul>
						</>
					: null}
					<div className={styles.logoff}>
						<Link to={'/'} onClick={this.logoff.bind(this)}>Выход из системы</Link>
					</div>
				</div>
				{!this.state.isAdmin && <div className={styles.footer}>сделано с <span>♥</span> <a href="//pavit.design" target="_blank" rel="noopener noreferrer">pavit.design</a></div>}
				{this.state.alertShow && <Confirm message={'Вы уверены что хотите выйти из системы администрирования?'} action={this.confim.bind(this)} close={this.close.bind(this)} />}
			</div>
		);
	};
}