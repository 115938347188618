import React, { Component } from 'react';
import LoadingBar from 'react-top-loading-bar';
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import { Images, Refusers } from '../../Models';
import { ls } from '../../Globals/Localstorage';
import styles from './RefPromos.module.css';
import Alert, { alertShow } from '../../Components/Alert';

class RefPromos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      user: null,
      loading: true,
      file: null,
    };

    this.handleFileChange = this.handleFileChange.bind(this);
  }

  componentDidMount = () => {
    const user = ls('user');

    if (user === null) {
      window.location.href = '/error403';
      return;
    }

    Refusers.getPromos(async (d) => {
      this.setState({
        data: d,
        loading: false,
      });
    });
  };

  handleFileChange = (e) => {
    this.setState({
      file: e.target.files[0],
    });

    console.log(e.target.files[0]);

    alertShow('Файл прикреплен');
  };

  handleSavePromo = () => {
    if (!this.state.file) {
      alertShow('Файл не загружен', true);
    }

    Refusers.addNewPromo({
      path: this.state.file.name,
    }).then((res) => {
      if (res?.data?.id) {
        this.fileSave(res?.data?.id, this.state.file);
      }
    });
  };

  handleUpload = async () => {
    if (!this.state.file) {
      alertShow('Прикрепите файл');
    }

    this.handleSavePromo();
  };

  fileSave = (id, file) => {
    if (file) {
      Images.upload(id, 'refpromo', 0, file).then(() => {
        alertShow('Файл сохранен');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    }
  };

  deletePromo = (id) => {
    Refusers.deletePromo(id).then(() => {
      alertShow('Файл удален');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };

  dataGet = (e) => {
    Refusers.getPromos(async (d) => {
      this.setState({
        data: this.state.file,
      });
    });
  };

  render() {
    return (
      <>
        <LoadingBar
          color="#f11946"
          progress={this.state.loading ? this.state.progress : 100}
        />
        {this.state.loading ? null : (
          <MainTemplate>
            <div>
              <h3>Промо файлы</h3>
            </div>
            <div className={styles.loadFile}>
              <input
                type="file"
                multiple={false}
                onChange={this.handleFileChange}
              />

              <button onClick={this.handleUpload}>Загрузить файл</button>
            </div>
            <div className={styles.table}>
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Название</th>
                    <th>Открыть</th>
                    <th>Удалить</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.path}</td>
                      <td>
                        <a
                          href={`https://kfsamara.ru/assets/refpromos/refpromo_${item.id}_0_${item.path}`}
                        >
                          Открыть
                        </a>
                      </td>
                      <td>
                        <a onClick={() => this.deletePromo(item.id)}>Удалить</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Alert />
          </MainTemplate>
        )}
      </>
    );
  }
}

export default RefPromos;
