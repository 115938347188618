import React,{Component} from 'react';

// plug-ins
import classNames from 'classnames';

// styles
import styles from './Scroller.module.css';
const cx = classNames.bind(styles);

class Scroller extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showReload:this.props.showReload,
			customControls:this.props.customControls
		};
	}
	ref = null;
	scroll = (direction, ref) => {
		if (direction === 'top') ref.scrollTop = 0;
		else {
			const symbol = direction === 'up' ? -1 : 1;
			ref.scrollTop = ref.scrollTop + (symbol * 100);
		}
	}
	reload = () => {
		if (this.state.showReload && this.props.reloadHadler)
			this.props.reloadHadler();
	}
	render() {
		return (
			<>
				{<div ref={(ref) => this.ref = ref} className={this.props.className}>
					{React.Children.map(this.props.children, (v,i) => React.cloneElement(v, {ref:i}))}
				</div>}
				<div className={styles.scrollcontrols}>
					<div className={styles.scrollcontrolsinner}>
						<div className={styles.buttonsblock}>
							{this.state.showReload ? <div className={cx(styles.scrollbutton,styles.top)} onClick={() => this.reload()}>↻</div> : null}
							<div className={cx(styles.scrollbutton,styles.top)} onClick={() => this.scroll('top', this.ref)}>⇪</div>
							{this.props.customControls ?
								(Array.isArray(this.props.customControls) ? this.props.customControls : [this.props.customControls]).map((v,i) => <div key={i}>{v}</div>)
								: null
							}
						</div>
						<div className={styles.buttonsblock}>
							<div className={cx(styles.scrollbutton,styles.down)} onClick={() => this.scroll('down', this.ref)}>⇩</div>
							<div className={cx(styles.scrollbutton,styles.up)} onClick={() => this.scroll('up', this.ref)}>⇧</div>
						</div>
					</div>
				</div>
			</>
		);
	};
}

export default Scroller;