import Http from '../../Globals/Http';

const getAll	= (callback)		=> Http.get('clientscriptproductsitems').then((res) => callback(res.data));
const get		= (id, callback)	=> Http.get('clientscriptproductsitems', {conditions:[{k:'clientScriptProductsId',v:id}]}).then((res) => callback(res.data));

const add		= (data)			=> Http.post('clientscriptproductsitems', {data});
const remove	= (id)				=> Http.remove('clientscriptproductsitems', {conditions:[{k:'clientScriptProductsId',v:id}]});

export {
	getAll,
	get,
	add,
	remove
}