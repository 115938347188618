import Http from '../../Globals/Http';
import {orderItemCookStatus,requestConditionType} from '../../Globals/Constants';

const get			= async (areaId, dayShiftId, cookId)	=> await Http.get('cookorders', {conditions:[{k:'areaId',v:areaId},{k:'dayShiftId',v:dayShiftId},{k:'cookId',v:cookId}]});
const getToday		= async (areaId, dayShiftId)			=> await Http.get('cookorders', {conditions:[{k:'areaId',v:areaId},{k:'dayShiftId',v:dayShiftId},{k:'status',v:orderItemCookStatus.DONE}]});

const getMonth		= async (areaId)						=> await Http.request('cookorders', 'monthStatisticGet', {areaId});

const getByOrderId	= async (orderId)						=> await Http.get('cookorders', {conditions:[{k:'orderId',v:orderId}]});
const getOthers		= async (orderId, sectionId, cookId)	=> await Http.get('cookorders', {conditions:[{k:'orderId',v:orderId},{k:'sectionId',v:sectionId},{k:'cookId',v:cookId,op:requestConditionType.NOT_EQUAL}]});
const getDone		= async (orderId)						=> await Http.get('cookorders', {conditions:[{k:'orderId',v:orderId},{k:'status',v:orderItemCookStatus.DONE}]});

const add			= async (data)							=> await Http.post('cookorders', {data});
const update		= async (orderId, itemId, cookId, data)	=> await Http.put('cookorders', {data,conditions:[{k:'orderId',v:orderId},{k:'orderItemId',v:itemId},{k:'cookId',v:cookId}]});

export {
	get,
	getToday,
	getMonth,
	getByOrderId,
	getOthers,
	getDone,
	add,
	update
}