import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';

// models
import {Categories,Events,Logs,Images} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';
import errorsParse from '../../Globals/Errors';

// texts
import Texts from '../../Globals/Texts';

// globals
import {comonStatusName,comonStatus,eventType,errorType,API} from '../../Globals/Constants';

// styles
import styles from './Category.module.css';

class Category extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		if (this.state.id !== 0) {
			Categories.get(this.state.id, (data) => {
				data.image = empty(data.image) ? null : `${API.assets}/categories/${data.image}`;
				this.setState({data,dataOld:{...data},notfound:empty(data),loading:false});
			});
		} else this.dataInit();
	}
	dataInit = () => this.setState({data:{name:'',link:'',description:'',image:'',imageFile:null,status:comonStatus.ACTIVE,sortOrder:''},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	imageChange = (e) => {
		const reader = new FileReader(), file = e.target.files[0];
		reader.onloadend = () => {
			let {data} = this.state
			data.image = reader.result;
			data.imageFile = file;
			data.imageName = file.name;
			this.setState({data});
		};
		reader.readAsDataURL(file);
	}
	imageDelete = () => {
		let {data} = this.state
		data.image = '';
		data.imageFile = null;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		const data = {
			name:this.state.data.name,
			link:this.state.data.link,
			description:this.state.data.description,
			status:this.state.data.status,
			sortOrder:this.state.data.sortOrder
		};
		if (id === 0) {
			Categories.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.categories.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.categories.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.CATEGORY);
					this.imageSave(id, this.state.data.image, this.state.data.imageFile, this.state.data.imageName, false);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicateLink:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			Categories.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.categories.update,iserror:false});
					Events.add(this.state.user.id, Texts.categories.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.CATEGORY);
					this.imageSave(id, this.state.data.image, this.state.data.imageFile, this.state.data.imageName, true);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicateLink:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	imageSave = (id, image, file, filename, isupdate) => {
		if (image) {
			if (file) {
				Images.upload(id, 'category', 0, file);
				Categories.update(id, {image:`category_${id}_0_${filename}`});
			}
		} else {
			if (isupdate) Categories.update(id, {image:''});
		}
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/categories'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить категорию' : 'Редактирование категории'}
								</span>
								<Link to={'/category'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<form onSubmit={this.save}>
									<div className={styles.formcontainer}>
										<div className={styles.cell}>
											<label>
												<span>Картинка для обложки</span>
												{this.state.data.image && <img src={this.state.data.image} alt="" className={styles.image} />}
												<input onChange={this.imageChange} name="image" className={styles.file} accept="image/jpeg,image/png" type="file" />
												{empty(this.state.data.image) && <div className={styles.imageAdd}></div>}
											</label>
											{!empty(this.state.data.image) && <div className={styles.link} onClick={this.imageDelete.bind(this)}>&times; удалить изображение</div>}
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Название</span>
													<input type="text" name="name" value={this.state.data.name} placeholder="Название" onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Ссылка</span>
													<input type="text" name="link" value={this.state.data.link} placeholder="Ссылка" onChange={this.handleForm} required />
												</label>
											</div>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Описание</span>
												<input type="text" name="description" value={this.state.data.description} placeholder="Описание, слоган" className={styles.double} onChange={this.handleForm} />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Порядок сортировки</span>
												<input type="text" name="sortOrder" value={this.state.data.sortOrder} onChange={this.handleForm} />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Статус</span>
												<select name="status" onChange={this.handleForm} defaultValue={this.state.data.status}>
													{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
												</select>
											</label>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Category);