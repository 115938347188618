import Http from '../../Globals/Http';

const get			= async (id)			=> await Http.get('courierrides', {conditions:[{k:'orderId',v:id}]});

const add			= (data, callback)		=> Http.post('courierrides', {data:data}).then((res) => callback ? callback(res) : {});
const update		= (id, data, callback)	=> Http.put('courierrides', {data:data,conditions:[{k:'orderId',v:id}]}).then((res) => callback ? callback(res) : {});


export {
	get,
	add,
	update
}