import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import Modal from 'react-awesome-modal';
import {YMaps,Map,ZoomControl} from 'react-yandex-maps';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import Close from '../../Components/Close';

// models
import {Areas,Orders,Discounts,Products,Settings,OrderStatuses,CourierOrders,CourierRoutes} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty,dateGet,phoneFormatter,rn2br} from '../../Globals/Utils';

// globals
import {orderStatusName,MAP,orderTypeName,targetTypeName,transactionPaymentSourceName} from '../../Globals/Constants';

// styles
import styles from './Order.module.css';

class Order extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			user:null,
			areas:[],
			products:[],
			orderitems:[],
			statuses:[],
			courier:null,
			notfound:false,
			progress:10,
			loading:true,
			deliveryCost:0,
			deliveryFreeAmount:0,
			modalmapshow:false,
			modalloading:false,
			maploaded:false
		};
		this.map = null;
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	dataGet = async () => {
		OrderStatuses.get(this.state.id, async (data) => {
			this.setState({statuses:data});
			CourierOrders.get(this.state.id, async (data) => {
				this.setState({courier:data});
				const courierroute = await CourierRoutes.get(this.state.id);
				this.setState({courierroute:courierroute.data});
				Orders.get(this.state.id, (data) => this.setState({data,dataOld:{...data},notfound:empty(data),loading:false}));
			});
		});
		Products.getAllActive((data) => this.setState({products:data}));
		Discounts.getAll((data) => this.setState({discounts:data}));
		Settings.getAll((data) => {
			const deliveryCost = parseFloat(data.find(f => f.code === 'delivery-cost').value);
			const deliveryFreeAmount = parseFloat(data.find(f => f.code === 'delivery-amount-free').value);
			this.setState({deliveryCost,deliveryFreeAmount});
		});
		const areas = await Areas.getAllActive();
		this.setState({areas:areas.data});
	}
	modalHide = () => this.setState({modalmapshow:false,modalloading:false});
	modalMapShow = () => this.setState({modalmapshow:true,modalloading:true});
	mapShow = () => this.modalMapShow();
	onMapLoad = (ymaps) => {
		ymaps.ready([]).then(() => {
			const coords = this.state.courierroute.map((v,i) => ({type:'wayPoint',point:[v.latitude,v.longitude]}));
			ymaps.route(coords, {mapStateAutoApply:true,reverseGeocoding:true}).then((route) => this.map.geoObjects.add(route));
		})
	}
	areaNameGet = (id) => {
		const area = this.state.areas.find(f => f.id === id);
		return area ? area.name : '—';
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/orders'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									Просмотр заказа
								</span>
							</h4>
							{this.state.notfound ? <NotFound /> :
							<>
								<div className={styles.formcontainer}>
									<form>
										<div className={styles.orderviewcontainer}>
											<div className={styles.clientorderheader}>
												<div className={styles.cell}>
													<label>
														<span>Номер заказа</span>
														<div>{this.state.data.id}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Статус</span>
														<div>{orderStatusName[this.state.data.status]}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Заказ создан</span>
														<div>{orderTypeName[this.state.data.type]}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Оплачен</span>
														<div>{this.state.data.isPaid ? 'Да' : 'Нет'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Платежный шлюз</span>
														<div>{transactionPaymentSourceName[this.state.data.transactionPaymentSource]} {this.state.data.isQuickPay ? '(быстрый платеж)' : null}</div>
													</label>
												</div>
											</div>
											<div className={styles.clientorderheader}>
												<div className={styles.cell}>
													<label>
														<span>Клиент</span>
														<div>{this.state.data.clientName ? <Link to={`/client/${this.state.data.clientId}`}>{this.state.data.clientName}</Link> : this.state.data.clientName}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Телефон</span>
														<div>{phoneFormatter(this.state.data.phone)}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Адрес доставки</span>
														<div className={styles.addressblock}>
															<div>{this.state.data.address}</div>
															<div onClick={() => this.mapShow()}><img src={require('../../Images/icons/map.svg')} className={styles.marker} alt="" /></div>
														</div>
													</label>
												</div>
											</div>
											<div className={styles.clientorderheader}>
												<div className={styles.cell}>
													<label>
														<span>Заказ на дату</span>
														<div>{this.state.data.date ? <b>{dateGet(this.state.data.date, {yearHide:true,showMonthFullName:true,showTime:true})}</b> : '—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Дата создания</span>
														<div>{dateGet(this.state.data.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Источник</span>
														<div>{targetTypeName[this.state.data.target]}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Оценка качества</span>
														<div>{this.state.data.rating||'нет оценки'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Отзыв</span>
														<div>{this.state.data.feedback||'—'}</div>
													</label>
												</div>
											</div>
											<div className={styles.clientorderheader}>
												{this.state.data.contactPhone ?
													<div className={styles.cell}>
														<label>
															<span>Контактный телефон</span>
															<div className={styles.phone}>{phoneFormatter(this.state.data.contactPhone)}</div>
														</label>
													</div> : null}
												<div className={styles.cell}>
													<label>
														<span>Комментарий</span>
														<div>{this.state.data.comment||'—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Комментарий к адресу</span>
														<div>{this.state.data.commentAddress||'—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Комментарий к системы</span>
														<div>{this.state.data.commentSystem||'—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Терминал</span>
														<div>{this.state.data.isTerminal ? 'Да' : 'Нет'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Оплата по QR</span>
														<div>{this.state.data.isQR ? 'Да' : 'Нет'}</div>
													</label>
												</div>
											</div>
											<div className={styles.clientorderheader}>
												<div className={styles.cell}>
													<label>
														<span>Смена</span>
														<div>{this.state.data.dayShiftId ? `№${this.state.data.dayShiftId}` : '—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Точка</span>
														<div>{this.areaNameGet(this.state.data.areaId)}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Промокод</span>
														<div>{this.state.data.promoCode||'—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Сдача</span>
														<div>{this.state.data.surrender||'—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Приборы</span>
														<div>{this.state.data.cutlery||'—'}</div>
													</label>
												</div>
											</div>
											<div><b>Список блюд</b></div>
											<div className={styles.clientorderheader}>
												<div className={styles.summaryview} dangerouslySetInnerHTML={{__html:rn2br(this.state.data.products)}} />
											</div>
											<div><b>Скидки</b></div>
											<div className={styles.clientorderheader}>
												<div className={styles.summaryview}>
													{this.state.data.manualDiscount || this.state.data.manualDiscountPercent ?
														<div className={styles.summaryblock}>
															<div>Сумма заказа: <b>{this.state.data.price} ₽</b></div>
															<div>Сумма со скидкой: {this.state.data.priceDiscount} ₽</div>
															<div>Сумма к оплате: <b>{(this.state.data.priceDiscount ? this.state.data.priceDiscount : this.state.data.price) + (this.state.data.deliveryIsFree ? 0 : this.state.data.deliveryCost) - this.state.data.useBalance} ₽</b></div>
														</div>
													:
														<div className={styles.summaryblock}>
															<div>Сумма заказа: <b>{this.state.data.price} ₽</b></div>
															<div>Сумма со скидкой: {this.state.data.priceDiscount ? <>{this.state.data.priceDiscount} ₽</> : '—'}</div>
															<div>Сумма к оплате: <b>{(this.state.data.priceDiscount ? this.state.data.priceDiscount : this.state.data.price) + (this.state.data.deliveryIsFree ? 0 : this.state.data.deliveryCost) - this.state.data.useBalance} ₽</b></div>
														</div>
													}
													<div className={styles.summaryblock}>
														<div>Скидка: {this.state.data.discount ? `${this.state.data.discount} ₽` : '—'}</div>
														{this.state.data.useBalance ? <div>Использовано бонусов: {this.state.data.useBalance} ₽</div> : null}
														{this.state.data.discountName && <div>Применена скидка: {this.state.data.discountName}</div>}
													</div>
													<div className={styles.summaryblock}>
														Доставка: {this.state.data.deliveryIsFree ? '0': this.state.data.deliveryCost} ₽
														<span className={styles.hint}>бесплатная доставка от {this.state.deliveryFreeAmount} ₽</span>
													</div>
												</div>
											</div>
											<div><b>Смена статусов</b></div>
											<div className={styles.clientorderheader}>
												<ul className={styles.statuseslist}>
													{this.state.statuses.map((v,i) => <li key={i}>
														<div>{dateGet(v.dateCreate, {showTime:true,showSeconds:true})}</div>
														<div>{orderStatusName[v.status]}</div>
														<div><Link to={`/user/${v.userId}`}>{v.userName}</Link></div>
														<div><i>{v.description}</i></div>
													</li>)}
												</ul>
											</div>
											<div><b>Курьер</b></div>
											<div className={styles.clientorderheader}>
											{this.state.courier ?
												<>
													<div className={styles.cell}>
														<label>
															<span>Курьер</span>
															<div>{this.state.courier.userName}</div>
														</label>
													</div>
													<div className={styles.cell}>
														<label>
															<span>Телефон</span>
															<div className={styles.phone}>{phoneFormatter(this.state.data.courierPhone)}</div>
														</label>
													</div>
												</>
												:
													<div className={styles.cell}>
														<label>
															<span>Курьер</span>
															<div>не выбран</div>
														</label>
													</div>
											}
												<div className={styles.cell}>
													<label>
														<span>Терминал для оплаты</span>
														<div>{this.state.data.isTerminal ? 'Да' : 'Нет'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Оплата по QR</span>
														<div>{this.state.data.isQR ? 'Да' : 'Нет'}</div>
													</label>
												</div>
											</div>

											{this.state.courier ?
												<>
													<div><b>Маршрут курьера</b></div>
													<YMaps query={{apikey:MAP.keyYandex}}>
														<Map
															instanceRef={(map) => this.map = map}
															onLoad={(ymaps) => this.onMapLoad(ymaps)}
															modules={['route']}
															state={{center:[this.state.data.latitude,this.state.data.longitude],zoom:16}}
															width={'auto'} height={400}>
																<ZoomControl />
															</Map>
													</YMaps>
												</> : null}
										</div>
									</form>
								</div>
								<Modal visible={this.state.modalmapshow} width={'80%'} height={'600'} effect={'fadeInUp'}>
									<div className={styles.map}>
										<YMaps query={{apikey:MAP.keyYandex}}>
											<Map
												state={{center:[this.state.data.latitude,this.state.data.longitude],zoom:16}}
												onLoad={() => this.setState({maploaded:true})}
												width={'auto'} height={'100%'}>
													<ZoomControl />
												</Map>
												{this.state.maploaded && <div className={styles.centerMarker}></div>}
										</YMaps>
									</div>
									<Close close={this.modalHide.bind(this)} />
								</Modal>
							</>}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Order);