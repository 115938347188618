import React,{Component} from 'react';
import {withRouter} from 'react-router';

// plug-ins
import classNames from 'classnames';

// components
import Loading from '../../Loading';
import Error from '../../Error';

// models
import {Orders} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';

// globals
import {orderStatus,timers,orderType,orderStatusName} from '../../../Globals/Constants';

// styles
import styles from './DashboardMonitor.module.css';
const cx = classNames.bind(styles);

class DashboardMonitor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			orders:{},
			activearea:null,
			modalshow:false,
			iserror:false,
			loading:true
		};
	}
	timerId = null;
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.dataGet();
	}
	componentWillUnmount = () => {
		clearInterval(this.timerId);
		this.timerId = null;
	}
	dataGet = async () => {
		this.setState({loading:true,iserror:false});
		const user = ls('user');
		try {
			this.setState({user,loading:true}, () => {
				const activearea = ls('activearea');
				this.setState({activearea});
			});
			this.ordersUpdate();
			this.timerId = setInterval(this.ordersUpdate, timers.NEW_ORDERS_GET_OPERATOR);
		} catch (ex) {
			this.setState({loading:false,iserror:true});
		}
	}
	ordersUpdate = async () => {
		const statuses = [
			orderStatus.OPERATOR_WAIT,
			orderStatus.COOK_WAIT,
			orderStatus.COOK_ACCEPTED,
			orderStatus.COOK_DONE,
			orderStatus.COLLECTOR_WAIT,
			orderStatus.COLLECTOR_ACCEPTED,
			orderStatus.COLLECTOR_DONE,
			orderStatus.LOGIST_WAIT,
			orderStatus.LOGIST_ACCEPTED,
			orderStatus.LOGIST_DONE
		];
		const {activearea} = this.state, orders = await Orders.getByStatuses([...statuses,orderStatus.FINISH]);
		if (orders) {
			const o = orders.data.filter(f => f.areaId === activearea.id && (f.isPickup === 1 || f.type === orderType.OPERATOR_HALL));
			const prepare = o.filter(f => statuses.includes(f.status));
			const done = o.filter(f => f.status === orderStatus.FINISH);
			this.setState({orders:{prepare,done},loading:false});
		}
		else this.setState({loading:false,iserror:true});
	}
	modalHide = () => this.setState({modalshow:false});
	render() {
		if (this.state.loading) return <div className={styles.main}><Loading className={styles.loading} /></div>;
		if (this.state.iserror) return <div className={styles.main}><Error refresh={this.dataGet} /></div>;
		return <div className={styles.container}>
			<div className={styles.areaactive}>
				<button>
					<img src={require('../../../Images/icons/marker_black.svg')}/>
					{this.state.activearea.name}
				</button>
			</div>
			<div className={styles.main}>
				<div className={styles.block}>
					<h3>Готовятся</h3>
					<div className={styles.inner}>
						{this.state.orders.prepare.map((v,i) => <div key={i} className={cx(styles.item,styles.prepare)}>{v.id}<span>{orderStatusName[v.status]}</span></div>)}
					</div>
				</div>
				<div className={styles.block}>
					<h3>Готовы</h3>
					<div className={styles.inner}>
						{this.state.orders.done.map((v,i) => <div key={i} className={cx(styles.item,styles.done)}>{v.id}</div>)}
					</div>
				</div>
			</div>
		</div>
	}
}

export default withRouter(DashboardMonitor);