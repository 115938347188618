import React,{Component} from 'react';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import Loading from '../../Components/Loading';

// models
import {Areas,DayShifts,DayShiftCashReports} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {dateGet,moneyFormat,tsDay} from '../../Globals/Utils';

// styles
import styles from './StatisticCasher.module.css';

class StatisticCasherList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			data:null,
			areas:[],
			dayshiftsAll:[],
			dayshifts:[],
			date:null,
			areaId:0,
			dayShiftId:0,
			progress:10,
			loading:true,
			loadingdata:false
		};
	}
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		const areas = await Areas.getAllActive();
		const ds = await DayShifts.getAll();
		this.setState({user,areas:areas.data,dayshiftsAll:ds.data,loading:false});
	}
	handleArea = (e) => {
		const areaId = parseInt(e.target.value);
		const dayshifts = areaId === 0 ? [] : this.state.dayshiftsAll.filter(f => f.areaId === areaId);
		this.setState({areaId,dayShiftId:0,dayshifts:[],report:null}, () => this.setState({dayshifts}));
	}
	handleDayshift = (e) => {
		const t = e.target;
		const date = t.options[t.selectedIndex].getAttribute('date');
		this.setState({dayShiftId:parseInt(t.value),date,loadingdata:true}, () => this.dataGet());
	}
	dataGet = async () => {
		const {areaId,dayShiftId} = this.state;
		if (areaId === 0 || dayShiftId === 0) return;
		const d = await DayShiftCashReports.get(areaId, dayShiftId);
		this.setState({data:d.data[0],loadingdata:false});
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>Отчет по кассе</h4>
							<div className={styles.controls}>
								<select onChange={this.handleArea}>
									<option value="0">Выберите точку</option>
									{this.state.areas.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
								</select>
								<select onChange={this.handleDayshift}>
									<option value="0">Выберите смену</option>
									{this.state.dayshifts.map((v,i) => <option key={i} date={v.date} value={v.id}>{dateGet(v.dateStart+tsDay/2)} - №{v.id} ({dateGet(v.dateStart+tsDay/2, {weekDayOnly:true})})</option>)}
								</select>
							</div>
							<div className={styles.formcontainer}>
								{this.state.areaId === 0 || this.state.dayShiftId === 0 ? <p className={styles.empty}>Для просмотра отчета выберите точку и смену</p> :
									this.state.loadingdata ? <Loading className={styles.loading} /> :
										<div className={styles.list}>
											{this.state.data ?
													<ul>
														<li><div>Кассир</div><div className={styles.count}>{this.state.data.userName}</div></li>
														<li><div>Заказов за день</div><div className={styles.count}>{moneyFormat(this.state.data.cashOrders, false)} ₽</div></li>
														<li><div>Сумма оплат картой</div><div className={styles.count}>{moneyFormat(this.state.data.cashCard, false)} ₽</div></li>
														<li><div>Сумма от логиста</div><div className={styles.count}>{moneyFormat(this.state.data.cashLogist, false)} ₽</div></li>
														<li><div>Сумма размена</div><div className={styles.count}>{moneyFormat(this.state.data.cashExchange, false)} ₽</div></li>
														<li><div>Итого</div><div className={styles.count}>{moneyFormat(this.state.data.amount, false)} ₽</div></li>
													</ul>
												: <p>Нет данных</p> 
											}
										</div>
								}
							</div>
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default StatisticCasherList;