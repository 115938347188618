import React from 'react';

// modules
import { Settings } from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// styles
import styles from './Update.module.css';

export default () => {
	const reload = () => {
		Settings.getAll((data) => {
			ls('settings', data);
			ls('isVersionChange', null);
			window.location.reload();
		});
	}
	return (
			<div className={styles.container}>
				<div className={styles.inner}>
					<div>
						<h4>Необходимо обновить систему</h4>
						<p>Для корректной работы необходимо обновить систему! Сделайте это в удобное для вас время.</p>
					</div>
					<button type="button" onClick={reload}>Обновить</button>
				</div>
			</div>
	);
}