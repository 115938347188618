import Http from '../../Globals/Http';
import {requestOderType,comonStatus} from '../../Globals/Constants';

const getAll		= async ()						=> await Http.get('areas');
const getAllSync	= (callback)					=> Http.get('areas', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const get			= async (id)					=> await Http.get('areas', {conditions:[{k:'id',v:id}]});
const getSync		= (id, callback)				=> Http.get('areas', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const getAllActive	= async ()						=> await Http.get('areas', {conditions:[{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'id',isd:requestOderType.DESC}]});

const getByArea		= async (latitude, longitude)	=> await Http.request('areas', 'byAreaGet', {latitude,longitude});

const add			= (data, callback, error)		=> Http.post('areas', {data:data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update		= (id, data, callback, error)	=> Http.put('areas', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export {
	getAll,
	getAllSync,
	get,
	getSync,
	getAllActive,
	getByArea,
	add,
	update
}