import React,{Component} from 'react';
import {withRouter} from 'react-router';

// plug-ins
import Modal from 'react-awesome-modal';
import classNames from 'classnames';

// components
import Loading from '../../Loading';
import Error from '../../Error';
import Close from '../../Close';

// models
import {Orders,Events,OrderStatuses,OrderItems,Settings} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {dateNow,dateGet,quantityFormat} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {orderStatus,orderItemCollectorStatus,eventType,measureTypeName,timers} from '../../../Globals/Constants';

// styles
import styles from './DashboardCollector.module.css';
const cx = classNames.bind(styles);

class DashboardCollector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			order:null,
			orders:[],
			products:[],
			activearea:null,
			noLogist:false,
			modalshow:false,
			iserror:false,
			loading:true
		};
	}
	timerId = null;
	timerFinishId = null;
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.dataGet();
	}
	componentWillUnmount = () => {
		clearInterval(this.timerId);
		clearInterval(this.timerFinishId);
		this.timerId = null;
		this.timerFinishId = null;
	}
	dataGet = async () => {
		this.setState({loading:true,iserror:false});
		const user = ls('user');
		try {
			const settings = ls('settings');
			this.setState({user,
				noLogist:parseInt(Settings.getByKey('no-logist', settings).value) === 1,
				loading:true
			}, () => {
				const activearea = ls('activearea');
				this.setState({activearea});
			});
			this.ordersUpdate();
			this.timerId = setInterval(this.ordersUpdate, timers.ORDERS_GET);
			this.timerFinishId = setInterval(this.orderFinish, timers.ORDERS_FINISH);
		} catch (ex) {
			this.setState({loading:false,iserror:true});
		}
	}
	ordersUpdate = async () => {
		const {activearea} = this.state, orders = await Orders.getByStatuses([orderStatus.COLLECTOR_WAIT,orderStatus.COLLECTOR_ACCEPTED,orderStatus.COLLECTOR_DONE]);
		if (orders) {
			const settings = ls('settings'), now = dateNow(), timeDelay = parseInt(Settings.getByKey('new-order-logist', settings).value);
			const o = orders.data.filter(f => f.areaId === activearea.id);
			o.sort((a) => (a.dateCreate + timeDelay) < now ? -1 : 0);
			this.setState({orders:o,loading:false});
		}
		else this.setState({loading:false,iserror:true});
	}
	additionalStatus = (order) => {
		const settings = ls('settings'), now = dateNow(), timeDelay = parseInt(Settings.getByKey('new-order-logist', settings).value);
		return (order.dateCreate + timeDelay) < now && order.status === orderStatus.COLLECTOR_WAIT ? styles.alarm : (order.status === orderStatus.COLLECTOR_DONE ? styles.paid : null);
	}
	orderSelect = async (order) => {
		const products = await OrderItems.get(order.id);
		this.setState({order,products:products.data}, () => {
			this.orderAccept(order);
			this.modalShow()
		});
	}
	statusSet = (item) => {
		const {products} = this.state, data = {statusCollect:orderItemCollectorStatus.DONE};
		OrderItems.update(item.id, data);
		Events.add(this.state.user.id, Texts.order_items_collect.update, null, JSON.stringify(data), item.id, eventType.ORDER_ITEM);
		products.forEach((v,i) => v.statusCollect = v.id === item.id ? orderItemCollectorStatus.DONE : v.statusCollect);
		this.setState({products});
	}
	orderAccept = (order) => {
		if (order.status === orderStatus.COLLECTOR_WAIT) {
			const data = {status:orderStatus.COLLECTOR_ACCEPTED}, {user} = this.state;
			Orders.update(order.id, data, () => {
				Events.add(user.id, Texts.order.update, JSON.stringify({status:order.status}), JSON.stringify(data), order.id, eventType.ORDER);
				this.ordersUpdate();
			});
		}
	}
	orderDone = () => {
		const {order,user} = this.state;
		const data = {status:orderStatus.COLLECTOR_DONE};
		Orders.update(order.id, data, () => {
			Events.add(user.id, Texts.order.update, JSON.stringify({status:order.status}), JSON.stringify(data), order.id, eventType.ORDER);
			this.ordersUpdate();
		});
		const statuses = {
			orderId:order.id,
			userId:user.id,
			userName:user.name,
			status:data.status
		};
		OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
		this.modalHide();
	}
	orderFinish = () => {
		const {user} = this.state, orders = this.state.orders.filter(f => f.status === orderStatus.COLLECTOR_DONE),
			status = this.state.noLogist ? orderStatus.COURIER_WAIT : orderStatus.LOGIST_WAIT;
		const data = {status};
		orders.forEach((v) => {
			Orders.update(v.id, data, () => {
				Events.add(user.id, Texts.order.update, JSON.stringify({status:v.status}), JSON.stringify(v), v.id, eventType.ORDER);
				this.ordersUpdate();
			});
			const statuses = {
				orderId:v.id,
				userId:user.id,
				userName:user.name,
				status:data.status
			};
			OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
		});
	}
	modalShow = () => this.setState({modalshow:true});
	modalHide = () => this.setState({modalshow:false});
	render() {
		if (this.state.loading) return <div className={styles.main}><Loading className={styles.loading} /></div>;
		if (this.state.iserror) return <div className={styles.main}><Error refresh={this.dataGet} /></div>;
		return <div className={styles.container}>
			<div className={styles.areaactive}>
				<button>
					<img src={require('../../../Images/icons/marker_black.svg')} alt="" />
					{this.state.activearea.name}
				</button>
			</div>
			<div className={styles.main}>
				{this.state.orders.length === 0 ? <div className={styles.empty}>Пусто</div> :
					this.state.orders.map((v,i) => <div key={i} className={cx(styles.item,this.additionalStatus(v))} onClick={() => this.orderSelect(v)}>
							<div className={styles.inneritem}>
								<div className={styles.number}>Заказ №{v.id}</div>
								<div className={styles.block}>
									<span>{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
								</div>
								<div className={styles.products}>
									{v.products.split('\r\n').map((v,i) => <div key={i}>{v}</div>)}
								</div>
							</div>
					</div>)}
			</div>
			{this.state.order && <Modal visible={this.state.modalshow} width={'900'} height={'500'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Состав заказа №{this.state.order.id}</h4>
						<span>{dateGet(this.state.order.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
					</div>
					<div className={cx(styles.productslist,this.state.order.status===orderStatus.COLLECTOR_DONE?styles.nobutton:null)}>
						<div className={styles.item}>
							<div className={styles.name}><b>Приборы: {this.state.order.cutlery} шт.</b></div>
						</div>
						{this.state.products.map((v,i) => <div key={i} className={styles.item}>
							<div className={cx(styles.name,v.statusCollect === orderItemCollectorStatus.UNKNOWN ? null : styles.statusDone)}>{v.productName}</div>
							<div className={cx(styles.measure,v.statusCollect === orderItemCollectorStatus.UNKNOWN ? null : styles.statusDone)}>x {quantityFormat(v.quantity)}</div>
							<div className={cx(styles.measure,v.statusCollect === orderItemCollectorStatus.UNKNOWN ? null : styles.statusDone)}>{v.measure} {measureTypeName[v.measureType]}</div>
							<div className={styles.status}>
								{v.statusCollect === orderItemCollectorStatus.UNKNOWN ? <button onClick={() => this.statusSet(v)}>Готово</button> : <div>готово</div>}
							</div>
						</div>)}
					</div>
					{this.state.order.status !== orderStatus.COLLECTOR_DONE ?
						<div className={styles.done}>
							<button onClick={() => this.orderDone()}><img src={require('../../../Images/icons/mark.svg')} alt="Заказ готов" />Заказ готов</button>
						</div> : null}
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
		</div>
	}
}

export default withRouter(DashboardCollector);