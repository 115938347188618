import React from 'react';

// styles
import styles from './Error.module.css';

export default (props) => {
	const refresh = () => props.refresh ? props.refresh() : {};
	return (
			<div className={styles.container}>
					<img src={require('../../Images/icons/error.svg')} alt="Ошибка!" />
					<h4>{props.title || 'Ошибка на сервере'}</h4>
					<p>
						{props.message || 
							<>
								На сервере произошла непредвиденная ошибка.<br />
								Пожайлуста, подождите она вскоре будет исправлена.
							</>
						}
					</p>
					<p className={styles.refresh}>
						Попробуйте обновить страницу через некоторое время.
					</p>
					<button type="button" onClick={refresh}>Обновить</button>
			</div>
	);
}