import React,{Component} from 'react';
import {Redirect} from 'react-router-dom';

// components
import Menu from '../../Menu';
import MenuStore from '../../MenuStore';
import MenuLoyalty from '../../MenuLoyalty'

// helpers
import {ls} from '../../../Globals/Localstorage';

// globals
import {userType} from '../../../Globals/Constants';

// styles
import styles from './MainTemplate.module.css';

class MainTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			redirect:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			this.setState({redirect:true});
			return;
		}
		this.setState({user});
	}
	render() {
		if (this.state.redirect) return <Redirect to='/error404' />;
		if (this.state.user === null) return null;
		return <div className={styles.container}>
			<div className={styles.side}>
				{this.props.isStore ? <MenuStore isAdmin={this.state.user.type === userType.ADMIN} /> :
					(this.props.isLoyalty ? <MenuLoyalty isAdmin={this.state.user.type === userType.ADMIN} /> : <Menu isAdmin={this.state.user.type === userType.ADMIN} />)}
			</div>
			<div className={styles.main}>
				{this.props.children}
			</div>
		</div>
	}
}

export default MainTemplate;