import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../../Components/DataGrid';
import NotFound from '../../../Components/NotFound';

// models
import {FortuneWheelPrizes} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';

class FortunePrizesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			user:null,
			progress:10,
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		FortuneWheelPrizes.getAll(async (data) => this.setState({dataShow:this.dataSet(data),data,dataFull:data,user,loading:false}));
	}
	dataSet = (data) => dgDataPrepare(data, 'fortuneWheelPrizes', ['id','productName','discount','freeDelivery','offline','isEmpty']);
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isLoyalty={true}>
						<DataGrid
							title={'Колесо удачи. Призы'}
							keys={['ID','Товар','Процент скидки','Бесплатная доставка','Оффлайн приз','Проигрыш']}
							link={'wofprize'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default FortunePrizesList;