import React from 'react';

// styles
import styles from './Close.module.css';

const Close = (props) => {
	return (
		<div className={styles.close} onClick={() => props.close()}>
			<img src={require('../../Images/icons/close.svg')} alt="закрыть окно" />
		</div>
	);
}
export default Close;