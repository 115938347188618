import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const getAll	= (callback)			=> Http.get('refcalls', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getPage	= (limits, callback)	=> Http.get('refcalls', {orders:[{k:'id',isd:requestOderType.DESC}],limits}).then((res) => callback(res.data));
const get		= (id, callback)		=> Http.get('refcalls', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const getByClient	= (clientId, callback)		=> Http.get('refcalls', {conditions:[{k:'clientId',v:clientId}]}).then((res) => callback(res.data));

const add		= (data, callback, error)	=> Http.post('refcalls', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update		= (id, data, callback, error)	=> Http.put('refcalls', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export {
	getAll,
	getPage,
	get,
	getByClient,
	add,
	update
}