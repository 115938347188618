import Http from '../../Globals/Http';

const getAll	= (callback)		=> Http.get('clientscriptproducts').then((res) => callback(res.data));
const getPublic	= (callback)		=> Http.get('clientscriptproducts', {conditions:[{k:'isSingle',v:0}]}).then((res) => callback(res.data));
const get		= (id, callback)	=> Http.get('clientscriptproducts', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const add		= (data, callback, error)		=> Http.post('clientscriptproducts', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update	= (id, data, callback, error)	=> Http.put('clientscriptproducts', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export {
	getAll,
	getPublic,
	get,
	add,
	update
}