import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';
import SaveStatus from '../../../Components/SaveStatus';

// models
import {FortuneWheelPrices,Events,Logs} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {empty} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {eventType} from '../../../Globals/Constants';

// styles
import styles from './FortunePrice.module.css';

class FortunePrice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	dataGet = () => FortuneWheelPrices.get(this.state.id, async (data) => this.setState({data,dataOld:{...data},notfound:empty(data),loading:false}));
	handleForm = (e) => {
		const {data,products} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		const data = {
			price:this.state.data.price
		};
		FortuneWheelPrices.update(id, data, () => {
			this.stop(() => {
				this.setState({saveStatus:Texts.fortunewheelprice.update,iserror:false});
				Events.add(this.state.user.id, Texts.fortunewheelprice.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.FORTUNE_WHEEL_PRICE);
			});
		}, (err) => {
			this.stop(() => {
				this.setState({saveStatus:Texts.errors.internalError,iserror:true});
				Logs.add(err);
			});
		});
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isLoyalty={true}>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/loyalty/wofprices'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
									{'Редактирование цены'}
								</span>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<div className={styles.formcontainer}>
									<form onSubmit={this.save}>
										<div className={styles.cell}>
											<label>
												<span>Цена вращения, ₽</span>
												<input type="number" name="price" min={0} max={999} value={this.state.data.price} placeholder="5" required onChange={this.handleForm} />
											</label>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</form>
								</div>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(FortunePrice);