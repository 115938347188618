import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../../Components/DataGrid';
import NotFound from '../../../Components/NotFound';

// models
import {PromoCodes} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';

class PromoCodesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			user:null,
			progress:10,
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		PromoCodes.getAll((data) => this.setState({dataShow:this.dataSet(data),data,dataFull:data,user,loading:false}));
	}
	dataSet = (data) => dgDataPrepare(data, 'promoCodes', ['id','name','discount','discountPercent','dateTill','dateCreate']);
	dataSearch = (data, search) => data.filter(f => f.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || f.code.toLowerCase().indexOf(search.toLowerCase()) !== -1);
	handleSearch = (e) => {
		const search = e.target.value;
		let data = this.state.dataFull;
		if (search.length > 1) data = this.dataSearch(data, search);
		this.setState({dataShow:this.dataSet(data),data});
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isLoyalty={true}>
						<DataGrid
							title={'Промокоды'}
							keys={['ID','Назвние','Скидка, ₽','Процент скидки','Дата окончания','Дата создания']}
							link={'promocode'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							controlPanel={<>
								<div>
									<input type="search" placeholder="Поиск" onChange={this.handleSearch} />
								</div>
							</>}
							controlAdd={<Link to={'/loyalty/promocode'}>Добавить</Link>}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default PromoCodesList;