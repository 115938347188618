import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import classNames from 'classnames';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';

// models
import {Feedbacks,Events,Logs} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty,dateGet,dateNow} from '../../Globals/Utils';

// texts
import Texts from '../../Globals/Texts';

// globals
import {eventType} from '../../Globals/Constants';

// styles
import styles from './Feedback.module.css';
const cx = classNames.bind(styles);

class Feedback extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			feedbacks:[],
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	dataGet = () => {
		if (this.state.id !== 0) {
			Feedbacks.get(this.state.id, (data) => {
				if (data.clientId !== 0) Feedbacks.getByClient(data.clientId, (feedbacks) => this.setState({feedbacks,notfound:false,loading:false}));
				else this.setState({data,notfound:empty(data),loading:false});
			});
		}
	}
	handleForm = (e) => this.setState({message:e.target.value});
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {message,user,feedbacks} = this.state;
		const f = feedbacks.find(f => !f.isAdmin)
		if (f === null) {
			this.stop();
			return;
		}
		const data = {
			message,
			clientId:f.clientId,
			contact:user.phone,
			name:user.name,
			isAdmin:true
		};
		Feedbacks.add(data, (res) => {
			this.stop(() => {
				data.id = res.data.id;
				data.dateCreate = dateNow();
				feedbacks.push(data);
				this.setState({saveStatus:Texts.feedback.add,iserror:false,feedbacks});
				Events.add(this.state.user.id, Texts.feedback.add, null, JSON.stringify(data), data.id, eventType.FEEDBACK);
			});
		 }, (err) => {
			this.stop(() => {
				this.setState({saveStatus:Texts.errors.internalError,iserror:true});
				Logs.add(err);
			});
		 });
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/feedbacks'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									Просмотр обращения
								</span>
							</h4>
							{this.state.notfound ? <NotFound /> :
								this.state.data !== null ?
									<div className={styles.formcontainer}>
										<form>
											<div className={styles.onelinefull}>
												<div className={styles.cell}>
													<label>
														<span>ID</span>
														<div>{this.state.data.id}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Дата</span>
														<div>{dateGet(this.state.data.dateCreate, {showTime:true,showSeconds:true})}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Клиент</span>
														<div>{this.state.data.name}</div>
													</label>
												</div>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Контактные данные</span>
													<div>{this.state.data.contact}</div>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Сообщение</span>
													<div>{this.state.data.message}</div>
												</label>
											</div>
										</form>
									</div>
								:
									<form onSubmit={this.save} className={styles.form}>
										<div className={styles.formcontainer}>
											<div className={styles.cell}>
												<label>
													<span>Сообщение</span>
													<textarea name="message" placeholder="Напишите сообщение" onChange={this.handleForm} required></textarea>
												</label>
											</div>
											<div className={styles.messages}>
												{this.state.feedbacks.map((v,i) => <div key={i} className={cx(styles.message,v.isAdmin?styles.messagesupport:null)}>
													<div className={styles.date}>{dateGet(v.dateCreate)}{v.isAdmin?' (ответ от поддержки)':null}</div>
													{v.message}
												</div>)}
											</div>
											<div className={styles.buttons}>
												{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
												{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
											</div>
										</div>
									</form>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Feedback);