import React,{Component} from 'react';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';

// models
import {} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';

// styles
import styles from './Loyalty.module.css';

class Loyalty extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			data:[],
			progress:10,
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	dataGet = async () => {
		const data = [];
		this.setState({data,loading:false});
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<>
						<MainTemplate isLoyalty={true}>
							<div className={styles.container}>
								<h4>Сигналы</h4>
								<div className={styles.formcontainer}>
								</div>
							</div>
						</MainTemplate>
					</>
				}
			</>
		);
	}
}

export default withRouter(Loyalty);