import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get = (id, callback) => Http.get('orderstatuses', {conditions:[{k:'orderId',v:id}],orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));

const add = (data, callback, error) => Http.post('orderstatuses', {data:data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update = (id, data, callback, error) => Http.put('orderstatuses', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export {
	get,
	add,
	update
}