import Http from '../../Globals/Http';
import {requestOderType,requestConcatinationType} from '../../Globals/Constants';

const login			= async (phone, password)	=> await Http.request('users', 'login', {conditions:[{k:'phone',v:phone},{k:'password',v:password}]});

const getAll		= (callback)		=> Http.get('users', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getAllAsync	= async ()			=> await Http.get('users', {orders:[{k:'id',isd:requestOderType.DESC}]});
const get			= (id, callback)	=> Http.get('users', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const getByType		= async (types)		=> await Http.get('users', {conditions:types.map((v) => ({k:'type',v:v,con:requestConcatinationType.OR})),orders:[{k:'id',isd:requestOderType.DESC}]});

const add			= (data, callback, error)		=> Http.post('users', {data:data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update		= (id, data, callback, error)	=> Http.put('users', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

const areaClear		= (id, callback)	=> Http.request('users', 'areaClear', {id}).then((res) => callback(res.data));

export {
	login,
	getAll,
	getAllAsync,
	get,
	getByType,
	add,
	update,
	areaClear
}