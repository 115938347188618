import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';

// models
import {Users,UserRoles,Roles,Events,Logs,Sections,Areas} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty,phoneNormalize,dateNow} from '../../Globals/Utils';
import errorsParse from '../../Globals/Errors';

// texts
import Texts from '../../Globals/Texts';

// globals
import {userStatusName,userStatus,userType,userTypeName,eventType,errorType} from '../../Globals/Constants';

// styles
import styles from './User.module.css';
const cx = classNames.bind(styles);

class User extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			roles:[],
			userRoles:[],
			userRolesOld:[],
			areas:[],
			sections:[],
			sectionsselect:[],
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		Roles.get((data) => {
			this.setState({roles:data});
			Sections.getAll((data) => {
				this.setState({sections:data});
				Areas.getAllSync((data) => {
					this.setState({areas:data});
					if (this.state.id !== 0) {
						UserRoles.get(this.state.id, (data) => {
							this.setState({userRoles:data,userRolesOld:{...data}});
							Users.get(this.state.id, (data) => {
								this.setState({data,dataOld:{...data},notfound:empty(data),loading:false});
								const sectionsselect = data.sections ? data.sections.split(',').map(Number) : [];
								this.setState({sectionsselect});
							});
						});
					} else this.dataInit();
				});
			});
		});
	}
	dataInit = () => this.setState({data:{name:'',phone:'',type:userType.OPERATOR,status:userStatus.ACTIVE,password:'',description:''},id:0,userRoles:[],userRolesOld:[],loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id,roles} = this.state;
		const data = {
			name:this.state.data.name,
			phone:phoneNormalize(this.state.data.phone),
			status:this.state.data.status,
			type:this.state.data.type,
			sections:this.state.sectionsselect.join(','),
		}
		if (this.state.data.areaId) data.areaId = this.state.data.areaId;
		if (!empty(this.state.data.description)) data.description = this.state.data.description;
		if (!empty(this.state.data.password)) data.password = this.state.data.password;
		if (data.status === userStatus.ACTIVE) data.description = '';
		if (id === 0) {
			Users.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.users.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.users.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.USER);
					if (parseInt(data.type) === userType.COOK) {
						const role = roles.find(f => f.code === 'dashboard');
						UserRoles.add({userId:id,roleId:role.id});
					}
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicatePhone:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			data.dateModify = dateNow();
			Users.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.users.update,iserror:false});
					Events.add(this.state.user.id, Texts.users.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.USER);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicatePhone:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	inRole = (roleId) => this.state.userRoles.filter(f => f.roleId === roleId).length !== 0;
	sectionCheck = (id) => this.state.sectionsselect.includes(id)
	sectionSet = (id) => {
		const {sectionsselect} = this.state;
		if (sectionsselect.includes(id)) this.setState({sectionsselect:sectionsselect.filter(f => f !== id)});
		else {
			sectionsselect.push(id);
			this.setState({sectionsselect});
		}
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/users'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить пользователя' : 'Редактирование пользователя'}
								</span>
								<Link to={'/user'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<form onSubmit={this.save}>
									<div className={cx(styles.formcontainer,styles.formcontainerfirst)}>
										<div className={styles.cell}>
											<label>
												<span>Имя</span>
												<input type="text" name="name" value={this.state.data.name} placeholder="Имя" onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Телефон</span>
													<InputMask type="text" name="phone" mask="+7 (999) 999-99-99" maskChar={null} placeholder="Телефон" value={this.state.data.phone} onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Пароль</span>
													<input type="password" name="password" value={this.state.data.password} placeholder="Пароль" onChange={this.handleForm} required={this.state.id===0} />
												</label>
											</div>
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Тип пользователя</span>
													<select name="type" onChange={this.handleForm} defaultValue={parseInt(this.state.data.type)}>
														{userTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Статус</span>
													<select name="status" onChange={this.handleForm} defaultValue={parseInt(this.state.data.status)}>
														{userStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</label>
											</div>
										</div>
										{parseInt(this.state.data.type) === userType.COOK ?
											<div className={styles.cell}>
												<label>
													<span>Секции</span>
													<ul className={styles.cookSection}>
														{this.state.sections.map((v,i) => <li key={i} className={this.sectionCheck(v.id) ? styles.selected : null} onClick={() => this.sectionSet(v.id)}>{v.name}</li>)}
													</ul>
												</label>
											</div>

										: null}
										{parseInt(this.state.data.type) === userType.COOK ?
											<div className={styles.cell}>
												<label>
													<span>Секции</span>
													<select name="areaId" onChange={this.handleForm} defaultValue={parseInt(this.state.data.areaId)}>
														{this.state.areas.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
													</select>
												</label>
											</div>
										: null}
										{parseInt(this.state.data.type) === userType.COURIER ?
											<div className={styles.cell}>
												<label>
													<span>Точка</span>
													<select name="areaId" onChange={this.handleForm} defaultValue={parseInt(this.state.data.areaId)}>
														{this.state.areas.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
													</select>
												</label>
											</div>
										: null}
										{parseInt(this.state.data.status) !== userStatus.ACTIVE ?
											<>
												<div className={styles.notice}>
													<span>Внимание! Вы выбрали статус пользователя <b>{userStatusName[this.state.data.status]}</b>. Необходимо указать причину смены статуса</span>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Описание блокировки</span>
														<textarea name="description" placeholder="Опишите причину блокировки оператора" className={styles.desc} value={this.state.data.description} onChange={this.handleForm} required></textarea>
													</label>
												</div>
											</> : null}
										{parseInt(this.state.data.type) !== userType.OPERATOR ?
											<div className={styles.buttons}>
												{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
												{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
											</div> : null}
										</div>
										{parseInt(this.state.data.type) === userType.OPERATOR ?
											<>
												<div className={styles.formcontainer}>
													<h4 className={styles.title}>Роли</h4>
													<ul>
														{this.state.roles.map((v,i) => <li key={i}><label><input type="checkbox" name="userRole" defaultChecked={this.inRole(v.id) ? 'checked' : null} /> {v.name}</label></li>)}
													</ul>
													<div className={styles.buttons}>
														{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
														{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
													</div>
												</div>
											</> : null}
								</form>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(User);