import React,{Component} from 'react';

// styles
import styles from './SaveStatus.module.css';

export default class SaveStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message:props.message,
			isError:props.isError,
			delay:props.delay||2400
		};
	}
	timer = null;
	componentWillReceiveProps = (p) => this.setState({message:p.message,type:p.isError}, () => this.show());
	componentDidMount = () => this.show();
	show = () => this.timer = setTimeout(() => this.setState({message:null}, () => this.hide()), this.state.delay);
	hide = () => {
		clearTimeout(this.timer);
		if (this.props.onfinish) this.props.onfinish();
	}
	render() {
		return this.state.message === null ? null :
			<div className={this.state.isError ? styles.error : styles.success}>
				<span dangerouslySetInnerHTML={{__html:this.state.message}} />
			</div>;
	}
}