import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import classNames from 'classnames';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';
import SaveStatus from '../../../Components/SaveStatus';
import Alert,{alertShow} from '../../../Components/Alert';

// models
import {ClientRankTypes,Events,Logs,Competitions,CompetitionClients} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {dateGet,empty,phoneFormatter} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {comonStatus,comonStatusName,competitionTypeName,eventType} from '../../../Globals/Constants';

// styles
import styles from './Competition.module.css';
const cx = classNames.bind(styles);

class Competition extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			rankTypes:[],
			clients:[],
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		ClientRankTypes.getAll((data) => {
			this.setState({rankTypes:data});
			if (this.state.id !== 0) {
				Competitions.get(this.state.id, (data) => this.setState({data,dataOld:{...data},notfound:empty(data),loading:false}));
				CompetitionClients.get(this.state.id, (clients) => this.setState({clients}));
			}
			else this.dataInit();
		});
	}
	dataInit = () => this.setState({data:{userId:0,name:'',type:'',discount:'',rankType:'',topUp:'',offline:'',status:comonStatus.ACTIVE},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		const {id} = this.state;
		const data = {
			userId:this.state.user.id,
			name:this.state.data.name,
			status:this.state.data.status,
			offline:this.state.data.offline,
		}
		if (this.state.data.discount) data.discount = parseInt(this.state.data.discount)
		if (this.state.data.rankType) data.rankType = parseInt(this.state.data.rankType)
		if (this.state.data.topUp) data.topUp = parseInt(this.state.data.topUp)
		if (this.state.data.offline) data.offline = this.state.data.offline
		if (!data.rankType && !data.topUp && !data.discount && !data.offline) {
			alertShow('<b>Ошибка!</b><br/>Вы не задали ни один из призов для соревнования!', true);
			return;
		}
		this.setState({issave:true});
		if (id === 0) {
			Competitions.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.competition.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.competition.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.COMPETITION);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			Competitions.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.competition.update,iserror:false});
					Events.add(this.state.user.id, Texts.competition.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.COMPETITION);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isLoyalty={true}>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/loyalty/competitions'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить соревнование' : 'Редактирование соревнование'}
								</span>
								<Link to={'/loyalty/competition'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<div className={styles.formcontainerouter}>
									<form onSubmit={this.save}>
										<div className={styles.formcontainer}>
											<div className={styles.cell}>
												<span className={styles.hint}>Опредедение победителей производится в автоматическом режиме какждый месяц 1-го числа</span>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Название</span>
													<input type="text" name="name" value={this.state.data.name} placeholder="Название" className={styles.double} onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Тип</span>
													<select name="type" onChange={this.handleForm} defaultValue={parseInt(this.state.data.type)} required>
														<option value=""></option>
														{competitionTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</label>
											</div>
											<div className={styles.conditions}>
												<b>Приз</b>
												<div className={styles.onelinetop}>
													<div className={styles.cell}>
														<label>
															<span>Процент скидки</span>
															<input type="number" name="discount" min={0} max={99} value={this.state.data.discount} placeholder="5" onChange={this.handleForm} />
														</label>
													</div>
													<div className={styles.cell}>
														<label>
															<span>Присвоить статус</span>
															<select name="rankType" onChange={this.handleForm} defaultValue={parseInt(this.state.data.rankType)}>
																<option value=""></option>
																{this.state.rankTypes.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
															</select>
														</label>
													</div>
													<div className={styles.cell}>
														<label>
															<span>Пополнить счет, ₽</span>
															<input type="number" name="topUp" min={0} max={999} value={this.state.data.topUp} placeholder="100" onChange={this.handleForm} />
														</label>
													</div>
												</div>
											</div>
											<div className={styles.conditions}>
												<b>Оффлайн приз</b>
												<div className={styles.onelinetop}>
													<div className={styles.cell}>
														<label>
															<input type="text" className={styles.double} name="offline" value={this.state.data.offline} placeholder="Оффлайн" onChange={this.handleForm} />
														</label>
													</div>
												</div>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Статус</span>
													<select name="status" onChange={this.handleForm} defaultValue={parseInt(this.state.data.status)}>
														{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</label>
											</div>
										</div>
										{this.state.id !== 0 ?
											<>
												<div className={cx(styles.formcontainer,styles.formcontainerclients)}>
													<h4 className={styles.title}>Победители</h4>
													{this.state.clients.length === 0 ? <NotFound message={'Нет ни одного победителя соревнования'} /> :
														<table>
															<thead>
																<tr>
																	<th>№</th>
																	<th>ID</th>
																	<th>Дата</th>
																	<th>Имя</th>
																	<th>Телефон</th>
																</tr>
															</thead>
															<tbody>
																{this.state.clients.map((v,i) => <tr key={i}>
																	<td>{i+1}</td>
																	<td>{v.clientId}</td>
																	<td>{dateGet(v.dateCreate, {showTime:true,showSeconds:true})}</td>
																	<td><Link to={`/client/${v.clientId}`}>{v.clientName}</Link></td>
																	<td>{phoneFormatter(v.phone)}</td>
																</tr>)}
															</tbody>
														</table>
													}
												</div>
											</> : null}
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</form>
								</div>
							}
						</div>
						<Alert />
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Competition);