import React,{Component} from 'react';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';

// models
import {Stores} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';

// globals
import {measureTypeName} from '../../../Globals/Constants';

// styles
import styles from './StoreBalances.module.css';

class StoreBalances extends Component {
	constructor(props) {
		super(props);
		this.state = {
			balances:null,
			balance:null,
			storeId:-1,
			user:null,
			stores:[],
			progress:10,
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	dataGet = async () => {
		const stores = await Stores.getAllAsync();
		this.setState({stores:stores.data});
		const balances = await Stores.balancesGet();
		this.setState({balances:balances.data,loading:false});
	}
	handleSelect = (e) => {
		const storeId = parseInt(e.target.value);
		const balance = storeId === -1 ? null : this.state.balances.filter(f => f.storeId === storeId);
		this.setState({storeId,balance});
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<>
						<MainTemplate isStore={true}>
							<div className={styles.container}>
								<h4>Остатки на складах</h4>
								<div className={styles.formcontainer}>
									<div className={styles.cell}>
										<label>
											<span>Склад</span>
											<select onChange={this.handleSelect} required>
												<option value={-1}>Выберите склад</option>
												{this.state.stores.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
											</select>
										</label>
									</div>
									{this.state.balance ?
										<div className={styles.linepositions}>
											<h4>Позиции</h4>
											<ul className={styles.productslist}>
												{this.state.balance.map((v,i) => <li key={i}>
													<span className={styles.name}>{i+1}. {v.cardId ? <>{v.cardName} <b>(п/ф)</b></> : v.ingredientName}</span>
													<span className={styles.measure}>{v.quantity.toFixed(3)} {measureTypeName[v.measureType]}</span>
												</li>)}
											</ul>
										</div> : null}
								</div>
							</div>
						</MainTemplate>
					</>
				}
			</>
		);
	}
}

export default withRouter(StoreBalances);