import React,{Component} from 'react';

// plug-ins
import InputMask from 'react-input-mask';

// components
import Alert,{alertShow} from '../../Components/Alert';
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';
import classNames from 'classnames';

// models
import {Areas,Users,Settings,DayShifts} from '../../Models';

// helpers
import {empty,phoneNormalize,dateNow,todayGet} from '../../Globals/Utils';
import {ls} from '../../Globals/Localstorage';
import Printer from '../../Globals/Printer';

// globals
import {comonStatus,userType} from '../../Globals/Constants';

// styles
import styles from './Login.module.css';
const cx = classNames.bind(styles);

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			areaId:0,
			areas:null,
			activearea:null,
			phone:undefined,
			password:undefined,
			isPhoneError:undefined,
			isPasswordError:undefined,
			phoneFocus:true,
			passwordFocus:false
		};
	}
	componentDidMount = async () => {
		document.addEventListener('keydown', this.handleKeyDown);
		const areas = await Areas.getAllActive();
		const activearea = ls('activearea');
		this.setState({areas:areas.data,activearea,areaId:activearea?activearea.id:0});
	};
	componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);
	handleArea = (e) => this.setState({areaId:parseInt(e.target.value)});
	handleKeyDown = (e) => {
		if (e.keyCode >= 48 && e.keyCode <= 57) this.numberSet(parseInt(String.fromCharCode(e.keyCode)));
		if (e.keyCode === 8) this.numberSet();
	}
	phoneFocusSet = () => this.setState({phoneFocus:true,passwordFocus:false,isPasswordError:this.state.password===undefined?undefined:(this.state.password.length<6)});
	passwordFocusSet = () => this.setState({phoneFocus:false,passwordFocus:true,isPhoneError:this.state.phone===undefined?undefined:(this.state.phone.length<10)});
	numberSet = (num) => {
		if (num === '.') return;
		if (this.state.phoneFocus) {
			if (num === undefined) this.setState({phone:this.state.phone.slice(0,-1)});
			else {
				const phone = `${this.state.phone===undefined?'':this.state.phone}${num}`;
				if (phone.length < 11) this.setState({phone,isPhoneError:undefined});
				else this.setState({isPhoneError:false});
			}
		}
		if (this.state.passwordFocus) {
			if (num === undefined) this.setState({password:this.state.password.slice(0,-1)});
			else {
				const password = `${this.state.password===undefined?'':this.state.password}${num}`;
				if (password.length < 7) this.setState({password,isPasswordError:undefined});
				else this.setState({isPasswordError:false});
			}
		}
	}
	login = async (type) => {
		let {phone,password} = this.state, iserror = false;
		phone = phoneNormalize(phone);
		if (empty(phone)) {
			this.setState({isPhoneError:true});
			iserror = true;
		}
		if (empty(password)) {
			this.setState({isPasswordError:true});
			iserror = true;
		}
		if (!iserror) {
			const user = await Users.login(phone, password);
			if (user && user.data) {
				const {areaId} = this.state;
				if (![userType.DISPATCHER,userType.CASHIER,userType.ADMIN].includes(user.data.type) || (user.data.type === userType.ADMIN && type === 'open')) {
						if (!areaId) return alertShow('Вы не выбрали точку на которой нужно открыть смену', true);
				}
				if (type !== undefined) {
					if (![userType.OPERATOR,userType.ADMIN].includes(user.data.type)) return alertShow('У вас нет прав на совершение этого действия!', true);
					const ds = await DayShifts.getCurrent(areaId);
					const data = {status:comonStatus.ACTIVE,userId:user.data.id,areaId};
					if (type === 'open') {
						if (ds) return alertShow('Смена уже открыта!', true);
						DayShifts.add({...data,date:todayGet(),dateStart:dateNow()});
						return this.finish(user.data, 'dayopen');
					}
				}
				return this.finish(user.data, 'dashboard');
			}
			this.setState({isPhoneError:true,isPasswordError:true});
			alertShow('Пользователь не найден!', true);
		}
	}
	finish = async (user, link) => {
		const settings = await Settings.getAllAsync();
		ls('settings', settings.data);
		if (link === 'dayopen') Printer.shift(user.name, Settings.getByKey('inn', settings.data).value, true);
		const activearea = this.state.areas.find(f => f.id === this.state.areaId);
		ls('user', user);
		ls('activearea', activearea);
		window.location.href=`/${link}`;
	}
	render() {
		if (this.state.areas === null) return null;
		return (
			<SimpleTemplate>
				<div className={styles.container}>
					<div className={styles.logo}>
						<img src={require('../../Images/nobug.logo.svg')} alt="No Bug. Управление общепитом" />
						<div className={styles.areacontainer}>
							<h4>Текущая точка</h4>
							<select onChange={this.handleArea} value={this.state.areaId}>
								<option></option>
								{this.state.areas.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
							</select>
						</div>
					</div>
					<div className={styles.formcontainer}>
						<div className={styles.form}>
							<p>
								Войдите с помощью мобильного телефона и кода-пароля
								<span>Для регистрации обратитесь к администратору системы</span>
							</p>
							<div className={styles.column}>
								<InputMask type="text" mask="+7 (999) 999-99-99" onFocus={this.phoneFocusSet} maskChar={null} readOnly={true} value={this.state.phone} iserror={`${this.state.isPhoneError}`} issuccess={`${this.state.isPhoneError!==undefined&&!this.state.isPhoneError}`} className={cx(styles.input,this.state.phoneFocus?styles.focus:null)} />
								<span className={styles.placeholder}>Номер мобильного телефона</span>
							</div>
							<div className={styles.column}>
								<input type="password" maxLength={6} onFocus={this.passwordFocusSet} readOnly={true} value={this.state.password} iserror={`${this.state.isPasswordError}`} issuccess={`${this.state.isPasswordError!==undefined&&!this.state.isPasswordError}`} className={cx(styles.input,styles.password,this.state.passwordFocus?styles.focus:null)} />
								<span className={styles.placeholder}>Код-пароль</span>
							</div>
						</div>
						<div className={styles.keypad}>
							<div className={styles.num} onClick={() => this.numberSet(7)}>7</div>
							<div className={styles.num} onClick={() => this.numberSet(8)}>8</div>
							<div className={styles.num} onClick={() => this.numberSet(9)}>9</div>
							<div className={styles.num} onClick={() => this.numberSet(4)}>4</div>
							<div className={styles.num} onClick={() => this.numberSet(5)}>5</div>
							<div className={styles.num} onClick={() => this.numberSet(6)}>6</div>
							<div className={styles.num} onClick={() => this.numberSet(1)}>1</div>
							<div className={styles.num} onClick={() => this.numberSet(2)}>2</div>
							<div className={styles.num} onClick={() => this.numberSet(3)}>3</div>
							<div className={cx(styles.num,styles.numlast)} onClick={() => this.numberSet(0)}>0</div>
							<div className={cx(styles.num,styles.numlast)} onClick={() => this.numberSet('.')}>.</div>
							<div className={cx(styles.num,styles.numlast,styles.delete)} onClick={() => this.numberSet()}>Удл</div>
						</div>
					</div>
					<div className={styles.controls}>
						<button onClick={() => this.login('open')} className={cx(styles.btn,styles.open)}>Открыть смену</button>
						<button onClick={() => this.login()} className={cx(styles.btn,styles.enter)}>Вход</button>
					</div>
				</div>
				<Alert />
			</SimpleTemplate>
		);
	}
}

export default Login;