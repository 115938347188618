import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {ClientRankTypes, ClientRanks} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';

// globals
import {GENERAL,clientStatus,comonStatusName} from '../../Globals/Constants';

class ClientRanksList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			user:null,
			status:clientStatus.ACTIVE,
			progress:10,
			startpage:0,
			loading:true,
            clientRankTypes:[]
		};
	}
	pagesize = GENERAL.PAGESIZE.medium;
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
        ClientRankTypes.getAll((res) => {
            const ranksTypes = res.reduce((acc, item) => {
                acc[item.id] = item.name;
                return acc;
            }, {});
            this.setState({clientRankTypes: ranksTypes});
            ClientRanks.getPage([this.state.startpage,this.pagesize], (data) => this.setState({dataShow:this.dataSet(data),data,dataFull:data,user,loading:false}));
        });
		document.addEventListener('scroll', this.dataGet);
	}
	componentWillUnmount = () => this.dataStop();
	dataStop = () => document.removeEventListener('scroll', this.dataGet);
	dataGet = (e) => {
		const el = e.target.scrollingElement;
		if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
			let {startpage} = this.state;
			startpage += this.pagesize;
			ClientRanks.getPage([startpage,this.pagesize], (d) => {
				if (d.length < this.pagesize) this.dataStop();
				const data = [...this.state.dataFull,...d];
				this.setState({dataShow:this.dataSet(data),data,dataFull:data,startpage});
			});
		}
	}
	dataSet = (data) => dgDataPrepare(data, 'clientranks', ['id','clientId','rankType','status','dateCreate'], this.state.clientRankTypes);
	dataSearch = (data, search) => data.filter(f => f.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || f.phone.toLowerCase().indexOf(search.toLowerCase()) !== -1);
	dataStatus = (data, status) => data.filter(f => f.status === status);
	handleSearch = (e) => {
		const search = e.target.value;
		let data = this.state.dataFull;
		if (search.length > 1) data = this.dataSearch(data, search);
		if (this.state.status != null) data = this.dataStatus(data, this.state.status);
		this.setState({dataShow:this.dataSet(data),data});
	}
	handleStatus = (e) => {
		let status = e.target.value;
		let data = this.state.dataFull;
		if (empty(status)) status = null;
		if (status != null) data = this.dataStatus(data, parseInt(status));
		this.setState({dataShow:this.dataSet(data),status,data});
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<DataGrid
							title={'Клиентские статусы'} 
							keys={['ID','Клиент','Ранк','Статус','Дата присвоения']}
							link={'clientrank'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							controlPanel={<>
								<div>
									<input type="search" placeholder="Поиск" onChange={this.handleSearch}  />
								</div>
								<div>
									<span>Статус</span>
										<select onChange={this.handleStatus}>
										<option value=" ">все</option>
										{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
									</select>
								</div>
							</>}
							controlAdd={<Link to={'/client'}>Добавить</Link>}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default ClientRanksList;