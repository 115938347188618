import Http from '../../Globals/Http';

const get = (userId, callback) => Http.get('userroles', {conditions:[{k:'userId',v:userId}]}).then((res) => callback(res.data));

const add = (data, callback, error) => Http.post('userroles', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const remove = (userId, callback, error) => Http.put('userroles', {conditions:[{k:'userId',v:userId}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export {
	get,
	add,
	remove
}