import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import Confirm from '../Confirm';

// helpers
import {ls} from '../../Globals/Localstorage';

// styles
import styles from './MenuStore.module.css';

export default class MenuStore extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAdmin:props.isAdmin,
			alertShow:false
		};
	}
	logoff = (e) => {
		e.preventDefault();
		this.setState({alertShow:true});
	}
	confim = () => {
		const version = ls('version');
		ls();
		ls('version', version);
		this.close();
		window.location.href='/login';
	}
	close = () => this.setState({alertShow:false});
	render() {
		return (
			<div className={styles.sidemenuside}>
				<div className={styles.menucontainer}>
					{this.state.isAdmin ?
						<ul>
							<li><Link to={'/statistics'}><img src={require('../../Images/icons/arrow_left.svg')} alt="Назад" /> назад</Link></li>
						</ul> : null}
					<ul>
						<li><Link to={'/store/alerts'}>Сигналы</Link></li>
					</ul>
					<ul>
						<li><b>Склад</b></li>
						<li><Link to={'/store/balances'}>Остатки</Link></li>
					</ul>
					<ul>
						<li><b>Документооборот</b></li>
						<li><Link to={'/store/requests'}>Заявки</Link></li>
						<li><Link to={'/store/incomes'}>Поступления</Link></li>
					</ul>
					<ul>
						<li><b>Позиции</b></li>
						<li><Link to={'/store/ingredients'}>Продукты, ингредиенты</Link></li>
					</ul>
					<ul>
						<li><b>Карты</b></li>
						<li><Link to={'/store/cardssemifinish'}>Полуфабрикаты</Link></li>
						<li><Link to={'/store/cards'}>Технологические карты</Link></li>
					</ul>
					<ul>
						<li><b>Справочники</b></li>
						<li><Link to={'/store/stores'}>Склады</Link></li>
						<li><Link to={'/store/provisors'}>Поставщики</Link></li>
						<li><Link to={'/store/sections'}>Разделы</Link></li>
					</ul>
					{!this.state.isAdmin ?
						<div className={styles.logoff}>
							<Link to={'/'} onClick={this.logoff.bind(this)}>Выход из системы</Link>
						</div> : null}
				</div>
				{this.state.alertShow && <Confirm message={'Вы уверены что хотите выйти из системы администрирования?'} action={this.confim.bind(this)} close={this.close.bind(this)} />}
			</div>
		);
	};
}