import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';
import SaveStatus from '../../../Components/SaveStatus';

// models
import {Areas,Stores,Events,Logs} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {empty} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {eventType} from '../../../Globals/Constants';

// styles
import styles from './StoreStore.module.css';

class StoreStore extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			areas:[],
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = async () => {
		const areas = await Areas.getAllActive();
		this.setState({areas:areas.data});
		if (this.state.id !== 0) Stores.get(this.state.id, (data) => this.setState({data,dataOld:{...data},notfound:empty(data),loading:false}));
		else this.dataInit();
	}
	dataInit = () => this.setState({data:{name:'',description:''},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		const data = {
			name:this.state.data.name,
			description:this.state.data.description
		};
		if (this.state.data.areaId !== 0) data.areaId = this.state.data.areaId;
		if (id === 0) {
			Stores.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.stores.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.stores.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.STORE);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			Stores.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.stores.update,iserror:false});
					Events.add(this.state.user.id, Texts.stores.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.STORE);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isStore={true}>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/store/stores'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить склад' : 'Редактирование склада'}
								</span>
								<Link to={'/store/store'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<form onSubmit={this.save}>
									<div className={styles.formcontainer}>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Название</span>
													<input type="text" name="name" value={this.state.data.name} placeholder="Название" onChange={this.handleForm} required />
												</label>
											</div>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Описание</span>
												<input type="text" name="description" value={this.state.data.description} placeholder="Описание, дополнительная информация" className={styles.double} onChange={this.handleForm} />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Точка</span>
												<select name="areaId" onChange={this.handleForm} defaultValue={parseInt(this.state.data.areaId)}>
													<option value={0}>—</option>
													{this.state.areas.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
												</select>
											</label>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(StoreStore);