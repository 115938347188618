import React from 'react';

// styles
import styles from './SimpleTemplate.module.css';

const SimpleTemplate = (props) => {
	return (
		<div className={styles.container}>
			{props.children}
		</div>
	);
}

export default SimpleTemplate;