import Http from '../../Globals/Http';
import {requestOderType,comonStatus} from '../../Globals/Constants';

const get		= async (userid) => await Http.get('dispatchercalls', {conditions:[{k:'userId',v:userid},{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'id',isd:requestOderType.DESC}]});

const update	= async (id, data) => await Http.put('dispatchercalls', {data,conditions:[{k:'id',v:id}]});

export {
	get,
	update
}