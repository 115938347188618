import {Component} from 'react';
import {withRouter} from 'react-router';

// helpers
import {ls} from '../../Globals/Localstorage';

class Start extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	componentDidMount = () => this.props.history.push(ls('user') === null ? '/login' : '/dashboard');
	render() {
		return null;
	}
}

export default withRouter(Start);