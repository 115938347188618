import React, { Component } from 'react';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import DataGrid, { dgDataPrepare } from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import { Clients, Refuserrequests } from '../../Models';

// helpers
import { ls } from '../../Globals/Localstorage';

// globals
import { GENERAL } from '../../Globals/Constants';

class RefRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataFull: [],
      dataShow: [],
      clients: [],
      user: null,
      progress: 10,
      startpage: 0,
      loading: true,
    };
  }
  pagesize = GENERAL.PAGESIZE.medium;
  componentDidMount = () => {
    const user = ls('user');
    if (user === null) {
      window.location.href = '/error403';
      return;
    }
    Refuserrequests.getPage([this.state.startpage,this.pagesize], async (data) => {
			Clients.load(this, data, () => this.setState({dataShow:this.dataSet(data),data,dataFull:data,user,loading:false}));
		});
    document.addEventListener('scroll', this.dataGet);
  };
  componentWillUnmount = () => this.dataStop();
  dataStop = () => document.removeEventListener('scroll', this.dataGet);
  dataGet = (e) => {
    const el = e.target.scrollingElement;
    if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
      let { startpage } = this.state;
      startpage += this.pagesize;
      Refuserrequests.getPage([startpage, this.pagesize], (d) => {
        if (d.length < this.pagesize) this.dataStop();
        const data = [...this.state.dataFull, ...d];
        Clients.load(this, data, () => this.setState({dataShow:this.dataSet(data),data,dataFull:data,startpage}));
      });
    }
  };
  dataSet = (data) =>
    dgDataPrepare(
      data,
      'refuserrequests',
      [
        'id',
        'refUserId',
        'amount',
        'method',
        'walletNumber',
        'dateCreate',
        'status',
      ],
      this.state.clients
    );
  render() {
    return (
      <>
        <LoadingBar
          color="#f11946"
          progress={this.state.loading ? this.state.progress : 100}
        />
        {this.state.loading ? null : (
          <MainTemplate>
            <DataGrid
              title={'Обращения'}
              keys={[
                'ID Заявки',
                'ID Пользователя',
                'Сумма',
                'Способ зачисления',
                'Счет',
                'Дата',
                'Статус',
              ]}
              link={'refrequest'}
              data={this.state.dataShow}
              dataFull={this.state.dataFull}
              notFound={<NotFound />}
            />
          </MainTemplate>
        )}
      </>
    );
  }
}

export default RefRequests;
