import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import InputMask from 'react-input-mask';
import Modal from 'react-awesome-modal';
import {YMaps,Map,GeolocationControl,ZoomControl} from 'react-yandex-maps';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';
import Close from '../../Components/Close';
import Overlay from '../../Components/Overlay';

// models
import {Clients,ClientAddresses,Events,Logs,Orders,OrderItems,OrderStatuses,Maps,Products,Discounts,Settings,ClientRankTypes,ClientRanks,ClientBalances} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty,phoneNormalize,dateNow,dateGet,phoneFormatter,dateSegmentsGet,rn2br} from '../../Globals/Utils';
import errorsParse from '../../Globals/Errors';

// texts
import Texts from '../../Globals/Texts';

// globals
import {API,clientStatusName,clientStatus,eventType,errorType,comonStatusName,MAP,comonStatus,orderStatusName,orderStatus,measureTypeName,orderType,transactionType} from '../../Globals/Constants';

// styles
import styles from './Client.module.css';

class Client extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			saveStatusOrder:null,
			iserror:false,
			rankTypes:[],
			orders:[],
			feedbacks:[],
			promocodes:[],
			modalshow:false,
			modalshoworder:false,
			modalshoworderadd:false,
			modalshowproducts:false,
			modalloading:true,
			maploaded:false,
			showsearch:false,
			position:MAP.defaultCoordinates,
			addressId:0,
			address:'',
			room:'',
			entrance:'',
			intercom:'',
			floor:'',
			status:comonStatus.ACTIVE,
			addressfull:{},
			addresses:[],
			latitude:MAP.defaultCoordinates[0],
			longitude:MAP.defaultCoordinates[1],
			orderStatus:orderStatus.ADD,
			orderitems:[],
			productsFull:[],
			products:[],
			price:0,
			discountId:0,
			discountName:null,
			priceDiscount:0,
			discount:0,
			discountPercent:0,
			manualDiscount:0,
			manualDiscountPercent:'',
			discounts:[],
			deliveryCost:0,
			deliveryFreeAmount:0,
			clientAddressId:0,
			comment:'',
			selectedorder:null,
			routes:[]
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		ClientRankTypes.getAll((data) => {
			this.setState({rankTypes:data});
			if (this.state.id !== 0) {
				Clients.get(this.state.id, (data) => {
					this.setState({progress:30});
					ClientAddresses.getAll(this.state.id, (addresses) => {
						this.setState({data,dataOld:{...data},notfound:empty(data),addresses,loading:false});
					});
					Orders.getByClient(this.state.id, (data) => this.setState({orders:data}));
					Products.getAllActive((data) => this.setState({products:data,productsFull:data}));
					Discounts.getAll((data) => this.setState({discounts:data}));
					Settings.getAll((data) => {
						const deliveryCost = parseFloat(data.find(f => f.code === 'delivery-cost').value);
						const deliveryFreeAmount = parseFloat(data.find(f => f.code === 'delivery-amount-free').value);
						this.setState({deliveryCost,deliveryFreeAmount});
					});
				});
			}
			else this.dataInit();
		});
	}
	dataInit = () => this.setState({data:{name:'',phone:'',email:'',balance:'',rankType:'',status:clientStatus.ACTIVE,password:'',description:''},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		let data = {
			name:this.state.data.name,
			phone:phoneNormalize(this.state.data.phone),
			email:this.state.data.email,
			balance:this.state.data.balance,
			rankType:this.state.data.rankType,
			status:this.state.data.status
		};
		if (!empty(this.state.data.description)) data.description = this.state.data.description;
		if (data.status === clientStatus.ACTIVE) data.description = '';
		if (id === 0) {
			data.refCode = this.state.data.refCode;
			Clients.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.client.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.client.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.CLIENT);
					this.additionalLogs(id, data, 0, 0);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicatePhone:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			data.dateModify = dateNow();
			Clients.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.client.update,iserror:false});
					Events.add(this.state.user.id, Texts.client.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.CLIENT);
					this.additionalLogs(id, data, this.state.dataOld.balance, this.state.dataOld.rankType);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicatePhone:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	additionalLogs = (clientId, data, balance, rankType) => {
		if (data.rankType && data.rankType !== rankType)
			ClientRanks.add({clientId,rankType:data.rankType});
		if (data.balance && data.balance !== balance)
			ClientBalances.add({clientId,amount:data.balance,type:transactionType.CREDIT,description:'Ручное пополнение через контрольную панель'});
	}
	modalShow = () => this.setState({modalshow:true,modalloading:true});
	modalOrderAddShow = () => this.setState({modalshoworderadd:true,modalloading:true});
	modalOrderShow = () => this.setState({modalshoworder:true,modalloading:true});
	modalHide = () => this.setState({modalshow:false,modalshoworderadd:false,modalshoworder:false,modalloading:false,maploaded:false});
	modalProductsShow = () => this.setState({modalshowproducts:true});
	modalProductsHide = () => this.setState({modalshowproducts:false});
	addressShow = (address) => {
		this.modalShow();
		if (address) {
			this.setState({
				addressId:address.id,
				latitude:address.latitude,longitude:address.longitude,
				address:address.address||'',room:address.room||'',entrance:address.entrance||'',intercom:address.intercom||'',floor:address.floor||'',
				status:address.status,
				addressfull:address
			});
		} else {
			this.setState({
				addressId:0,
				latitude:this.state.latitude,longitude:this.state.longitude,
				address:'',room:'',entrance:'',intercom:'',floor:'',status:comonStatus.ACTIVE,
				addressfull:{status:comonStatus.IN_ACTIVE,clientId:this.state.id}
			});
		}
		this.setState({modalloading:false});
	}
	onBoundsChange = () => {
		const position = this.map.getCenter(), latitude = position[0], longitude = position[1];
		this.setState({position,latitude,longitude});
		this.addressGet(latitude,longitude);
	}
	addressGet = (latitude, longitude) => {
		Maps.locationGetCoordinates(latitude, longitude, (res) => {
			if (res.response.GeoObjectCollection.featureMember.length) {
				const address = res.response.GeoObjectCollection.featureMember[0].GeoObject.name;
				const addressfull = {
					addressId:this.state.addressId,
					clientId:this.state.id,
					address,
					latitude,longitude,
					status:comonStatus.IN_ACTIVE
				};
				this.setState({addressfull,address:addressfull.address});
			}
		});
	};
	selectAddressGet = (e) => {
		const {address} = this.state;
		if (address && address.length > 1) {
			this.setState({showsearch:true,addresses:[]});
			Maps.locationGetAddress(address, (res) => {
				const addresses = [];
				if (res) {
					res.response.GeoObjectCollection.featureMember.forEach((v) => {
						const coors = v.GeoObject.Point.pos.split(' ');
						addresses.push({name:v.GeoObject.name,description:v.GeoObject.description,latitude:parseFloat(coors[1]),longitude:parseFloat(coors[0])});
					});
				}
				this.setState({addresses});
			});
		}
	}
	handleStreetAddress = (e) => {
		let value = e.target.value;
		if (value === null || value.length === 1) this.setState({showsearch:false})
		this.setState({address:value});
	}
	searchAddressSelect = (item) => {
		this.addressGet(item.latitude, item.longitude);
		const latitude = item.latitude, longitude = item.longitude, position = [latitude,longitude];
		this.setState({position,latitude,longitude,showsearch:false});
	}
	handleAddress = (e) => this.setState({[e.target.name]:e.target.value});
	addressSave = (e) => {
		e.preventDefault();
		let {addressfull} = this.state;
		addressfull.room = this.state.room;
		addressfull.entrance = this.state.entrance;
		addressfull.floor = this.state.floor;
		addressfull.intercom = this.state.intercom;
		addressfull.status = this.chk.checked ? comonStatus.ACTIVE : comonStatus.IN_ACTIVE;
		const id = addressfull.addressId;
		delete addressfull.addressId;
		if (id) {
			ClientAddresses.update(id, addressfull, (res) => {
				const addresses = this.state.addresses.map((v,i) => {
					if (v.id === id) {
						v = addressfull;
						v.id = id;
					}
					return v;
				});
				this.setState({addresses}, () => this.modalHide());
				Events.add(this.state.user.id, Texts.client_address.add, null, JSON.stringify(addressfull), id, eventType.CLIENT_ADDRESS);
			}, () => this.modalHide());
		} else {
			addressfull.comment = '';
			ClientAddresses.add(addressfull, (res) => {
				addressfull.id = res.data.id;
				const {addresses} = this.state;
				addresses.push(addressfull);
				this.setState({addresses}, () => this.modalHide());
				Events.add(this.state.user.id, Texts.client_address.update, null, JSON.stringify(addressfull), res.data.id, eventType.CLIENT_ADDRESS);
			}, () => this.modalHide());
		}
	}
	orderViewShow = (order) => {
		this.modalOrderShow();
		order.discountName = order.discountId ? this.state.discounts.find(f => f.id === order.discountId).name : null;
		this.setState({selectedorder:order,modalloading:false});
	}
	orderAddShow = () => {
		this.modalOrderAddShow();
		this.setState({modalloading:false});
	}
	orderSave = (e) => {
		e.preventDefault();
		const {orderitems} = this.state;
		if (orderitems.length === 0) {
			this.setState({saveStatusOrder:'Блюда не добавлены к заказу!'});
			return;
		}
		const {user,data} = this.state;
		const address = this.state.addresses.find(f => f.id === parseInt(this.state.clientAddressId));
		const products = orderitems.map((v,i) => `${i+1}. ${v.name}: ${v.priceDiscount||v.price} ₽`).join('\r\n');
		let order = {
			userId:user.id,
			clientId:data.id,
			clientName:data.name,
			phone:data.phone,
			address:address.address,
			latitude:address.latitude,
			longitude:address.longitude,
			price:this.state.price,
			products:products,
			deliveryCost:this.state.deliveryCost,
			deliveryIsFree:this.state.price >= this.state.deliveryFreeAmount,
			comment:this.state.comment,
			type:orderType.OPERATOR,
			status:orderStatus.ADD
		};
		if (this.state.manualDiscount || this.state.manualDiscountPercent) {
			order.manualDiscount = this.state.manualDiscount;
			order.manualDiscountPercent = this.state.manualDiscountPercent;
			order.priceDiscount = this.state.priceDiscount;
		}
		if (this.state.discountId) {
			order.discountId = this.state.discountId;
			order.priceDiscount = this.state.priceDiscount;
			order.discount = this.state.discount;
			order.discountPercent = this.state.discountPercent;
		}
		Orders.add(order, (res) => {
			const id = res.data.id;
			Events.add(this.state.user.id, Texts.order.add, null, JSON.stringify(order), res.data.id, eventType.ORDER);
			const statuses = {
				orderId:id,
				userId:user.id,
				userName:user.name,
				status:orderStatus.ADD
			};
			OrderStatuses.add(statuses, (res) => Events.add(this.state.user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
			orderitems.forEach((v,i) => {
				const item = {
					orderId:id,
					productId:v.id,
					productName:v.name,
					price:v.price,
					priceDiscount:v.priceDiscount,
					discount:v.discount,
					discountPercent:v.discountPercent,
					measure:v.measure,
					measureType:v.measureType
				};
				OrderItems.add(item);
			});
		}, (err) => {
			this.setState({saveStatusOrder:Texts.errors.internalError});
			Logs.add(err);
		});
	}
	productAddShow = () => this.modalProductsShow();
	productSelect = (item) => {
		const {orderitems} = this.state;
		orderitems.push(item);
		this.setState({orderitems}, () => this.priceCalc());
		this.modalProductsHide();
	}
	productDelete = (idx) => {
		const orderitems = [];
		this.state.orderitems.forEach((v,i) => {
			if (i !== idx) orderitems.push(v);
		});
		this.setState({orderitems}, () => this.priceCalc());
	}
	priceCalc = () => {
		const {orderitems,discounts} = this.state;
		const price = orderitems.reduce((a, b) => a + (b.priceDiscount||b.price), 0);
		const discountGet = (v, price) => {
			if (v.discount) return v.discount;
			else if (v.discountPercent) return Math.ceil((price * v.discountPercent) / 100);
			return 0;
		};
		const priceDiscountGet = (v, price) => {
			if (v.discount) return price - v.discount;
			else if (v.discountPercent) return price - discountGet(v, price);
			return 0;
		};
		const timeCheck = (v) => {
			const start = parseInt(v.conditionTimeStart.replace(':', '')), end = parseInt(v.conditionTimeEnd.replace(':', '')),
				time = dateSegmentsGet().hour * 100 + dateSegmentsGet().minutes;
			return time >= start && time < end;
		};
		let discountId = 0, priceDiscount = 0, discountName, discount = 0;
		const {manualDiscount,manualDiscountPercent} = this.state;
		if (manualDiscount || manualDiscountPercent) {
			if (manualDiscount) {
				discount = manualDiscount;
				priceDiscount = price - discount;
			}
			else if (manualDiscountPercent) {
				discount = Math.ceil((price * manualDiscountPercent) / 100);
				priceDiscount = price - discount;
			}
			if (discount !== 0) discountName = 'персональная';
		}
		else {
			discounts.forEach((v) => {
				let pd = 0, d = 0;
				if (v.conditionTimeStart && v.conditionTimeEnd && (v.conditionAmount !== 0 && price >= v.conditionAmount)) {
					if (timeCheck(v)) {
						pd = priceDiscountGet(v, price);
						d = discountGet(v, price);
					}
				}
				else if (v.conditionAmount !== 0 && price >= v.conditionAmount) {
					pd = priceDiscountGet(v, price);
					d = discountGet(v, price);
				}
				else if (v.conditionTimeStart && v.conditionTimeEnd) {
					if (timeCheck(v)) {
						pd = priceDiscountGet(v, price);
						d = discountGet(v, price);
					}
				}
				if ((priceDiscount === 0 && pd > priceDiscount) || (pd > 0 && pd < priceDiscount)) {
					priceDiscount = pd;
					discount = d;
					discountId = v.id;
					discountName = v.name;
				}
			});
		}
		this.setState({price,priceDiscount,discountId,discountName,discount});
	}
	handleDiscountForm = (e) => this.setState({[e.target.name]:e.target.value}, () => this.priceCalc());
	handleOrderForm = (e) => this.setState({[e.target.name]:e.target.value});
	imageDefault = (e) => e.target.src = require('../../Images/no-image.svg');
	handleSearch = (e) => {
		const search = e.target.value;
		let products = this.state.productsFull;
		if (search.length > 1) products = products.filter(f => f.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
		this.setState({products});
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				<Modal visible={this.state.modalshow} width={'80%'} height={'600'} effect={'fadeInUp'}>
					<div className={styles.modalcontainer}>
						{this.state.modalloading ? <Overlay /> :
						<>
							<h4 className={styles.title}>Адрес доставки</h4>
							<form onSubmit={this.addressSave}>
								<div className={styles.addressinput}>
									<input type="text" placeholder="Адрес" value={this.state.address} onChange={this.handleStreetAddress} onKeyUp={this.selectAddressGet} autoComplete="off" required />
									{this.state.showsearch && <div className={styles.addresses}>
										{this.state.addresses.map((v, i) => <div key={i} onClick={() => this.searchAddressSelect(v)}>{v.name}<span>{v.description}</span></div>)}
									</div>}
									<div className={styles.inputs}>
										<div className={styles.row}>
											<label>
												Комната/Офис
												<input type="text" name="room" placeholder="Комната/Офис" value={this.state.room} onChange={this.handleAddress} required />
											</label>
										</div>
										<div className={styles.row}>
											<label>
												Подъезд
												<input type="text" name="entrance" placeholder="Подъезд" value={this.state.entrance} onChange={this.handleAddress} />
											</label>
										</div>
										<div className={styles.row}>
											<label>
												Этаж
												<input type="text" name="floor" placeholder="Этаж" value={this.state.floor} onChange={this.handleAddress} />
											</label>
										</div>
										<div className={styles.row}>
											<label>
												Домофон
												<input type="text" name="intercom" placeholder="Домофон" value={this.state.intercom} onChange={this.handleAddress} />
											</label>
										</div>
										<div className={styles.row}>
											<label>
												Текущий адрес
												<input type="checkbox" ref={(chk) => this.chk = chk} />
											</label>
										</div>
									</div>
								</div>
								<div className={styles.map}>
									<YMaps query={{apikey:MAP.keyYandex}}>
										<Map
											state={{center:[this.state.latitude,this.state.longitude],zoom:16}}
											instanceRef={(map) => this.map = map}
											onBoundsChange={this.onBoundsChange}
											onLoad={() => this.setState({maploaded:true})}
											width={'auto'} height={'100%'}>
												<GeolocationControl />
												<ZoomControl />
											</Map>
											{this.state.maploaded && <div className={styles.centerMarker}></div>}
									</YMaps>
								</div>
								<div className={styles.modalbuttons}>
									<button>Сохранить</button>
								</div>
							</form>
						</>}
					</div>
					<Close close={this.modalHide.bind(this)} />
				</Modal>
				<Modal visible={this.state.modalshoworder} width={'80%'} height={'600'} effect={'fadeInUp'}>
					<div className={styles.modalcontainer}>
						{this.state.modalloading || this.state.selectedorder === null ? <Overlay /> :
						<>
							<h4 className={styles.title}>Заказ №{this.state.selectedorder.id}</h4>
							<div className={styles.orderviewcontainer}>
								<div className={styles.clientorderheader}>
									<div className={styles.cell}>
										<label>
											<span>Клиент</span>
											<div><b>{this.state.selectedorder.clientName}</b></div>
										</label>
									</div>
									<div className={styles.cell}>
										<label>
											<span>Телефон</span>
											<div className={styles.clientphone}>{phoneFormatter(this.state.selectedorder.phone)}</div>
										</label>
									</div>
									<div className={styles.cell}>
										<label>
											<span>Адрес доставки</span>
											<div>{this.state.selectedorder.address}</div>
										</label>
									</div>
									<div className={styles.cell}>
										<label>
											<span>Статус</span>
											<div>{orderStatusName[this.state.selectedorder.status]}</div>
										</label>
									</div>
									<div className={styles.cell}>
										<label>
											<span>Комментарий</span>
											<div>{this.state.selectedorder.comment}</div>
										</label>
									</div>
								</div>
								<div><b>Список блюд</b></div>
								<div className={styles.summaryview} dangerouslySetInnerHTML={{__html:rn2br(this.state.selectedorder.products)}} />
								<div><b>Скидки</b></div>
								<div className={styles.summaryview}>
									{this.state.selectedorder.manualDiscount || this.state.selectedorder.manualDiscountPercent ?
										<div className={styles.summaryblock}>
											<div>Сумма заказа: <b>{this.state.selectedorder.price} ₽</b></div>
											<div>Сумма со скидкой: {this.state.selectedorder.priceDiscount} ₽</div>
											<div>Сумма к оплате: <b>{(this.state.selectedorder.priceDiscount ? this.state.selectedorder.priceDiscount : this.state.selectedorder.price) + (this.state.selectedorder.deliveryIsFree ? 0 : this.state.selectedorder.deliveryCost)} ₽</b></div>
										</div>
									:
										<div className={styles.summaryblock}>
											<div>Сумма заказа: <b>{this.state.selectedorder.price} ₽</b></div>
											<div>Сумма со скидкой: {this.state.selectedorder.priceDiscount ? <>{this.state.selectedorder.priceDiscount} ₽</> : '—'}</div>
											<div>Сумма к оплате: <b>{(this.state.selectedorder.priceDiscount ? this.state.selectedorder.priceDiscount : this.state.selectedorder.price) + (this.state.selectedorder.deliveryIsFree ? 0 : this.state.selectedorder.deliveryCost)} ₽</b></div>
										</div>
									}
									<div className={styles.summaryblock}>
										<div>Скидка: {this.state.selectedorder.discount ? `${this.state.selectedorder.discount} ₽` : '—'}</div>
										{this.state.selectedorder.discountName && <div>Применена скидка: {this.state.selectedorder.discountName}</div>}
									</div>
									<div className={styles.summaryblock}>
										Доставка: {this.state.selectedorder.deliveryIsFree ? '0': this.state.selectedorder.deliveryCost} ₽
										<span className={styles.hint}>бесплатная доставка от {this.state.selectedorder.deliveryFreeAmount} ₽</span>
									</div>
								</div>
							</div>
							<Link to={`/order/${this.state.selectedorder.id}`} className={styles.gotoorders}>Перейти в заказы</Link>
						</>}
					</div>
					<Close close={this.modalHide.bind(this)} />
				</Modal>
				<Modal visible={this.state.modalshoworderadd} width={'80%'} height={'610'} effect={'fadeInUp'}>
					<Modal visible={this.state.modalshowproducts} width={'100%'} height={'90%'} effect={'fadeInUp'}>
						<div className={styles.modalcontainer}>
							<h4 className={styles.title}>Выбрать блюда</h4>
							<div className={styles.productsfilter}>
								<input type="text" placeholder="Поиск" onChange={this.handleSearch} />
							</div>
							<div className={styles.productitemscontainer}>
								<div className={styles.productitems}>
									{this.state.products.map((v,i) => <div key={i} className={styles.productitem} onClick={() => this.productSelect(v)}>
										<div className={styles.productheader}>
											<div className={styles.producttitle}>
												{v.name}
												<span>{v.measure} {measureTypeName[v.measureType]}</span>
											</div>
											<div className={styles.productprice}>{v.price} ₽</div>
										</div>
										<img src={`${API.assets}/products/${v.image}`} onError={this.imageDefault} alt={this.name} />
										<div className={styles.productdesc}>{v.description}</div>
									</div>)}
								</div>
							</div>
						</div>
						<Close close={this.modalProductsHide.bind(this)} />
					</Modal>
					<div className={styles.modalcontainer}>
						{this.state.modalloading ? <Overlay /> :
						<>
							<h4 className={styles.title}>Добавить заказ</h4>
							<form onSubmit={this.orderSave}>
								<div className={styles.ordercontainer}>
									<div className={styles.clientorderheader}>
										<div className={styles.cell}>
											<label>
												<span>Клиент</span>
												<div><b>{this.state.data.name}</b></div>
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Телефон</span>
												<div>{phoneFormatter(this.state.data.phone)}</div>
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Адрес доставки</span>
												<select name="clientAddressId" onChange={this.handleOrderForm} required>
													<option value=""></option>
													{this.state.addresses.map((v,i) => <option key={i} value={v.id}>{v.address}</option>)}
												</select>
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Комментарий</span>
												<input type="text" name="comment" value={this.state.comment} onChange={this.handleOrderForm} />
											</label>
										</div>
									</div>
									<div className={styles.onelinefull}>
										<b>Список блюд</b>
										<a className={styles.productadd} onClick={() => this.productAddShow()}></a>
									</div>
									<div className={styles.productscontainer}>
										{this.state.orderitems.length === 0 ?
											<div className={styles.emptyproducts}>
												<p>Выберите блюда и добавьте их к заказу</p>
												<div className={styles.buttonadd} onClick={() => this.productAddShow()}>Добавить</div>
											</div>
										:
											<ul>
												{this.state.orderitems.map((v,i) => <li key={i}><div>{i+1}. {v.name} — {v.priceDiscount ? <>{v.priceDiscount} ₽ (<s>{v.price} ₽</s>)</> : <>{v.price} ₽</>}</div><img src={require('../../Images/icons/close_black.svg')} onClick={() => this.productDelete(i) } alt="удалить"/></li>)}
											</ul>
										}
									</div>
									<div className={styles.oneline}>
										<div className={styles.cell}>
											<label>
												<span>Персональная скидка, %</span>
												<input type="number" name="manualDiscountPercent" min={0} max={50} value={this.state.manualDiscountPercent} placeholder="15" onChange={this.handleDiscountForm} />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Персональная скидка, ₽</span>
												<input type="number" name="manualDiscount" min={0} max={99999} value={this.state.manualDiscount} placeholder="100" onChange={this.handleDiscountForm} />
											</label>
										</div>
									</div>
									{this.state.orderitems.length > 0 && <div className={styles.summary}>
										{this.state.manualDiscount || this.state.manualDiscountPercent ?
											<div className={styles.summaryblock}>
												<div>Сумма заказа: <b>{this.state.price} ₽</b></div>
												<div>Сумма со скидкой: {this.state.priceDiscount} ₽</div>
												<div>Сумма к оплате: <b>{(this.state.priceDiscount ? this.state.priceDiscount : this.state.price) + (this.state.price >= this.state.deliveryFreeAmount ? 0 : this.state.deliveryCost)} ₽</b></div>
											</div>
										:
											<div className={styles.summaryblock}>
												<div>Сумма заказа: <b>{this.state.price} ₽</b></div>
												<div>Сумма со скидкой: {this.state.priceDiscount ? <>{this.state.priceDiscount} ₽</> : '—'}</div>
												<div>Сумма к оплате: <b>{(this.state.priceDiscount ? this.state.priceDiscount : this.state.price) + (this.state.price >= this.state.deliveryFreeAmount ? 0 : this.state.deliveryCost)} ₽</b></div>
											</div>
										}
										<div className={styles.summaryblock}>
											<div>Скидка: {this.state.discount ? `${this.state.discount} ₽` : '—'}</div>
											{this.state.discountName && <div>Применена скидка: {this.state.discountName}</div>}
										</div>
										<div className={styles.summaryblock}>
											Доставка: {this.state.price >= this.state.deliveryFreeAmount ? '0': this.state.deliveryCost} ₽
											<span className={styles.hint}>бесплатная доставка от {this.state.deliveryFreeAmount} ₽</span>
										</div>
									</div>}
								</div>
								<div className={styles.modalbuttons}>
									{this.state.saveStatusOrder ? <SaveStatus message={this.state.saveStatusOrder} isError={true} onfinish={() => this.setState({saveStatusOrder:null})} /> : <div></div>}
									<button>Создать</button>
								</div>
							</form>
						</>}
					</div>
					{!this.state.modalshowproducts && <Close close={this.modalHide.bind(this)} />}
				</Modal>
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/clients'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить клиента' : 'Редактирование клиента'}
								</span>
								<Link to={'/client'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
							<>
								<div className={styles.formcontainerouter}>
									<form onSubmit={this.save} className={styles.form}>
										<div className={styles.formcontainer}>
											<div className={styles.cell}>
												<label>
													<span>Имя</span>
													<input type="text" name="name" value={this.state.data.name} placeholder="Имя" onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.oneline}>
												<div className={styles.cell}>
													<label>
														<span>Телефон</span>
														<InputMask type="text" name="phone" mask="+7 (999) 999-99-99" maskChar={null} placeholder="Телефон" value={this.state.data.phone} onChange={this.handleForm} required />
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Адрес эл.почты</span>
														<input type="email" name="email" value={this.state.data.email} placeholder="Адрес эл.почты" onChange={this.handleForm} />
													</label>
												</div>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Реферальный код</span>
													<input type="refCode" name="refCode" value={this.state.data.refCode} placeholder="Реферальный код" onChange={this.handleForm} disabled={this.state.data.id!==0} />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Баланс, ₽</span>
													<input type="number" name="balance" min={0} max={9999} value={this.state.data.balance} placeholder="100" onChange={this.handleForm} />
												</label>
											</div>
											<div className={styles.oneline}>
												<div className={styles.cell}>
													<label>
														<span>Тип</span>
														<select name="rankType" onChange={this.handleForm} defaultValue={parseInt(this.state.data.rankType)}>
															<option value=""></option>
															{this.state.rankTypes.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
														</select>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Статус</span>
														<select name="status" onChange={this.handleForm} defaultValue={parseInt(this.state.data.status)}>
															{clientStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
														</select>
													</label>
												</div>
											</div>
											{parseInt(this.state.data.status) !== clientStatus.ACTIVE ?
											<>
												<div className={styles.notice}>
													<span>Внимание! Вы выбрали статус клиента <b>{clientStatusName[this.state.data.status]}</b>. Необходимо указать причину смены статуса</span>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Описание блокировки</span>
														<textarea name="description" placeholder="Опишите причину блокировки оператора" className={styles.desc} value={this.state.data.description} onChange={this.handleForm} required></textarea>
													</label>
												</div>
											</>
											: null}
										</div>
										{this.state.id !== 0 ?
										<>
											<div className={styles.formcontainer}>
												<h4 className={styles.title}>
													Заказы
													<a className={styles.plus} onClick={() => this.orderAddShow()}></a>
												</h4>
												{this.state.orders.length === 0 ? <NotFound message={'У пользователя нет ни одного созданного заказа'} /> :
													<table>
														<thead>
															<tr>
																<th>№</th>
																<th>ID</th>
																<th>Дата</th>
																<th>Сумма, ₽</th>
																<th>Адрес</th>
																<th>Статус</th>
															</tr>
														</thead>
														<tbody>
															{this.state.orders.map((v,i) => <tr key={i}>
																<td>{i+1}</td>
																<td>{v.id}</td>
																<td><a onClick={() => this.orderViewShow(v)} className={styles.link}>{dateGet(v.dateCreate, {showTime:true,showSeconds:true})}</a></td>
																<td>{(v.priceDiscount||v.price) + (v.deliveryIsFree?0:v.deliveryCost)}</td>
																<td>{v.address}</td>
																<td>{orderStatusName[v.status]}</td>
															</tr>)}
														</tbody>
													</table>
												}
												<div className={styles.buttons}>
													{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
													{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
												</div>
											</div>
											<div className={styles.formcontainer}>
												<h4 className={styles.title}>
													Адреса
													<a className={styles.plus} onClick={() => this.addressShow()}></a>
												</h4>
												{this.state.addresses.length === 0 ? <NotFound message={'У пользователя нет ни одного сохраненного адреса доставки'} /> :
													<table>
														<thead>
															<tr>
																<th>№</th>
																<th>Адрес</th>
																<th>Квартира/Офис</th>
																<th>Подъезд</th>
																<th>Этаж</th>
																<th>Домофон</th>
																<th>Статус</th>
															</tr>
														</thead>
														<tbody>
															{this.state.addresses.map((v,i) => <tr key={i}>
																<td>{i+1}</td>
																<td><a onClick={() => this.addressShow(v)} className={styles.link}>{v.address}</a></td>
																<td>{v.room}</td>
																<td>{v.entrance}</td>
																<td>{v.floor}</td>
																<td>{v.intercom}</td>
																<td>{comonStatusName[v.status]}</td>
															</tr>)}
														</tbody>
													</table>
												}
												<div className={styles.buttons}>
													{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
													{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
												</div>
											</div>
										</>
										: null}
									</form>
								</div>
							</>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Client);