import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import JsonTable from '../../Components/JsonTable';

// models
import {Events,Users} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty,dateGet} from '../../Globals/Utils';

// globals
import {eventTypeName} from '../../Globals/Constants';

// styles
import styles from './Event.module.css';

class Event extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			user:null,
			initiator:null,
			notfound:false,
			progress:10,
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	dataGet = () => {
		Events.get(this.state.id, (data) => {
			if (data.userId) Users.get(data.userId, (initiator) => this.setState({data,notfound:empty(data),initiator,loading:false}));
			else this.setState({data,notfound:empty(data),loading:false});
		});
	}
	initiatorGet = () => this.state.initiator ? this.state.initiator.name : null;
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/events'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									Просмотр события
								</span>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<div className={styles.formcontainer}>
									<form>
										<div className={styles.onelinefull}>
											<div className={styles.cell}>
												<label>
													<span>ID</span>
													<div>{this.state.data.id}</div>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Дата</span>
													<div>{dateGet(this.state.data.dateCreate, {showTime:true,showSeconds:true})}</div>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Инициатор</span>
													<div>{this.state.initiator ? <Link to={`/user/${this.state.initiator.id}`}>{this.state.initiator.name}</Link> : '—'}</div>
												</label>
											</div>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Событие</span>
												<div>{this.state.data.message}</div>
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Тип события</span>
												<div>{eventTypeName[this.state.data.type]}</div>
											</label>
										</div>
										<h4 className={styles.title}>Изменения данных</h4>
										<div className={styles.onelinefulldata}>
											<div className={styles.cell}>
												<label>
													<span>Старые данные</span>
													<div>{this.state.data.dataOld ? <JsonTable data={this.state.data.dataOld} /> : 'нет данных'}</div>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Новые данные</span>
													<div>{this.state.data.dataNew ? <JsonTable data={this.state.data.dataNew} /> : 'нет данных'}</div>
												</label>
											</div>
										</div>
									</form>
								</div>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Event);