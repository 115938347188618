import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';
import SaveStatus from '../../../Components/SaveStatus';

// models
import {ClientPromoCodes,Events,Logs} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {empty,timestampConvert} from '../../../Globals/Utils';
import errorsParse from '../../../Globals/Errors';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {eventType,errorType,comonStatus} from '../../../Globals/Constants';

// styles
import styles from './ClientPromoCode.module.css';

class ClientPromoCode extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		if (this.state.id !== 0) {
			ClientPromoCodes.get(this.state.id, (data) => {
				if (data.dateTill) data.dateTill = timestampConvert(data.dateTill);
				this.setState({data,dataOld:{...data},notfound:empty(data),loading:false});
			});
		} else this.dataInit();
	}
	dataInit = () => this.setState({data:{clientId:'',code:'',discount:'',type:'',count:'',status:'',dateTill:''},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id,user} = this.state;
		const data = {
			clientId:user.id,
			discount:this.state.data.discount,
			count:this.state.data.count,
			type:1,
			status:comonStatus.ACTIVE
		}
		if (this.state.data.dateTill) data.dateTill = Math.round(new Date(this.state.data.dateTill).getTime() / 1000);
		if (id === 0) {
			data.code = this.state.data.code;
			ClientPromoCodes.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.promocodes.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.client_promocodes.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.CLIENT_PROMOCODE);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicatePromoCode:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			ClientPromoCodes.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.promocodes.update,iserror:false});
					Events.add(this.state.user.id, Texts.client_promocodes.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.CLIENT_PROMOCODE);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicatePromoCode:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isLoyalty={true}>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/loyalty/clientpromocodes'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить промокод' : 'Редактирование промокода'}
								</span>
								<Link to={'/loyalty/promocode'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<div className={styles.formcontainer}>
									<form onSubmit={this.save}>
										<div className={styles.cell}>
											<label>
												<span>Промокод</span>
												<input type="text" name="code" value={this.state.data.code} placeholder="Промокод" onChange={this.handleForm} required disabled={this.state.id!==0} />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>ID клиента</span>
												<input type="text" name="clientId" value={this.state.data.clientId} placeholder="ID клиента" onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Процент скидки</span>
												<input type="number" name="discount" min={0} max={99} value={this.state.data.discount} placeholder="5" required onChange={this.handleForm} />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Количество доступных кодов</span>
												<input type="number" name="count" value={this.state.data.count} onChange={this.handleForm} />
												<span className={styles.hint}>не обязательное поле. пустое значение - неограниченое количество</span>
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Дата окончания действия</span>
												<input type="datetime-local" name="dateTill" value={this.state.data.dateTill} placeholder="Название" onChange={this.handleForm} />
												<span className={styles.hint}>не обязательное поле. путое значение - без срока действия</span>
											</label>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</form>
								</div>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(ClientPromoCode);