import React,{Component} from 'react';
import {withRouter} from 'react-router';

import classNames from 'classnames';

import Loading from '../../Loading';
import Error from '../../Error';
import Modal from 'react-awesome-modal';

import {
    Areas,
    Users,
    CourierOrders,
    Settings,
    OrderItems,
    Orders,
    Events,
    Accountings
} from '../../../Models';

import {ls} from '../../../Globals/Localstorage';
import {dateNow,dateGet, quantityFormat, moneyFormat, phoneFormatter} from '../../../Globals/Utils';

import {
    timers,
    userType,
    userStatus,
    measureTypeName,
    orderStatus,
    eventType,
    orderItemCookStatus,
    orderItemCourierStatus,
    orderStatusName,
    orderTypeName,
    targetTypeName
} from '../../../Globals/Constants';

import styles from './DashboardCourier.module.css';
import Scroller from '../../Scroller';
import Texts from '../../../Globals/Texts';
import Close from '../../Close';

const cx = classNames.bind(styles);

class DashboardCourier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user:null,
            client:null,
            order:null,
            orders:[],
            orderinfo:null,
            lastorders:[],
            areas:[],
            dayshift:null,
            inwork:[],
            ordersall:[],
            courier:null,
            courierId:null,
            couriers:[],
            courierorders:[],
            courierorderstoday:[],
            courierordersmonth:[],
            lastcountorders:5,
            avgorderscomplete:{},
            route:[],
            activearea:null,
            amount:undefined,
            amountTransfer:undefined,
            debt:[],
            isOnline:0,
            report:null,
            modalshow:false,
            iserror:false,
            loading:true,
            loadinginner:true,
            loadingmodal:true,
			tab:0,
            tabGroup:1,
            statisticshow:true
        };
    }
    timerId = null;
    timerFinishId = null;
    componentDidMount = async () => {
        const user = ls('user');
        if (user === null) {
            window.location.href='/error403';
            return;
        }
        this.dataGet();
    }
    componentWillUnmount = () => {
        clearInterval(this.timerId);
        clearInterval(this.timerFinishId);
        this.timerId = null;
        this.timerFinishId = null;
    }
    dataGet = async () => {
        this.setState({loading:true,iserror:false});
        const user = ls('user');
        try {
			const activearea = ls('activearea');
            const lastcountorders = 40;
            const users = await Users.getByType([userType.COURIER]);
            const couriers = users.data.filter(f => f.status === userStatus.ACTIVE);
            const areas = await Areas.getAllActive();
            const orders = await CourierOrders.getCourierOrders(user.id, (res) => { return res });
            console.log(orders)
            const lastorders = await CourierOrders.getLastOrders(user.id, lastcountorders, (res) => { return res.filter(f => f.dateModify > f.dateCreate); });
            let avgorderscomplete = {};
            if (lastorders.length) {
                const sum = lastorders.reduce((prev, curr, i) => (lastorders[i].dateModify) - (lastorders[i].dateCreate) + prev, 0);
                const avg = (sum / lastorders.length) || 0;

                const d = Math.floor(avg / (60 * 60 * 24));
                const h = Math.floor(avg / 3600);
                const m = Math.floor(avg % 3600 / 60);
                const s = Math.floor(avg % 3600 % 60);
                avgorderscomplete = {d,h,m,s};
            }

            this.setState({
                user,
                couriers,
                courierId:user.id,
                orders,
				activearea,
                lastcountorders,
                avgorderscomplete,
                lastorders,
                areas:areas.data,
                loading:true
            });
            this.courierUpdate();
            this.ordersFilter();
            this.timerId = setInterval(this.courierUpdate, timers.ORDERS_GET);
        } catch (ex) {
            console.log(ex);
            this.setState({loading:false,iserror:true});
        }
    }
	areaNameGet = (id) => {
		const area = this.state.areas.find(f => f.id === id);
		return area ? area.name : '—';
	}
	modalShow = () => this.setState({modalshow:true});
	modalHide = () => this.setState({modalshow:false});
    courierUpdate = async () => {
        const {courierId} = this.state;
        const now = new Date();
        const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const thirtyDaysAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);

        const startOfDayInSeconds = Math.floor(startOfDay.getTime() / 1000);
		const courierorderstoday = await CourierOrders.getToday(startOfDayInSeconds, (res) => { return res })

        const thirtyDaysAgoInSeconds = Math.floor(thirtyDaysAgo.getTime() / 1000);
        const courierordersmonth = await CourierOrders.getMonth(thirtyDaysAgoInSeconds, (res) => { return res })

        console.log(courierorderstoday)
        console.log(courierordersmonth)

        const d = await Accountings.get(this.state.id);
        const debt = d ? d.data.reduce((a,b) => a + b.debt, 0) : 0;

        this.setState({courierorderstoday,courierordersmonth,debt});
    }
	setTab = (tab) => this.setState({tab});
	setTabGroup = (tabGroup) => this.setState({tabGroup});
    ordersFilter = () => {
		let {orders} = this.state;
		switch (this.state.tab) {
			default:
			case 0:
				orders = this.state.orders.filter(f => f.status === 1)
				break;
			case 1:
				orders = orders;
				break;
		}
		switch (this.state.tabGroup) {
			default:
			case 1:
				break;
			case 2:
				const o = [];
				// orders.map((v) => {
				// 	v.products.map((v) => {
                //         console.log(v)
				// 		const item = o.find(f => f.productId === v.productId);
				// 		if (item) item.quantity += v.quantity;
				// 		else o.push({...v,quantity:v.quantity});
				// 	});
				// });
				return o;
		}
		return orders;
	}
	additionalStatus = (order) => {
		const settings = ls('settings'), now = dateNow(), timeDelay = parseInt(Settings.getByKey('new-order-cook', settings).value);
		const date = order.date ? order.date - timeDelay * 3 : order.dateCreate + timeDelay;
		return date < now ? styles.alarm : (order.status === orderStatus.COOK_DONE ? styles.paid : null);
	}
	orderSelect = async (order) => {
		const {usersections} = this.state, products = usersections ? {data:order.products} : await OrderItems.get(order.orderId);
		const p = usersections ? products.data.filter(f => this.state.usersections.includes(f.sectionId)) : products.data;
		this.setState({order,products:p,orderinfo:null}, async () => {
			this.modalShow()
		});
        Orders.get(order.orderId, (res) => {
            this.setState({orderinfo: res});
            OrderItems.getSync(order.orderId, (res) => { this.setState({orderproducts: res})});
        });
	}
    statDayShiftGet = () => {
		const {courierorderstoday,user} = this.state;
		const courierorders = courierorderstoday.filter(f => f.status === orderItemCourierStatus.DONE);
		if (courierorders.length) {
            const couriers = {};
            courierorders.forEach(order => {
                const userId = order.userId;
                const courier = couriers[userId];
                if (!courier) {
                    couriers[userId] = { id: userId, orderCount: 1, position: 0 };
                } else {
                    couriers[userId].orderCount++;
                }

            });
            const courierArray = Object.values(couriers);
            courierArray.sort((a, b) => b.orderCount - a.orderCount);
            const courierCount = courierArray.length;
            courierArray.forEach((courier, index) => {
                courier.position = index + 1;
            });
            const userCourier = couriers[user.id];
            const userOrderCount = userCourier ? userCourier.orderCount : 0;
            const userPosition = userCourier ? userCourier.position : 0;
			const settings = ls('settings');
            const amount = parseInt(Settings.getByKey('delivery-cost', settings).value) * userOrderCount;
			const orders = [];
			courierorderstoday.forEach((v) => {
				const order = orders.find(f => f.id === v.userId);
				if (order) order.count = order.count + v.quantity;
				else orders.push({id:v.userId,count:v.quantity});
			});
			orders.sort((a, b) => b.count - a.count);
			return <ul>
				<li><span>Количество заказов</span>{userOrderCount} шт.</li>
				<li><span><b>Сумма к выплате</b></span>{moneyFormat(amount)} ₽</li>
				<li><span>Место в рейтинге</span>{userPosition} / {courierCount}</li>
			</ul>
		}
		return <div className={styles.empty}>Нет информации</div>
    }
    statMonthGet = () => {
		const {courierordersmonth,user} = this.state;
		const courierorders = this.state.courierordersmonth.filter(f => f.status === orderItemCourierStatus.DONE);
		if (courierorders.length) {
            const couriers = {};
            courierorders.forEach(order => {
                const userId = order.userId;
                const courier = couriers[userId];
                if (!courier) {
                    couriers[userId] = { id: userId, orderCount: 1, position: 0 };
                } else {
                    couriers[userId].orderCount++;
                }
            });
            const courierArray = Object.values(couriers);
            courierArray.sort((a, b) => b.orderCount - a.orderCount);
            const courierCount = courierArray.length;
            courierArray.forEach((courier, index) => {
                courier.position = index + 1;
            });
            const userCourier = couriers[user.id];
            const userOrderCount = userCourier ? userCourier.orderCount : 0;
            const userPosition = userCourier ? userCourier.position : 0;
			const settings = ls('settings');
            const amount = parseInt(Settings.getByKey('delivery-cost', settings).value) * userOrderCount;
			const orders = [];
			courierordersmonth.forEach((v) => {
				const order = orders.find(f => f.id === v.userId);
				if (order) order.count = order.count + v.quantity;
				else orders.push({id:v.userId,count:v.quantity});
			});
			orders.sort((a, b) => b.count - a.count);
			return <ul>
				<li><span>Количество заказов</span>{userOrderCount} шт.</li>
				<li><span><b>Сумма к выплате</b></span>{moneyFormat(amount)} ₽</li>
				<li><span>Место в рейтинге</span>{userPosition} / {courierCount}</li>
			</ul>
		}
		return <div className={styles.empty}>Нет информации</div>
	}
    getOrderInfo = () => {
        const order = this.state.orderinfo
        const orderproducts = this.state.orderproducts ?? []
        return <>
            <div className={styles.title}>
                <div className={styles.cell}>
                    <label>
                        <span>Клиент</span>
                        <div>{order.clientName}</div>
                    </label>
                </div>
                <div className={styles.cell}>
                    <label>
                        <span>Телефон</span>
                        <div className={styles.phone}>{phoneFormatter(order.phone)}</div>
                    </label>
                </div>
                <div className={styles.cell}>
                    <label>
                        <span>Адрес доставки</span>
                        <div className={styles.addressblock}>
                            <div>{order.address}</div>
                        </div>
                    </label>
                </div>
                <div className={styles.cell}>
                    <label>
                        <span>Сумма заказа</span>
                        <div>{moneyFormat(order.priceFull, false)} ₽</div>
                    </label>
                </div>
                <div className={styles.cell}>
                    <label>
                        <span>Оплачен</span>
                        <div>{order.isPaid ? 'Да' : 'Нет'}</div>
                    </label>
                </div>
            </div>
            {/* <div className={styles.title}>
                <div className={styles.cell}>
                    <label>
                        <span>Дата создания</span>
                        <div>{dateGet(order.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</div>
                    </label>
                </div>
                <div className={styles.cell}>
                    <label>
                        <span>Статус</span>
                        <div>{orderStatusName[order.status]}</div>
                    </label>
                </div>
                <div className={styles.cell}>
                    <label>
                        <span>Заказ создан</span>
                        <div>{orderTypeName[order.type]}</div>
                    </label>
                </div>
                <div className={styles.cell}>
                    <label>
                        <span>Источник</span>
                        <div>{targetTypeName[order.target]}</div>
                    </label>
                </div>
            </div> */}
            <div className={styles.title}>
                <div className={styles.cell}>
                    <label>
                        <span>Доставить к</span>
                        <div>{order.date ? <b>{dateGet(order.date, {yearHide:true,showMonthFullName:true,showTime:true})}</b> : '—'}</div>
                    </label>
                </div>
                {order.contactPhone ?
                    <div className={styles.cell}>
                        <label>
                            <span>Контактный телефон</span>
                            <div className={styles.phone}>{phoneFormatter(order.contactPhone)}</div>
                        </label>
                    </div> : null}
                <div className={styles.cell}>
                    <label>
                        <span>Комментарий</span>
                        <div>{order.comment||'—'}</div>
                    </label>
                </div>
                <div className={styles.cell}>
                    <label>
                        <span>Комментарий к адресу</span>
                        <div>{order.commentAddress||'—'}</div>
                    </label>
                </div>
                {/* <div className={styles.cell}>
                    <label>
                        <span>Комментарий к системе</span>
                        <div>{order.commentSystem||'—'}</div>
                    </label>
                </div> */}
            </div>
            <div className={styles.title}>
                <div className={styles.cell}>
                    <label>
                        <span>Смена</span>
                        <div>{order.dayShiftId ? `№${order.dayShiftId}` : '—'}</div>
                    </label>
                </div>
                <div className={styles.cell}>
                    <label>
                        <span>Точка</span>
                        <div>{this.areaNameGet(order.areaId)}</div>
                    </label>
                </div>
                {/* <div className={styles.cell}>
                    <label>
                        <span>Промокод</span>
                        <div>{order.promoCode||'—'}</div>
                    </label>
                </div> */}
                <div className={styles.cell}>
                    <label>
                        <span>Сдача</span>
                        <div>{order.surrender||'—'}</div>
                    </label>
                </div>
                <div className={styles.cell}>
                    <label>
                        <span>Приборы</span>
                        <div>{order.cutlery||'—'}</div>
                    </label>
                </div>
                {!order.courierId ?
                    <div className={styles.cell}>
                        <label>
                            <span>Терминал для оплаты</span>
                            <div>{order.isTerminal ? 'Да' : 'Нет'}</div>
                        </label>
                    </div> : null}
                {!order.courierId ?
                    <div className={styles.cell}>
                        <label>
                            <span>Оплата по QR</span>
                            <div>{order.isQR ? 'Да' : 'Нет'}</div>
                        </label>
                    </div> : null}
            </div>
            {order.courierId ?
                <div className={styles.title}>
                    <div className={styles.cell}>
                        <label>
                            <span>Курьер</span>
                            <div>{order.courierName}</div>
                        </label>
                    </div>
                    <div className={styles.cell}>
                        <label>
                            <span>Телефон</span>
                            <div className={styles.phone}>{phoneFormatter(order.courierPhone)}</div>
                        </label>
                    </div>
                </div> : null}
            <div><b>Список блюд</b></div>
            <div className={cx(styles.title,styles.titlenoborder)}>
                <div className={styles.productslist}>
                    {orderproducts && orderproducts.map((v,i) => <div key={i} className={styles.item}>
                        <div className={styles.name}>
                            <div>{i+1}. {v.productName}</div>
                            <div className={styles.measure}>{v.measure} {measureTypeName[v.measureType]}</div>
                        </div>
                        <div className={styles.count}>x {quantityFormat(v.quantity)}</div>
                        <div className={styles.price}>{moneyFormat(v.priceDiscount ? v.priceDiscount : v.price, true)} ₽</div>
                    </div>)}
                </div>
            </div>
            {/* <div className={styles.title}>
                <div className={styles.summaryview}>
                    {order.manualDiscount || order.manualDiscountPercent ?
                        <div className={styles.summaryblock}>
                            <div>Сумма заказа: <b>{moneyFormat(order.price, false)} ₽</b></div>
                            <div>Сумма со скидкой: {moneyFormat(order.priceDiscount, false)} ₽</div>
                            <div>Сумма к оплате: <b>{moneyFormat(order.priceFull, false)} ₽</b></div>
                        </div>
                    :
                        <div className={styles.summaryblock}>
                            <div>Сумма заказа: <b>{moneyFormat(order.price, false)} ₽</b></div>
                            <div>Сумма со скидкой: {order.priceDiscount ? <>{moneyFormat(order.priceDiscount, false)} ₽</> : '—'}</div>
                            <div>Сумма к оплате: <b>{moneyFormat(order.priceFull, false)} ₽</b></div>
                        </div>
                    }
                    <div className={styles.summaryblock}>
                        <div>Скидка: {order.discount ? `${order.discount} ₽` : '—'}</div>
                        {order.discountName && <div>Применена скидка: {order.discountName}</div>}
                    </div>
                    <div className={styles.summaryblock}>
                        Доставка: {order.isPickup ? 'Самовывоз' : (order.deliveryIsFree ? '0': <>{order.deliveryCostManual||order.deliveryCost} ₽</>)}
                    </div>
                </div>
            </div> */}</>
    }
	statShow = () => this.setState({statisticshow:!this.state.statisticshow});
    render() {
        if (this.state.loading) return <div className={styles.main}><Loading className={styles.loading} /></div>;
        if (this.state.iserror) return <div className={styles.main}><Error refresh={this.dataGet} /></div>;
        return <div className={styles.container}>
            <div className={styles.areaactive}>
                <button>
                    <img src={require('../../../Images/icons/marker_black.svg')} alt="Точка" />
                    {this.state.activearea.name}
                </button>
            </div>
            <div className={styles.main}>
                {/* <div className={styles.row}>Курьер (id {this.state.courierId}) <h3>{this.state.user.name}</h3></div> */}
                {/* <div>Выполнено заказов всего {this.state.orders.filter(f => f.status === 0).length}</div> */}
                {/* <div>Выполнено заказов cегодня {this.state.orders.filter(f => f.status === 0 && f.dateModify > (new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), 9, 0, 0, 0)).getTime()/1000).length}</div> */}
                {/* <div>Выполняются {this.state.orders.filter(f => f.status === 1).length}</div> */}
                {this.state.avgorderscomplete.length ? <div>Среднее время выполнения заказа (последние {this.state.lastcountorders}): {this.state.avgorderscomplete.d === 0 ? '' : this.state.avgorderscomplete.d + 'дн. '} {this.state.avgorderscomplete.h + 'ч. '} {this.state.avgorderscomplete.m + 'м. '}</div>:null}
                {
                    //this.state.courier.orders.map((v,i) => <div key={i} className={styles.row}>{v}</div>)}
                }
                
                <div className={styles.viewcontols}>
                    <ul className={styles.tabs}>
                        <li className={styles.tab}>{this.state.user.name}</li>
                        <li className={cx(styles.tab,this.state.tab===0?styles.tabselectedcurrent:null)} onClick={() => this.setTab(0)}>В работе ({this.state.orders.filter(f => f.status === 1).length})</li>
                        <li className={cx(styles.tab,this.state.tab===1?styles.tabselected:null)} onClick={() => this.setTab(1)}>Завершенные ({this.state.orders.filter(f => f.status === 0).length})</li>
                    </ul>
                    <ul className={styles.tabs}>
                        <li className={cx(styles.tabgroup,this.state.tabGroup===1?styles.tabgroupselected:null)} onClick={() => this.setTabGroup(1)}>По заказам</li>
                        {/* <li className={cx(styles.tabgroup,this.state.tabGroup===2?styles.tabgroupselected:null)} onClick={() => this.setTabGroup(2)}>По позициям</li> */}
                    </ul>
                </div>
                <Scroller className={cx(styles.innercontainer,this.state.usersections?styles.innercontainersection:null)}>
					<div className={styles.orderslistinner}>
						<div className={cx(styles.orderslist,this.state.statisticshow?null:styles.orderslistfull)}>
                            {this.state.tabGroup === 1 ?
								(
                                    this.ordersFilter().length ? this.ordersFilter().map((v,i) => <div key={i} className={cx(styles.item,this.additionalStatus(v),this.state.usersections?styles.inneritemlite:null)} onClick={() => this.orderSelect(v)}>
                                        <div className={styles.inneritem}>
                                            <div className={styles.number}>Заказ №{v.id}</div>
                                            <div className={styles.block}>
                                                <span>{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
                                                <span className={styles.delivery}>{v.isPickup ? 'Самовывоз' : 'Доставка'}</span>
                                            </div>
                                            {v.date ? <div className={styles.date}>{dateGet(v.date, {yearHide:true,showMonthFullName:true,showTime:true})}</div> : null}
                                            {this.state.usersections ? null :
                                                <div className={styles.products}>
                                                    {/* {v.products.split('\r\n').map((v,i) => <div key={i}>{v}</div>)} */}
                                                </div>}
                                        </div>
                                    </div>)
                                :
                                    <div className={styles.empty}>Заказы не найдены</div>
                            )
                            :
                            
									this.ordersFilter().map((v,i) => <div key={i} className={styles.item}>
                                    <div className={styles.name}>{v.productName}</div>
                                    <div className={styles.measure}>{v.measure} {measureTypeName[v.measureType]}</div>
                                    <div className={styles.quantity}>{v.quantity}</div>
                                </div>)
                            }
                        </div>
                        {this.state.statisticshow ?
							<div className={styles.statisticinfo}>
								<div className={styles.title}>Статистика за сегодня</div>
								<div className={styles.block}>{this.statDayShiftGet()}</div>
								<div className={cx(styles.title,styles.titleall)}>Статистика за 30 дней</div>
								<div className={styles.block}>{this.statMonthGet()}</div>
								<div className={cx(styles.title,styles.titledebt)}>Текущий долг</div>
								<div className={styles.block}><ul>
                                    <li><span><b>Текущий долг</b></span>{moneyFormat(this.state.debt)} ₽</li>
			                    </ul></div>
                            </div>
                        :null}
					</div>
				</Scroller>
            </div>
            {this.state.order && <Modal visible={this.state.modalshow} width={'900'} height={'700'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Информация о заказе №{this.state.order.id}</h4>
						<span>{dateGet(this.state.order.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
					</div>
					<Scroller className={cx(styles.productslist,this.state.usersections?(this.state.order.status === orderStatus.COOK_DONE?null:styles.productslistsection):null)}>
                        {this.state.orderinfo ? this.getOrderInfo() : <div>Информация о заказе не найдена</div>}
					</Scroller>
					<div className={styles.end}></div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
        </div>;
    }
}

export default withRouter(DashboardCourier);