import {MAP} from '../../Globals/Constants';

const locationGetCoordinates = (latitude, longitude, callback) => {
	fetch(MAP.geoUrlCoordinates(latitude, longitude), {method:'GET'})
		.then(res => res.json())
		.then(res => callback(res));
};

const locationGetAddress = (address, callback) => {
	fetch(MAP.geoUrlAddress(address), {method:'GET'})
		.then(res => res.json())
		.then(res => callback(res));
};

export {
	locationGetCoordinates,
	locationGetAddress
}