import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const getAll	= (callback) => Http.get('promocodeclients', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const get		= (id, callback) => Http.get('promocodeclients', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const add		= (data, callback) => Http.post('promocodeclients', {data}).then((res) => callback ? callback(res) : {});

export {
	getAll,
	get,
	add
}