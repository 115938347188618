import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const getAll	= (callback)			=> Http.get('refusers', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getPage	= (limits, callback)	=> Http.get('refusers', {orders:[{k:'id',isd:requestOderType.DESC}],limits}).then((res) => callback(res.data));
const get		= (id, callback)		=> Http.get('refusers', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const getByClient	= (clientId, callback)		=> Http.get('refusers', {conditions:[{k:'clientId',v:clientId}]}).then((res) => callback(res.data));

const add		= (data, callback, error)	=> Http.post('refusers', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update		= (id, data, callback, error)	=> Http.put('refusers', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const getBalanceByUserIDs = async (ids)                => await Http.request('refusers', 'getBalanceByUserIDs', {data:{ids}});
const addNotification = (refUserId, message) => Http.request('refusers', 'addNotification', {data:{id: refUserId, message}});
const addOrder = (data) => 						Http.request('refusers', 'addOrder', {data});
const getPromos	= (callback)			=> Http.get('refpromos', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const addPromo = (data) =>	Http.request('refusers', 'addPromo', {data});

const getByPromoId		= (id, callback)		=> Http.get('refpromos', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const addNewPromo		= (data)	=> Http.post('refpromos', {data});
const deletePromo		= (id)	=> Http.remove('refpromos', {conditions:[{k:'id',v:id}]});

export {
	addNewPromo,
	getByPromoId,
	getAll,
	getPage,
	get,
	getByClient,
	add,
	update,
	getBalanceByUserIDs,
	addNotification,
	addOrder,
	getPromos,
	addPromo,
	deletePromo
}