import React,{Component} from 'react';
import {Redirect} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import Sidebar from 'react-sidebar';
import Clock from 'react-live-clock';

// components
import Menu from '../../Menu';
import Update from '../../Update';

// models
import {DayShifts,Settings} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {dateGet,dateNow} from '../../../Globals/Utils';

// globals
import {userType} from '../../../Globals/Constants';

// styles
import styles from './DashboardTemplate.module.css';

class DashboardTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			sidebarOpen:false,
			userType:this.props.userType,
			dayShifts:null,
			redirect:false,
			isneedupdate:false,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			this.setState({redirect:true});
			return;
		}
		this.setState({user});
		this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
		if (this.isDayShiftNeeds()) {
			try {
				const activearea = ls('activearea');
				const ds = await DayShifts.getCurrent(activearea ? activearea.id : 0);
				this.setState({dayShifts:ds?ds.id:null});
			} catch (e) {}
		}
		Settings.getCode('site-version',  (data) => {
			if (data) {
				const version = parseInt(data.value), storedVersion = ls('version');
				if (version !== storedVersion) {
					ls('version', version);
					ls('isVersionChange', true);
					this.setState({isneedupdate:true});
				}
			}
		});
		this.setState({isneedupdate:ls('isVersionChange')});
	}
	roleNameGet = (type) => {
		switch (type) {
			case userType.OPERATOR:
				return 'АРМ Оператора';
			case userType.DISPATCHER:
				return 'АРМ Диспетчера';
			case userType.COOK:
				return 'АРМ Повара';
			case userType.COLLECTOR:
				return 'АРМ Сборщика';
			case userType.LOGIST:
				return 'АРМ Логиста';
			case userType.MONITOR:
				return 'Список заказов';
			case userType.COURIER:
				return 'АРМ Курьера';
			default: return 'АРМ';
		}
	}
	onSetSidebarOpen = (sidebarOpen) => this.setState({sidebarOpen});
	isDayShiftNeeds = () => [userType.OPERATOR,userType.LOGIST].includes(this.state.userType);
	render() {
		if (this.state.redirect) return <Redirect to='/error404' />;
		if (this.state.user === null) return null;
		return (
			<>
				<Sidebar sidebar={<Menu onClose={() => this.onSetSidebarOpen(false)} isAdmin={false} />} open={this.state.sidebarOpen} onSetOpen={this.onSetSidebarOpen} styles={{sidebar:{zIndex:10100,background:'#fff'}}}>
					<div className={styles.container}>
						<div className={styles.header}>
							<div className={styles.inner}>
								<img src={require('../../../Images/icons/menu.svg')} className={styles.menuicon} alt="меню" onClick={() => this.onSetSidebarOpen(true)} />
								<h1>{this.roleNameGet(this.state.userType)}</h1>
								<div className={styles.innerinner}>
									{this.isDayShiftNeeds() ?
										<div className={styles.ds}>
											<span>смена</span>
											{this.state.dayShifts || <div>не задана</div>}
										</div> : null}
									<div className={styles.clock}>
										<span>{dateGet(dateNow(), {showWeekday:true,showMonthFullName:true,addYearLetter:true})}</span>
										<Clock format={'HH:mm:ss'} ticking={true} />
									</div>
								</div>
							</div>
						</div>
						{this.props.children}
					</div>
				</Sidebar>
				{this.state.isneedupdate ? <Update /> : null}
			</>
		);
	}
}

export default withRouter(DashboardTemplate);