import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';

// models
import {Ads,Events,Logs} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';

// texts
import Texts from '../../Globals/Texts';

// globals
import {eventType} from '../../Globals/Constants';

// styles
import styles from './Ad.module.css';

class Ad extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		if (this.state.id !== 0) Ads.get(this.state.id, (data) => this.setState({data,dataOld:{...data},notfound:empty(data),loading:false}));
		else this.dataInit();
	}
	dataInit = () => this.setState({data:{link:'',name:'',content:''},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		const data = {
			link:this.state.data.link,
			name:this.state.data.name,
			content:this.state.data.content
		}
		if (id === 0) {
			Ads.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.ads.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.ads.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.AD);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			Ads.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.ads.update,iserror:false});
					Events.add(this.state.user.id, Texts.ads.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.AD);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/ads'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить рекламный блок' : 'Редактирование рекламного блока'}
								</span>
								<Link to={'/ad'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
							<>
								<form onSubmit={this.save} className={styles.form}>
									<div className={styles.formcontainer}>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Ссылка</span>
													<input type="text" name="link" value={this.state.data.link} placeholder="Ссылка" onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Название</span>
													<input type="text" name="name" value={this.state.data.name} placeholder="Название, описание" onChange={this.handleForm} required />
												</label>
											</div>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Контент</span>
												<textarea name="content" placeholder="Описание, контент страницы" value={this.state.data.content} onChange={this.handleForm} required></textarea>
											</label>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							</>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Ad);