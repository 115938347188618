import Http from '../../Globals/Http';
import {fortuneWheelStatus, requestOderType} from '../../Globals/Constants';

const defcount = 10000000;

const get					= (id, callback)	=> Http.get('fortunewheelclients', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));
const getAll				= (count, callback)	=> Http.get('fortunewheelclients', {orders:[{k:'id',isd:requestOderType.DESC}],limits:[count||defcount]}).then((res) => callback(res.data));

const getWinners			= (count, callback)	=> Http.get('fortunewheelclients', {conditions:[{k:'status',v:fortuneWheelStatus.WIN}],orders:[{k:'id',isd:requestOderType.DESC}],limits:[count||defcount]}).then((res) => callback(res.data));

const getActiveByClientId	= (clientId, callback) => Http.get('fortunewheelclients', {conditions:[{k:'clientId',v:clientId},{k:'status',v:fortuneWheelStatus.WIN}],limits:[5]}).then((res) => callback(res.data));

export {
	get,
	getAll,
	getWinners,
	getActiveByClientId
}