import React,{Component} from 'react';
import {withRouter} from 'react-router';

// plug-ins
import Modal from 'react-awesome-modal';
import classNames from 'classnames';

// components
import Loading from '../../Loading';
import Error from '../../Error';
import Close from '../../Close';
import Scroller from '../../Scroller';

// models
import {Orders,Events,OrderStatuses,OrderItems,Settings,Areas,DayShifts,Sections,CookOrders} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {dateNow,dateGet,quantityFormat,moneyFormat} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {orderStatus,orderItemCookStatus,eventType,measureTypeName,timers} from '../../../Globals/Constants';

// styles
import styles from './DashboardCook.module.css';
const cx = classNames.bind(styles);

class DashboardCook extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			usersections:null,
			sections:null,
			order:null,
			orders:[],
			products:[],
			areas:[],
			item:null,
			cookorders:[],
			cookorderstoday:[],
			cookordersmonth:[],
			dayshift:null,
			activearea:null,
			tab:0,
			tabGroup:1,
			percent:2,
			percenttype:1,
			noCollector:false,
			noLogist:false,
			statisticshow:true,
			modalshow:false,
			modalshoworderbusy:false,
			modalshoworderitembusy:false,
			iserror:false,
			loading:true
		};
	}
	timerId = null;
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.dataGet();		
	}
	componentWillUnmount = () => {
		clearInterval(this.timerId);
		this.timerId = null;
	}
	dataGet = async () => {
		this.setState({loading:true,iserror:false});
		const user = ls('user');
		try {
			const activearea = ls('activearea');
			const settings = ls('settings');
			const areas = await Areas.getAllActive();
			const dayshift = await DayShifts.getCurrent(activearea.id);
			const usersections = user.sections ? user.sections.split(',').map(Number) : null;
			const sections = usersections ? (await Sections.getAllSync()).data : null;
			const percent = parseInt(Settings.getByKey('cooking-percent', settings).value);
			const percentType = parseInt(Settings.getByKey('cooking-percent-type', settings).value);
			const cookordersmonth = await CookOrders.getMonth(activearea.id);
			this.setState({user,
				noCollector:parseInt(Settings.getByKey('no-collector', settings).value) === 1,
				noLogist:parseInt(Settings.getByKey('no-logist', settings).value) === 1,
				areas:areas.data,
				activearea,
				dayshift,
				usersections,
				sections,
				percent,
				percentType,
				cookordersmonth:cookordersmonth.data,
				loading:true
			});
			this.ordersUpdate();
			this.timerId = setInterval(this.ordersUpdate, timers.ORDERS_GET);
		} catch (ex) {
			this.setState({loading:false,iserror:true});
		}
	}
	ordersUpdate = async () => {
		const {activearea,usersections,dayshift,user} = this.state, orders = await Orders.getByStatuses([orderStatus.COOK_WAIT,orderStatus.COOK_ACCEPTED,orderStatus.COOK_DONE]);
		const cookorders = await CookOrders.get(activearea.id, dayshift.id, user.id);
		const cookorderstoday = await CookOrders.getToday(activearea.id, dayshift.id);
		this.setState({cookorders:cookorders.data,cookorderstoday:cookorderstoday.data});
		if (orders) {
			const settings = ls('settings'), now = dateNow(), timeDelay = parseInt(Settings.getByKey('new-order-cook', settings).value);
			const o = orders.data.filter(f => f.areaId === activearea.id);
			o.sort((a) => (a.dateCreate + timeDelay) < now ? -1 : 0);
			const sectionOrders = usersections ? [] : o;
			if (usersections) {
				for (let i = 0, j = o.length; i < j; i++) {
					const oi = await OrderItems.get(o[i].id);
					const items = oi.data.filter(f => usersections.includes(f.sectionId));
					if (items.length) {
						const co = await CookOrders.getByOrderId(o[i].id);
						if (co.data && co.data.length) {
							const products = [], sections = [];
							items.forEach((v) => {
								const c = co.data.find(f => f.orderItemId === v.id);
								if (c === undefined) {
									if (!sections.includes(v.sectionId)) products.push(v);
								}
								else if (c.cookId !== user.id) sections.push(v.sectionId);
								else {
									if (c.status !== orderItemCookStatus.DONE && !sections.includes(v.sectionId)) products.push(v);
								}
							});
							if (products.length) {
								o[i].products = products;
								sectionOrders.push(o[i]);
							}
						} else {
							o[i].products = items;
							sectionOrders.push(o[i]);
						}
					}
				}
			}
			const unique = [...new Map(sectionOrders.map(v => [v['id'], v])).values()];
			this.setState({orders:unique,loading:false});
		}
		else this.setState({loading:false,iserror:true});
	}
	additionalStatus = (order) => {
		const settings = ls('settings'), now = dateNow(), timeDelay = parseInt(Settings.getByKey('new-order-cook', settings).value);
		const date = order.date ? order.date - timeDelay * 3 : order.dateCreate + timeDelay;
		return date < now ? styles.alarm : (order.status === orderStatus.COOK_DONE ? styles.paid : null);
	}
	nextStatusGet = () => {
		let status = orderStatus.COLLECTOR_WAIT;
		if (this.state.noCollector) {
			status = orderStatus.LOGIST_WAIT;
			if (this.state.noLogist) status = orderStatus.COURIER_WAIT;
		}
		return status;
	}
	orderSelect = async (order) => {
		const {usersections} = this.state, products = usersections ? {data:order.products} : await OrderItems.get(order.id);
		const p = usersections ? products.data.filter(f => this.state.usersections.includes(f.sectionId)) : products.data;
		this.setState({order,products:p}, async () => {
			await this.orderAccept(order);
			this.modalShow()
		});
	}
	statusSet = async (item, status) => {
		const {user,dayshift,activearea,percent,percentType} = this.state;
		const cookorders = await CookOrders.getOthers(item.orderId, item.sectionId, user.id);
		if (cookorders.data && cookorders.data.length === 0) {
			if (status === orderItemCookStatus.DONE) {
				const cookorderdata = {status,dateFinish:dateNow()};
				await CookOrders.update(item.orderId, item.id, user.id, cookorderdata);
				Events.add(user.id, Texts.cook_orders.update, null, JSON.stringify(cookorderdata), item.id, eventType.COOK_ORDERS);
				const co = await CookOrders.getDone(item.orderId);
				const orderitems = await OrderItems.get(item.orderId);
				if (co.data.length === orderitems.data.length) this.orderDone();
			} else {
				const cookorderdata = {
					orderId:item.orderId,
					cookId:user.id,
					cookName:user.name,
					dayShiftId:dayshift.id,
					areaId:activearea.id,
					orderItemId:item.id,
					orderItemName:item.productName,
					sectionId:item.sectionId,
					sectionName:item.sectionName,
					price:item.price,
					quantity:item.quantity,
					percent,
					percentType,
					status
				};
				await CookOrders.add(cookorderdata);
				Events.add(user.id, Texts.cook_orders.add, null, JSON.stringify(cookorderdata), item.id, eventType.COOK_ORDERS);
			}
			// старая логика заврешения заказа
			/*
			const {products,usersections} = this.state, data = {status:orderItemCookStatus.DONE};
			await OrderItems.update(item.id, data);
			Events.add(this.state.user.id, Texts.order_items.update, null, JSON.stringify(data), item.id, eventType.ORDER_ITEM);
			products.forEach((v,i) => v.status = v.id === item.id ? orderItemCookStatus.DONE : v.status);
			this.setState({products});
			if (usersections) {
				const products = await OrderItems.get(item.orderId);
				if (products.data.filter(f => f.status === orderItemCookStatus.UNKNOWN).length === 0) this.orderDone()
			}
			*/
		} else {
			const orderItem = cookorders.data.find(f => f.orderItemId === item.id);
			if (orderItem) this.setState({item}, () => this.orderItemBusyShow());
			else this.setState({item}, () => this.orderBusyShow());
		}
		this.ordersUpdate();
	}
	orderAccept = async (order) => {
		if (order.status === orderStatus.COOK_WAIT) {
			const data = {status:orderStatus.COOK_ACCEPTED}, {user} = this.state;
			Orders.update(order.id, data, () => {
				Events.add(user.id, Texts.order.update, JSON.stringify({status:order.status}), JSON.stringify(data), order.id, eventType.ORDER);
				this.ordersUpdate();
			});
		}
	}
	orderDone = () => {
		const {order,user} = this.state;
		const data = {status:orderStatus.COOK_DONE};
		Orders.update(order.id, data, () => {
			Events.add(user.id, Texts.order.update, JSON.stringify({status:order.status}), JSON.stringify(data), order.id, eventType.ORDER);
			this.ordersUpdate();
		});
		const statuses = {
			orderId:order.id,
			userId:user.id,
			userName:user.name,
			status:data.status
		};
		OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
		this.modalHide();
		this.orderFinish(order);
	}
	orderFinish = (order) => {
		const {user} = this.state, status = this.nextStatusGet();
		const data = {status};
		Orders.update(order.id, data, () => {
			Events.add(user.id, Texts.order.update, JSON.stringify({status:order.status}), JSON.stringify(data), order.id, eventType.ORDER);
		});
		const statuses = {
			orderId:order.id,
			userId:user.id,
			userName:user.name,
			status:data.status
		};
		OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
	}
	sectionsNameGet = () => {
		const {usersections,sections} = this.state;
		const s = sections.filter(f => usersections.includes(f.id));
		return s.map((v,i) => v.name).join(', ');
	}
	modalShow = () => this.setState({modalshow:true});
	modalHide = () => this.setState({modalshow:false});
	setTab = (tab) => this.setState({tab});
	setTabGroup = (tabGroup) => this.setState({tabGroup});
	statShow = () => this.setState({statisticshow:!this.state.statisticshow});
	ordersFilter = () => {
		let {orders} = this.state;
		switch (this.state.tab) {
			default:
			case 0:
				const o = [];
				this.state.cookorders.forEach((v) => {
					const order = orders.find(f => f.id === v.orderId);
					if (order) o.push(order);
				});
				const unique = [...new Map(o.map(v => [v['id'], v])).values()];
				orders = unique;
				break;
			case 1:
				orders = orders;
				break;
			case 2:
				orders = orders.filter(f => f.date > 0);
				break;
			case 3:
				const now = dateNow();
				const settings = ls('settings');
				const timeDelay = parseInt(Settings.getByKey('cooking-order-cook', settings).value);
				orders = orders.filter(f => (f.date ? f.date - timeDelay * 3 : f.dateCreate + timeDelay) < now);
				break;
			case 4:
				orders = orders.filter(f => f.isPickup === 1);
				break;
			case 5:
				orders = orders.filter(f => f.isPickup === 0);
				break;
		}
		switch (this.state.tabGroup) {
			default:
			case 1:
				break;
			case 2:
				const o = [];
				orders.forEach((v) => {
					v.products.forEach((v) => {
						const item = o.find(f => f.productId === v.productId);
						if (item) item.quantity += v.quantity;
						else o.push({...v,quantity:v.quantity});
					});
				});
				return o;
		}
		return orders;
	}
	cookOrderStatusGet = (orderItem) => {
		const item = this.state.cookorders.find(f => f.orderId === orderItem.orderId && f.orderItemId === orderItem.id);
		return item === undefined || item.status === orderItemCookStatus.UNKNOWN ?
				<button onClick={() => this.statusSet(orderItem, orderItemCookStatus.ACCEPTED)} className={styles.ready}>Взять</button>
			:
				(
					item.status === orderItemCookStatus.ACCEPTED ? <button onClick={() => this.statusSet(orderItem, orderItemCookStatus.DONE)}>Готово</button> : <div>готово</div>
				);	
	}
	orderBusyShow = () => this.setState({modalshoworderbusy:true});
	orderItemBusyShow = () => this.setState({modalshoworderitembusy:true});
	orderBusyClose = () => this.setState({modalshoworderbusy:false,modalshoworderitembusy:false}, () => this.ordersUpdate());
	statDayShiftGet = () => {
		const cookorders = this.state.cookorders.filter(f => f.status === orderItemCookStatus.DONE);
		const {cookorderstoday,user} = this.state;
		if (cookorders.length) {
			const data = {
				sum:cookorders.reduce((a, b) => a + b.price * b.quantity, 0),
				percent:cookorders[0].percent
			};
			data.count = cookorders.reduce((a, b) => a + b.quantity, 0);
			data.amount = data.sum * data.percent / 100;
			data.orders = cookorders.map((v,i) => v.orderId).filter((v,i,a) => a.indexOf(v) === i).length;
			const orders = [];
			cookorderstoday.forEach((v) => {
				const order = orders.find(f => f.id === v.cookId);
				if (order) order.count = order.count + v.quantity;
				else orders.push({id:v.cookId,count:v.quantity});
			});
			orders.sort((a, b) => b.count - a.count);
			data.position = orders.findIndex(f => f.id === user.id) + 1;
			data.positionAll = orders.length;
			return <ul>
				<li><span>Количество заказов</span>{data.orders} шт.</li>
				<li><span>Количество позиций</span>{data.count} шт.</li>
				<li><span>Сумма всех позиций</span>{moneyFormat(data.sum)} ₽</li>
				<li><span>Процент</span>{data.percent} %</li>
				<li><span><b>Сумма к выплате</b></span>{moneyFormat(data.amount)} ₽</li>
				<li><span>Место в рейтинге</span>{data.position} / {data.positionAll}</li>
			</ul>
		}
		return <div className={styles.empty}>Нет информации</div>
	}
	statMonthGet = () => {
		const {cookordersmonth,user} = this.state;
		const cookorders = this.state.cookorders.filter(f => f.status === orderItemCookStatus.DONE && f.cookId === user.id);
		if (cookorders.length) {
			const data = {
				sum:cookorders.reduce((a, b) => a + b.price * b.quantity, 0),
				percent:cookorders[0].percent
			};
			data.count = cookorders.reduce((a, b) => a + b.quantity, 0);
			data.amount = data.sum * data.percent / 100;
			data.orders = cookorders.map((v,i) => v.orderId).filter((v,i,a) => a.indexOf(v) === i).length;
			const orders = [];
			cookordersmonth.forEach((v) => {
				const order = orders.find(f => f.id === v.cookId);
				if (order) order.count = order.count + v.quantity;
				else orders.push({id:v.cookId,count:v.quantity});
			});
			orders.sort((a, b) => b.count - a.count);
			data.position = orders.findIndex(f => f.id === user.id) + 1;
			data.positionAll = orders.length;
			return <ul>
				<li><span>Количество заказов</span>{data.orders} шт.</li>
				<li><span>Количество позиций</span>{data.count} шт.</li>
				<li><span>Сумма всех позиций</span>{moneyFormat(data.sum)} ₽</li>
				<li><span>Процент</span>{data.percent} %</li>
				<li><span><b>Сумма к выплате</b></span>{moneyFormat(data.amount)} ₽</li>
				<li><span>Место в рейтинге</span>{data.position} / {data.positionAll}</li>
			</ul>
		}
		return <div className={styles.empty}>Нет информации</div>
	}
	render() {
		if (this.state.loading) return <div className={styles.main}><Loading className={styles.loading} /></div>;
		if (this.state.iserror) return <div className={styles.main}><Error refresh={this.dataGet} /></div>;
		return <div className={styles.container}>
			<div className={styles.areaactive}>
				<button>
					<img src={require('../../../Images/icons/marker_black.svg')} alt="Точка" />
					{this.state.activearea.name}
				</button>
			</div>
			<div className={styles.main}>
				{this.state.usersections ?
					<div className={styles.header}>
						<div>Цех: <b>{this.sectionsNameGet()}</b></div>
						<div>
							<b>{this.state.user.name}</b>
							<button onClick={() => this.statShow()} className={this.state.statisticshow?null:styles.hide}>Статистика</button>
						</div>
					</div> : null}
				<div className={styles.viewcontols}>
					<ul className={styles.tabs}>
						<li className={cx(styles.tab,this.state.tab===0?styles.tabselectedcurrent:null)} onClick={() => this.setTab(0)}>В работе</li>
						<li className={cx(styles.tab,this.state.tab===1?styles.tabselected:null)} onClick={() => this.setTab(1)}>Все</li>
						<li className={cx(styles.tab,this.state.tab===2?styles.tabselected:null)} onClick={() => this.setTab(2)}>На дату</li>
						<li className={cx(styles.tab,this.state.tab===3?styles.tabselected:null)} onClick={() => this.setTab(3)}>Просроченные</li>
						<li className={cx(styles.tab,this.state.tab===4?styles.tabselected:null,styles.tabmargin)} onClick={() => this.setTab(4)}>Самовывоз</li>
						<li className={cx(styles.tab,this.state.tab===5?styles.tabselected:null)} onClick={() => this.setTab(5)}>Доставка</li>
					</ul>
					<ul className={styles.tabs}>
						<li className={cx(styles.tabgroup,this.state.tabGroup===1?styles.tabgroupselected:null)} onClick={() => this.setTabGroup(1)}>По заказам</li>
						<li className={cx(styles.tabgroup,this.state.tabGroup===2?styles.tabgroupselected:null)} onClick={() => this.setTabGroup(2)}>По позиция</li>
					</ul>
				</div>
				<Scroller className={cx(styles.innercontainer,this.state.usersections?styles.innercontainersection:null)}>
					<div className={styles.orderslistinner}>
						<div className={cx(styles.orderslist,this.state.statisticshow?null:styles.orderslistfull)}>
							{this.state.tabGroup === 1 ?
									(
											this.ordersFilter().length ? this.ordersFilter().map((v,i) => <div key={i} className={cx(styles.item,this.additionalStatus(v),this.state.usersections?styles.inneritemlite:null)} onClick={() => this.orderSelect(v)}>
												<div className={styles.inneritem}>
													<div className={styles.number}>Заказ №{v.id}</div>
													<div className={styles.block}>
														<span>{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
														<span className={styles.delivery}>{v.isPickup ? 'Самовывоз' : 'Доставка'}</span>
													</div>
													{v.date ? <div className={styles.date}>{dateGet(v.date, {yearHide:true,showMonthFullName:true,showTime:true})}</div> : null}
													{this.state.usersections ? null :
														<div className={styles.products}>
															{v.products.split('\r\n').map((v,i) => <div key={i}>{v}</div>)}
														</div>}
												</div>
											</div>)
										:
											<div className={styles.empty}>Заказы не найдены</div>
									)
								:
									this.ordersFilter().map((v,i) => <div key={i} className={styles.item}>
										<div className={styles.name}>{v.productName}</div>
										<div className={styles.measure}>{v.measure} {measureTypeName[v.measureType]}</div>
										<div className={styles.quantity}>{v.quantity}</div>
									</div>)
							}
						</div>
						{this.state.statisticshow ?
							<div className={styles.statisticinfo}>
								<div className={styles.title}>Статистика за смену</div>
								<div className={styles.block}>
									{this.statDayShiftGet()}
								</div>
								<div className={cx(styles.title,styles.titleall)}>Статистика за месяц</div>
								<div className={styles.block}>
									{this.statMonthGet()}
								</div>
							</div> : null}
					</div>
				</Scroller>
			</div>
			{this.state.order && <Modal visible={this.state.modalshow} width={'900'} height={this.state.usersections && this.state.order.status !== orderStatus.COOK_DONE ? '640' : '700'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Состав заказа №{this.state.order.id}</h4>
						<span>{dateGet(this.state.order.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
					</div>
					<Scroller className={cx(styles.productslist,this.state.usersections?(this.state.order.status === orderStatus.COOK_DONE?null:styles.productslistsection):null)}
						customControls={this.state.usersections ? (this.state.order.status === orderStatus.COOK_DONE ? <div className={cx(styles.done,styles.finish)}>Заказ готов!</div> : null) :
							this.state.order.status === orderStatus.COOK_DONE ? <div className={cx(styles.done,styles.finish)}>Заказ готов!</div> :
								<div className={styles.done}>
									<button onClick={() => this.orderDone()}><img src={require('../../../Images/icons/mark.svg')} alt="Заказ готов" />Заказ готов</button>
								</div>
						}>
						{this.state.products.map((v,i) => <div key={i} className={styles.item}>
							<div className={cx(styles.name,v.status !== orderItemCookStatus.DONE ? null : styles.statusDone)}>{v.productName}</div>
							<div className={cx(styles.measure,v.statusCollect !== orderItemCookStatus.DONE ? null : styles.statusDone)}>x {quantityFormat(v.quantity)}</div>
							<div className={cx(styles.measure,v.status !== orderItemCookStatus.DONE ? null : styles.statusDone)}>{v.measure} {measureTypeName[v.measureType]}</div>
							<div className={styles.status}>
								{this.cookOrderStatusGet(v)}
							</div>
						</div>)}
					</Scroller>
					<div className={styles.end}></div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshoworderbusy && <Modal visible={this.state.modalshoworderbusy} width={'600'} height={'220'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Заказ уже в работе!</h4>
					</div>
					<div className={styles.promptcontainer}>
						<p>Заказ <b>{this.state.order.id}</b> для цеха <b>{this.state.item.sectionName}</b> уже взяли в работу</p>
						<div>
							<button className={styles.button} onClick={() => this.orderBusyClose()}>Понятно</button>
						</div>
					</div>
				</div>
			</Modal>}
			{this.state.modalshoworderitembusy && <Modal visible={this.state.modalshoworderitembusy} width={'600'} height={'220'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Позиция уже в работе!</h4>
					</div>
					<div className={styles.promptcontainer}>
						<p>Позицию <b>{this.state.item.productName}</b> уже взяли в работу</p>
						<div>
							<button className={styles.button} onClick={() => this.orderBusyClose()}>Понятно</button>
						</div>
					</div>
				</div>
			</Modal>}
		</div>
	}
}

export default withRouter(DashboardCook);