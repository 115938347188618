import React from "react";
import styles from "./Couriers.module.css";
import { Divider } from "./Divider.js";
import Tooltip from "./Tooltip";


const Couriers = ({ couriers, courierOrderShow, getStatusRide }) => {
    couriers = couriers.filter(f => f.online);
    const allOrders = couriers.reduce((orders, courier) => {
        orders.push(...courier.orders);
        return orders;
    }, []);

    const completedOrders = allOrders.filter(order => order.order.status === 18);
    const notCompletedOrders = allOrders.filter(order => order.order.status !== 18);

    const completedCount = completedOrders.length;
    const notCompletedCount = notCompletedOrders.length;

    console.log(allOrders)

    return (
        <div className={styles.courier_stats}>
            <div className={styles.header}>
                <div className={styles.title}>Сегодня курьеры</div>
                    <div className={styles.date}>
                        <div>
                            <span>с</span>
                            <input type="date" />
                        </div>
                        <div>
                            <span>по</span>
                            <input type="date" />
                        </div>
                    </div>
                </div>
                <Divider />
                <div className={styles.container}>
                    <div className={styles.header}>
                        <span>
                            Заказов <i>{allOrders?.length}</i>
                        </span>
                        <span>
                            Выполнено <i className={styles.done}>{completedCount}</i>
                        </span>
                        <span>
                            Выполняются <i className={styles.inwork}>{notCompletedCount}</i>
                        </span>
                        <span>
                            Опаздывают <i className={styles.error}>0</i>
                        </span>
                    </div>
                    <div className={styles.content}>
                        <Divider />
                        <div className={styles.item}>
                            <div className={styles.courier}>Курьер</div>
                            <div className={styles.position}>Позиция</div>
                            <div className={styles.area}>Склад</div>
                            <div className={styles.orders}>Заказы</div>
                        </div>
                        <Divider />
                        {couriers.map((courier, i) => (
                            <div className={styles.item} key={i}>
                                <div className={styles.courier}><span className={courier.online ? styles.online : styles.offline}></span><span>{courier.name}</span></div>
                                <div className={styles.position}>{courier.online ? getStatusRide(courier) : 'Оффлайн'}</div>
                                <div className={styles.area}>{courier?.area?.name || '—'}</div>
                                <div className={styles.orders}>
                                    {courier.orders.map((order, i) => {
                                        const style = order?.order?.status === 18 ? styles.success : styles.order;
                                        return <Tooltip key={i} text={
                                                        <div>
                                                            <div>Заказ №{order?.order?.id} ({order?.order?.status === 18 ? 'Доставлен' : 'В работе'})</div>
                                                            <div>{order?.order?.address}</div>
                                                        </div>
                                                    }>
                                                    <div className={style} onClick={() => courierOrderShow(order?.order)}></div>
                                            </Tooltip>
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
        </div>
    );
};

export default Couriers;
