import React from 'react';
import {Link} from 'react-router-dom';

// components
import SimpleTemplate from '../../Components/MasterPages/SimpleTemplate';

// helpers
import {dateGet,dateNow,empty} from '../../Globals/Utils';

// globals
import {API} from '../../Globals/Constants';

// styles
import styles from './Info.module.css';

const Info = () => {
	const goto = (link) => window.location.href = link;
	const Dashboard = <Link to={'/dashboard'}>В панель администрирования</Link>;
	const CurrentTime = <span>{dateGet(dateNow(), {showTime:true,showMonthFullName:true})}</span>;
	let page = window.location.pathname,
		title = <>No Bug</>,
		text = <>
			<p>Комплексное решение для автоматизации<br/>кафе и ресторанов!</p>
			<p>
				<button className={styles.btn} onClick={() => goto('/login')}>На главную</button>
				{Dashboard}
			</p>
		</>;
	if (!empty(page)) page = page.replace('/', '');
	switch(page) {
		case 'dayopen':
			title = <>Смена открыта!{CurrentTime}</>;
			text = <>
				<p>В конце дня пожалуйста не забудьте закрыть смену.</p>
				<p>
					<button className={styles.btn} onClick={() => goto('/login')}>На главную</button>
					{Dashboard}
				</p>
			</>;
			break;
		case 'dayclose':
			title = <>Смена закрыта!{CurrentTime}</>;
			text = <>
				<p>Для начала работы пожалуйста не забудьте открыть смену.</p>
				<p>
					<button className={styles.btn} onClick={() => goto('/login')}>На главную</button>
					{Dashboard}
				</p>
			</>;
			break;
		case 'suspend':
			title = <>Режим ожидания</>;
			text = <>
				<p>Вы перешли в режим ожидания, для продолжения <br/>работы нажмите на кнопку</p>
				<p>
					<button className={styles.btn} onClick={() => goto('dashboard')}>Продолжить</button>
				</p>
			</>;
			break;
		case 'stop':
			title = <>Доступ закрыт</>;
			text = <>
				<p>Для продолжения работы вам необходимов оплатить подписку!<br/>Свяжитесь с через сайт <b>No Bug</b></p>
				<p>
					<button className={styles.btn} onClick={() => goto(API.franchise.url)}>Продолжить</button>
				</p>
			</>;
			break;
		default: break;
	}
	return (
		<SimpleTemplate title={title}>
			<div className={styles.container}>
				<img src={require('../../Images/nobug.logo.svg')} alt="No Bug. Управление общепитом" className={styles.logo} />
				{title && <h1>{title}</h1>}
				{text}
			</div>
		</SimpleTemplate>
	);
}

export default Info;