import React,{Component} from 'react';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Clients,Feedbacks} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// globals
import {GENERAL} from '../../Globals/Constants';

class FeedbacksList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			clients:[],
			user:null,
			progress:10,
			startpage:0,
			loading:true
		};
	}
	pagesize = GENERAL.PAGESIZE.medium;
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		Feedbacks.getPage([this.state.startpage,this.pagesize], async (data) => {
			Clients.load(this, data, () => this.setState({dataShow:this.dataSet(data),data,dataFull:data,user,loading:false}));
		});
		document.addEventListener('scroll', this.dataGet);
	}
	componentWillUnmount = () => this.dataStop();
	dataStop = () => document.removeEventListener('scroll', this.dataGet);
	dataGet = (e) => {
		const el = e.target.scrollingElement;
		if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
			let {startpage} = this.state;
			startpage += this.pagesize;
			Feedbacks.getPage([startpage,this.pagesize], (d) => {
				if (d.length < this.pagesize) this.dataStop();
				const data = [...this.state.dataFull,...d];
				Clients.load(this, data, () => this.setState({dataShow:this.dataSet(data),data,dataFull:data,startpage}));
			});
		}
	}
	dataSet = (data) => dgDataPrepare(data, 'feedbacks', ['id','dateCreate','clientId','name','message'], this.state.clients);
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<DataGrid
							title={'Обращения'}
							keys={['ID','Дата','Клиент','Имя','Сообщение']}
							link={'feedback'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default FeedbacksList;