import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../../Components/DataGrid';
import NotFound from '../../../Components/NotFound';

// models
import {ClientPromoCodes,Clients} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';

class ClientPromoCodesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			user:null,
			clients:[],
			progress:10,
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		ClientPromoCodes.getAll(async (data) => {
			Clients.load(this, data, () => this.setState({dataShow:this.dataSet(data),data,dataFull:data,user,loading:false}));
		});
	}
	dataSet = (data) => dgDataPrepare(data, 'clientPromoCodes', ['id','clientId','code','discount','count','dateTill','status','dateCreate'], this.state.clients);
	dataSearch = (data, search) => data.filter(f => f.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || f.code.toLowerCase().indexOf(search.toLowerCase()) !== -1);
	handleSearch = (e) => {
		const search = e.target.value;
		let data = this.state.dataFull;
		if (search.length > 1) data = this.dataSearch(data, search);
		Clients.load(this, data, () => this.setState({dataShow:this.dataSet(data),data}));
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isLoyalty={true}>
						<DataGrid
							title={'Персональные промокоды'}
							keys={['ID','Клиент','Код','Процент скидки','Количество','Дата окончания','Статус','Дата создания']}
							link={'clientpromocode'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							controlPanel={<>
								<div>
									<input type="search" placeholder="Поиск" onChange={this.handleSearch} />
								</div>
							</>}
							controlAdd={<Link to={'/loyalty/clientpromocode'}>Добавить</Link>}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default ClientPromoCodesList;