import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';
import SaveStatus from '../../../Components/SaveStatus';

// models
import {Discounts,Events,Logs} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {empty,timestampConvert} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {eventType} from '../../../Globals/Constants';

// styles
import styles from './Discount.module.css';

class Discount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		if (this.state.id !== 0) {
			Discounts.get(this.state.id, (data) => {
				if (data.dateTill) data.dateTill = timestampConvert(data.dateTill);
				this.setState({data,dataOld:{...data},notfound:empty(data),loading:false});
			});
		} else this.dataInit();
	}
	dataInit = () => this.setState({data:{name:'',code:'',discount:'',discountPercent:'',count:'',total:'',dateTill:''},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		const data = {
			userId:this.state.user.id,
			name:this.state.data.name,
			code:this.state.data.code,
			discount:this.state.data.discount,
			discountPercent:this.state.data.discountPercent,
			conditionAmount:this.state.data.conditionAmount,
			conditionTimeStart:empty(this.state.data.conditionTimeStart) ? null : this.state.data.conditionTimeStart,
			conditionTimeEnd:empty(this.state.data.conditionTimeEnd) ? null : this.state.data.conditionTimeEnd,
			dateTill:empty(this.state.data.dateTill) ? null : Math.round(new Date(this.state.data.dateTill).getTime() / 1000)
		}
		if (empty(data.discount) && empty(data.discountPercent)) {
			this.stop(() => this.setState({saveStatus:Texts.errors.discount,iserror:true}));
			return;
		}
		if ((empty(data.conditionTimeStart) && !empty(data.conditionTimeEnd)) || (!empty(data.conditionTimeStart) && empty(data.conditionTimeEnd))) {
			this.stop(() => this.setState({saveStatus:Texts.errors.conditionTime,iserror:true}));
			return;
		}
		if (id === 0) {
			Discounts.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.discounts.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.discounts.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.DISCOUNT);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			Discounts.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.discounts.update,iserror:false});
					Events.add(this.state.user.id, Texts.discounts.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.DISCOUNT);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isLoyalty={true}>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/loyalty/discounts'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить скидку' : 'Редактирование скидки'}
								</span>
								<Link to={'/loyalty/discount'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<form onSubmit={this.save}>
									<div className={styles.formcontainer}>
										<div className={styles.cell}>
											<label>
												<span>Название, описание</span>
												<input type="text" name="name" value={this.state.data.name} placeholder="Название" onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.onelinetop}>
											<div className={styles.cell}>
												<label>
													<span>Скидка, ₽</span>
													<input type="number" name="discount" min={0} max={999999} value={this.state.data.discount} placeholder="100" onChange={this.handleForm} />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Процент скидки</span>
													<input type="number" name="discountPercent" min={0} max={99} value={this.state.data.discountPercent} placeholder="5" onChange={this.handleForm} />
												</label>
											</div>
										</div>
										<div className={styles.conditions}>
											<b>Условия</b>
											<div className={styles.onelinetop}>
												<div className={styles.cell}>
													<label>
														<span>Сумма, ₽</span>
														<input type="number" name="conditionAmount" min={0} max={999999} value={this.state.data.conditionAmount} placeholder="1000" onChange={this.handleForm} />
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Начало действия</span>
														<input type="time" name="conditionTimeStart" value={this.state.data.conditionTimeStart} onChange={this.handleForm} />
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Окончание действия</span>
														<input type="time" name="conditionTimeEnd" value={this.state.data.conditionTimeEnd} onChange={this.handleForm} />
													</label>
												</div>
											</div>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Дата окончания действия</span>
												<input type="datetime-local" name="dateTill" value={this.state.data.dateTill} onChange={this.handleForm} />
												<span className={styles.hint}>не обязательное поле. путое значение - без срока действия</span>
											</label>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Discount);