import Http from '../../Globals/Http';

const get		= (id, callback)				=> Http.get('fortunewheelprizes', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const getAll	= (callback)					=> Http.get('fortunewheelprizes').then((res) => callback(res.data));

const add		= (data)						=> Http.post('fortunewheelprizes', {data});
const update	= (id, data, callback, error)	=> Http.put('fortunewheelprizes', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback ? callback(res) : {}).catch((err) => error ? error(err) : {});

export {
	get,
	getAll,
	add,
	update
}