import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Orders,Clients} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';

// globals
import {GENERAL,orderStatusName,orderStatus} from '../../Globals/Constants';

class OrdersList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			clients:[],
			user:null,
			status:orderStatus.ACTIVE,
			progress:10,
			startpage:0,
			loading:true
		};
	}
	pagesize = GENERAL.PAGESIZE.medium;
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		Orders.getPage([this.state.startpage,this.pagesize], async (data) => {
			Clients.load(this, data, () => this.setState({dataShow:this.dataSet(data),data,dataFull:data,user,loading:false}));
		});
		document.addEventListener('scroll', this.dataGet);
	}
	componentWillUnmount = () => this.dataStop();
	dataStop = () => document.removeEventListener('scroll', this.dataGet);
	dataGet = (e) => {
		const el = e.target.scrollingElement;
		if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
			let {startpage} = this.state;
			startpage += this.pagesize;
			Orders.getPage([startpage,this.pagesize], (d) => {
				if (d.length < 30) this.dataStop();
				const data = [...this.state.dataFull,...d];
				Clients.load(this, data, () => this.setState({dataShow:this.dataSet(data),data,dataFull:data,startpage}));
			});
		}
	}
	clientsGet = async (orders, callback) => {
		const {clients} = this.state;
		const ids = orders.map(m => m.clientId).filter(f => f !== 0);
		const c = await Clients.byIdsGetAsync(ids);
		c.data.forEach((v) => {
			const client = clients.find(f => f.id === v.id);
			if (client === undefined) clients.push(v);
		});
		this.setState({clients}, () => {
			if (callback) callback();
		});
	}
	dataSet = (data) => dgDataPrepare(data, 'orders', ['id','dateCreate','price','clientId','status'], this.state.clients);
	dataSearch = (data, search) => data.filter(f => {
		if(f.clientName?.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
			return true;
		}
		return false;
	});
	dataStatus = (data, status) => data.filter(f => f.status === status);
	handleSearch = (e) => {
		const search = e.target.value;
		let data = this.state.dataFull;
		if (search.length > 1) data = this.dataSearch(data, search);
		if (this.state.status != null) data = this.dataStatus(data, this.state.status);
		this.setState({dataShow:this.dataSet(data),data});
	}
	handleStatus = (e) => {
		let status = e.target.value;
		let data = this.state.dataFull;
		if (empty(status)) status = null;
		if (status != null) data = this.dataStatus(data, parseInt(status));
		this.setState({dataShow:this.dataSet(data),status,data});
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<DataGrid
							title={'Заказы'}
							tableName={'orders'}
							keys={['ID','Дата','Цена, ₽','Клиент','Статус']}
							link={'order'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							controlPanel={<>
								<div>
									<input type="search" placeholder="Поиск" onChange={this.handleSearch} />
								</div>
								<div>
									<span>Статус</span>
										<select onChange={this.handleStatus}>
										<option value=" ">все</option>
										{orderStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
									</select>
								</div>
							</>}
							controlSearch={<Link to={'/orderssearch'}>Поиск</Link>}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default OrdersList;