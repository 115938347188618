import React,{Component} from 'react';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Refusers} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// globals
import {GENERAL} from '../../Globals/Constants';

class RefUsers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			clients:[],
			user:null,
			progress:10,
			startpage:0,
			loading:true
		};
	}
	pagesize = GENERAL.PAGESIZE.medium;
	componentDidMount = () => {
		const user = ls('user');

		if (user === null) {
			window.location.href='/error403';
			return;
		}
    
        Refusers.getPage([this.state.startpage, this.pagesize], async (data) => {
            const clients = await Refusers.getBalanceByUserIDs(data?.map((client) => client.id));

            const newData = data?.map((client) => ({ ...client, ...clients?.data?.find((item) => item.id === client.id) }))?.sort((a, b) => b.amount - a.amount);

            this.setState({
                dataShow: this.dataSet(newData),
                dataFull: newData,
                user,
                data: newData,
                loading: false,
            });
        });
    
        document.addEventListener('scroll', this.dataGet);
	}
	componentWillUnmount = () => this.dataStop();
	dataStop = () => document.removeEventListener('scroll', this.dataGet);
	dataGet = (e) => {
		const el = e.target.scrollingElement;
		if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
			let {startpage} = this.state;
			startpage += this.pagesize;
			Refusers.getPage([startpage,this.pagesize], async (d) => {
                
                const clients = await Refusers.getBalanceByUserIDs(d?.map((client) => client.id));

                const newData = d?.map((client) => ({ ...client, ...clients?.data?.find((item) => item.id === client.id) }))?.sort((a, b) => b.amount - a.amount);

				if (newData.length < this.pagesize) this.dataStop();
				const data = [...this.state.dataFull,...newData];

				this.setState({
                    dataShow:this.dataSet(data),
                    data,
                    dataFull:data,
                    startpage
                });
			});
		}
	}
	dataSet = (data) => dgDataPrepare(data, 'refusers', ['id','dateCreate','name','phone','username','email','amount'], this.state.clients);
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<DataGrid
							title={'Пользователи реферальной системы'}
							keys={['ID','Дата регистрации','Имя','Телефон','Логин','Почта','Баланс']}
							link={'refuser'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default RefUsers;