import Http from '../../Globals/Http';
import {requestOderType,comonStatus} from '../../Globals/Constants';

const getAll = (id, callback) => Http.get('clientaddresses', {conditions:[{k:'clientId',v:id}],orders:[{k:'status',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const get = (id, callback) => Http.get('clientaddresses', {conditions:[{k:'clientId',v:id},{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'status',isd:requestOderType.DESC}]}).then((res) => callback(res.data));

const add = (data, callback, error) => Http.post('clientaddresses', {data:data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update = (id, data, callback, error) => Http.put('clientaddresses', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export {
	getAll,
	get,
	add,
	update
}