import React, { useState, useRef, useEffect } from 'react';
import { createPopper } from '@popperjs/core';
import styles from './Couriers.module.css'

const Tooltip = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false);
  const referenceElement = useRef(null);
  const popperElement = useRef(null);

  const showTooltip = () => {
    setIsVisible(true);
  };

  const hideTooltip = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (referenceElement.current && popperElement.current) {
      const popperInstance = createPopper(referenceElement.current, popperElement.current, {
        placement: 'top',
      });

      return () => {
        popperInstance.destroy();
      };
    }
  }, []);

  return (
    <div>
      <div
        ref={referenceElement}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {children}
      </div>
      {isVisible && (
        <div
          ref={popperElement}
          role="tooltip"
          style={{ display: isVisible ? 'block' : 'none' }}
          className={styles.tooltip}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;