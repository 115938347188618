import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';

// models
import {Users,UserRoles,Events,Logs,Refusers, Refuserrequests} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty,phoneNormalize,dateNow} from '../../Globals/Utils';
import errorsParse from '../../Globals/Errors';

// texts
import Texts from '../../Globals/Texts';

// globals
import {userStatusName,userStatus,userType,eventType,errorType, refRequestStatusName} from '../../Globals/Constants';

// styles
import styles from './RefUser.module.css';
import Alert, { alertShow } from '../../Components/Alert';
import { Divider } from '../../Components/Dashboards/Logist/Divider';
const cx = classNames.bind(styles);

class RefRequest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			roles:[],
			refUserId:null,
			userRoles:[],
			userRolesOld:[],
			areas:[],
			sectionsselect:[],
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false,
            username:null,
            email:null,
			refUser:null,
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		Refuserrequests.get(this.state.id, (data) => {
			Refusers.get(data.refUserId, (refuser) => {
				this.setState({refuser});
			});
            this.setState({data,dataOld:{...data},notfound:empty(data),loading:false});
        });
	}
	dataInit = () => this.setState({
		data:{
			name:'',
			phone:'',
			username:'',
			type:userType.OPERATOR,
			email:'',
			status:userStatus.ACTIVE,
			password:'',
			description:''
		},
		id:0,
		userRoles:[],
		userRolesOld:[],
		loading:false
	});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		const data = {
			amount: this.state.data.amount,
			comment: this.state.data.comment,
			status: this.state.data.status,
			refUserId: this.state.data.refUserId,
		}
		if (id === 0) {
			Refuserrequests.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.users.add,iserror:false,id});
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicatePhone:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			Refuserrequests.update(id, data, () => {
				this.stop(() => {
                    alertShow('Сохранено');
					this.setState({saveStatus:Texts.users.update,iserror:false});

					let message = '';

					if(this.state.data.status === '0') {
						message = 'Новый статус: Заявка <span style="color: #f9ca24">отклонена</span>.';
					}

					if(this.state.data.status === '1') {
						message = 'Новый статус: Заявка <span style="color: #2ecc71">выполнена</span>.';
					}

					if(this.state.data.status === '2') {
						message = 'Новый статус: Заявка <span style="color: #e74c3c">отклонена</span>.';
					}

					Refusers.addNotification(this.state.data.refUserId, `Статус вашей заявки #${id} был изменен. ${message}`);
					Events.add(this.state.user.id, Texts.users.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.USER);
				});
			}, (err) => {
				this.stop(() => {
                    alertShow('Ошибка при сохранении');
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicatePhone:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	inRole = (roleId) => this.state.userRoles.filter(f => f.roleId === roleId).length !== 0;
	sectionCheck = (id) => this.state.sectionsselect.includes(id)
	sectionSet = (id) => {
		const {sectionsselect} = this.state;
		if (sectionsselect.includes(id)) this.setState({sectionsselect:sectionsselect.filter(f => f !== id)});
		else {
			sectionsselect.push(id);
			this.setState({sectionsselect});
		}
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/refrequests'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить заявку на вывод' : 'Редактирование заявки на вывод'}
								</span>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<form onSubmit={this.save}>
									<div className={cx(styles.formcontainer,styles.formcontainerfirst)}>
										<div className={styles.oneline}>
                                            <div className={styles.cell}>
                                                <label>
                                                    <span>ID</span>
                                                    <input type="text" name="refUserId" value={this.state.data.refUserId} placeholder="ID" disabled onChange={this.handleForm} required={this.state.id===0} />
                                                </label>
                                            </div>
                                            <div className={styles.cell}>
                                                <label>
                                                    <span>Имя</span>
                                                    <input type="text" name="name" value={this.state.refuser?.name} placeholder="Имя" disabled onChange={this.handleForm} required={this.state.id===0} />
                                                </label>
                                            </div>
										</div>
										<div className={styles.oneline}>
                                            <div className={styles.cell}>
                                                <label>
                                                    <span>Почта</span>
                                                    <input type="text" name="email" value={this.state.refuser?.email} placeholder="Почта" disabled onChange={this.handleForm} required={this.state.id===0} />
                                                </label>
                                            </div>
                                            <div className={styles.cell}>
                                                <label>
                                                    <span>Телефон</span>
                                                    <input type="text" name="phone" value={this.state.refuser?.phone} disabled placeholder="Телефон" onChange={this.handleForm} required={this.state.id===0} />
                                                </label>
                                            </div>
										</div>
										<Divider />
										<div className={styles.oneline}>
                                            <div className={styles.cell}>
                                                <label>
                                                    <span>Сумма</span>
                                                    <input type="text" name="amount" value={this.state.data.amount} placeholder="Сумма" onChange={this.handleForm} required={this.state.id===0} />
                                                </label>
                                            </div>
                                            {this.state.id === 0 && <div className={styles.cell}>
                                                <label>
                                                    <span>ID Пользователя</span>
                                                    <input type="text" name="refUserId" value={this.state.data.refUserId} placeholder="Пользователь" onChange={this.handleForm} required={this.state.id===0} />
                                                </label>
                                            </div>}
                                            <div className={styles.cell}>
                                                <label>
                                                    <span>Комментарий</span>
                                                    <input type="text" name="comment" value={this.state.data.comment} placeholder="Комментарий" onChange={this.handleForm} required={this.state.id===0} />
                                                </label>
                                            </div>
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Статус</span>
													<select name="status" onChange={this.handleForm} defaultValue={parseInt(this.state.data.status)}>
														{refRequestStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</label>
											</div>
										</div>
										{parseInt(this.state.data.type) !== userType.OPERATOR ?
											<div className={styles.buttons}>
												{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
												{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
											</div> : null}
									</div>
								</form>
							}
						</div>
                        <Alert />
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(RefRequest);