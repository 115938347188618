import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const getAll	= (callback)					=> Http.get('promocodes', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const get		= (id, callback)				=> Http.get('promocodes', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const getActive	= (callback)					=> Http.request('promocodes', 'activeGet').then((res) => callback(res.data));
const check		= (code, callback)				=> Http.request('promocodes', 'check', {code}).then((res) => callback(res.data));
const getByCode	= (code, callback)				=> Http.get('promocodes', {conditions:[{k:'code',v:code}]}).then((res) => callback(res.data));

const add		= (data, callback, error)		=> Http.post('promocodes', {data}).then((res) => callback ? callback(res) : {}).catch((err) => error ? error(err) : {});
const update	= (id, data, callback, error)	=> Http.put('promocodes', {data,conditions:[{k:'id',v:id}]}).then((res) => callback ? callback(res) : {}).catch((err) => error ? error(err) : {});

export {
	getAll,
	get,
	getActive,
	check,
	getByCode,
	add,
	update
}