import React from 'react';
import { Link } from 'react-router-dom';

// helpers
import { phoneFormatter, weightFormat, dateGet } from '../../Globals/Utils';

// globals
import {
  API,
  userStatusName,
  userTypeName,
  comonStatusName,
  eventTypeName,
  orderStatusName,
  transactionPaymentTypeName,
  transactionStatusName,
  requestStatusName,
  ratingTypeName,
  requestTypeName,
  lotteryStatusName,
  fortuneWheelStatusName,
  refRequestStatusName,
} from '../../Globals/Constants';

// styles
import styles from './DataGrid.module.css';

const DataGrid = (props) => {
  const {
    title,
    keys,
    data,
    dataFull,
    link,
    notFound,
    controlPanel,
    controlAdd,
    controlSearch,
    tableName,
  } = props;
  const linkShow = (link, item, parent, table) => {
    if (
      (table === 'orders' && item.key === 'dateCreate') ||
      (link !== 'clientrank' && item.key === 'id') ||
      item.key === 'name' ||
      item.key === 'productName' ||
      item.key === 'title' ||
      (table === 'events' && item.key === 'message')
    )
      return <Link to={`${link}/${idGet(parent)}`}>{item.show}</Link>;
    if (table === 'transactions' && item.key === 'orderId')
      return <Link to={`order/${item.value}`}>{item.show}</Link>;
    if (link === 'refuserrequests' && item.key === 'refUserId')
      return <Link to={`refuser/${item.value}`}>{item.show}</Link>;
    if (table === 'stories' && item.key === 'imageAvatar') {
      const uri = `${API.assets}/stories/${item.value}`;
      return (
        <Link to={`${link}/${idGet(parent)}`}>
          <img src={uri} alt="" className={styles.img} />
        </Link>
      );
    }
    return item.show;
  };

  const idGet = (parent) => {
    for (const item of parent) {
      if (item.key === 'id') return item.value;
    }
    return 0;
  };
  return (
    <div className={styles.container}>
      {title || controlAdd || controlSearch ? (
        <h4>
          {title}
          {controlAdd || null}
          {controlSearch ? (
            <div className={styles.search}>{controlSearch}</div>
          ) : null}
        </h4>
      ) : null}
      {dataFull === null || dataFull.length === 0 ? (
        notFound
      ) : (
        <div className={styles.list}>
          {controlPanel ? (
            <div className={styles.controls}>{controlPanel}</div>
          ) : null}
          {data === null || data.length === 0 ? (
            notFound
          ) : (
            <table>
              <thead>
                <tr>
                  <th>№</th>
                  {keys.map((v, i) => (
                    <th key={i}>{v}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((v, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    {v.map((r, j) => (
                      <td key={j}>{linkShow(link, r, v, tableName)}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default DataGrid;

export const dgDataPrepare = (data, tableName, keys = [], collection = {}) => {
  const out = [];
  data.forEach((v) => {
    const inner = [];
    if (keys.length === 0) {
      for (const k in v) inner.push(valueGet(k, v[k], tableName, collection));
    } else
      keys.forEach((k) => inner.push(valueGet(k, v[k], tableName, collection)));
    out.push(inner);
  });
  return out;
};
const valueGet = (key, value, tableName, collection) => {
  let show = value;
  switch (key) {
    case 'phone':
      show = phoneFormatter(value);
      break;
    case 'amount':
      show = <span>{value} ₽</span>;
      break;
    case 'dateCreate':
      if (tableName === 'refusers') {
        show = dateGet(value, { showTime: false, showSeconds: false });
      } else {
        show = dateGet(value, { showTime: true, showSeconds: true });
      }
      break;
    case 'dateTill':
      show = value
        ? dateGet(value, { showTime: true, showSeconds: true })
        : '—';
      break;
    case 'status':
      show = statusGet(tableName, value);
      break;
    case 'online':
      show = value ? (
        <span className={styles.statusOnline}>Онлайн</span>
      ) : (
        <span className={styles.statusOffline}>Оффлайн</span>
      );
      break;
    case 'type':
      show = typeGet(tableName, value);
      break;
    case 'userId':
      if (collection) {
        const user = collection.find((f) => f.id === value);
        show = user ? <Link to={`/user/${user.id}`}>{user.name}</Link> : '—';
      } else show = null;
      break;
    case 'method':
      show = methodGet(value);
      break;
    case 'rankType':
      if (collection) {
        const rank = collection[value];
        show = rank ?? value;
      } else show = value;
      break;
    case 'clientId':
      if (collection.length) {
        const client = collection.find((f) => f.id === value);
        show = client ? (
          <Link to={`/client/${client.id}`}>
            {client.name || phoneFormatter(client.phone)}
          </Link>
        ) : (
          '—'
        );
      } else if (value > 0) {
        show = <Link to={`/client/${value}`}>{value}</Link>;
      } else {
        show = null;
      }
      break;
    case 'priceDiscount':
      show = value === 0 ? null : value;
      break;
    case 'discountPercent':
      show = value === 0 ? null : value;
      break;
    case 'discount':
      show = value === 0 ? null : value;
      break;
    case 'paymentType':
      show = transactionPaymentTypeName[value];
      break;
    case 'brutto':
    case 'netto':
    case 'portion':
      show = weightFormat(value / 1000);
      break;
    case 'rating':
      show = ratingTypeName[value];
      break;
    case 'isEmpty':
      show = value ? 'Да' : '';
      break;
    case 'offline':
      show = value || '—';
      break;
    case 'freeDelivery':
      show = value ? 'Да' : '';
      break;
    case 'isUsed':
      show = value ? 'Да' : '';
      break;
    case 'alwaysPaid':
      show = value ? 'Да' : 'Нет';
      break;
    default:
      break;
  }
  return { key, value, show };
};
const statusGet = (tableName, value) => {
  switch (tableName) {
    case 'users':
    case 'clients':
      return userStatusName[value];
    case 'orders':
      return orderStatusName[value];
    case 'transactions':
      return transactionStatusName[value];
    case 'storerequests':
      return requestStatusName[value];
    case 'lotteries':
      return lotteryStatusName[value];
    case 'fortuneWheelClients':
      return fortuneWheelStatusName[value];
    case 'refuserrequests':
      return refRequestStatusName[value];
    default:
      return comonStatusName[value];
  }
};
const typeGet = (tableName, value) => {
  switch (tableName) {
    case 'users':
      return userTypeName[value];
    case 'events':
      return eventTypeName[value];
    case 'storerequests':
      return requestTypeName[value];
    default:
      return value;
  }
};
const methodGet = (value) => {
  switch (value) {
    case 1:
      return 'Qiwi';
    case 2:
      return 'Банковская карта';
    case 3:
      return 'Баланс телефона';
    default:
      return value;
  }
};
