import React,{Component} from 'react';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';

// models
import {License} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {dateGet} from '../../Globals/Utils';

// globals
import {API} from '../../Globals/Constants';

// styles
import styles from './License.module.css';

class StatisticsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			license:null,
			progress:10,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		const lic = await License.get();
		this.setState({user,license:lic.data[0],loading:false});
	}
	expireGet = (d) => d === 0 ? 'бессрочно' : `до ${dateGet(d)}`;
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>Лицензия</h4>
							<div className={styles.formcontainer}>
								<h3>No Bugs</h3>
								Комплексное решение для автоматизации кафе и ресторанов.<br/>
								Система администрирования
								<div className={styles.version}>версия {API.version}</div>
								<div className={styles.license}>
									Активная лицензия, действует {this.expireGet(this.state.license.dateExpire)}
								</div>
							</div>
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default StatisticsList;