import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../../Components/DataGrid';
import NotFound from '../../../Components/NotFound';

// models
import {FortuneWheelClients} from '../../../Models';

// globals
import {fortuneWheelStatus,fortuneWheelStatusName} from '../../../Globals/Constants';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {empty} from '../../../Globals/Utils';

class FortuneClientsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			user:null,
			status:fortuneWheelStatus.IN_ACTIVE,
			progress:10,
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		FortuneWheelClients.getAll(2000, async (data) => {
			this.setState({dataShow:this.dataSet(data),data,dataFull:data,user,loading:false})
		});
	}
	dataSet = (data) => dgDataPrepare(data, 'fortuneWheelClients', ['id','clientId','clientName','phone','discount','productName','freeDelivery','isUsed','status','dateCreate']);
	dataSearch = (data, search) => data.filter(f => f.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || f.code.toLowerCase().indexOf(search.toLowerCase()) !== -1);
	handleSearch = (e) => {
		const search = e.target.value;
		let data = this.state.dataFull;
		if (search.length > 1) data = this.dataSearch(data, search);
		if (this.state.status != null) data = this.dataStatus(data, this.state.status);
		this.setState({dataShow:this.dataSet(data),data});
	}
	dataStatus = (data, status) => data.filter(f => f.status === status);
	handleStatus = (e) => {
		let status = e.target.value;
		let data = this.state.dataFull;
		if (empty(status)) status = null;
		if (status != null) data = this.dataStatus(data, parseInt(status));
		this.setState({dataShow:this.dataSet(data),status,data});
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isLoyalty={true}>
						<DataGrid
							title={'Участники (попытки)'}
							keys={['ID','Клиент ID','Клиент','Телефон','Процент скидки','Товар','Доставка','Использован','Статус','Дата создания']}
							link={'wofclient'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							controlPanel={<>
								<div>
									<input type="search" placeholder="Поиск" onChange={this.handleSearch} />
								</div>
								<div>
									<span>Статус</span>
										<select onChange={this.handleStatus}>
										<option value=" ">все</option>
										{fortuneWheelStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
									</select>
								</div>
							</>}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default FortuneClientsList;