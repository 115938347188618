import React,{Component} from 'react';

// styles
import styles from './NotFound.module.css';

export default class NotFound extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message:props.message
		};
	}
	render() {
		return (
			<div className={styles.notFound}>
				{this.state.message ||
					<>
						<h4>Ничего не найдено в выбранной области поиска!</h4>
						<p>
							Воспользуйтесь меню, смягчите условия поиска или фильтра.<br/>
							Задайте запрос по-другому или установите более мягкие ограничения.
						</p>
					</>
				}
		</div>
		);
	}
}