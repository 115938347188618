import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import classNames from 'classnames';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';
import Alert,{alertShow} from '../../Components/Alert';

// models
import {Products,ProductRelations,ProductRelationItems,Events,Logs,Sections} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';

// texts
import Texts from '../../Globals/Texts';

// globals
import {eventType} from '../../Globals/Constants';

// styles
import styles from './ProductRelation.module.css';
const cx = classNames.bind(styles);

class ProductRelation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			sections:[],
			products:[],
			relations:[],
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		Sections.getActiveAll((data) => {
			this.setState({sections:data});
			Products.getAllActive((data) => {
				this.setState({products:data.filter(f => f.siteHide === 0)});
				if (this.state.id !== 0) {
					ProductRelations.get(this.state.id, (data) => {
						this.setState({data,dataOld:{...data},notfound:empty(data)});
						ProductRelationItems.get(data.id, (data) => this.setState({relations:data,loading:false}));
					});
				}
				else this.dataInit();
			});
		});
	}
	dataInit = () => this.setState({data:{sectionId:0,sectionName:''},id:0,relations:[],loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	handleCheckboxForm = (e, id) => {
		if (e.target.checked) this.setState({relations:[...this.state.relations, this.state.products.find(f => f.id === id)]})
		else {
			const relations = this.state.relations.filter(f => f.productName ? (f.productId !== id) : (f.id !== id))
			this.setState({relations})
		}
	}
	save = (e) => {
		e.preventDefault();
		const {id,relations} = this.state;
		if (relations.length === 0) {
			alertShow('<b>Ошибка!</b><br/>Вы не выбрали ни одной позиции для рекомендации!', true);
			return;
		}
		this.setState({issave:true});
		const sectionId = parseInt(this.state.data.sectionId);
		const data = {
			sectionId,
			sectionName:this.state.sections.filter(f => f.id === sectionId)[0].name
		};
		ProductRelationItems.remove(id);
		setTimeout(() => {
			if (id === 0) {
				ProductRelations.add(data, (res) => {
					this.stop(() => {
						const id = res.data.id;
						this.setState({saveStatus:Texts.productrelations.add,iserror:false,id});
						Events.add(this.state.user.id, Texts.productrelations.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.PRODUCT_RELATION);
						this.itemsSave(id);
					});
				 }, (err) => {
					this.stop(() => {
						this.setState({saveStatus:Texts.errors.internalError,iserror:true});
						Logs.add(err);
					});
				 });
			} else {
				ProductRelations.update(id, data, () => {
					this.stop(() => {
						this.setState({saveStatus:Texts.productrelations.update,iserror:false});
						Events.add(this.state.user.id, Texts.productrelations.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.PRODUCT_RELATION);
						this.itemsSave(id);
					});
				}, (err) => {
					this.stop(() => {
						this.setState({saveStatus:Texts.errors.internalError,iserror:true});
						Logs.add(err);
					});
				});
			}
		}, 1000);
	}
	itemsSave = (id) => this.state.relations.forEach((v) => ProductRelationItems.add({productRelationId:id,productId:v.productName?v.productId:v.id,productName:v.productName||v.name}));
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/productrelations'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить связанные позиции' : 'Редактирование связанной позиции'}
								</span>
								<Link to={'/productrealtion'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<form onSubmit={this.save}>
									<div className={cx(styles.formcontainer,styles.formcontainerfirst)}>
										<div className={styles.cell}>
											<label>
												<span>Раздел</span>
												<select name="sectionId" onChange={this.handleForm} defaultValue={this.state.data.sectionId} required>
													<option value=""></option>
													{this.state.sections.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
												</select>
											</label>
										</div>
									</div>
									<div className={styles.formcontainer}>
										<h4 className={styles.title}>Связанные позиции</h4>
										{this.state.relations && this.state.relations.length ?
											<ul className={styles.selectedproducts}>
												{this.state.relations.map((v,i) => <li key={i}><label><input type="checkbox" checked={true} onChange={(e) => this.handleCheckboxForm(e, v.productName?v.productId:v.id)} /> {v.name||v.productName}</label></li>)}
											</ul> : null}
										<ul>
											{this.state.products.filter(f => this.state.relations.find(d => d.productName ? (d.productId === f.id) : (d.id === f.id)) === undefined).map((v,i) => <li key={i}><label><input type="checkbox" checked={false} onChange={(e) => this.handleCheckboxForm(e, v.id)}  /> {v.name}</label></li>)}
										</ul>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							}
						</div>
						<Alert />
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(ProductRelation);