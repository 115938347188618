import { comonStatus } from '../../Globals/Constants';
import Http from '../../Globals/Http';

const get				= async (id)					=> await Http.get('accountings', {conditions:[{k:'userId',v:id},{k:'status',v:comonStatus.ACTIVE}]});

const report		= {
	finish				: async (areaId, dayShiftId)	=> await Http.request('accountings', 'finishGet', {areaId,dayShiftId}),
	inWork				: async (areaId, dayShiftId)	=> await Http.request('accountings', 'inWorkGet', {areaId,dayShiftId}),
	finishOld			: async (areaId, dayShiftId)	=> await Http.request('accountings', 'finishOldGet', {areaId,dayShiftId}),
	inWorkOld			: async (areaId, dayShiftId)	=> await Http.request('accountings', 'inWorkOldGet', {areaId,dayShiftId}),
	logist				: async (areaId, dayShiftId)	=> await Http.request('accountings', 'logistClose', {areaId,dayShiftId})
};

const byOrderIdGet		= async (orderId)				=> await Http.get('accountings', {conditions:[{k:'orderId',v:orderId}]});

const byUserIdsGet		= async (ids)					=> await Http.request('accountings', 'byUserIdsGet', ids);

const add				= async (data)					=> await Http.post('accountings', {data});
const update			= async (id, data)				=> await Http.put('accountings', {data,conditions:[{k:'id',v:id}]})

const byOrderIdUpdate	= async (orderId, data)			=> await Http.put('accountings', {data,conditions:[{k:'orderId',v:orderId}]})

const remove			= async (id) 					=> await Http.remove('accountings', {conditions:[{k:'id',v:id}]});

export {
	get,
	report,
	byOrderIdGet,
	byUserIdsGet,
	add,
	update,
	byOrderIdUpdate,
	remove
}