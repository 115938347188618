import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import {Line} from 'react-chartjs-2';
import classNames from 'classnames';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Areas,Clients,Orders,Feedbacks,FortuneWheelClients} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {dateGet,dateNow,moneyFormat,tsHour,tsDay} from '../../Globals/Utils';

// globals
import {GENERAL,timers} from '../../Globals/Constants';

// styles
import styles from './Statistics.module.css';
const cx = classNames.bind(styles);

class StatisticsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			areas:[],
			ordersHistory:[],
			ordersHistoryCounts:[],
			ordersNow:[],
			ordersNowCounts:[],
			clientsCount:[],
			clientsNowCount:[],
			ordersAreas:[],
			ordersAreasCount:[],
			popularAll:[],
			popularNow:[],
			dataRating:[],
			dataFeedback:[],
			dataWOF:[],
			progress:10,
			loading:true
		};
	}
	timerId = null;
	pagesize = GENERAL.PAGESIZE.dashboard;
	chartOptions = {
		scales:{y:{ticks:{beginAtZero:true,callback:(v) => v % 1 === 0 ? v : null}}},
		maintainAspectRatio:false,
		plugins:{
			legend:{display:false},
			tooltip:{callbacks:{label:(item) => item.dataset.iscount ? `${item.raw} шт.` : (item.dataset.isclient ? `${item.raw} чел.` : `${moneyFormat(item.raw, false)} ₽`)}}
		}
	};
	colors = {
		backgroundColor:['rgb(255,99,132)','rgb(54,162,235)','rgb(255,206,86)','rgb(75,192,192,86)','rgb(153,102,255)','rgb(255,159,64)'],
		borderColor:['rgba(255,99,132,.2)','rgba(54,162,235,.2)','rgba(255,206,86,.2)','rgba(75,192,192,.2)','rgba(153,102,255,.2)','rgba(255,159,64,.2)']
	};
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		const areas = await Areas.getAllActive();
		this.setState({user,areas:areas.data});
		Orders.getFeedbacks([0,this.pagesize], (data) => this.setState({dataRating:dgDataPrepare(data, 'orders', ['id','dateCreate','price','feedback','rating'])}));
		Feedbacks.getPage([0,this.pagesize], (data) => this.setState({dataFeedback:dgDataPrepare(data, 'feedbacks', ['id','dateCreate','name','message'])}));
		FortuneWheelClients.getWinners(5, async (data) => this.setState({dataWOF:dgDataPrepare(data, 'fortuneWheelClients', ['id','clientId','clientName','phone','discount','productName','freeDelivery','isUsed','dateCreate'])}));
		this.ordersPanelsUpdate();
		this.timerId = setInterval(this.ordersPanelsUpdate, timers.ORDERS_ADMIN);
	}
	componentWillUnmount = () => {
		clearInterval(this.timerId);
		this.timerId = null;
	}
	ordersPanelsUpdate = async () => {
		const {areas} = this.state, now = dateNow(), start = now - tsDay * 14;
		const o1 = await Orders.stats.get(start, now), o2 = await Orders.stats.get();
		const c1 = await Clients.stats.get(), c2 = await Clients.stats.getToday();
		const p1 = await Orders.stats.popular(true), p2 = await Orders.stats.popular();
		const data1 = o1.data.reduce((r, a) => {
			const d = Math.floor(a.dateCreate / tsDay);
			r[d] = [...r[d] || [], a];
			return r;
		}, {});
		const data2 = o2.data.reduce((r, a) => {
			const d = Math.floor(a.dateCreate / tsHour);
			r[d] = [...r[d] || [], a];
			return r;
		}, {});
		const data3 = c2.data.reduce((r, a) => {
			const d = Math.floor(a.dateCreate / tsHour);
			r[d] = [...r[d] || [], a];
			return r;
		}, {});
		const ordersHistory = [], ordersHistoryCounts = [], ordersNow = [], ordersNowCounts = [], clientsCount = [], clientsNowCount = [];
		let ordersAreas = [], ordersAreasCount = [];
		const labelsHistory = Object.entries(data1).map((v) => {
			const d = dateGet(v[0] * tsDay, {hideYear:true});
			ordersHistory.push(v[1].reduce((a, v) => a + (v.priceDiscount||v.price), 0));
			ordersHistoryCounts.push(v[1].length);
			return `${d.day}.${d.month}`;
		});
		const labelsNow = Object.entries(data2).map((v) => {
			const d = dateGet(v[0] * tsHour, {onlyTime:true});
			ordersNow.push(v[1].reduce((a, v) => a + (v.priceDiscount||v.price), 0));
			ordersNowCounts.push(v[1].length);
			return d;
		});
		const labelsClients = c1.data.slice(-30).map((v) => {
			const d = dateGet(v.date, {hideYear:true});
			clientsCount.push(v.newUsers);
			return `${d.day}.${d.month}`;
		});
		const labelsClientsNow = Object.entries(data3).map((v) => {
			const d = dateGet(v[0] * tsHour, {onlyTime:true});
			clientsNowCount.push(v[1].length);
			return d;
		});
		if (areas.length > 1) {
			const dt1 = [], dt2 = [];
			let labels = null;
			areas.forEach((v,i) => {
				const o = o1.data.filter(f => f.areaId === v.id);
				const data = o.reduce((r, a) => {
					const d = Math.floor(a.dateCreate / tsDay);
					r[d] = [...r[d] || [], a];
					return r;
				}, {});
				const prices = [], counts = [];
				const l = Object.entries(data).map((v) => {
					const d = dateGet(v[0] * tsDay, {hideYear:true});
					prices.push(v[1].reduce((a, v) => a + (v.priceDiscount||v.price), 0));
					counts.push(v[1].length);
					return `${d.day}.${d.month}`;
				});
				if (labels === null) labels = l;
				dt1.push({data:prices,backgroundColor:this.colors.backgroundColor[i],borderColor:this.colors.borderColor[i],label:`${v.name}`,tension:.3});
				dt2.push({data:counts,backgroundColor:this.colors.backgroundColor[i],borderColor:this.colors.borderColor[i],label:`${v.name}`,tension:.3,iscount:true});
			});
			ordersAreas = {labels,datasets:dt1};
			ordersAreasCount = {labels,datasets:dt2};
		}
		this.setState({
			ordersHistory:{labels:labelsHistory,datasets:[{data:ordersHistory,backgroundColor:'rgb(54,162,235)',borderColor:'rgba(54,162,235,.4)',tension:.3}]},
			ordersHistoryCounts:{labels:labelsHistory,datasets:[{data:ordersHistoryCounts,backgroundColor:'rgb(75,192,192)',borderColor:'rgba(75,192,192,.4)',tension:.3,iscount:true}]},
			clientsCount:{labels:labelsClients,datasets:[{data:clientsCount,backgroundColor:'rgb(255,99,132)',borderColor:'rgba(255,99,132,.4)',tension:.3,isclient:true}]},
			ordersNow:{labels:labelsNow,datasets:[{data:ordersNow,backgroundColor:'rgb(54,162,235)',borderColor:'rgba(54,162,235,.4)',tension:.3}]},
			ordersNowCounts:{labels:labelsNow,datasets:[{data:ordersNowCounts,backgroundColor:'rgb(75,192,192)',borderColor:'rgba(75,192,192,.4)',tension:.3,iscount:true}]},
			clientsNowCount:{labels:labelsClientsNow,datasets:[{data:clientsNowCount,backgroundColor:'rgb(255,99,132)',borderColor:'rgba(255,99,132,.4)',tension:.3,isclient:true}]},
			ordersAreas,ordersAreasCount,
			popularAll:p1.data,popularNow:p2.data,
			loading:false
		});
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>Отзывы на заказы</h4>
							<div className={styles.formcontainer}>
								<DataGrid
									keys={['Заказ','Дата','Цена, ₽','Отзыв','Оценка']}
									link={'order'}
									data={this.state.dataRating}
									dataFull={this.state.dataRating}
									controlPanel={<Link to={'/ratings'}>все отзывы</Link>}
									notFound={<NotFound />} />
							</div>
							<h4>Обращения в поддержку</h4>
							<div className={styles.formcontainer}>
								<DataGrid
									keys={['ID','Дата','Имя','Сообщение']}
									link={'feedback'}
									data={this.state.dataFeedback}
									dataFull={this.state.dataFeedback}
									controlPanel={<Link to={'/feedbacks'}>все обращения</Link>}
									notFound={<NotFound />} />
							</div>
							<h4>Победители в колесе фортуны</h4>
							<div className={styles.formcontainer}>
								<DataGrid
									keys={['ID','Клиент ID','Клиент','Телефон','Процент скидки','Товар','Доставка','Использован','Дата']}
									link={'wofclientwin'}
									data={this.state.dataWOF}
									dataFull={this.state.dataWOF}
									controlPanel={<Link to={'/loyalty/wofclientswin'}>все победители</Link>}
									notFound={<NotFound />} />
							</div>
							<h4>Статистика</h4>
							<div className={styles.formcontainer}>
								<h4>За сегодня</h4>
								<div className={styles.statcontainer}>
									{this.state.ordersNow.labels.length ?
										<>
											<div className={styles.chart}>
												<h4>Выручка, ₽</h4>
												<Line data={this.state.ordersNow} options={this.chartOptions} />
											</div>
											<div className={styles.chart}>
												<h4>Заказы, шт.</h4>
												<Line data={this.state.ordersNowCounts} options={this.chartOptions} />
											</div>
											<div className={styles.chart}>
												<h4>Новые пользователи, чел.</h4>
												<Line data={this.state.clientsNowCount} options={this.chartOptions} />
											</div>
										</> : <p className={styles.notfound}>Нет данных</p>}
								</div>
								<h4>За 14 дней</h4>
								<div className={cx(styles.statcontainer,styles.statcontainerlight)}>
									{this.state.ordersHistory.labels.length ?
										<>
											<div className={styles.chart}>
												<h4>Выручка, ₽</h4>
												<Line data={this.state.ordersHistory} options={this.chartOptions} />
											</div>
											<div className={styles.chart}>
												<h4>Заказы, шт.</h4>
												<Line data={this.state.ordersHistoryCounts} options={this.chartOptions} />
											</div>
											<div className={styles.chart}>
												<h4>Новые пользователи, чел.</h4>
												<Line data={this.state.clientsCount} options={this.chartOptions} />
											</div>
										</> : <p className={styles.notfound}>Нет данных</p>}
								</div>
							</div>
							{this.state.areas.length > 1 ?
								<>
									<h4>Рапределение по точкам</h4>
									<div className={styles.formcontainer}>
										<div className={styles.statcontainer}>
										{this.state.ordersAreas.labels.length ?
											<>
												<div className={styles.chart}>
													<h4>Выручка, ₽</h4>
													<Line data={this.state.ordersAreas} options={this.chartOptions} />
												</div>
												<div className={styles.chart}>
													<h4>Заказы, шт.</h4>
													<Line data={this.state.ordersAreasCount} options={this.chartOptions} />
												</div>
												<div className={styles.chart}>
													<ul className={styles.legend}>
														{this.state.areas.map((v,i) => <li key={i}><div style={{background:`${this.colors.backgroundColor[i]}`}}></div>{v.name}</li>)}
													</ul>
												</div>
											</> : <p className={styles.notfound}>Нет данных</p>}
										</div>
									</div>
								</> : null}
							<h4>ТОП 50 популярных позиций</h4>
							<div className={styles.formcontainer}>
								<div className={styles.statlink}>
									<Link to={'/statistic-products'}>все позиции</Link>
								</div>
								<h4>За последний месяц</h4>
								<div className={styles.list}>
									{this.state.popularNow.length === 0 ? <p>Нет данных</p> :
										<ul>
											{this.state.popularNow.map((v,i) => <li key={i}><div className={styles.num}>{i+1}</div><div>{v.productName}</div><div className={styles.count}>{v.count} шт.</div></li>)}
										</ul>
									}
								</div>
								<h4>За сегодня</h4>
								<div className={styles.list}>
									{this.state.popularAll.length === 0 ? <p>Нет данных</p> :
										<ul>
											{this.state.popularAll.map((v,i) => <li key={i}><div className={styles.num}>{i+1}</div><div>{v.productName}</div><div className={styles.count}>{v.count} шт.</div></li>)}
										</ul>
									}
								</div>
							</div>
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default StatisticsList;