import Http from '../../Globals/Http';

const getAll = (callback) => Http.get('contents').then((res) => callback(res.data));
const get = (id, callback) => Http.get('contents', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const add = (data, callback, error) => Http.post('contents', {data:data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update = (id, data, callback, error) => Http.put('contents', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export {
	getAll,
	get,
	add,
	update
}