import React,{Component} from 'react';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Events,Users} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';

// globals
import {GENERAL,eventTypeName} from '../../Globals/Constants';

class EventsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			type:null,
			users:[],
			user:null,
			progress:10,
			startpage:0,
			pagesize:300,
			loading:true
		};
	}
	pagesize = GENERAL.PAGESIZE.large;
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		Users.getAll((data) => {
			this.setState({users:data,progress:30});
			Events.getPage([this.state.startpage,this.pagesize], (data => this.setState({dataShow:this.dataSet(data),data,dataFull:data,user,loading:false})));
		});
		document.addEventListener('scroll', this.dataGet);
	}
	componentWillUnmount = () => this.dataStop();
	dataStop = () => document.removeEventListener('scroll', this.dataGet);
	dataGet = (e) => {
		const el = e.target.scrollingElement;
		if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
			let {startpage} = this.state;
			startpage += this.pagesize;
			Events.getPage([startpage,this.pagesize], (d) => {
				if (d.length < this.pagesize) this.dataStop();
				const data = [...this.state.dataFull,...d];
				this.setState({dataShow:this.dataSet(data),data,dataFull:data,startpage});
			});
		}
	}
	dataSet = (data) => dgDataPrepare(data, 'events', ['id','type','message','userId','dateCreate'], this.state.users);
	dataSearch = (data, search) => data.filter(f => f.message.toLowerCase().indexOf(search.toLowerCase()) !== -1);
	dataType = (data, type) => data.filter(f => f.type === type);
	handleSearch = (e) => {
		const search = e.target.value;
		let data = this.state.dataFull;
		if (search.length > 1) data = this.dataSearch(data, search);
		if (this.state.type != null) data = this.dataType(data, this.state.type);
		this.setState({dataShow:this.dataSet(data),data});
	}
	handleType = (e) => {
		let type = e.target.value;
		let data = this.state.dataFull;
		if (empty(type)) type = null;
		if (type != null) data = this.dataType(data, parseInt(type));
		this.setState({dataShow:this.dataSet(data),type,data});
	}
	usersGet = (id) => {
		const user = this.state.users.filter(f => f.id === id);
		return user.length > 0 ? user[0] : {};
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<DataGrid
							title={'События'}
							tableName={'events'}
							keys={['ID','Тип','Сообщение','Инициатор','Дата']}
							link={'event'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							controlPanel={<>
								<div>
									<input type="search" placeholder="Поиск" onChange={this.handleSearch} />
								</div>
								<div>
									<span>Статус</span>
										<select onChange={this.handleType}>
										<option value=" ">все</option>
										{eventTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
									</select>
								</div>
							</>}
							controlAdd={<div />}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default EventsList;