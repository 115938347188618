import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import Modal from 'react-awesome-modal';
import classNames from 'classnames';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';
import SaveStatus from '../../../Components/SaveStatus';
import Close from '../../../Components/Close';
import Alert,{alertShow} from '../../../Components/Alert';

// models
import {Stores,Events,Logs} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {empty,moneyFormat,weightFormat} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {eventType,measureTypeName,semifinishType} from '../../../Globals/Constants';

// styles
import styles from './StoreCardSemifinish.module.css';
const cx = classNames.bind(styles);

class StoreCardSemifinish extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			productName:null,
			price:null,
			measureType:null,
			defaultCount:null,
			minimumCount:null,
			item:null,
			items:[],
			ingredientId:0,
			ingredients:[],
			ingredientsFull:[],
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false,
			modalitem:false,
			modalingredients:false,
			isitemnew:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = async () => {
		const ingredients = await Stores.ingredientsGetAsync();
		this.setState({ingredients:ingredients.data,ingredientsFull:ingredients.data});
		if (this.state.id !== 0) {
			const items = await Stores.cardItemsGet(this.state.id);
			Stores.semifinishGet(this.state.id, (data) => {
				this.setState({data,dataOld:{...data},productName:data.productName,measureType:data.measureType,minimumCount:data.minimumCount,defaultCount:data.defaultCount,notfound:empty(data),items:items.data,loading:false});
				const price = items.data.reduce((a, b) => a + (b.price / 100), 0);
				this.setState({price:(data.price?data.price/100:price).toFixed(2)});
			});
		}
		else this.dataInit();
	}
	dataInit = () => this.setState({data:{productName:'',price:'',defaultValue:null,minimumCount:null,measureType:null,quantity:0,brutto:0,netto:0},id:0,item:null,loading:false});
	totalGet = () => {
		const price = this.state.items.reduce((a, b) => a + (b.price / 100), 0);
		this.setState({price:price.toFixed(2)});
	}
	handleForm = (e) => {
		let {item} = this.state;
		item = item || {};
		item[e.target.name] = e.target.name === 'priceKg' ? parseFloat(e.target.value) * 100 : parseInt(e.target.value);
		this.setState({item}, this.calc);
	}
	handleFormMain = (e) => this.setState({[e.target.name]:e.target.value});
	handleQuantity = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.checked ? 1 : 0;
		this.setState({data});
	}
	calc = () => {
		const {item} = this.state;
		if (item) {
			if (item.netto && item.priceKg) item.price = item.netto / 1000 * item.priceKg;
			if (item.netto) item.output = item.netto - (item.losses ? item.losses : 0);
			this.setState({item});
		}
	}
	saveIng = (e) => {
		e.preventDefault();
		const {item,data,isitemnew} = this.state;
		if (!item.ingredientId) {
			alertShow('<b>Ошибка!</b><br/>Вы не выбрали продукт, ингредиент', true);
			this.modalIngredientShow();
			return;
		}
		let {items} = this.state;
		if (!isitemnew) {
			items = [];
			this.state.items.forEach((v) => {
				if (v.ingredientId !== item.ingredientId) items.push(v);
			});
		}
		items.push(item);
		let brutto = 0, netto = 0;
		items.forEach((v) => {
			brutto += v.brutto;
			netto += v.netto;
		});
		data.netto = netto;
		data.brutto = brutto;
		data.type = semifinishType.SEMIFINISH;
		this.setState({items,data}, () => {
			this.totalGet();
			this.modalHide();
		});
	}
	save = (e) => {
		e.preventDefault();
		const {id,data,productName,measureType,defaultCount,minimumCount,items,price} = this.state;
		if (items.length === 0) {
			alertShow('<b>Ошибка!</b><br/>Вы не добавили продукт, ингредиент', true);
			return;
		}
		data.productName = productName;
		data.measureType = measureType;
		data.defaultCount = defaultCount;
		data.minimumCount = minimumCount;
		data.price = price*100;
		delete data.productId;
		this.setState({issave:true});
		if (id === 0) {
			Stores.semifinishAdd(data, (res) => {
				this.stop(() => {
					const id = parseInt(res.data.id);
					this.setState({saveStatus:Texts.card.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.card.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.STORE_CARD);
					this.itemsAdd(id);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			Stores.semifinishUpdate(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.card.update,iserror:false});
					Events.add(this.state.user.id, Texts.card.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.STORE_CARD);
					this.itemsAdd(id);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
		}
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	itemsAdd = async (id) => {
		//this.state.items.forEach(async (v) => v.id && await Stores.cardItemDelete(v.id));
		await Stores.cardItemsDelete(id);
		setTimeout(() => {
			this.state.items.forEach(async (v) => {
				const d = {
					brutto:v.brutto,
					cardId:id,
					ingredientId:v.ingredientId,
					ingredientName:v.ingredientName,
					netto:v.netto,
					output:v.output,
					price:v.price,
					priceKg:v.priceKg,
					losses:v.losses
				};
				v.cardId = id;
				v.id = parseInt((await Stores.cardItemAdd(d)).data?.id);
			});
		}, 1000);
	}
	itemRemove = (e, idx) => {
		e.stopPropagation();
		const items = [];
		this.state.items.forEach((v,i) => {
			if (i !== idx) items.push(v);
		});
		this.setState({items}, this.totalGet);
	}
	nameGet = (data, id) => data.find(f => f.id === id)?.name;
	searchIngredients = (e) => {
		const ss = e.target.value;
		if (empty(ss)) {
			this.setState({ingredients:this.state.ingredientsFull});
			return;
		}
		const ingredients = this.state.ingredientsFull.filter(f => f.name.toLowerCase().indexOf(ss.toLowerCase()) !== -1);
		this.setState({ingredients});
	}
	modalHide = () => this.setState({modalitem:false,modalingredient:false,isitemnew:true});
	modalIngredientHide = () => this.setState({modalingredients:false});
	modalItemShow = (item) => this.setState({modalitem:true,item,isitemnew:item===null});
	modalIngredientShow = () => this.setState({modalingredients:true});
	ingredientSelect = (e) => {
		const id = parseInt(e.target.value);
		const ingredient = this.objectGet(this.state.ingredients, id);
		let {item} = this.state;
		item = item || {};
		item.ingredientId = ingredient.id;
		item.ingredientName = ingredient.name;
		item.priceKg = ingredient.price*100;
		this.setState({item,ingredientId:id}, this.modalIngredientHide);
	}
	objectGet = (data, id) => data.find(f => f.id === parseInt(id));
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isStore={true}>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/store/cardssemifinish'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить полуфабрикат' : 'Редактирование полуфабриката'}
								</span>
								<Link to={'/store/cardsemifinish'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<form onSubmit={this.save}>
									<div className={styles.formcontainer}>
										<div className={styles.cell}>
											<label>
												<span>Название</span>
												<input type="text" name="productName" value={this.state.productName} className={styles.double} placeholder="Название" onChange={this.handleFormMain} required />
											</label>
										</div>
										<div className={cx(styles.onelinefull,styles.onelinefulltop)}>
											<div className={styles.cell}>
												<label>
													<span>Количество для заявки</span>
													<input type="text" name="defaultCount" value={this.state.defaultCount} placeholder="Количество для заявки, кг/шт" onChange={this.handleFormMain} required />
													<div className={styles.notice}>Целое число, количество по умолчанию, которое подставлятся в заявку.</div>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Минимальное количество</span>
													<input type="text" name="minimumCount" value={this.state.minimumCount} placeholder="Минимальное количество, кг/шт" onChange={this.handleFormMain} required />
													<div className={styles.notice}>Целое число, количество, при котором срабатывает сигнал, что на складе заказничвается товар.</div>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Единица измерения</span>
													<select name="measureType" onChange={this.handleFormMain} defaultValue={this.state.measureType} required>
														<option value=""></option>
														{measureTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</label>
											</div>
										</div>
										<div className={cx(styles.onelinefull,styles.onelinefulltop)}>
											<div className={styles.cell}>
												<label>
													<span>Брутто</span>
													<div>{this.state.data.brutto ? <>{weightFormat(this.state.data.brutto/1000)}, кг</> : '—'}</div>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Нетто</span>
													<div>{this.state.data.netto ? <>{weightFormat(this.state.data.netto/1000)}, кг</> : '—'}</div>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Расчетная цена, ₽</span>
													<input type="text" name="price" value={this.state.price} placeholder="Цена комплекта" onChange={this.handleFormMain} required />
													<div className={styles.notice}>Подсчитывается автоматически. При необходимости можно задать в ручную.</div>
												</label>
											</div>
										</div>
										<div className={styles.linepositions}>
											<h4>
												Позиции, продукты
												{this.state.data.quantity === 1 && this.state.items.length === 1 ? null : <a className={styles.plus} onClick={() => this.modalItemShow(null)}></a>}
											</h4>
											{this.state.items.length > 0 ?
												<table>
													<thead>
														<tr>
															<th>Название</th>
															<th>Брутто, кг</th>
															<th>Нетто, кг</th>
															<th>Потери, кг</th>
															<th>Выход, кг</th>
															<th>Стоимость за кг, ₽</th>
															<th>Стоимость, ₽</th>
															<th>&nbsp;</th>
														</tr>
													</thead>
													<tbody>
														{this.state.items.map((v,i) => <tr key={i} onClick={() => this.modalItemShow(v)}>
															<td>{v.ingredientName}</td>
															<td>{weightFormat(v.brutto/1000)}</td>
															<td>{weightFormat(v.netto/1000)}</td>
															<td>{weightFormat(v.losses?v.losses/1000:0)}</td>
															<td>{weightFormat(v.output/1000)}</td>
															<td>{moneyFormat(v.priceKg/100)}</td>
															<td>{moneyFormat(v.price/100)}</td>
															<td><img src={require('../../../Images/icons/close_black.svg')} alt="Удалить" onClick={(e) => this.itemRemove(e, i)} /></td>
														</tr>)}
													</tbody>
												</table> : null}
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							}
						</div>
					</MainTemplate>
				}
				<Modal visible={this.state.modalitem} width={'80%'} height={'520'} effect={'fadeInUp'}>
					<div className={styles.modalcontainer}>
						<h4 className={styles.title}>{this.state.item ? 'Изменить продукт, ингредиент' : 'Добавить продукт, ингредиент'}</h4>
						<form onSubmit={this.saveIng}>
							<div className={cx(styles.formcontainer,styles.formcontainerclean)}>
								<div className={styles.onelinefull}>
									<div className={styles.cell}>
										<label>
											<span>Продукт, ингредиент</span>
											{this.state.item ? <div><b>{this.state.item.ingredientName}</b></div> : null}
											<div className={styles.cellbutton}>
												<button type="button" className={styles.button} onClick={() => this.modalIngredientShow()}>выбрать</button>
											</div>
										</label>
									</div>
								</div>
								<div className={styles.oneline}>
									<div className={styles.cell}>
										<label>
											<span>Стоимость, ₽ <sup>*</sup></span>
											<input type="text" name="priceKg" placeholder="Стоимость" value={this.state.item?.priceKg ? this.state.item.priceKg/100 : ''} onChange={this.handleForm} required />
										</label>
									</div>
								</div>
								<div className={styles.oneline}>
									<div className={styles.cell}>
										<label>
											<span>Брутто, гр.</span>
											<input type="text" name="brutto" placeholder="Брутто, вес с упаковкой в гр." value={this.state.item?.brutto ? this.state.item.brutto : ''} onChange={this.handleForm} required />
										</label>
									</div>
									<div className={styles.cell}>
										<label>
											<span>Нетто, гр.</span>
											<input type="text" name="netto" placeholder="Нетто, чистый вес в гр." value={this.state.item?.netto ? this.state.item.netto : ''} onChange={this.handleForm} required />
										</label>
									</div>
									<div className={styles.cell}>
										<label>
											<span>Потери, гр.</span>
											<input type="text" name="losses" placeholder="Потери в гр." value={this.state.item?.losses ? this.state.item.losses : 0} onChange={this.handleForm} required />
										</label>
									</div>
								</div>
								<div className={styles.onelinefull}>
									<div className={styles.cell}>
										<label>
											<span>Выход</span>
											<div>{this.state.item?.output ? <>{weightFormat(this.state.item.output/1000)} кг</> : '—'}</div>
										</label>
									</div>
									<div className={styles.cell}>
										<label>
											<span>Стоимость</span>
											<div>{this.state.item?.price ? <>{moneyFormat(this.state.item.price/100)} ₽</> : '—'}</div>
										</label>
									</div>
								</div>
								<div className={styles.oneline}>
									<p><sup>*</sup> для весовых продуктов стоимость за кг</p>
								</div>
								<div className={styles.control}>
									<button className={styles.button}>{this.state.item ? 'Сохранить' : 'Добавить'}</button>
								</div>
							</div>
						</form>
					</div>
					<Close close={this.modalHide.bind(this)} />
				</Modal>
				<Modal visible={this.state.modalingredients} width={'90%'} height={'700'} effect={'fadeInUp'}>
					<div className={styles.modalcontainer}>
						<h4 className={styles.title}>Выбрать продукт, ингредиент</h4>
						<div className={styles.search}>
							<input type="search" onChange={this.searchIngredients} />
						</div>
						<div className={styles.products}>
							<ul>
								{this.state.ingredients.map((v,i) => <li key={i}><label><input type="radio" name="ingredientId" value={v.id} checked={this.state.ingredientId===v.id} onChange={this.ingredientSelect} /> {v.name}</label></li>)}
							</ul>
						</div>
					</div>
					<Close close={this.modalIngredientHide.bind(this)} />
				</Modal>
				<Alert />
			</>
		);
	}
}

export default withRouter(StoreCardSemifinish);