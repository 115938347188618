import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const getAll	= (callback)			=> Http.get('events', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getPage	= (limits, callback)	=> Http.get('events', {orders:[{k:'id',isd:requestOderType.DESC}],limits}).then((res) => callback(res.data));
const get		= (id, callback)		=> Http.get('events', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const add		= (userId, message, dataOld, dataNew, objectId, type)	=> Http.post('events', {data:{userId,objectId,type,message,dataOld,dataNew}});

export {
	getAll,
	getPage,
	get,
	add
}