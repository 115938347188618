import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../../Components/DataGrid';
import NotFound from '../../../Components/NotFound';

// models
import {Stores,Users} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';

class StoreIncomesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			user:null,
			users:[],
			stores:[],
			progress:10,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		const users = await Users.getAllAsync();
		const stores = await Stores.getAllAsync();
		this.setState({users:users.data,stores:stores.data});
		Stores.incomesGet((data) => {
			data.forEach((v) => v.storeName = this.storeNameGet(v.storeId));
			this.setState({dataShow:this.dataSet(data),data,dataFull:data,user,loading:false});
		});
	}
	dataSet = (data) => dgDataPrepare(data, 'storeincomes', ['id','dateCreate','storeName','userId'], this.state.users);
	storeNameGet = (id) => this.state.stores.find(f => f.id === id)?.name;
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isStore={true}>
						<DataGrid
							title={'Поступления'}
							keys={['ID','Дата','Склад','Пользователь']}
							link={'income'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							controlAdd={<Link to={'/store/income'}>Добавить</Link>}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default StoreIncomesList;