import Http from '../../Globals/Http';
import {requestOderType,requestConditionType} from '../../Globals/Constants';

const get			= (id, callback)		=> Http.get('courierorders', {conditions:[{k:'orderId',v:id}]}).then((res) => callback(res.data[0]));

const getByCourier 	= (id, callback)		=> Http.get('courierorders', {conditions:[{k:'userId',v:id}],orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getByCourierAsync	= async (id)		=> await Http.request('courierorders', 'byCourierGet', id);
const getAllByCourierAsync	= async (ids)	=> await Http.request('courierorders', 'byCourierGetAll', ids);

const add			= (data, callback)		=> Http.post('courierorders', {data:data}).then((res) => callback ? callback(res) : {});
const update		= (id, data, callback)	=> Http.put('courierorders', {data:data,conditions:[{k:'orderId',v:id}]}).then((res) => callback ? callback(res) : {});

const removeByOrder	= (orderId, callback)	=> Http.remove('courierorders', {conditions:[{k:'orderId',v:orderId}]}).then((res) => callback ? callback(res) : {});

const getCourierOrders = (id, callback)		=> Http.get('courierorders', {conditions:[{k:'userId',v:id}]}).then((res) => callback(res.data));
const getLastOrders = (id, count, callback) => Http.get('courierorders', {conditions:[{k:'userId',v:id}, {k:'status',v:0}],orders:[{k:'id',isd:requestOderType.DESC}],limits:[count]}).then((res) => callback(res.data));
const getToday		= (today, callback)		=> Http.get('courierorders', {conditions:[{k:"dateCreate",v:today,op:requestConditionType.MORE}]}).then((res) => callback(res.data));
const getMonth		= (month, callback)		=> Http.get('courierorders', {conditions:[{k:"dateCreate",v:month,op:requestConditionType.MORE}]}).then((res) => callback(res.data));

export {
	get,
	getByCourier,
	getByCourierAsync,
	add,
	update,
	removeByOrder,
	getCourierOrders,
	getLastOrders,
	getToday,
	getMonth,
	getAllByCourierAsync
}