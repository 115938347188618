import React,{Component} from 'react';

// plug-ins
import classNames from 'classnames';

// helpers
import {dateGet,moneyFormat,phoneFormatter,quantityFormat} from '../../Globals/Utils';

// globals
import {orderStatusName,orderTypeName,measureTypeName,targetTypeName} from '../../Globals/Constants';

// styles
import styles from './OrderInfo.module.css';
const cx = classNames.bind(styles);

class OrderInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:this.props.data,
			orderproducts:this.props.orderproducts,
			areas:this.props.areas
		};
	}
	componentDidMount = async () => { }
	areaNameGet = (id) => {
		const area = this.state.areas.find(f => f.id === id);
		return area ? area.name : '—';
	}
	render() {
		return (
			<>
				<div className={styles.title}>
					<div className={styles.cell}>
						<label>
							<span>Клиент</span>
							<div>{this.state.data.clientName}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Телефон</span>
							<div className={styles.phone}>{phoneFormatter(this.state.data.phone)}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Адрес доставки</span>
							<div className={styles.addressblock}>
								<div>{this.state.data.address}</div>
							</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Сумма заказа</span>
							<div>{moneyFormat(this.state.data.priceFull, false)} ₽</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Оплачен</span>
							<div>{this.state.data.isPaid ? 'Да' : 'Нет'}</div>
						</label>
					</div>
				</div>
				<div className={styles.title}>
					<div className={styles.cell}>
						<label>
							<span>Заказ на дату</span>
							<div>{this.state.data.date ? <b>{dateGet(this.state.data.date, {yearHide:true,showMonthFullName:true,showTime:true})}</b> : '—'}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Дата создания</span>
							<div>{dateGet(this.state.data.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Статус</span>
							<div>{orderStatusName[this.state.data.status]}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Заказ создан</span>
							<div>{orderTypeName[this.state.data.type]}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Источник</span>
							<div>{targetTypeName[this.state.data.target]}</div>
						</label>
					</div>
				</div>
				<div className={styles.title}>
					<div className={styles.cell}>
						<label>
							<span>Смена</span>
							<div>{this.state.data.dayShiftId ? `№${this.state.data.dayShiftId}` : '—'}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Точка</span>
							<div>{this.areaNameGet(this.state.data.areaId)}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Промокод</span>
							<div>{this.state.data.promoCode||'—'}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Приборы</span>
							<div>{this.state.data.cutlery||'—'}</div>
						</label>
					</div>
				</div>
				<div className={styles.title}>
					<div className={styles.cell}>
						<label>
							<span>Сдача</span>
							<div>{this.state.data.surrender||'—'}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Терминал для оплаты</span>
							<div>{this.state.data.isTerminal ? 'Да' : 'Нет'}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Оплата по QR</span>
							<div>{this.state.data.isQR ? 'Да' : 'Нет'}</div>
						</label>
					</div>
				</div>
				<div className={styles.title}>
					{this.state.data.contactPhone ?
						<div className={styles.cell}>
							<label>
								<span>Контактный телефон</span>
								<div className={styles.phone}>{phoneFormatter(this.state.data.contactPhone)}</div>
							</label>
						</div> : null}
					<div className={styles.cell}>
						<label>
							<span>Комментарий</span>
							<div>{this.state.data.comment||'—'}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Комментарий к адресу</span>
							<div>{this.state.data.commentAddress||'—'}</div>
						</label>
					</div>
					<div className={styles.cell}>
						<label>
							<span>Комментарий к системе</span>
							<div>{this.state.data.commentSystem||'—'}</div>
						</label>
					</div>
				</div>
				{this.state.data.courierId ?
					<div className={styles.title}>
						<div className={styles.cell}>
							<label>
								<span>Курьер</span>
								<div>{this.state.data.courierName}</div>
							</label>
						</div>
						<div className={styles.cell}>
							<label>
								<span>Телефон</span>
								<div className={styles.phone}>{phoneFormatter(this.state.data.courierPhone)}</div>
							</label>
						</div>
					</div> : null}
				<div><b>Список блюд</b></div>
				<div className={cx(styles.title,styles.titlenoborder)}>
					<div className={styles.productslist}>
						{this.state.orderproducts.map((v,i) => <div key={i} className={styles.item}>
							<div className={styles.name}>
								<div>{i+1}. {v.productName}</div>
								<div className={styles.measure}>{v.measure} {measureTypeName[v.measureType]}</div>
							</div>
							<div className={styles.count}>x {quantityFormat(v.quantity)}</div>
							<div className={styles.price}>{moneyFormat(v.priceDiscount ? v.priceDiscount : v.price, true)} ₽</div>
						</div>)}
					</div>
				</div>
				<div className={styles.title}>
					<div className={styles.summaryview}>
						{this.state.data.manualDiscount || this.state.data.manualDiscountPercent ?
							<div className={styles.summaryblock}>
								<div>Сумма заказа: <b>{moneyFormat(this.state.data.price, false)} ₽</b></div>
								<div>Сумма со скидкой: {moneyFormat(this.state.data.priceDiscount, false)} ₽</div>
								<div>Сумма к оплате: <b>{moneyFormat(this.state.data.priceFull, false)} ₽</b></div>
							</div>
						:
							<div className={styles.summaryblock}>
								<div>Сумма заказа: <b>{moneyFormat(this.state.data.price, false)} ₽</b></div>
								<div>Сумма со скидкой: {this.state.data.priceDiscount ? <>{moneyFormat(this.state.data.priceDiscount, false)} ₽</> : '—'}</div>
								<div>Сумма к оплате: <b>{moneyFormat(this.state.data.priceFull, false)} ₽</b></div>
							</div>
						}
						<div className={styles.summaryblock}>
							<div>Скидка: {(this.state.data.discount || this.state.data.promoCodeDiscount) ? `${this.state.data.discount || this.state.data.promoCodeDiscount} ₽` : '—'}</div>
							{this.state.data.useBalance ? <div>Использовано бонусов: {this.state.data.useBalance} ₽</div> : null}
							{this.state.data.discountName && <div>Применена скидка: {this.state.data.discountName}</div>}
						</div>
						<div className={styles.summaryblock}>
							Доставка: {this.state.data.isPickup ? 'Самовывоз' : (this.state.data.deliveryIsFree ? '0': <>{this.state.data.deliveryCostManual||this.state.data.deliveryCost} ₽</>)}
						</div>
					</div>
				</div>
			</>
		);
	};
}

export default OrderInfo;