import React,{Component} from 'react';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import Loading from '../../Components/Loading';

// models
import {Areas,DayShifts,Orders} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {dateGet,dateNow,moneyFormat,tsDay} from '../../Globals/Utils';

// globals
import {orderPaid,orderPickup,orderStatus,orderType,targetType} from '../../Globals/Constants';

// styles
import styles from './Dayshifts.module.css';

class DayshiftsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			areas:[],
			dayshiftsAll:[],
			dayshifts:[],
			areaId:0,
			dayShiftId:0,
			date:null,
			report:null,
			progress:10,
			loading:true,
			loadingdata:false
		};
	}
	allowStatuses = [
		orderStatus.OPERATOR_WAIT,
		orderStatus.COOK_WAIT,
		orderStatus.COOK_ACCEPTED,
		orderStatus.COOK_DONE,
		orderStatus.COLLECTOR_WAIT,
		orderStatus.COLLECTOR_ACCEPTED,
		orderStatus.COLLECTOR_DONE,
		orderStatus.LOGIST_WAIT,
		orderStatus.LOGIST_ACCEPTED,
		orderStatus.LOGIST_DONE,
		orderStatus.COURIER_WAIT,
		orderStatus.COURIER_ACCEPTED,
		orderStatus.COURIER_IN_ROUTE,
		orderStatus.COURIER_DONE,
		orderStatus.FINISH,
		orderStatus.ARCHIVE
	];
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		const areas = await Areas.getAllActive();
		const ds = await DayShifts.getAll();
		this.setState({user,areas:areas.data,dayshiftsAll:ds.data,loading:false});
	}
	handleArea = (e) => {
		const areaId = parseInt(e.target.value);
		const dayshifts = areaId === 0 ? [] : this.state.dayshiftsAll.filter(f => f.areaId === areaId);
		this.setState({areaId,dayShiftId:0,dayshifts:[],report:null}, () => this.setState({dayshifts}));
	}
	handleDayshift = (e) => {
		const t = e.target;
		const date = t.options[t.selectedIndex].getAttribute('date');
		this.setState({dayShiftId:parseInt(t.value),date,loadingdata:true}, () => this.dataGet());
	}
	reportOrdersGet = async (areaId, dayShiftId) => {
		const now = dateNow();
		const orders = await Orders.getDayShiftOrders(areaId, dayShiftId);
		return orders.data.filter(f => (f.date === 0 || f.date < now) && this.allowStatuses.includes(f.status));
	}
	reportOrderCancelGet = async (areaId, dayShiftId) => {
		const orders = await Orders.getDayShiftOrders(areaId, dayShiftId);
		return orders.data.filter(f => f.status === orderStatus.CANCEL);
	}
	reportOrderRefundGet = async (areaId, dayShiftId) => {
		const orders = await Orders.getDayShiftOrders(areaId, dayShiftId);
		return orders.data.filter(f => f.status === orderStatus.REFUND);
	}
	reportOrderOldGet = async (start, end) => await Orders.reports.ondate(start, end);
	reportSumCalc = (data) => data.length > 0 ? data.reduce((a, b) => a + b.priceFull, 0) : 0;
	dataGet = async () => {
		const {areaId,dayShiftId} = this.state;
		if (areaId === 0 || dayShiftId === 0) return;
		const date = parseInt(this.state.date);
		const ordersOldData = await this.reportOrderOldGet(date + tsDay, date + tsDay + tsDay);
		const ordersOld = ordersOldData.data.filter(f => f.areaId === areaId);
		const ordersAll = [...await this.reportOrdersGet(areaId, dayShiftId),...ordersOld];
		const ordersOnline = ordersAll.filter(f => (f.target === targetType.WEBSITE || f.target === targetType.IOS || f.target === targetType.ANDROID || f.target === targetType.VK || f.target === targetType.ROBOT || f.target === targetType.KIOSK) && f.type === orderType.CLIENT);
		const ordersOnlinePaid = ordersOnline.filter(f => f.isPaid === orderPaid.PAID);
		const ordersOnlinePaidPickup = ordersOnlinePaid.filter(f => f.isPickup === orderPickup.PICKUP);
		const ordersOnlinePaidDelivery = ordersOnlinePaid.filter(f => f.isPickup !== orderPickup.PICKUP);
		const ordersOnlineNotPaidDelivery = ordersOnline.filter(f => f.isPaid === orderPaid.NOT_PAID && f.isPickup !== orderPickup.PICKUP);
		const ordersOnlineNotPaidPickup = ordersOnline.filter(f => f.isPaid === orderPaid.NOT_PAID && f.isPickup === orderPickup.PICKUP);
		const ordersOperator = ordersAll.filter(f => f.target === targetType.DISPATCHER && f.type === orderType.DISPATCHER);
		const ordersOperatorDelivery = ordersOperator.filter(f => f.isPickup === orderPickup.DELIVERY);
		const ordersOperatorPickup = ordersOperator.filter(f => f.isPickup === orderPickup.PICKUP);
		const ordersHall = ordersAll.filter(f => f.target === targetType.OPERATOR && f.type === orderType.OPERATOR_HALL);
		const ordersCancel = await this.reportOrderCancelGet(areaId, dayShiftId);
		const ordersRefund = await this.reportOrderRefundGet(areaId, dayShiftId);
		const report = [
			{
				name:'В зале',
				count:ordersHall.length,
				amount:this.reportSumCalc(ordersHall)
			},
			{
				name:'Онлайн. Оплачено. Доставка',
				count:ordersOnlinePaidDelivery.length,
				amount:this.reportSumCalc(ordersOnlinePaidDelivery)
			},
			{
				name:'Онлайн. Оплачено. Самовывоз',
				count:ordersOnlinePaidPickup.length,
				amount:this.reportSumCalc(ordersOnlinePaidPickup)
			},
			{
				name:'Онлайн. Курьеру. Доставка',
				count:ordersOnlineNotPaidDelivery.length,
				amount:this.reportSumCalc(ordersOnlineNotPaidDelivery)
			},
			{
				name:'Онлайн. Не оплачено. Самовывоз',
				count:ordersOnlineNotPaidPickup.length,
				amount:this.reportSumCalc(ordersOnlineNotPaidPickup)
			},
			{
				name:'Оператор. Доставка',
				count:ordersOperatorDelivery.length,
				amount:this.reportSumCalc(ordersOperatorDelivery)
			},
			{
				name:'Оператор. Самовывоз',
				count:ordersOperatorPickup.length,
				amount:this.reportSumCalc(ordersOperatorPickup)
			},
			{
				name:'Прошлые заказы',
				count:ordersOld.length,
				amount:this.reportSumCalc(ordersOld)
			},
			{
				name:'Отмена',
				count:ordersCancel.length,
				amount:this.reportSumCalc(ordersCancel)
			},
			{
				name:'Возврат',
				count:ordersRefund.length,
				amount:this.reportSumCalc(ordersRefund)
			},
			{
				name:'Всего',
				count:ordersAll.length,
				amount:this.reportSumCalc(ordersAll)
			}
		];
		this.setState({report,loadingdata:false});
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>Отчет по сменам</h4>
							<div className={styles.controls}>
								<select onChange={this.handleArea}>
									<option value="0">Выберите точку</option>
									{this.state.areas.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
								</select>
								<select onChange={this.handleDayshift}>
									<option value="0">Выберите смену</option>
									{this.state.dayshifts.map((v,i) => <option key={i} date={v.date} value={v.id}>{dateGet(v.dateStart+tsDay/2)} - №{v.id} ({dateGet(v.dateStart+tsDay/2, {weekDayOnly:true})})</option>)}
								</select>
							</div>
							<div className={styles.formcontainer}>
								{this.state.areaId === 0 || this.state.dayShiftId === 0 ? <p className={styles.empty}>Для просмотра отчета выберите точку и смену</p> :
									this.state.loadingdata ? <Loading className={styles.loading} /> :
										<ul className={styles.reportcontainer}>
											{this.state.report.map((v,i) => <li key={i} className={styles.row}>
													<span className={styles.title}>{v.name}</span>
													<div className={styles.cell}>
														<span>{v.count} шт.</span>
														<span>{moneyFormat(v.amount)} ₽</span>
													</div>
												</li>
											)}
									</ul>
								}
							</div>
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default DayshiftsList;