import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get		= (id, callback)				=> Http.get('competitionclients', {conditions:[{k:'competitionId',v:id}],orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));

const add		= (data, callback, error)		=> Http.post('competitionclients', {data:data}).then((res) => callback ? callback(res) : {}).catch((err) => error ? error(err) : {});
const update	= (id, data, callback, error)	=> Http.put('competitionclients', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback ? callback(res) : {}).catch((err) => error ? error(err) : {});

export {
	get,
	add,
	update
}