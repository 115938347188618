import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Areas} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

class AreasList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			user:null,
			progress:10,
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		Areas.getAllSync((data) => this.setState({dataShow:this.dataSet(data),data,dataFull:data,user,loading:false}));
	}
	dataSet = (data) => dgDataPrepare(data, 'areas', ['id','name','address','description','status']);
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<DataGrid
							title={'Точки'}
							keys={['ID','Назвние','Адрес','Описание','Статус']}
							link={'area'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							controlAdd={<Link to={'/area'}>Добавить</Link>}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default AreasList;