import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';
import SaveStatus from '../../../Components/SaveStatus';

// models
import {ClientRankTypes,Events,Logs} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {empty} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {eventType} from '../../../Globals/Constants';

// styles
import styles from './ClientRankType.module.css';

class ClientRankType extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		if (this.state.id !== 0) ClientRankTypes.get(this.state.id, (data) => this.setState({data,dataOld:{...data},notfound:empty(data),loading:false}));
		else this.dataInit();
	}
	dataInit = () => this.setState({data:{name:'',discount:'',deliveryFree:'',isFirst:'',count:''},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	handleCheckbox = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.checked ? 1 : 0;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		const data = {
			name:this.state.data.name,
			discount:this.state.data.discount,
			deliveryFree:this.state.data.deliveryFree,
			isFirst:this.state.data.isFirst,
			count:this.state.data.count
		}
		if (this.state.data.price) data.price = this.state.data.price;
		if (id === 0) {
			ClientRankTypes.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.client_rank.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.client_rank.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.CLIENT_RANK);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			ClientRankTypes.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.client_rank.update,iserror:false});
					Events.add(this.state.user.id, Texts.client_rank.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.CLIENT_RANK);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isLoyalty={true}>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/loyalty/clientranktypes'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить статус' : 'Редактирование статуса'}
								</span>
								<Link to={'/loyalty/discount'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<form onSubmit={this.save}>
									<div className={styles.formcontainer}>
										<div className={styles.cell}>
											<label>
												<span>Название, описание</span>
												<input type="text" name="name" value={this.state.data.name} placeholder="Название" className={styles.double} onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.onelinetop}>
											<div className={styles.cell}>
												<label>
													<span>Процент скидки</span>
													<input type="number" name="discount" min={0} max={99} value={this.state.data.discount} placeholder="5" onChange={this.handleForm} required />
												</label>
											</div>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Цена, ₽</span>
												<input type="number" name="price" min={0} value={this.state.data.price} placeholder="100" onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.conditions}>
											<b>Условия</b>
											<div className={styles.onelinetop}>
												<div className={styles.cell}>
													<label>
														<span>Бесплатная доставка</span>
														<div><input type="checkbox" name="deliveryFree" defaultChecked={this.state.data.deliveryFree} onChange={this.handleCheckbox} /> да</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Первая доставка</span>
														<div><input type="checkbox" name="isFirst" defaultChecked={this.state.data.isFirst} onChange={this.handleCheckbox} /> да</div>
													</label>
												</div>
											</div>
											<div className={styles.onelinetop}>
												<div className={styles.cell}>
													<label>
														<span>Количество покупок в месяц</span>
														<input type="number" name="count" min={0} max={99} value={this.state.data.count} placeholder="10" onChange={this.handleForm} />
													</label>
												</div>
											</div>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(ClientRankType);