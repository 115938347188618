import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';

// models
import {Areas,Events,Logs} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';

// texts
import Texts from '../../Globals/Texts';

// globals
import {comonStatusName,eventType} from '../../Globals/Constants';

// styles
import styles from './Area.module.css';

class Area extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		if (this.state.id !== 0) Areas.getSync(this.state.id, (data) => this.setState({data,dataOld:{...data},notfound:empty(data),loading:false}));
		else this.dataInit();
	}
	dataInit = () => this.setState({data:{name:'',description:'',address:'',latitude:'',longitude:'',area:'',phone:'',workTime:'',status:0},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		const data = {
			name:this.state.data.name,
			description:this.state.data.description,
			area:this.state.data.area,
			latitude:this.state.data.latitude,
			longitude:this.state.data.longitude,
			phone:this.state.data.phone,
			workTime:this.state.data.workTime,
			address:this.state.data.address,
			status:this.state.data.status
		}
		if (id === 0) {
			Areas.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.area.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.area.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.AREA);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			Areas.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.area.update,iserror:false});
					Events.add(this.state.user.id, Texts.area.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.AREA);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/areas'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить точку' : 'Редактирование точки'}
								</span>
								<Link to={'/area'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
							<>
								<form onSubmit={this.save} className={styles.form}>
									<div className={styles.formcontainer}>
										<div className={styles.cell}>
											<label>
												<span>Название</span>
												<input type="text" name="name" value={this.state.data.name} placeholder="Название" className={styles.double} onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Описание</span>
												<textarea name="description" placeholder="Описание" value={this.state.data.description} onChange={this.handleForm}></textarea>
											</label>
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Телефон</span>
													<input type="text" name="phone" value={this.state.data.phone} placeholder="Телефон" onChange={this.handleForm} />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Время работы</span>
													<input type="text" name="workTime" value={this.state.data.workTime} placeholder="Время работы" onChange={this.handleForm} />
												</label>
											</div>
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Адрес</span>
													<input type="text" name="address" value={this.state.data.address} placeholder="Адрес" onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Координата. Широта</span>
													<input type="text" name="latitude" value={this.state.data.latitude} placeholder="Координата. Широта" onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Координата. Долгота</span>
													<input type="text" name="longitude" value={this.state.data.longitude} placeholder="Координата. Долгота" onChange={this.handleForm} required />
												</label>
											</div>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Область на карте</span>
												<textarea name="area" placeholder="Область на карте" value={this.state.data.area} onChange={this.handleForm} className={styles.large} required></textarea>
											</label>
											<a href="https://yandex.ru/map-constructor" target="_blank" rel="noopener noreferrer">Конструктор карт Яндека</a>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Статус</span>
												<select name="status" onChange={this.handleForm} defaultValue={parseInt(this.state.data.status)}>
													{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
												</select>
											</label>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							</>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Area);