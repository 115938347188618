import Http from '../../Globals/Http';
import {comonStatus} from '../../Globals/Constants';

const get			= async (id) => await Http.get('orderprints', {conditions:[{k:'orderId',v:id}]});
const getActive		= async (areaId, dayShiftId) => await Http.get('orderprints', {conditions:[{k:'areaId',v:areaId},{k:'dayShiftId',v:dayShiftId},{k:'status',v:comonStatus.ACTIVE}]});

const getActiveOld	= async (areaId) => await Http.get('orderprints', {conditions:[{k:'areaId',v:areaId},{k:'status',v:comonStatus.ACTIVE}]});

const add			= async (data) => await Http.post('orderprints', {data});
const update		= async (id, data) => await Http.put('orderprints', {data,conditions:[{k:'id',v:id}]});


export {
	get,
	getActive,
	getActiveOld,
	add,
	update
}