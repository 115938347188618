import Http from '../../Globals/Http';
import {requestOderType,comonStatus} from '../../Globals/Constants';

const getAll = (callback) => Http.get('categories', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getActiveAll = (callback) => Http.get('categories', {conditions:[{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const get = (id, callback) => Http.get('categories', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const add = (data, callback, error) => Http.post('categories', {data:data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update = (id, data, callback, error) => Http.put('categories', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export {
	getAll,
	getActiveAll,
	get,
	add,
	update
}