import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';

// models
import {FortuneWheelClients} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {dateGet,phoneFormatter} from '../../../Globals/Utils';

// globals
import {fortuneWheelStatusName} from '../../../Globals/Constants';

// styles
import styles from './FortuneClient.module.css';

class FortuneClient extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			user:null,
			loading:true
		};
		this.map = null;
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	dataGet = async () => FortuneWheelClients.get(this.state.id, async (data) => this.setState({data,loading:false}));
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/loyalty/wofclients'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
									Просмотр попытки
								</span>
							</h4>
							{this.state.notfound ? <NotFound /> :
							<>
								<div className={styles.formcontainer}>
									<form>
										<div className={styles.orderviewcontainer}>
											<div className={styles.clientorderheader}>
												<div className={styles.cell}>
													<label>
														<span>Статус</span>
														<div>{fortuneWheelStatusName[this.state.data.status]}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Использован</span>
														<div>{this.state.data.isUsed ? 'Да' : 'Нет'}</div>
													</label>
												</div>
											</div>
											<div className={styles.clientorderheader}>
												<div className={styles.cell}>
													<label>
														<span>Клиент</span>
														<div>{this.state.data.clientName ? <Link to={`/client/${this.state.data.clientId}`}>{this.state.data.clientName}</Link> : this.state.data.clientName}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Телефон</span>
														<div>{phoneFormatter(this.state.data.phone)}</div>
													</label>
												</div>
											</div>
											<div className={styles.clientorderheader}>
												<div className={styles.cell}>
													<label>
														<span>Скидка</span>
														<div>{this.state.data.discount ? `${this.state.data.discount}%` : '—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Промокод</span>
														<div>{this.state.data.discountCode ? this.state.data.discountCode : '—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Бесплатный товар</span>
														<div>{this.state.data.productName ? <Link to={`/product/${this.state.data.productId}`}>{this.state.data.productName}</Link> : '—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Бесплатная доставка</span>
														<div>{this.state.data.freeDelivery ? 'Да' : '—'}</div>
													</label>
												</div>
											</div>
											<div className={styles.clientorderheader}>
												<div className={styles.cell}>
													<label>
														<span>Оффлайн приз</span>
														<div>{this.state.data.offline ? this.state.data.offline : '—'}</div>
													</label>
												</div>
											</div>
											<div className={styles.clientorderheader}>
												<div className={styles.cell}>
													<label>
														<span>Цена попытки</span>
														<div>{this.state.data.price} ₽</div>
													</label>
												</div>
											</div>
											<div className={styles.clientorderheader}>
												<div className={styles.cell}>
													<label>
														<span>Действует до</span>
														<div>{dateGet(this.state.data.dateTill, {yearHide:true,showMonthFullName:true,showTime:true})}</div>
													</label>
												</div>
											</div>
											<div className={styles.clientorderheader}>
												<div className={styles.cell}>
													<label>
														<span>Дата создания</span>
														<div>{dateGet(this.state.data.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Дата изменения</span>
														<div>{this.state.data.dateModify ? dateGet(this.state.data.dateModify, {yearHide:true,showMonthFullName:true,showTime:true}) : '—'}</div>
													</label>
												</div>
											</div>
										</div>
									</form>
								</div>
							</>}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(FortuneClient);