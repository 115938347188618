import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get		= (id, callback)	=> Http.get('clientbalances', {conditions:[{k:'clientId',v:id}],orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));

const add		= (data)			=> Http.post('clientbalances', {data});

const update		= (id, data, callback, error)	=> Http.put('clientbalances', {data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export {
	get,
	add,
	update
}