import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// components
import Confirm from '../Confirm';

// helpers
import {ls} from '../../Globals/Localstorage';

// styles
import styles from './MenuLoyalty.module.css';

export default class MenuLoyalty extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAdmin:props.isAdmin,
			alertShow:false
		};
	}
	logoff = (e) => {
		e.preventDefault();
		this.setState({alertShow:true});
	}
	confim = () => {
		const version = ls('version');
		ls();
		ls('version', version);
		this.close();
		window.location.href='/login';
	}
	close = () => this.setState({alertShow:false});
	render() {
		return (
			<div className={styles.sidemenuside}>
				<div className={styles.menucontainer}>
					{this.state.isAdmin ?
						<ul>
							<li><Link to={'/statistics'}><img src={require('../../Images/icons/arrow_left.svg')} alt="Назад" /> назад</Link></li>
						</ul> : null}
					<ul>
						<li><Link to={'/loyalty/alerts'}>Сигналы</Link></li>
					</ul>
					<ul>
						<li><b>Розыгрыши</b></li>
						<li><Link to={'/loyalty/lotteries'}>Розыгрыши</Link></li>
						<li><Link to={'/loyalty/competitions'}>Соревнования</Link></li>
					</ul>
					<ul>
						<li><b>Колесо удачи</b></li>
						<li><Link to={'/loyalty/wofprizes'}>Призы</Link></li>
						<li><Link to={'/loyalty/wofprices'}>Цены</Link></li>
						<li><Link to={'/loyalty/wofclients'}>Участники (попытки)</Link></li>
						<li><Link to={'/loyalty/wofclientswin'}>Список победителей</Link></li>
					</ul>
					<ul>
						<li><b>Справочники</b></li>
						<li><Link to={'/loyalty/discounts'}>Скидки</Link></li>
						<li><Link to={'/loyalty/promocodes'}>Промокоды</Link></li>
						<li><Link to={'/loyalty/clientpromocodes'}>Персональные промокоды</Link></li>
						<li><Link to={'/loyalty/clientranktypes'}>Клиентские статусы</Link></li>
					</ul>
					{!this.state.isAdmin ?
						<div className={styles.logoff}>
							<Link to={'/'} onClick={this.logoff.bind(this)}>Выход из системы</Link>
						</div> : null}
				</div>
				{this.state.alertShow && <Confirm message={'Вы уверены что хотите выйти из системы администрирования?'} action={this.confim.bind(this)} close={this.close.bind(this)} />}
			</div>
		);
	};
}