import React, { useState, useEffect, useRef } from 'react';
import styles from './Domru.module.css';
import { Clients } from '../../Models';
import cn from 'classnames'
import Http from '../../Globals/Http';
import { requestConditionType, timers } from '../../Globals/Constants';
import { phoneFormatter } from '../../Globals/Utils';

const Domru = ({searchClientSelectDomru, clientAddDomru}) => {
    const [expanded, setExpanded] = useState(true);
    const [active, setActive] = useState([]);
    const [clients, setClients] = useState([]);

    const toggleExpand = () => setExpanded(!expanded);

    const getActive = async () => {
        try {
            const response = await Http.get('domruevents', {conditions: [{k: 'dateCreate', v: Math.floor(Date.now() / 1000) - (10 * 60), op: requestConditionType.MORE}, {k: 'status', v: 1}, {k: 'incoming', v: 1}, {k: 'status', v: 6, con: 1}, {k: 'dateCreate', v: Math.floor(Date.now() / 1000) - (10 * 60), op: requestConditionType.MORE}]});
            const data = response.data;
            setActive(data);
            data.map((call) => (call.phone > 0 && call.dateCreate > 0) ? getClient(call.phone, call.dateCreate) : null)
        } catch (error) {
            console.error('Error fetching active data:', error);
        }
    };

    const getClient = (phone, startCall) => {
        if (!clients[phone] || !clients[phone].id || !clients[phone].startCall) {
            var newClients = clients;
            Clients.byPhoneGet(phone, (res) => {
                if (res && res?.id > 0) {
                    res.startCall = startCall
                    newClients[phone] = res;
                    setClients(newClients);
                    return res
                } else {
                    newClients = clients
                    newClients[phone] = {startCall, phone, name: 'Незарегистрирован'}
                    setClients(newClients);
                }
            })
        }
        return clients[phone] ?? [{startCall, phone, name: 'Незарегистрирован'}]
    }

    const calculateCallDuration = (startTimeInSeconds) => {
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        const durationInSeconds = currentTimeInSeconds - startTimeInSeconds;
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = durationInSeconds % 60;
        return { minutes, seconds };
      };

    const getClientInfo = (phone, callId, callStatus) => {
        if (!clients[phone]) return;
        const client = clients[phone];
        const callDuration = calculateCallDuration(client.startCall)
        return client.hasOwnProperty('id') && client.id > 0 ?
        <div className={styles.card}>
            <div className={styles.userInfo}>
                <h2 className={styles.name}>{`${client.name === '' ? 'Имя не указано' : client.name}`}</h2>
                <p className={styles.number}>{`Телефон ${phoneFormatter(phone)}`}</p>
                <p className={styles.status}>Статус</p>
                {callStatus === 1 ? <button className={styles.newOrder} onClick={() => {
                    searchClientSelectDomru(client, callId)
                }}>Создать заказ</button> : <button className={styles.offButton}>Принят диспетчером</button>}
                {client.startCall ? <p className={styles.status}>Звонок: {callDuration.minutes} минут, {callDuration.seconds} сек.</p> : null}
            </div>
        </div>
        :
        <div className={styles.card}>
            <div className={styles.userInfo}>
                <h2 className={styles.name}>Незарегистрирован</h2>
                <p>{`Телефон ${phoneFormatter(phone)}`}</p>
                {callStatus === 1 ? <button className={styles.newClient} onClick={() => {
                    clientAddDomru(phone, callId)
                }}>Новый клиент</button> : <button className={styles.offButton}>Принят диспетчером</button>}
                <p className={styles.status}>Звонок: {callDuration.minutes} минут, {callDuration.seconds} сек.</p>
            </div>
        </div>
    }

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                await getActive();
            } catch (error) {
                console.error('Error updating active data:', error);
            }
        }, timers.DOMRU_CALL);
        return () => clearInterval(interval);
    }, []);
    
    const handleOutsideClick = () => (!expanded) ? toggleExpand() : null;

    const OutsideClickHandler = ({ onOutsideClick, children }) => {
        const wrapperRef = useRef(null);
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    onOutsideClick();
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => { document.removeEventListener('mousedown', handleClickOutside); };
        }, [onOutsideClick]);
        return <div ref={wrapperRef}>{children}</div>;
    };

    return (<>
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
            <div className={cn(styles.domruCard, expanded ? styles.active : null)}>
                <div className={styles.domruCard__header}>
                    {active.length === 0 ? <div>Нет активных звонков</div> : active.map((call, i) => (<div key={i} className={styles.domruCard__clientInfo}>{getClientInfo(call.phone, call.callId, call.status)}</div>))}
                </div>
            </div>
            <button className={styles.expandButton} onClick={toggleExpand}>
                <span>Текущие звонки ({active.length})</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className={styles.arrowIcon}><path d="M12 15.5L6.5 10h11L12 15.5z" /></svg>
                <svg width="24" height="24" className={styles.callIcon} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21.0001 10.9999C21.0001 6.58166 17.4184 2.99994 13.0001 2.99994M14 7.12593C15.4057 7.48768 16.5123 8.59432 16.8741 10M16.062 14.2507V14.2507C16.6052 13.5264 17.5886 13.2943 18.3983 13.6992L19.6337 14.3168C20.4404 14.7202 20.8773 15.6135 20.7004 16.4979L20.3216 18.3922C20.1346 19.3271 19.3109 20.0146 18.3636 19.9075C11.1005 19.0859 4.91414 12.8995 4.09253 5.63644C3.98537 4.68912 4.67292 3.86542 5.60777 3.67845L7.50207 3.29959C8.38646 3.12271 9.27982 3.55963 9.68316 4.36632L10.3008 5.60168C10.7057 6.41142 10.4736 7.39483 9.7493 7.93802V7.93802C9.27761 8.2918 8.99002 8.86004 9.21796 9.40381C10.0014 11.2728 12.7272 13.9986 14.5962 14.782C15.14 15.01 15.7082 14.7224 16.062 14.2507Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                {active.length > 0 ? <p>{active.length}</p> : null}
            </button>
        </OutsideClickHandler>
    </>);
  };
export default Domru;