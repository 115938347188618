import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../../Components/DataGrid';
import NotFound from '../../../Components/NotFound';

// models
import {Competitions} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {empty} from '../../../Globals/Utils';
import {comonStatusName} from '../../../Globals/Constants';

class CompetitionsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			user:null,
			progress:10,
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		Competitions.getAll((data) => this.setState({dataShow:this.dataSet(data),data,dataFull:data,user,loading:false}));
	}
	dataSet = (data) => dgDataPrepare(data, 'competitions', ['id','name','discount','status','dateCreate']);
	dataStatus = (data, status) => data.filter(f => f.status === status);
	handleStatus = (e) => {
		let status = e.target.value;
		let data = this.state.dataFull;
		if (empty(status)) status = null;
		if (status != null) data = this.dataStatus(data, parseInt(status));
		this.setState({dataShow:this.dataSet(data),status,data});
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isLoyalty={true}>
						<DataGrid
							title={'Соревнования'}
							keys={['ID','Назвние','Скидка','Статус','Дата создания']}
							link={'competition'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							controlPanel={<>
								<div></div>
								<div>
									<span>Статус</span>
										<select onChange={this.handleStatus}>
										<option value=" ">все</option>
										{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
									</select>
								</div>
							</>}
							controlAdd={<Link to={'/loyalty/competition'}>Добавить</Link>}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default CompetitionsList;