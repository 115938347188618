import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import classNames from 'classnames';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';
import SaveStatus from '../../../Components/SaveStatus';
import Alert,{alertShow} from '../../../Components/Alert';

// models
import {ClientPromoCodes,ClientRanks,ClientRankTypes,Events,Logs,Lotteries,LotteryClients,Clients,ClientBalances,Pushes} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {dateNow,empty,phoneFormatter,timestampConvert,codeGen} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {comonStatus,eventType,lotteryStatus,lotteryStatusName,transactionType} from '../../../Globals/Constants';

// styles
import styles from './Lottery.module.css';
const cx = classNames.bind(styles);

class Lottery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			rankTypes:[],
			clients:[],
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false,
			isfinish:false,
			winner:false,
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		ClientRankTypes.getAll((data) => {
			this.setState({rankTypes:data});
			if (this.state.id !== 0) {
				Lotteries.get(this.state.id, (data) => {
					const isfinish = (dateNow() > data.dateTill || data.status === lotteryStatus.FINISH);
					data.dateTill = timestampConvert(data.dateTill);
					this.setState({data,dataOld:{...data},notfound:empty(data),isfinish,loading:false});
				});
				LotteryClients.get(this.state.id, (clients) => {
					this.setState({clients})
					clients.forEach((client) => {
						if (client.isWinner) {
							this.setState({winner:true});
						}
					});
				});
			}
			else this.dataInit();
		});
	}
	dataInit = () => this.setState({data:{userId:0,name:'',description:'',price:'',discount:'',promoCode:'',rankType:'',topUp:'',offline:'',status:lotteryStatus.ACTIVE,dateTill:''},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		const {id} = this.state;
		const data = {
			userId:this.state.user.id,
			name:this.state.data.name,
			description:this.state.data.description,
			price:parseInt(this.state.data.price),
			offline:this.state.data.offline,
			status:this.state.data.status,
			dateTill:Math.round(new Date(this.state.data.dateTill).getTime() / 1000)
		}
		if (this.state.data.discount) data.discount = parseInt(this.state.data.discount)
		if (this.state.data.rankType) data.rankType = parseInt(this.state.data.rankType)
		if (this.state.data.topUp) data.topUp = parseInt(this.state.data.topUp)
		if (this.state.data.offline) data.offline = this.state.data.offline
		if (!data.rankType && !data.topUp && !data.discount && !data.offline) {
			alertShow('<b>Ошибка!</b><br/>Вы не задали ни один из призов для розыгрыша!', true);
			return;
		}
		this.setState({issave:true});
		if (id === 0) {
			Lotteries.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.lottery.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.lottery.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.LOTTERY);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			Lotteries.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.lottery.update,iserror:false});
					Events.add(this.state.user.id, Texts.lottery.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.LOTTERY);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	winner = (id, clientId) => {
		const {data} = this.state;
		if (data.topUp || data.rankType) {
			Clients.get(clientId, (client) => {
				const user = {};
				if (data.topUp) {
					user.balance = client.balance + data.topUp;
					ClientBalances.add({clientId,amount:data.topUp,type:transactionType.DEBET,description:`Выигрыш в розыгрыш призов"${data.name}"`,commentSystem:this.state.id});
				}
				if (data.rankType) {
					user.rankType = data.rankType;
					ClientRanks.add({clientId,rankType:data.rankType,status:comonStatus.ACTIVE});
				}
				if (data.discount) user.discount = data.discount;
				Clients.update(clientId, user);
			});
		}
		if (data.promoCode) ClientPromoCodes.add({clientId,code:codeGen(5),discount:data.discount,status:comonStatus.ACTIVE,type:1,dateTill:0});
		LotteryClients.update(id, {isWinner:true});
		Lotteries.update(this.state.id, {status:lotteryStatus.FINISH});
		const {clients} = this.state;
		clients.forEach((v) => {
			if (v.clientId === clientId) {
				v.isWinner = true;
				Pushes.lottery.win(v.clientId);
			} else Pushes.lottery.lose(v.clientId);
		});
		this.setState({winner:true,clients});
		alertShow('<b>Розыгрыш завершен!</b><br/>Вы выбрали победителя, розыгрыш завершен!');
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isLoyalty={true}>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/loyalty/lotteries'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить розыгрыш' : 'Редактирование розыгрыша'}
								</span>
								<Link to={'/loyalty/lottery'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<div className={styles.formcontainerouter}>
									<form onSubmit={this.save}>
										<div className={styles.formcontainer}>
											<div className={styles.cell}>
												<label>
													<span>Название</span>
													<input type="text" name="name" value={this.state.data.name} placeholder="Название" onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Описание</span>
													<input type="text" name="description" value={this.state.data.description} placeholder="Описание" className={styles.double} onChange={this.handleForm} />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Цена, ₽</span>
													<input type="number" name="price" min={0} max={999} value={this.state.data.price} placeholder="100" onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.conditions}>
												<b>Приз</b>
												<div className={styles.onelinetop}>
													<div className={styles.cell}>
														<label>
															<span>Процент скидки</span>
															<input type="number" name="discount" min={0} max={99} value={this.state.data.discount} placeholder="5" onChange={this.handleForm} />
														</label>
													</div>
													<div className={styles.cell}>
														<label>
															<span>Процент скидки (промокод)</span>
															<input type="number" name="promoCode" min={0} max={99} value={this.state.data.promoCode} placeholder="5" onChange={this.handleForm} />
														</label>
													</div>
													<div className={styles.cell}>
														<label>
															<span>Присвоить статус</span>
															<select name="rankType" onChange={this.handleForm} defaultValue={parseInt(this.state.data.rankType)}>
																<option value=""></option>
																{this.state.rankTypes.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
															</select>
														</label>
													</div>
												</div>
												<div className={styles.onelinetop}>
													<div className={styles.cell}>
														<label>
															<span>Пополнить счет, ₽</span>
															<input type="number" name="topUp" min={0} max={999} value={this.state.data.topUp} placeholder="100" onChange={this.handleForm} />
														</label>
													</div>
													<div className={styles.cell}>
														<label>
															<span>Оффлайн приз</span>
															<input type="text" className={styles.double} name="offline" value={this.state.data.offline} placeholder="Оффлайн" onChange={this.handleForm} />
														</label>
													</div>
												</div>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Дата розыгрыша</span>
													<input type="datetime-local" name="dateTill" value={this.state.data.dateTill} placeholder="Дата" onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Статус</span>
													<select name="status" onChange={this.handleForm} defaultValue={parseInt(this.state.data.status)}>
														{lotteryStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</label>
											</div>
										</div>
										{this.state.id !== 0 ?
											<>
												<div className={cx(styles.formcontainer,styles.formcontainerclients)}>
													<h4 className={styles.title}>Участники</h4>
													{this.state.clients.length === 0 ? <NotFound message={'Нет ни одного участника розыгрыша'} /> :
														<table>
															<thead>
																<tr>
																	<th>№</th>
																	<th>ID</th>
																	<th>Имя</th>
																	<th>Телефон</th>
																	<th>&nbsp;</th>
																</tr>
															</thead>
															<tbody>
																{this.state.clients.map((v,i) => <tr key={i}>
																	<td>{i+1}</td>
																	<td>{v.clientId}</td>
																	<td><Link to={`/client/${v.clientId}`}>{v.clientName}</Link></td>
																	<td>{phoneFormatter(v.phone)}</td>
																	<td>{this.state.isfinish && this.state.winner ? (v.isWinner ? 'победитель' : '—') : <button type='button' onClick={() => this.winner(v.id, v.clientId)}>Сделать победителем</button>}</td>
																</tr>)}
															</tbody>
														</table>
													}
												</div>
											</> : null}
										{!this.state.isfinish ?
											<div className={styles.buttons}>
												{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
												{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
											</div> : null}
									</form>
								</div>
							}
						</div>
						<Alert />
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Lottery);