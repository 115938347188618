import React from 'react';

// helpers
import {dateGet} from '../../Globals/Utils';

// styles
import styles from './JsonTable.module.css';

// funcs
const fieldNameGet = (field) => {
	switch(field) {
		case 'id': return 'ID';
		case 'name': return 'Имя, название';
		case 'phone': return 'Телефон';
		case 'password': return 'Пароль';
		case 'type': return 'Тип';
		case 'status': return 'Статус';
		case 'statusCollect': return 'Статус сборки';
		case 'description': return 'Описание';
		case 'dateCreate': return 'Дата создания';
		case 'dateModify': return 'Дата изменения';
		case 'dateTill': return 'Дата окончания';
		case 'code': return 'Код';
		case 'discount': return 'Скидка';
		case 'discountId': return 'ID Cкидки';
		case 'discountPercent': return 'Процент скидки';
		case 'total': return 'Использовано';
		case 'count': return 'Количество';
		case 'userId': return 'Пользователь';
		case 'link': return 'Ссылка';
		case 'image': return 'Изображение';
		case 'sortOrder': return 'Сортировка';
		case 'clientId': return 'ID Клиента';
		case 'clientName': return 'Клиент';
		case 'latitude': return 'Широта';
		case 'longitude': return 'Долгота';
		case 'address': return 'Адрес';
		case 'room': return 'Квартира/Офис';
		case 'entrance': return 'Подъезд';
		case 'floor': return 'Этаж';
		case 'intercom': return 'Домофон';
		case 'title': return 'Заголовок';
		case 'keywords': return 'Ключевые слова';
		case 'content': return 'Контент';
		case 'value': return 'Значение';
		case 'orderId': return 'ID Заказа';
		case 'clientAddressId': return 'ID Адреса клиента';
		case 'price': return 'Цена';
		case 'products': return 'Позиции заказа';
		case 'deliveryCost': return 'Цена доставки';
		case 'deliveryIsFree': return 'Бесплатная доставка';
		case 'comment': return 'Комментарий';
		case 'priceDiscount': return 'Цена со скидкой';
		case 'sectionId': return 'ID Раздела';
		case 'sectionName': return 'Раздел';
		case 'categoryId': return 'ID Категории';
		case 'categoryName': return 'Категори';
		case 'measure': return 'Количество';
		case 'measureType': return 'Размерность';
		default: return field;
	}
}

const valueGet = (field, value) => {
	switch(field) {
		case 'phone': return 'Телефон';
		case 'dateCreate': return dateGet(value, {showTime:true,showSeconds:true});
		case 'dateModify': return dateGet(value, {showTime:true,showSeconds:true});
		case 'dateTill': return dateGet(value, {showTime:true,showSeconds:true});
		default: return value;
	}
}

const JsonTable = (props) => {
	const data = JSON.parse(props.data);
	if (data === null) return 'нет данных';
	return (
		<table className={styles.table}>
			<tbody>
				{data && Object.keys(data).map((v,i) => <tr key={i}><td>{fieldNameGet(v)}</td><td>{valueGet(v,data[v])}</td></tr>)}
			</tbody>
		</table>
	);
}

export default JsonTable;