import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import classNames from 'classnames';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';
import Alert,{alertShow} from '../../Components/Alert';

// models
import {Products,ClientScriptProducts,ClientScriptProductsItems,Events,Logs,Images} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';

// texts
import Texts from '../../Globals/Texts';

// globals
import {API, eventType} from '../../Globals/Constants';

// styles
import styles from './ClientScriptProduct.module.css';
const cx = classNames.bind(styles);

class ClientScriptProduct extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			products:[],
			relations:[],
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		Products.getAllActive((data) => {
			this.setState({products:data});
			if (this.state.id !== 0) {
				ClientScriptProducts.get(this.state.id, (data) => {
					data.image = empty(data.image) ? null : `${API.assets}/scriptproducts/${data.image}`;
					data.imageCover = empty(data.imageCover) ? null : `${API.assets}/scriptproducts/${data.imageCover}`;
					this.setState({data,dataOld:{...data},notfound:empty(data)});
					ClientScriptProductsItems.get(data.id, (data) => this.setState({relations:data,loading:false}));
				});
			}
			else this.dataInit();
		});
	}
	dataInit = () => this.setState({data:{name:'',image:'',imageFile:null,imageCover:'',imageCoverFile:null,isSingle:0},id:0,relations:[],loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	handleCheckboxForm = (e, id) => {
		if (e.target.checked) this.setState({relations:[...this.state.relations, this.state.products.find(f => f.id === id)]})
		else {
			const relations = this.state.relations.filter(f => f.productName ? (f.productId !== id) : (f.id !== id))
			this.setState({relations})
		}
	}
	save = (e) => {
		e.preventDefault();
		const {id,relations} = this.state;
		if (relations.length === 0) {
			alertShow('<b>Ошибка!</b><br/>Вы не выбрали ни одной позиции из меню!', true);
			return;
		}
		const data = {
			name:this.state.data.name,
			isSingle:this.state.data.isSingle
		};
		this.setState({issave:true});
		ClientScriptProductsItems.remove(id);
		setTimeout(() => {
			if (id === 0) {
				ClientScriptProducts.add(data, (res) => {
					this.stop(() => {
						const id = res.data.id;
						this.setState({saveStatus:Texts.client_products.add,iserror:false,id});
						Events.add(this.state.user.id, Texts.client_products.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.CLIENT_PRODUCT);
						this.imageSave(id, this.state.data.image, this.state.data.imageFile, this.state.data.imageName, false);
						this.imageCoverSave(id, this.state.data.imageCover, this.state.data.imageCoverFile, this.state.data.imageCoverName, false);
						this.itemsSave(id);
					});
				 }, (err) => {
					this.stop(() => {
						this.setState({saveStatus:Texts.errors.internalError,iserror:true});
						Logs.add(err);
					});
				 });
			} else {
				ClientScriptProducts.update(id, data, () => {
					this.stop(() => {
						this.setState({saveStatus:Texts.client_products.update,iserror:false});
						Events.add(this.state.user.id, Texts.client_products.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.CLIENT_PRODUCT);
						this.imageSave(id, this.state.data.image, this.state.data.imageFile, this.state.data.imageName, true);
						this.imageCoverSave(id, this.state.data.imageCover, this.state.data.imageCoverFile, this.state.data.imageCoverName, true);
						this.itemsSave(id);
					});
				}, (err) => {
					this.stop(() => {
						this.setState({saveStatus:Texts.errors.internalError,iserror:true});
						Logs.add(err);
					});
				});
			}
		}, 1000);
	}
	itemsSave = (id) => this.state.relations.forEach((v) => ClientScriptProductsItems.add({clientScriptProductsId:id,productId:v.productName?v.productId:v.id,productName:v.productName||v.name}));
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	priceFullGet = () => this.state.relations.reduce((a, b) => a + this.priceGet(b.productId||b.id), 0);
	priceGet = (id) => {
		const p = this.state.products.find(f => f.id === id);
		return p ? p.priceDiscount || p.price : 0;
	}
	imageChange = (e) => {
		const reader = new FileReader(), file = e.target.files[0];
		reader.onloadend = () => {
			let {data} = this.state
			data.image = reader.result;
			data.imageFile = file;
			data.imageName = file.name;
			this.setState({data});
		};
		reader.readAsDataURL(file);
	}
	imageDelete = () => {
		let {data} = this.state
		data.image = '';
		data.imageFile = null;
		this.setState({data});
	}
	imageSave = (id, image, file, filename, isupdate) => {
		if (image) {
			if (file) {
				Images.upload(id, 'scriptproduct', 0, file);
				ClientScriptProducts.update(id, {image:`scriptproduct_${id}_0_${filename}`});
			}
		} else {
			if (isupdate) ClientScriptProducts.update(id, {image:''});
		}
	}
	imageCoverChange = (e) => {
		const reader = new FileReader(), file = e.target.files[0];
		reader.onloadend = () => {
			let {data} = this.state
			data.imageCover = reader.result;
			data.imageCoverFile = file;
			data.imageCoverName = file.name;
			this.setState({data});
		};
		reader.readAsDataURL(file);
	}
	imageCoverDelete = () => {
		let {data} = this.state
		data.imageCover = '';
		data.imageCoverFile = null;
		this.setState({data});
	}
	imageCoverSave = (id, image, file, filename, isupdate) => {
		if (image) {
			if (file) {
				Images.upload(id, 'scriptproduct', 0, file);
				ClientScriptProducts.update(id, {imageCover:`scriptproduct_${id}_0_${filename}`});
			}
		} else {
			if (isupdate) ClientScriptProducts.update(id, {imageCover:''});
		}
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/clientscriptproducts'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить комплект' : 'Редактирование комплекта'}
								</span>
								<Link to={'/clientscriptproduct'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<form onSubmit={this.save}>
									<div className={cx(styles.formcontainer,styles.formcontainerfirst)}>
										<div className={styles.cell}>
											<label>
												<span>Картинка комплекта</span>
												{this.state.data.image && <img src={this.state.data.image} alt="" className={styles.image} />}
												<input onChange={this.imageChange} name="image" className={styles.file} accept="image/jpeg,image/png" type="file" />
												{empty(this.state.data.image) && <div className={styles.imageAdd}></div>}
											</label>
											{!empty(this.state.data.image) && <div className={styles.link} onClick={this.imageDelete.bind(this)}>&times; удалить изображение</div>}
										</div>
										<div className={styles.cell}>
											<label>
												<span>Обложка (задник) комплекта</span>
												{this.state.data.imageCover && <img src={this.state.data.imageCover} alt="" className={styles.image} />}
												<input onChange={this.imageCoverChange} name="image" className={styles.file} accept="image/jpeg,image/png" type="file" />
												{empty(this.state.data.imageCover) && <div className={styles.imageAdd}></div>}
											</label>
											{!empty(this.state.data.imageCover) && <div className={styles.link} onClick={this.imageCoverDelete.bind(this)}>&times; удалить изображение</div>}
										</div>
										<div className={styles.cell}>
											<label>
												<span>Название</span>
												<input type="text" name="name" value={this.state.data.name} placeholder="Название, описание" className={styles.double} onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Выделенный комплект</span>
												<select name="isSingle" onChange={this.handleForm} defaultValue={this.state.data.isSingle}>
													<option value={0}>нет</option>
													<option value={1}>да</option>
												</select>
											</label>
										</div>
									</div>
									<div className={styles.formcontainer}>
										<h4 className={styles.title}>
											Позиции меню
											{this.state.relations && this.state.relations.length ? <div>Комплект на сумму <b>{this.priceFullGet()}</b> ₽</div> : null}
										</h4>
										{this.state.relations && this.state.relations.length ?
											<ul className={styles.selectedproducts}>
												{this.state.relations.map((v,i) => <li key={i}><label><input type="checkbox" checked={true} onChange={(e) => this.handleCheckboxForm(e, v.productName?v.productId:v.id)} /> {v.name||v.productName}</label></li>)}
											</ul> : null}
										<ul>
											{this.state.products.filter(f => this.state.relations.find(d => d.productName ? (d.productId === f.id) : (d.id === f.id)) === undefined).map((v,i) => <li key={i}><label><input type="checkbox" checked={false} onChange={(e) => this.handleCheckboxForm(e, v.id)}  /> {v.name}</label></li>)}
										</ul>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							}
						</div>
						<Alert />
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(ClientScriptProduct);