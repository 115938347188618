import Http from '../../Globals/Http';

const get		= async (orderId) => await Http.get('orderitems', {conditions:[{k:'orderId',v:orderId}]});
const getSync	= (orderId, callback) => Http.get('orderitems', {conditions:[{k:'orderId',v:orderId}]}).then((res) => callback(res.data));

const add		= async (data) => Http.post('orderitems', {data});
const update	= async (id, data) => Http.put('orderitems', {data,conditions:[{k:'id',v:id}]});

const remove	= async (orderId) => Http.remove('orderitems', {conditions:[{k:'orderId',v:orderId}]});

export {
	get,
	getSync,
	add,
	update,
	remove
}