import React,{Component} from 'react';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';

// models
import {Stores} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import { empty } from '../../../Globals/Utils';

// globals
import {measureTypeName} from '../../../Globals/Constants';

// styles
import styles from './StoreAlerts.module.css';

class StoreAlerts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			data:[],
			progress:10,
			notfound:false,
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	dataGet = async () => {
		const stores = await Stores.getAllAsync();
		const balances = await Stores.balancesGet();
		const ingredients = await Stores.ingredientsGetAsync();
		const semifinish = await Stores.semifinishesGetAsync();
		const data = [];
		stores.data.forEach((v) => {
			const i = balances.data.filter(f => f.storeId === v.id);
			i.forEach((a) => {
				a.min = a.cardId ? this.minGet(a.cardId, semifinish.data) : this.minGet(a.ingredientId, ingredients.data);
			});
			const items = i.filter(f => f.min >= f.quantity);
			if (items.length) data.push({name:v.name,items});
		});
		this.setState({data,notfound:empty(data),loading:false});
	}
	minGet = (id, obj) => {
		const o = obj.find(f => f.id === id)
		return o ? o.minimumCount || 1 : 1;
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<>
						<MainTemplate isStore={true}>
							<div className={styles.container}>
								<h4>Сигналы</h4>
								{this.state.notfound ? <NotFound /> :
									<div className={styles.formcontainer}>
										{this.state.data.map((v,i) => <div key={i} className={styles.linepositions}>
											<h4>{v.name}</h4>
											<ul className={styles.productslist}>
												{v.items.map((v,i) => <li key={i}>
													<span className={styles.name}>{i+1}. {v.cardId ? <>{v.cardName} <b>(п/ф)</b></> : v.ingredientName}</span>
													<span className={styles.measure}>{v.quantity.toFixed(3)} {measureTypeName[v.measureType]} / {v.min} {measureTypeName[v.measureType]}</span>
												</li>)}
											</ul>
										</div>)}
									</div>
								}
							</div>
						</MainTemplate>
					</>
				}
			</>
		);
	}
}

export default withRouter(StoreAlerts);