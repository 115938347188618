import React,{Component} from 'react';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import DashboardTemplate	from '../../Components/MasterPages/DashboardTemplate';
import DashboardDispatcher	from '../../Components/Dashboards/Dispatcher';
import DashboardOperator	from '../../Components/Dashboards/Operator';
import DashboardCook		from '../../Components/Dashboards/Cook';
import DashboardCollector	from '../../Components/Dashboards/Collector';
import DashboardLogist		from '../../Components/Dashboards/Logist';
import DashboardMonitor		from '../../Components/Dashboards/Monitor';
import DashboardCourier		from '../../Components/Dashboards/Courier';

// helpers
import {ls} from '../../Globals/Localstorage';
import {zeroPad} from '../../Globals/Utils';

// globals
import {GENERAL,userType,timers} from '../../Globals/Constants';

// styles
import styles from './Dashboard.module.css';

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			loading:true
		};
	}
	workTimerId = null;
	suspendTimerId = null;
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		if (user.type === userType.ADMIN) {
			window.location.href='/statistics';
			return;
		}
		if (user.type === userType.CASHIER) {
			window.location.href='/store';
			return;
		}
		if (user.type === userType.COURIER) {
			//
		}
		this.setState({user});
		this.workTimerId = setInterval(() => {
			const d = new Date();
			const time = parseInt(`${zeroPad(d.getHours(), 2)}${zeroPad(d.getMinutes(), 2)}`);
			if (time > GENERAL.workTime.end || time < GENERAL.workTime.start) {
				this.suspendStart();
			}
		}, 1000);
	}
	componentWillUnmount = () => {
		['mousemove','touchmove','keydown','click'].forEach((e) => document.removeEventListener(e, this.timerReset, false));
		clearTimeout(this.suspendTimerId);
		this.suspendTimerId = null;
		this.workTimerStop();
	}
	workTimerStop = () => {
		clearTimeout(this.workTimerId);
		this.workTimerId = null;
	}
	suspendStart = () => {
		this.workTimerStop();
		this.timerReset();
		['mousemove','touchmove','keydown','click'].forEach((e) => document.addEventListener(e, this.timerReset, false));
	}
	timerReset = () => {
		clearTimeout(this.suspendTimerId);
        this.suspendTimerId = setTimeout(() => window.location.href = '/suspend', timers.IDLE);
	}
	dashboardGet = () => {
		const callback = () => this.setState({loading:false});
		switch(this.state.user.type) {
			case userType.DISPATCHER:
				return <DashboardDispatcher onFinish={callback} />
			case userType.OPERATOR:
				return <DashboardOperator onFinish={callback} />
			case userType.COOK:
				return <DashboardCook onFinish={callback} />
			case userType.COLLECTOR:
				return <DashboardCollector onFinish={callback} />
			case userType.LOGIST:
				return <DashboardLogist onFinish={callback} />
			case userType.MONITOR:
				return <DashboardMonitor onFinish={callback} />
			case userType.COURIER:
				return <DashboardCourier onFinish={callback} />
		}
		return null;
	}
	render() {
		const {user} = this.state;
		if (user === null) return null;
		return <DashboardTemplate userType={this.state.user.type}>
			<LoadingBar color='#f11946' progress={this.state.loading?100:0} />
			<div className={styles.container}>
				{this.dashboardGet()}
			</div>
		</DashboardTemplate>;
	}
}

export default withRouter(Dashboard);