import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

// screens
import Start				from './Screens/Start';
import Login				from './Screens/Login';

import Dashboard			from './Screens/Dashboard';

import License				from './Screens/License';

import Feedbacks			from './Screens/Feedbacks';
import Feedback				from './Screens/Feedback';
import Ratings				from './Screens/Ratings';
import Products				from './Screens/Products';
import Product				from './Screens/Product';
import ProductRealations	from './Screens/ProductRealations';
import ProductRelation		from './Screens/ProductRelation';
import Orders				from './Screens/Orders';
import Order				from './Screens/Order';
import OrdersSearch			from './Screens/OrdersSearch';
import Sections				from './Screens/Sections';
import Section				from './Screens/Section';
import Categories			from './Screens/Categories';
import Category				from './Screens/Category';
import Clients				from './Screens/Clients';
import Client				from './Screens/Client';
import ClientRanks			from './Screens/ClientRanks';
import ClientsSearch		from './Screens/ClientsSearch';
import Couriers				from './Screens/Couriers';
import Courier				from './Screens/Courier';
import Users				from './Screens/Users';
import User					from './Screens/User';
import Events				from './Screens/Events';
import Event				from './Screens/Event';
import Transactions			from './Screens/Transactions';
import Pages				from './Screens/Pages';
import Page					from './Screens/Page';
import Ads					from './Screens/Ads';
import Ad					from './Screens/Ad';
import Stories				from './Screens/Stories';
import Story				from './Screens/Story';
import Contents				from './Screens/Contents';
import Content				from './Screens/Content';
import ClientScripts		from './Screens/ClientScripts';
import ClientScript			from './Screens/ClientScript';
import ClientScriptProducts	from './Screens/ClientScriptProducts';
import ClientScriptProduct	from './Screens/ClientScriptProduct';
import Settings				from './Screens/Settings';
import Setting				from './Screens/Setting';
import Areas				from './Screens/Areas';
import Area					from './Screens/Area';
import DeliveryAreas		from './Screens/DeliveryAreas';
import DeliveryArea			from './Screens/DeliveryArea';
import Statistics			from './Screens/Statistics';
import Dayshifts			from './Screens/Dayshifts';
import StatisticProducts	from './Screens/StatisticProducts';
import StatisticSections	from './Screens/StatisticSections';
import StatisticCasher		from './Screens/StatisticCasher';

import Loyalty				from './Screens/Loyalty/Loyalty';
import Discounts			from './Screens/Loyalty/Discounts';
import Discount				from './Screens/Loyalty/Discount';
import PromoCodes			from './Screens/Loyalty/PromoCodes';
import PromoCode			from './Screens/Loyalty/PromoCode';
import ClientPromoCodes		from './Screens/Loyalty/ClientPromoCodes';
import ClientPromoCode		from './Screens/Loyalty/ClientPromoCode';
import ClientRankTypes		from './Screens/Loyalty/ClientRankTypes';
import ClientRankType		from './Screens/Loyalty/ClientRankType';
import Lotteries			from './Screens/Loyalty/Lotteries';
import Lottery				from './Screens/Loyalty/Lottery';
import Competitions			from './Screens/Loyalty/Competitions';
import Competition			from './Screens/Loyalty/Competition';
import FortunePrizes		from './Screens/Loyalty/FortunePrizes';
import FortunePrize			from './Screens/Loyalty/FortunePrize';
import FortunePrices		from './Screens/Loyalty/FortunePrices';
import FortunePrice			from './Screens/Loyalty/FortunePrice';
import FortuneClients		from './Screens/Loyalty/FortuneClients';
import FortuneClientsWin	from './Screens/Loyalty/FortuneClientsWin';
import FortuneClient		from './Screens/Loyalty/FortuneClient';

import StoreAlerts			from './Screens/Store/Alerts';
import StoreSection			from './Screens/Store/Section';
import StoreSections		from './Screens/Store/Sections';
import StoreStore			from './Screens/Store/Store';
import StoreStores			from './Screens/Store/Stores';
import StoreProvisor		from './Screens/Store/Provisor';
import StoreProvisors		from './Screens/Store/Provisors';
import StoreIngredient		from './Screens/Store/Ingredient';
import StoreIngredients		from './Screens/Store/Ingredients';
import StoreCard			from './Screens/Store/Card';
import StoreCards			from './Screens/Store/Cards';
import StoreCardSemifinish	from './Screens/Store/CardSemifinish';
import StoreCardsSemifinish	from './Screens/Store/CardsSemifinish';
import StoreBalances		from './Screens/Store/Balances';
import StoreRequests		from './Screens/Store/Requests';
import StoreRequest			from './Screens/Store/Request';
import StoreIncomes			from './Screens/Store/Incomes';
import StoreIncome			from './Screens/Store/Income';
import StoreIncomeRequest	from './Screens/Store/IncomeRequest';

import RefCalls				from './Screens/RefCalls';
import RefRequests			from './Screens/RefRequests';
import RefRequest 			from './Screens/RefRequest';
import RefUsers				from './Screens/RefUsers';
import RefPromos  			from './Screens/RefPromos';
import RefUser				from './Screens/RefUser';

import Info					from './Screens/Info';

import Error				from './Screens/Error';

// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={Start} exact />
			<Route path='/login' component={Login} exact />
			<Route path='/dashboard' component={Dashboard} exact />
			<Route path='/license' component={License} exact />

			<Route path='/orders' component={Orders} exact />
			<Route path='/order/:id' component={Order} exact />
			<Route path='/orderssearch' component={OrdersSearch} exact />
			<Route path='/ratings' component={Ratings} exact />
			<Route path='/feedbacks' component={Feedbacks} exact />
			<Route path='/feedback/:id' component={Feedback} exact />
			<Route path='/products' component={Products} exact />
			<Route path='/product' component={Product} exact />
			<Route path='/product/:id' component={Product} exact />
			<Route path='/productrelations' component={ProductRealations} exact />
			<Route path='/productrelation' component={ProductRelation} exact />
			<Route path='/productrelation/:id' component={ProductRelation} exact />
			<Route path='/sections' component={Sections} exact />
			<Route path='/section' component={Section} exact />
			<Route path='/section/:id' component={Section} exact />
			<Route path='/categories' component={Categories} exact />
			<Route path='/category' component={Category} exact />
			<Route path='/category/:id' component={Category} exact />
			<Route path='/clientranks' component={ClientRanks} exact />
			<Route path='/clients' component={Clients} exact />
			<Route path='/client' component={Client} exact />
			<Route path='/client/:id' component={Client} exact />
			<Route path='/clientssearch' component={ClientsSearch} exact />
			<Route path='/couriers' component={Couriers} exact />
			<Route path='/courier' component={Courier} exact />
			<Route path='/courier/:id' component={Courier} exact />
			<Route path='/users' component={Users} exact />
			<Route path='/user' component={User} exact />
			<Route path='/user/:id' component={User} exact />
			<Route path='/events' component={Events} exact />
			<Route path='/event' component={Event} exact />
			<Route path='/event/:id' component={Event} exact />
			<Route path='/transactions' component={Transactions} exact />
			<Route path='/pages' component={Pages} exact />
			<Route path='/page' component={Page} exact />
			<Route path='/page/:id' component={Page} exact />
			<Route path='/ads' component={Ads} exact />
			<Route path='/ad' component={Ad} exact />
			<Route path='/ad/:id' component={Ad} exact />
			<Route path='/stories' component={Stories} exact />
			<Route path='/story' component={Story} exact />
			<Route path='/story/:id' component={Story} exact />
			<Route path='/contents' component={Contents} exact />
			<Route path='/content' component={Content} exact />
			<Route path='/content/:id' component={Content} exact />
			<Route path='/clientscripts' component={ClientScripts} exact />
			<Route path='/clientscript' component={ClientScript} exact />
			<Route path='/clientscript/:id' component={ClientScript} exact />
			<Route path='/clientscriptproducts' component={ClientScriptProducts} exact />
			<Route path='/clientscriptproduct' component={ClientScriptProduct} exact />
			<Route path='/clientscriptproduct/:id' component={ClientScriptProduct} exact />
			<Route path='/settings' component={Settings} exact />
			<Route path='/setting' component={Setting} exact />
			<Route path='/setting/:id' component={Setting} exact />
			<Route path='/areas' component={Areas} exact />
			<Route path='/area' component={Area} exact />
			<Route path='/area/:id' component={Area} exact />
			<Route path='/deliveryareas' component={DeliveryAreas} exact />
			<Route path='/deliveryarea' component={DeliveryArea} exact />
			<Route path='/deliveryarea/:id' component={DeliveryArea} exact />
			<Route path='/statistics' component={Statistics} exact />
			<Route path='/dayshifts' component={Dayshifts} exact />
			<Route path='/statistic-products' component={StatisticProducts} exact />
			<Route path='/statistic-sections' component={StatisticSections} exact />
			<Route path='/statistic-casher' component={StatisticCasher} exact />

			<Route path='/dayopen' component={Info} exact />
			<Route path='/dayclose' component={Info} exact />
			<Route path='/suspend' component={Info} exact />
			<Route path='/stop' component={Info} exact />

			<Route path='/refcalls' component={RefCalls} exact />
			<Route path='/refrequests' component={RefRequests} exact />
			<Route path='/refrequest/:id' component={RefRequest} exact />
			<Route path='/refusers' component={RefUsers} exact />
			<Route path='/refpromos' component={RefPromos} exact />
			<Route path='/refuser/:id' component={RefUser} exact />

			<Route path='/loyalty/alerts' component={Loyalty} exact />
			<Route path='/loyalty/discounts' component={Discounts} exact />
			<Route path='/loyalty/discount' component={Discount} exact />
			<Route path='/loyalty/discount/:id' component={Discount} exact />
			<Route path='/loyalty/promocodes' component={PromoCodes} exact />
			<Route path='/loyalty/promocode' component={PromoCode} exact />
			<Route path='/loyalty/promocode/:id' component={PromoCode} exact />
			<Route path='/loyalty/clientpromocodes' component={ClientPromoCodes} exact />
			<Route path='/loyalty/clientpromocode' component={ClientPromoCode} exact />
			<Route path='/loyalty/clientpromocode/:id' component={ClientPromoCode} exact />
			<Route path='/loyalty/clientranktypes' component={ClientRankTypes} exact />
			<Route path='/loyalty/clientranktype' component={ClientRankType} exact />
			<Route path='/loyalty/clientranktype/:id' component={ClientRankType} exact />
			<Route path='/loyalty/lotteries' component={Lotteries} exact />
			<Route path='/loyalty/lottery' component={Lottery} exact />
			<Route path='/loyalty/lottery/:id' component={Lottery} exact />
			<Route path='/loyalty/competitions' component={Competitions} exact />
			<Route path='/loyalty/competition' component={Competition} exact />
			<Route path='/loyalty/competition/:id' component={Competition} exact />
			<Route path='/loyalty/wofprizes' component={FortunePrizes} exact />
			<Route path='/loyalty/wofprize/:id' component={FortunePrize} exact />
			<Route path='/loyalty/wofprices' component={FortunePrices} exact />
			<Route path='/loyalty/wofprice/:id' component={FortunePrice} exact />
			<Route path='/loyalty/wofclients' component={FortuneClients} exact />
			<Route path='/loyalty/wofclientswin' component={FortuneClientsWin} exact />
			<Route path='/loyalty/wofclient/:id' component={FortuneClient} exact />

			<Route path='/store' component={StoreAlerts} exact />
			<Route path='/store/alerts' component={StoreAlerts} exact />
			<Route path='/store/section' component={StoreSection} exact />
			<Route path='/store/section/:id' component={StoreSection} exact />
			<Route path='/store/sections' component={StoreSections} exact />
			<Route path='/store/store' component={StoreStore} exact />
			<Route path='/store/store/:id' component={StoreStore} exact />
			<Route path='/store/stores' component={StoreStores} exact />
			<Route path='/store/provisor' component={StoreProvisor} exact />
			<Route path='/store/provisor/:id' component={StoreProvisor} exact />
			<Route path='/store/provisors' component={StoreProvisors} exact />
			<Route path='/store/ingredient' component={StoreIngredient} exact />
			<Route path='/store/ingredient/:id' component={StoreIngredient} exact />
			<Route path='/store/ingredients' component={StoreIngredients} exact />
			<Route path='/store/card' component={StoreCard} exact />
			<Route path='/store/card/:id' component={StoreCard} exact />
			<Route path='/store/cards' component={StoreCards} exact />
			<Route path='/store/cardsemifinish' component={StoreCardSemifinish} exact />
			<Route path='/store/cardsemifinish/:id' component={StoreCardSemifinish} exact />
			<Route path='/store/cardssemifinish' component={StoreCardsSemifinish} exact />
			<Route path='/store/balances' component={StoreBalances} exact />
			<Route path='/store/requests' component={StoreRequests} exact />
			<Route path='/store/request' component={StoreRequest} exact />
			<Route path='/store/request/:id' component={StoreRequest} exact />
			<Route path='/store/incomes' component={StoreIncomes} exact />
			<Route path='/store/income' component={StoreIncome} exact />
			<Route path='/store/income/:id' component={StoreIncome} exact />
			<Route path='/store/incomerequest/:id' component={StoreIncomeRequest} exact />

			<Route path='/error' component={Error} status={500} exact />
			<Route path='/error401' component={Error} status={401} exact />
			<Route path='/error403' component={Error} status={403} exact />
			<Route path='/error404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />
		</Switch>
	</BrowserRouter>
);

export default App;