import Http from '../../Globals/Http';
import {requestOderType, semifinishType} from '../../Globals/Constants';

// stores
const getAll			= (callback)					=> Http.get('storestores').then((res) => callback(res.data));
const getAllAsync		= async ()						=> await Http.get('storestores');
const getAreaAsync		= async (areaId)				=> await Http.get('storestores', {conditions:[{k:'areaId',v:areaId}]});
const get				= (id, callback)				=> Http.get('storestores', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));
const getAsync			= async (id)					=> await Http.get('storestores', {conditions:[{k:'id',v:id}]});
const add				= (data, callback, error)		=> Http.post('storestores', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update			= (id, data, callback, error)	=> Http.put('storestores', {data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

// sections
const sectionsGet		= (callback)					=> Http.get('storesections').then((res) => callback(res.data));
const sectionsGetAsync	= async ()						=> await Http.get('storesections');
const sectionGet		= (id, callback)				=> Http.get('storesections', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));
const sectionAdd		= (data, callback, error)		=> Http.post('storesections', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const sectionUpdate		= (id, data, callback, error)	=> Http.put('sections', {data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

// provisors
const provisorsGet		= (callback)					=> Http.get('storeprovisors').then((res) => callback(res.data));
const provisorsGetAsync	= async ()						=> await Http.get('storeprovisors');
const provisorGet		= (id, callback)				=> Http.get('storeprovisors', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));
const provisorAdd		= (data, callback, error)		=> Http.post('storeprovisors', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const provisorUpdate	= (id, data, callback, error)	=> Http.put('storeprovisors', {data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

// ingredients
const ingredientsGet	= (callback)					=> Http.get('storeingredients').then((res) => callback(res.data));
const ingredientsGetAsync	= async ()					=> await Http.get('storeingredients');
const ingredientGet		= (id, callback)				=> Http.get('storeingredients', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));
const ingredientAdd		= (data, callback, error)		=> Http.post('storeingredients', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const ingredientUpdate	= (id, data, callback, error)	=> Http.put('storeingredients', {data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

// cards
const cardsGet			= (callback)					=> Http.get('storecards', {conditions:[{k:'type',v:semifinishType.NORMAL}]}).then((res) => callback(res.data));
const cardGet			= (id, callback)				=> Http.get('storecards', {conditions:[{k:'id',v:id},{k:'type',v:semifinishType.NORMAL}]}).then((res) => callback(res.data[0]));
const cardAdd			= (data, callback, error)		=> Http.post('storecards', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const cardUpdate		= (id, data, callback, error)	=> Http.put('storecards', {data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

// card items
const cardItemsGet		= async (cardId)			=> await Http.get('storecarditems', {conditions:[{k:'cardId',v:cardId}]});
const cardItemAdd		= async (data)				=> await Http.post('storecarditems', {data});
const cardItemDelete	= async (id)				=> await Http.remove('storecarditems', {conditions:[{k:'id',v:id}]});
const cardItemsDelete	= async (cardId)			=> await Http.remove('storecarditems', {conditions:[{k:'cardId',v:cardId}]});

// semifinish
const semifinishesGet	= (callback)				=> Http.get('storecards', {conditions:[{k:'type',v:semifinishType.SEMIFINISH}]}).then((res) => callback(res.data));
const semifinishesGetAsync	= async (callback)			=> await Http.get('storecards', {conditions:[{k:'type',v:semifinishType.SEMIFINISH}]});
const semifinishGet		= (id, callback)			=> Http.get('storecards', {conditions:[{k:'id',v:id},{k:'type',v:semifinishType.SEMIFINISH}]}).then((res) => callback(res.data[0]));
const semifinishAdd		= (data, callback, error)	=> Http.post('storecards', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const semifinishUpdate	= (id, data, callback, error)	=> Http.put('storecards', {data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

// balance
const balancesGet		= async ()					=> await Http.get('storebalances');
const balanceUpdate		= (data)					=> Http.put('storebalances', data);
const balanceSell		= (orderId)					=> Http.request('storebalances', 'sell', {orderId});

// requests
const requestsGet		= (callback)				=> Http.get('storerequests', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const requestGet		= (id, callback)			=> Http.get('storerequests', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));
const requestsStoreGetAsync	= async (storeId, type, status)	=> await Http.get('storerequests', {conditions:[{k:'storeId',v:storeId},{k:'type',v:type},{k:'status',v:status}]});
const requestAdd		= (data, callback, error)	=> Http.post('storerequests', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const requestUpdate		= (id, data)				=> Http.put('storerequests', {data,conditions:[{k:'id',v:id}]});

// request items
const requestItemsGet	= async (requestId)			=> await Http.get('storerequestitems', {conditions:[{k:'requestId',v:requestId}]});
const requestItemAdd	= async (data)				=> await Http.post('storerequestitems', {data});

// incomes
const incomesGet		= (callback)				=> Http.get('storeincomes', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const incomesGetAsync	= async ()					=> await Http.get('storeincomes', {orders:[{k:'id',isd:requestOderType.DESC}]});
const incomeGet			= (id, callback)			=> Http.get('storeincomes', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));
const incomeAdd			= (data, callback, error)	=> Http.post('storeincomes', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

// income items
const incomeItemsGet	= async (incomeId)			=> await Http.get('storeincomeitems', {conditions:[{k:'incomeId',v:incomeId}]});
const incomeItemsAdd	= async (data)				=> await Http.post('storeincomeitems', {data});

export {
	getAll,
	getAllAsync,
	getAreaAsync,
	get,
	getAsync,
	add,
	update,
	sectionsGet,
	sectionsGetAsync,
	sectionGet,
	sectionAdd,
	sectionUpdate,
	provisorsGet,
	provisorsGetAsync,
	provisorGet,
	provisorAdd,
	provisorUpdate,
	ingredientsGet,
	ingredientsGetAsync,
	ingredientGet,
	ingredientAdd,
	ingredientUpdate,
	cardsGet,
	cardGet,
	cardAdd,
	cardUpdate,
	cardItemsGet,
	cardItemAdd,
	cardItemDelete,
	cardItemsDelete,
	semifinishesGet,
	semifinishesGetAsync,
	semifinishGet,
	semifinishAdd,
	semifinishUpdate,
	balancesGet,
	balanceUpdate,
	balanceSell,
	requestsGet,
	requestGet,
	requestsStoreGetAsync,
	requestAdd,
	requestUpdate,
	requestItemsGet,
	requestItemAdd,
	incomesGet,
	incomesGetAsync,
	incomeGet,
	incomeAdd,
	incomeItemsGet,
	incomeItemsAdd
}