import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';

// models
import {Users,Events,Logs,Areas,Accountings} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty,phoneNormalize,dateNow, tsDay, dateGet, moneyFormat} from '../../Globals/Utils';
import errorsParse from '../../Globals/Errors';

// texts
import Texts from '../../Globals/Texts';

// globals
import {userStatusName,userStatus,userType,eventType,errorType} from '../../Globals/Constants';

// styles
import styles from './Courier.module.css';
const cx = classNames.bind(styles);

class Courier extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			areas:[],
			debt:null,
			moneyreports:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		Areas.getAllSync(async (data) => {
			this.setState({areas:data});
			if (this.state.id !== 0) {
				const d = await Accountings.get(this.state.id);
				const debt = d ? d.data.reduce((a,b) => a + b.debt, 0) : 0;
				this.setState({debt});
				Users.get(this.state.id, (data) => this.setState({data,dataOld:{...data},notfound:empty(data),loading:false}));
			} else this.dataInit();
		});
	}
	dataInit = () => this.setState({data:{name:'',phone:'',type:userType.COURIER,status:userStatus.ACTIVE,password:'',description:''},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		let data = {
			name:this.state.data.name,
			phone:phoneNormalize(this.state.data.phone),
			status:this.state.data.status,
			type:userType.COURIER,
			areaId:this.state.data.areaId
		}
		data.password = `courier_${data.phone}`;
		if (!empty(this.state.data.description)) data.description = this.state.data.description;
		if (data.status === userStatus.ACTIVE) data.description = '';
		if (id === 0) {
			Users.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.users.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.users.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.COURIER);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicatePhone:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			data.dateModify = dateNow();
			Users.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.users.update,iserror:false});
					Events.add(this.state.user.id, Texts.users.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.USER);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicatePhone:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	dateget = (v) => {
		const d = dateGet(v * tsDay, {hideYear:true});
		return `${d.day}.${d.month}`;
	}
	totalget = (v) => v.reduce((a, b) => a + b.amount, 0)
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/couriers'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить курьера' : 'Редактирование курьера'}
								</span>
								<Link to={'/courier'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<form onSubmit={this.save}>
									<div className={styles.formcontainer}>
										<div className={styles.cell}>
											<label>
												<span>Имя</span>
												<input type="text" name="name" value={this.state.data.name} placeholder="Имя" onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Телефон</span>
												<InputMask type="text" name="phone" mask="+7 (999) 999-99-99" maskChar={null} placeholder="Телефон" value={this.state.data.phone} onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Точка</span>
												<select name="areaId" onChange={this.handleForm} defaultValue={parseInt(this.state.data.areaId)} required>
													<option></option>
													{this.state.areas.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
												</select>
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Статус</span>
												<select name="status" onChange={this.handleForm} defaultValue={parseInt(this.state.data.status)}>
													{userStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
												</select>
											</label>
										</div>
										{parseInt(this.state.data.status) !== userStatus.ACTIVE ?
											<>
												<div className={styles.notice}>
													<span>Внимание! Вы выбрали статус курьера <b>{userStatusName[this.state.data.status]}</b>. Необходимо указать причину смены статуса</span>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Описание блокировки</span>
														<textarea name="description" placeholder="Опишите причину блокировки оператора" className={styles.desc} value={this.state.data.description} onChange={this.handleForm} required></textarea>
													</label>
												</div>
											</>
										: null}
									</div>
									<div className={cx(styles.formcontainer,styles.moneyreportcontainer)}>
										<h4 className={styles.title}>Отчет о внесении денег</h4>
										<div className={styles.moneyreport}>
											<div className={styles.income}>
												<span><b>Текущий долг</b></span>
												<span><b>{moneyFormat(this.state.debt||0, false)} ₽</b></span>
											</div>
										</div>
										{this.state.moneyreports ? Object.entries(this.state.moneyreports).map((v,i) => <div key={i} className={styles.moneyreport}>
												<b>{this.dateget(v[0])}</b>
												{v[1].map((v,i) => <div key={i} className={styles.income}>
														<span>{dateGet(v.dateCreate, {showTime:true})}</span>
														<span>{moneyFormat(v.amount, false)} ₽</span>
													</div>)}
												<div className={styles.income}>
													<span><b>Итого</b></span>
													<span><b>{moneyFormat(this.totalget(v[1]), false)} ₽</b></span>
												</div>
											</div>) : null}
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Courier);