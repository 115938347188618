import Http from '../../Globals/Http';
import {requestOderType,comonStatus} from '../../Globals/Constants';


const getLastManual	= async (id)	=> await Http.get('courierstatuses', {conditions:[{k:'userId',v:id}],orders:[{k:'id',isd:requestOderType.DESC}],limits:[0,1]});
const getLast		= async (ids)	=> await Http.request('courierstatuses', 'byUserIdsLastGet', ids);


export {
	getLastManual,
	getLast
}