import Http from '../../Globals/Http';
import {requestOderType,requestConcatinationType,requestConditionType,orderStatus} from '../../Globals/Constants';

const getAll		= (callback)			=> Http.get('orders', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getPage		= (limits, callback)	=> Http.get('orders', {orders:[{k:'id',isd:requestOderType.DESC}],limits}).then((res) => callback(res.data));

const get			= (id, callback)		=> Http.get('orders', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const getAsync		= async (id)			=> await Http.request('orders', 'byIdGet', id);

const getByClient	= (id, callback)		=> Http.get('orders', {conditions:[{k:'clientId',v:id}],orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getByPhone	= (phone, callback)		=> Http.get('orders', {conditions:[{k:'phone',v:phone}]}).then((res) => callback(res.data));

const getDayShiftOrders	= async (areaId, dayShiftId)	=> await Http.get('orders', {conditions:[{k:'areaId',v:areaId},{k:'dayShiftId',v:dayShiftId}]});
const getDayOrders		= async (date)		=> await Http.get('orders', {conditions:[{k:'dateCreate',v:date,op:requestConditionType.MORE}]});

const getOnDateGet	= async (date)			=> await Http.get('orders', {conditions:[{k:'date',v:date,op:requestConditionType.MORE}]});

const getByStatuses	= async (statuses) 		=> await Http.get('orders', {conditions:statuses.map((v) => ({k:'status',v:v,con:requestConcatinationType.OR})),orders:[{k:'id',isd:requestOderType.DESC}]});

const getDispatcherActivce	= async ()		=> await Http.request('orders', 'dispatcherActiveGet');

const getFeedbacks	= (limits, callback)	=> Http.get('orders', {conditions:[{k:'status',v:orderStatus.REFUND,op:requestConditionType.MORE},{k:'feedback',v:null,op:requestConditionType.NOT_EQUAL}],orders:[{k:'id',isd:requestOderType.DESC}],limits}).then((res) => callback(res.data));

const find			= (data, callback)		=> Http.request('orders', 'find', data).then((res) => callback(res.data));

const add			= (data, callback, error)		=> Http.post('orders', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update		= (id, data, callback, error)	=> Http.put('orders', {data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

const addFull		= (data, callback, error)		=> Http.request('orders', 'addFull', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

const reports		= {
	online			: async (areaId, dayShiftId)	=> await Http.request('orders', 'reportOnline', {areaId,dayShiftId}),
	ondatenow		: async ()						=> await Http.request('orders', 'deferredGet'),
	ondate			: async (start, end)			=> await Http.request('orders', 'deferredGet', {start,end})
}

const stats			= {
	get				: async (start, end)	=> await Http.request('orders', 'statisticGet', {start,end}),
	popular			: async (start)			=> await Http.request('orders', 'popularProductsGet', {start}),
	products		: async (areaId, dayShiftId)	=> await Http.request('orders', 'popularProductsAllGet', {areaId,dayShiftId}),
	productsoldnow	: async (areaId)		=> await Http.request('orders', 'popularProductsOldGet', {areaId}),
	productsold		: async (areaId,start, end)	=> await Http.request('orders', 'popularProductsOldGet', {areaId,start,end})
}

const updateItems = (data, callback, error) => Http.request('orders', 'updateItems', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export {
	get,
	getAll,
	getPage,
	getAsync,
	getByClient,
	getByPhone,
	getByStatuses,
	getDispatcherActivce,
	getFeedbacks,
	getDayShiftOrders,
	getDayOrders,
	getOnDateGet,
	find,
	add,
	update,
	addFull,
	reports,
	stats,
	updateItems
}