
const Texts = {
	errors: {
		internalError		: 'Во время операции произошла ошибка. Пожайлуста повторите операцию.',
		duplicatePhone		: 'Такой телефон уже используется в системе',
		duplicatePromoCode	: 'Такой промокод уже используется в системе',
		duplicateLink		: 'Такая ссылка уже используется в системе',
		discount			: 'Необходимо установить сумму скидки или процент скидки',
		conditionTime		: 'Необходимо установить время начала и окончания действия скидки, либо оставить поля пустыми'
	},
	success: {
		simple				: 'Операция прошла успешно!'
	},
	info: {
		courierNotSelected	: 'Внимание! Вы не выбрали курьеа для заказа, он будет назначен автоматически!<br/>Нажмите еще раз кнопку <b>Принять заказ</b>',
	},
	users: {
		add					: 'Пользователь создан',
		update				: 'Пользователь обновлен'
	},
	client: {
		add					: 'Клиент создан',
		update				: 'Клиент обновлен'
	},
	client_address: {
		add					: 'Адрес клиента создан',
		update				: 'Адрес клиента обновлен'
	},
	promocodes: {
		add					: 'Промокод создан',
		update				: 'Промокод обновлен'
	},
	lottery: {
		add					: 'Розыгрыш создан',
		update				: 'Розыгрыш обновлен'
	},
	competition: {
		add					: 'Соревнование создано',
		update				: 'Соревнование обновлено'
	},
	client_promocodes: {
		add					: 'Клиентский промокод создан',
		update				: 'Клиентский промокод обновлен'
	},
	discounts: {
		add					: 'Скидка создана',
		update				: 'Скидка обновлена'
	},
	categories: {
		add					: 'Категория создана',
		update				: 'Категория обновлена'
	},
	sections: {
		add					: 'Раздел создан',
		update				: 'Раздел обновлен'
	},
	products: {
		add					: 'Позиция меню создана',
		update				: 'Позиция меню обновлена'
	},
	productrelations: {
		add					: 'Связанная позиция меню создана',
		update				: 'Связанная позиция меню обновлена'
	},
	client_scripts: {
		add					: 'Клиентский сценарий создан',
		update				: 'Клиентский сценарий обновлен'
	},
	client_products: {
		add					: 'Комплект создан',
		update				: 'Комплект обновлен'
	},
	pages: {
		add					: 'Страница создана',
		update				: 'Страница обновлена'
	},
	ads: {
		add					: 'Рекламный блок создан',
		update				: 'Рекламный блок обновлен'
	},
	area: {
		add					: 'Точка создана',
		update				: 'Точка обновлена'
	},
	deliveryarea: {
		add					: 'Зона доставки создана',
		update				: 'Зона доставки обновлена'
	},
	contents: {
		add					: 'Блок контнета создан',
		update				: 'Блок контнета обновлен'
	},
	settings: {
		add					: 'Настройка создана',
		update				: 'Настройка обновлена'
	},
	order: {
		add					: 'Заказ в ручном режиме создан',
		update				: 'Статус заказа обновлен'
	},
	order_status: {
		add					: 'Статус для заказа создан',
		update				: 'Статус заказа обновлен'
	},
	client_discount: {
		add					: 'Клиентская скидка создана'
	},
	client_rank: {
		add					: 'Клиентский статус создан',
		update				: 'Клиентский статус обновлен'
	},
	stories: {
		add					: 'Акция-история создана',
		update				: 'Акция-история обновлена'
	},
	courier_orders: {
		add					: 'Заказ назначен на курьера',
		update				: 'Заказ завершен',
		remove				: 'Заказ снят с курьера'
	},
	cook_orders: {
		add					: 'Заказ назначен на повара',
		update				: 'Повар приготовил заказ'
	},
	courier_route: {
		add					: 'Геопозиция курьера добавлена'
	},
	order_items: {
		update				: 'Позиция в заказе готова'
	},
	order_items_collect: {
		update				: 'Позиция в заказе готова'
	},
	transaction: {
		add					: 'Транзакция создана'
	},
	order_check: {
		add					: 'Чек для заказа создан (напечатан)'
	},
	order_money_report: {
		add					: 'Накладная создана',
		update				: 'Накладная обновлена',
		remove				: 'Накладная удалена'
	},
	feedback: {
		add					: 'Обращение создано'
	},
	stores: {
		add					: 'Склад создан',
		update				: 'Склад обновлен'
	},
	provisors: {
		add					: 'Поставщик создан',
		update				: 'Поставщик обновлен'
	},
	ingredients: {
		add					: 'Позиция создана',
		update				: 'Позиция обновлена'
	},
	card: {
		add					: 'Технологическая карта создана',
		update				: 'Технологическая карта обновлена'
	},
	carditem: {
		add					: 'Позиция технологической карты создана',
		update				: 'Позиция технологической карты обновлена'
	},
	request: {
		add					: 'Заявка создана',
		update				: 'Заявка обновлена'
	},
	requestitem: {
		add					: 'Позиция заявки создана',
		update				: 'Позиция заявки обновлена'
	},
	income: {
		add					: 'Поступление создано',
		update				: 'Поступление обновлено'
	},
	incomeitem: {
		add					: 'Позиция поступления создана',
		update				: 'Позиция поступления обновлена'
	},
	fortunewheelprize: {
		add					: 'Колесо удачи. Приз создан',
		update				: 'Колесо удачи. Приз обновлен'
	},
	fortunewheelprice: {
		add					: 'Колесо удачи. Цена создана',
		update				: 'Колесо удачи. Цена обновлена'
	}
};

export default Texts;