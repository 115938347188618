import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';

// models
import {DeliveryAreas,Events,Logs} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';

// texts
import Texts from '../../Globals/Texts';

// globals
import {comonStatusName,eventType} from '../../Globals/Constants';

// styles
import styles from './DeliveryArea.module.css';

class DeliveryArea extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		if (this.state.id !== 0) DeliveryAreas.getSync(this.state.id, (data) => this.setState({data,dataOld:{...data},notfound:empty(data),loading:false}));
		else this.dataInit();
	}
	dataInit = () => this.setState({data:{name:'',description:'',area:'',price:0,priceFree:0,minimumAmount:0,status:0},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	handleCheckbox = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.checked ? 1 : 0;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		const data = {
			name:this.state.data.name,
			description:this.state.data.description,
			area:this.state.data.area,
			price:this.state.data.price,
			priceFree:this.state.data.priceFree,
			minimumAmount:this.state.data.minimumAmount,
			alwaysPaid:this.state.data.alwaysPaid,
			status:this.state.data.status
		}
		if (id === 0) {
			DeliveryAreas.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.deliveryarea.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.deliveryarea.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.DELIVERY_AREA);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			DeliveryAreas.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.deliveryarea.update,iserror:false});
					Events.add(this.state.user.id, Texts.deliveryarea.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.DELIVERY_AREA);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/deliveryareas'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить зону доставки' : 'Редактирование зоны доставки'}
								</span>
								<Link to={'/deliveryarea'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfouand ? <NotFound /> :
							<>
								<form onSubmit={this.save} className={styles.form}>
									<div className={styles.formcontainer}>
										<div className={styles.cell}>
											<label>
												<span>Название</span>
												<input type="text" name="name" value={this.state.data.name} placeholder="Название" className={styles.double} onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Описание</span>
												<textarea name="description" placeholder="Описание" value={this.state.data.description} onChange={this.handleForm}></textarea>
											</label>
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Цена доставки, ₽</span>
													<input type="text" name="price" value={this.state.data.price} placeholder="Цена доставки, ₽" onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Бесплатная доставка, ₽</span>
													<input type="text" name="priceFree" value={this.state.data.priceFree} placeholder="Бесплатная доставка, ₽" onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Минимальный заказ, ₽</span>
													<input type="text" name="minimumAmount" value={this.state.data.minimumAmount} placeholder="Минимальный заказ, ₽" onChange={this.handleForm} required />
												</label>
											</div>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Всегда платная доставка</span>
												<div><input type="checkbox" name="alwaysPaid" defaultChecked={this.state.data.alwaysPaid} onChange={this.handleCheckbox} /> да</div>
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Область на карте</span>
												<textarea name="area" placeholder="Область на карте" value={this.state.data.area} onChange={this.handleForm} className={styles.large} required></textarea>
											</label>
											<a href="https://yandex.ru/map-constructor" target="_blank" rel="noopener noreferrer">Конструктор карт Яндека</a>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Статус</span>
												<select name="status" onChange={this.handleForm} defaultValue={parseInt(this.state.data.status)}>
													{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
												</select>
											</label>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							</>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(DeliveryArea);