import Http from '../../Globals/Http';
import {requestOderType,comonStatus} from '../../Globals/Constants';

const getAll		= (callback)		=> Http.get('products', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const get			= (id, callback)	=> Http.get('products', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));
const getAllActive	= (callback)		=> Http.get('products', {conditions:[{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getAllActiveAsync	= async ()		=> await Http.get('products', {conditions:[{k:'status',v:comonStatus.ACTIVE}]});

const getZero		= async ()			=> await Http.get('products', {conditions:[{k:'status',v:comonStatus.ACTIVE},{k:'price',v:0}]});

const add			= (data, callback, error)		=> Http.post('products', {data:data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update		= (id, data, callback, error)	=> Http.put('products', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

const priceGet = (item) => item.priceDiscount ? item.priceDiscount : item.price

export {
	getAll,
	get,
	getAllActive,
	getAllActiveAsync,
	getZero,
	add,
	update,
	priceGet
}