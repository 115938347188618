import {API} from '../../Globals/Constants';

const isDevelopmentMode = process.env.NODE_ENV !== 'production';

const upload = (id, type, index, fileData) => {
	let fd = new FormData();
	fd.append('id', id);
	fd.append('type', type);
	fd.append('index', index);
	fd.append('image', fileData);
	return new Promise((resolve, reject) => {
		const url = `${API.url}/images/add`;
		const options = {
			method:'POST',
			headers:new Headers({'Authentication':API.key}),
			body: fd
		};
		fetch(url, options)
			.then(res => {
				if (isDevelopmentMode) console.log(res);
				return res.json();
			})
			.then(res => {
				if (isDevelopmentMode) console.log(res);
				if (res.code === 0) resolve(res);
				else reject(res);
			})
			.catch(error => {
				if (isDevelopmentMode) console.log(error);
				reject(error);
		});
	});
}

export {
	upload
}