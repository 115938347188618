const isDeveloperMode = process.env.NODE_ENV !== 'production';
const url = isDeveloperMode ? '//localhost' : '//kfsamara.ru';
const url_franchise = isDeveloperMode ? '//localhost:8080' : '//nb.kfsamara.ru';

const API = {
	url			: `${url}/api`,
	assets		: `${url}/assets`,
	key			: 'ef36a9ca-0f42-4aa5-a8a5-08821766a1fe',
	push: {
		client	: '95ff8bee-f106-4a4a-9de0-952cdc34f59a',
		courier	: '293b14cb-19e3-4b3f-99a6-f33069bc38ed'
	},
	version		: '2.3.31',
	siteURL		: 'https://kfsamara.ru',
	franchise	: {
		url		: url_franchise
	}
};

const SITE = {
	url			: url,
	docs		: (data, type) =>  `${url}/docs/?data=${data}&type=${type}`
};

const MAP = {
	keyYandex			: 'ad2b9fe2-ef89-45ee-8fac-9c91f4a5b1df',
	//keyYandex			: '247507cd-4b56-4b1c-b511-ae999a49d559', // old worked key
	geoUrlCoordinates	: (latitude, longitude) => `https://geocode-maps.yandex.ru/1.x/?apikey=${MAP.keyYandex}&format=json&lang=ru_RU&geocode=${longitude},${latitude}`,
	geoUrlAddress		: (address) => `https://geocode-maps.yandex.ru/1.x/?apikey=${MAP.keyYandex}&format=json&lang=ru_RU&geocode=${encodeURIComponent(address)}`,
	defaultCoordinates	: [53.200302, 50.159226],
	defaultCity			: 'Самара'
};

const GENERAL = {
	workTime : {
		start			: 930,
		end				: 2330
	},
	PAGESIZE: {
		dashboard		: 3,
		small			: 30,
		medium			: 100,
		large			: 300
	}
};

const PRINTER = {
	url					: 'http://localhost:5893',
	username			: 'Admin',
	password			: '',
	timeout				: 60000,
	printers: {
		fiscal			: 1,
		printer			: 2,
		printer_add		: 3,
		printer_add2	: 4
	}
};

const PIN_CODE = [
	'1987',
	'6666'
];

const requestConditionType = {
	EQUAL		: 0,
	NOT_EQUAL	: 1,
	LESS		: 2,
	MORE		: 3,
	IS_NULL		: 4,
	NOT_NULL	: 5,
	LIKE		: 6
};

const requestConcatinationType = {
	AND			: 0,
	OR			: 1
};

const requestOderType = {
	ASC			: 0,
	DESC		: 1
};

const timers = {
	NEW_MESSAGES				: 60 * 1000 * 5,
	NEW_ORDERS_GET_OPERATOR		: 60 * 1000 * 5,
	NEW_ORDERS_GET_DISPATCHER	: 60 * 1000 * 2,	//60 * 1000 * .5,
	ORDERS_GET					: 60 * 1000 * 4,	//60 * 1000 * 2
	ORDERS_FINISH				: 60 * 1000 * 2,
	ORDERS_ADMIN				: 60 * 1000 * 5,
	DISPATCHER_CALL				: 2  * 1000,
	PRINTER_CHECK				: 30 * 1000,
	IDLE						: 60 * 1000 * 10,
	COURIER_ROUTE_GET			: 60 * 1000 * 5,	//60 * 1000 * 2
	DOMRU_CALL					: 10 * 1000			//3 * 1000
};

const userStatus = {
	ACTIVE		: 0,
	BAN			: 1,
	DELETE		: 2
};
const userStatusName = ['активен','забанен','удален'];

const userType = {
	ADMIN		: 0,
	OPERATOR	: 1,
	COURIER		: 2,
	DISPATCHER	: 3,
	COOK		: 4,
	CASHIER		: 5,
	COLLECTOR	: 6,
	LOGIST		: 7,
	MANAGER		: 8,
	MONITOR		: 99
};
const userTypeName = ['администратор','оператор','курьер','диспетчер','повар','кассир','сборщик'];

const clientStatus = {
	ACTIVE		: 0,
	BAN			: 1,
	DELETE		: 2
};
const clientStatusName = ['активен','забанен','удален'];

const comonStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1
};
const comonStatusName = ['не активен','активен'];

const lotteryStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1,
	FINISH		: 2
};
const lotteryStatusName = ['не активен','активен','завершен'];

const requestStatus = {
	CREATE		: 0,
	FINISH		: 1
};
const requestStatusName = ['новая','завершена'];

const requestType = {
	MANUAL		: 0,
	AUTO		: 1
};
const requestTypeName = ['в ручном режиме','автоматически'];

const orderItemCollectorStatus = {
	UNKNOWN		: 0,
	DONE		: 1
};
const orderItemCollectorStatusName = ['','готов'];

const orderItemCourierStatus = {
	DONE		: 0,
	INWORK		: 1,
};
const orderItemCourierStatusName = ['в работе','доставлен']

const orderItemCookStatus = {
	UNKNOWN		: 0,
	ACCEPTED	: 1,
	DONE		: 2
};
const orderItemCookStatusName = ['','в работе','готов'];

const competitionType = {
	ORDER_COUNT	: 0,
	ORDER_SUM	: 1
};
const competitionTypeName = ['количество заказов','сумма заказов'];

const targetType = {
	DISPATCHER	: 0,
	OPERATOR	: 1,
	WEBSITE		: 2,
	IOS			: 3,
	ANDROID		: 4,
	VK			: 5,
	ROBOT		: 6,
	KIOSK		: 7
};
const targetTypeName = ['оператор','в зале','сайт','iOS','Android','ВКонтакте','Голосовой робот','Киоск'];

const eventType = {
	LOGIN			: 0,
	USER			: 1,
	CLIENT			: 2,
	DISCOUNT		: 3,
	PROMOCODE		: 4,
	CATEGORY		: 5,
	SECTION			: 6,
	PRODUCT			: 7,
	COURIER			: 8,
	CLIENT_ADDRESS	: 9,
	PAGE			: 10,
	AD				: 11,
	CONTENT			: 12,
	DELIVERY_AREA	: 13,
	SETTINGS		: 14,
	ORDER			: 15,
	CLIENT_DISCOUNT	: 16,
	ORDER_STATUS	: 17,
	TRANSACTION		: 18,
	STORIES			: 19,
	COURIER_ORDERS	: 20,
	ORDER_ITEM		: 21,
	CLIENT_CALL		: 22,
	CLIENT_PROMOCODE: 23,
	ORDER_CHECK		: 24,
	ORDER_MONEY		: 25,
	COURIER_ROUTE	: 26,
	AREA			: 27,
	FEEDBACK		: 28,
	PRODUCT_RELATION: 29,
	CLIENT_RANK		: 30,
	LOTTERY			: 31,
	COMPETITION		: 32,
	CLIENT_SCRIPT	: 32,
	CLIENT_PRODUCT	: 33,
	FORTUNE_WHEEL_PRIZE	: 34,
	FORTUNE_WHEEL_PRICE	: 35,

	STORE			: 51,
	STORE_SECTION	: 52,
	STORE_PROVISOR	: 53,
	STORE_INGREDIENT: 54,
	STORE_CARD		: 55,
	STORE_CARDITEM	: 56,
	STORE_REQUEST	: 57,
	STORE_REQUESTITEM: 58,
	STORE_INCOME	: 59,
	STORE_INCOMEITEM: 60,
	STORE_BALANCE	: 61,

	COOK_ORDERS		: 62
};
const eventTypeName = [
	'Вход в систему',
	'Пользователь',
	'Клиент',
	'Скидка',
	'Промокод',
	'Категория',
	'Раздел',
	'Позиция меню',
	'Курьер',
	'Адрес клиента',
	'Страница',
	'Рекламный блок',
	'Блок с контентом',
	'Зона доставки',
	'Настройка',
	'Заказ',
	'Клиетская скидка',
	'Статус заказа',
	'Транзакция',
	'Сторис',
	'Заказы курьера',
	'Позиции заказа',
	'Звонок клиента',
	'Промокод клиента',
	'Печать чека',
	'Накладная по заказу',
	'Геопозиция курьера',
	'Точка',
	'Обращение',

	'Склад. Склад',
	'Склад. Раздел',
	'Склад. Поставщик',
	'Склад. Позиция, товар, ингредиент',
	'Склад. Технологическая карта',
	'Склад. Технологическая карта. Позиция',
	'Склад. Заявка',
	'Склад. Заявка. Позиция',
	'Склад. Поступление',
	'Склад. Поступление. Позиция',
	'Склад. Баланс',

	'Повар. Заказы'
];

const errorType = {
	UNKNOWN		: 0,
	DUPLICATE	: 1
};

const measureType = {
	PIECES		: 0,
	KILOGRAM	: 1,
	GRAMS		: 2,
	LITER		: 3,
	MILLILITER	: 4
};
const measureTypeName = [
	'шт',
	'кг',
	'г',
	'л',
	'мл'
];

const semifinishType = {
	NORMAL		: 0,
	SEMIFINISH	: 1
};
const semifinishTypeName = ['тех.карта','полуфабрикат'];

const orderMoneyStatus = {
	IN_WORK		: 0,
	FINISH		: 1
};
const orderMoneyStatusName = ['в работе','расчет'];

const moneyTransferType = {
	LOGIST_CASHER	: 0,
	CASHIER_LOGIST	: 1,
	DAILY_CHANGE	: 2
};

const dayShiftCashType = {
	OPERATOR	: 0,
	LOGIST		: 1
};

const accountingDirection = {
	DEBIT		: 0,
	CREDIT		: 1
};

const accountingType = {
	UNKNOWN		: 0,
	COURIER		: 1,
	CASHER		: 2
};

const orderStatus = {
	USER_ADDED			: 0,
	USER_PAID			: 1,
	OPERATOR_WAIT		: 2,
	COOK_WAIT			: 3,
	COOK_ACCEPTED		: 4,
	COOK_DONE			: 5,
	COLLECTOR_WAIT		: 6,
	COLLECTOR_ACCEPTED	: 7,
	COLLECTOR_DONE		: 8,
	LOGIST_WAIT			: 9,
	LOGIST_ACCEPTED		: 10,
	LOGIST_DONE			: 11,
	COURIER_WAIT		: 12,
	COURIER_ACCEPTED	: 13,
	COURIER_IN_ROUTE	: 14,
	COURIER_DONE		: 15,
	CANCEL				: 16,
	REFUND				: 17,
	FINISH				: 18,
	HOLD				: 19,
	HIDE				: 20,
	ARCHIVE				: 21,
	WAITING_USER_ADDED	: 22,
	WAITING_USER_PAID	: 23,
	ROBOT_PREPARE		: 24,
	KIOSK_PREPARE		: 25
};
const orderStatusName = ['создан','оплачен','ожидание распределения','ожидание готовки','готовится','приготовлен','ожидание сборки','сборка','собран','ожидание распределения','выбор курьера','курьер назначен','ожидание курьера','принят курьером','в пути','доставлен','отменен','деньги возвращены','завершен','отложен','скрыт','архив','отложен. создан','отложен. оплачен','предзаказ через робота','предзаказ через киоск'];

const orderType = {
	CLIENT			: 0,
	DISPATCHER		: 1,
	OPERATOR		: 2,
	OPERATOR_HALL	: 3
};
const orderTypeName = ['клиентом','через оператора','в зале','в зале без доставки'];

const transactionStatus = {
	ADD				: 0,
	FINISH			: 1
};
const transactionStatusName = ['создана','завершена'];

const orderTransactionPaymentType = {
	UNKNOWN		: 0,
	ONLINE		: 1,
	OFFLINE		: 2
};
const orderTransactionPaymentTypeName = ['не задана','онлайн','оффлайн'];

const transactionPaymentType = {
	ONLINE		: 0,
	OFFLINE		: 1
};
const transactionPaymentTypeName = ['онлайн','оффлайн'];

const orderPaymentType = {
	CARD		: 0,
	CASH		: 1
};
const orderPaymentTypeName = ['карта','наличные'];

const orderPaid = {
	NOT_PAID	: 0,
	PAID		: 1
};
const orderPaidName = ['не оплачен','оплачен'];

const transactionType = {
	CREDIT		: 0,
	DEBET		: 1
};
const transactionTypeName = ['поступление','расход'];

const orderPickup = {
	DELIVERY	: 0,
	PICKUP		: 1
};
const orderPickupName = ['доставка','самовывоз'];

const pushTarget = {
	CLIENT		: 0,
	COURIER		: 1
};

const ratingType = {
	BAD			: 1,
	GOOD		: 2
};
const ratingTypeName  = ['','не понравилось','все хорошо'];

const fortuneWheelStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1,
	WIN			: 2,
	LOSE		: 3
};
const fortuneWheelStatusName = ['не оплачен','оплачен','выигрыш','проигрыш'];

const refRequestStatusName = ['создан', 'оплачен', 'отклонен'];

const transactionPaymentSource = {
	UNKNOW			: 0,
	SBERBANK		: 1,
	CLOUDPAYMENTS	: 2,
	TINKOFF			: 3
};
const transactionPaymentSourceName = ['','Сбербанк','CloudPayments','Тинькофф'];

const courierStatus = {
	UNKNOWN		: 0,
	ON_BASE		: 1,
	ON_ROUTE	: 2,
	ON_ADDRESS	: 3,
	ON_BACK		: 4
};
const courierStatusName = ['','на базе','в пути','у клиента','возвращается'];

const printerJobType = {
	UNKNOWN			: 0,
	DAILY_REPORT	: 1,
	COURIERS_DEBT	: 2
};

export {
	url,
	API,
	SITE,
	MAP,
	GENERAL,
	PRINTER,
	PIN_CODE,
	requestConditionType,
	requestConcatinationType,
	requestOderType,
	timers,
	userStatus,
	userStatusName,
	userType,
	userTypeName,
	clientStatus,
	clientStatusName,
	eventType,
	eventTypeName,
	errorType,
	comonStatus,
	comonStatusName,
	lotteryStatus,
	lotteryStatusName,
	requestStatus,
	requestStatusName,
	competitionType,
	competitionTypeName,
	requestType,
	requestTypeName,
	measureType,
	measureTypeName,
	transactionStatus,
	transactionStatusName,
	semifinishType,
	semifinishTypeName,
	orderStatus,
	orderStatusName,
	orderType,
	orderTypeName,
	orderPaymentType,
	orderPaymentTypeName,
	transactionPaymentType,
	transactionPaymentTypeName,
	orderItemCollectorStatus,
	orderItemCollectorStatusName,
	orderItemCourierStatus,
	orderItemCourierStatusName,
	orderItemCookStatus,
	orderItemCookStatusName,
	orderPaid,
	orderPaidName,
	targetType,
	targetTypeName,
	orderMoneyStatus,
	orderMoneyStatusName,
	orderTransactionPaymentType,
	orderTransactionPaymentTypeName,
	orderPickup,
	orderPickupName,
	pushTarget,
	ratingType,
	ratingTypeName,
	transactionType,
	transactionTypeName,
	moneyTransferType,
	dayShiftCashType,
	fortuneWheelStatus,
	fortuneWheelStatusName,
	transactionPaymentSource,
	transactionPaymentSourceName,
	refRequestStatusName,
	accountingDirection,
	accountingType,
	courierStatus,
	courierStatusName,
	printerJobType
};