import { comonStatus } from '../../Globals/Constants';
import Http from '../../Globals/Http';

const getActive = (areaId, callback) => Http.get('printerjobs', {conditions:[{k:'areaId',v:areaId},{k:'status',v:comonStatus.ACTIVE}]}).then((res) => callback(res.data));

const add = (data) => Http.post('printerjobs', {data});
const update = (id, data) => Http.put('printerjobs', {data,conditions:[{k:'id',v:id}]});

export {
	getActive,
	add,
	update
}