import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get				= (id, callback)				=> Http.get('clientpromocodes', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));
const getByClientId		= (clientId, callback)			=> Http.request('clientpromocodes', 'getByClientId', clientId).then((res) => callback(res.data));
const getByCode			= (code, callback)				=> Http.get('clientpromocodes', {conditions:[{k:'code',v:code}]}).then((res) => callback(res.data));

const getAll			= (callback)					=> Http.get('clientpromocodes', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));

const check				= (code, callback)				=> Http.request('clientpromocodes', 'checkPersonal', {code}).then((res) => callback(res.data));

const add				= (data)						=> Http.post('clientpromocodes', {data});
const remove			= async (id) 					=> Http.remove('clientpromocodes', {conditions:[{k:'id',v:id}]});
const update			= (id, data, callback, error)	=> Http.put('clientpromocodes', {data,conditions:[{k:'id',v:id}]}).then((res) => callback ? callback(res) : {}).catch((err) => error ? error(err) : {});

export {
	add,
	get,
	getByClientId,
	getAll,
	remove,
	update,
	getByCode,
	check
}