import Http from '../../Globals/Http';
import {comonStatus, requestOderType} from '../../Globals/Constants';

const get		= (id, callback)		=> Http.get('clientranks', {conditions:[{k:'clientId',v:id},{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getPage	= (limits, callback)	=> Http.get('clientranks', {orders:[{k:'id',isd:requestOderType.DESC}],limits}).then((res) => callback(res.data));

const checkExt	= (id, callback)		=> Http.request('clientranks', 'checkExt', id).then((res) => callback(res.data));

const add		= (data)				=> Http.post('clientranks', {data});


export {
	get,
	checkExt,
	add,
	getPage
}