import Http from '../../Globals/Http';
import {pushTarget} from '../../Globals/Constants';

const add = (title, subtitle, text, userid, target) => {
	const data = {
		title,
		subtitle,
		text,
		tag: target === pushTarget.CLIENT ? 'client_id' : 'courier_id',
		value:userid,
		target
	};
	Http.post('pushes', data);
}

const title = 'KF Samara - доставка еды';
const clientAdd = (subtitle, text, clientId) => {
	if (clientId && clientId !== 0)
		add('KF Samara - доставка еды', subtitle, text, clientId, pushTarget.CLIENT);
}

const client = {
	prepare		: (clientId) => clientAdd('Заказ принят', 'Ваш заказ начали готовить! Мы сообщим вам когда он будет готов и передан курьеру.', clientId),
	done		: (clientId) => clientAdd('Заказ готов!', 'Ваш заказ готов и передан курьеру. Скоро он будет у вас.', clientId)
}

const courier = {
	neworder	: (courierId) => add(title, 'Новый заказ', 'Вам назначен новый заказ на доставку', courierId, pushTarget.COURIER) 
}

const lottery = {
	win			: (clientId) => clientAdd('Вы выиграли!', 'Поздравляем! Вы выиграли в розыгрыше! Свяжитесь с нами для получения приза', clientId),
	lose		: (clientId) => clientAdd('Розыгрыш завершен!', 'Проверить результаты розыгрыша можно в приложении и на сайте', clientId)
}

export {
	add,
	client,
	courier,
	lottery
}