import Http from '../../Globals/Http';

const get		= (id, callback)	=> Http.get('clientscriptitems', {conditions:[{k:'clientScriptId',v:id}]}).then((res) => callback(res.data));

const add		= (data)			=> Http.post('clientscriptitems', {data});
const remove	= (id)				=> Http.remove('clientscriptitems', {conditions:[{k:'clientScriptId',v:id}]});

export {
	get,
	add,
	remove
}