import Http from '../../Globals/Http';

import { comonStatus } from '../../Globals/Constants';

const get		= (id, callback)				=> Http.get('lotteryclients', {conditions:[{k:'lotteryId',v:id},{k:'status',v:comonStatus.ACTIVE}]}).then((res) => callback(res.data));

const add		= (data, callback, error)		=> Http.post('lotteryclients', {data:data}).then((res) => callback ? callback(res) : {}).catch((err) => error ? error(err) : {});
const update	= (id, data, callback, error)	=> Http.put('lotteryclients', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback ? callback(res) : {}).catch((err) => error ? error(err) : {});

export {
	get,
	add,
	update
}