import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';

// models
import {Products,Sections,Categories,Events,Logs,Images} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';

// texts
import Texts from '../../Globals/Texts';

// globals
import {measureTypeName,comonStatusName,comonStatus,eventType,API} from '../../Globals/Constants';

// styles
import styles from './Product.module.css';

class Product extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			sections:[],
			categories:[],
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		Sections.getActiveAll((data) => {
			this.setState({sections:data});
			Categories.getActiveAll((data) => {
				this.setState({categories:data});
				if (this.state.id !== 0) {
					Products.get(this.state.id, (data) => {
						for (let i = 0; i < 2; i++) {
							const idx = i === 0 ? '' : i + 1;
							data[`image${idx}`] = empty(data[`image${idx}`]) ? null : `${API.assets}/products/${data[`image${idx}`]}`;
						}
						data.imageMain = data.imageMain || 1;
						data.areas = data.area1Off === 1 && data.area2Off === 1 ? 3 : (data.area1Off === 1 ? 1 : (data.area2Off === 1 ? 2 : 0));
						this.setState({data,dataOld:{...data},notfound:empty(data),loading:false});
					});
				} else this.dataInit();
			});
		});
	}
	dataInit = () => this.setState({data:{name:'',link:'',description:'',image:'',imageFile:null,status:comonStatus.ACTIVE,sortOrder:'',areas:0},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
		if (e.target.name === 'discountPercent') {
			if (data.discountPercent !== 0) {
				data.discount = (this.state.data.price * data.discountPercent) / 100;
				data.priceDiscount = this.state.data.price - data.discount;
			}
		}
	}
	imageChange = (e, idx) => {
		const reader = new FileReader(), file = e.target.files[0];
		idx = idx === 0 ? '' : (idx + 1);
		reader.onloadend = () => {
			const {data} = this.state
			data[`image${idx}`] = reader.result;
			data[`imageFile${idx}`] = file;
			data[`imageName${idx}`] = file.name;
			this.setState({data});
		};
		reader.readAsDataURL(file);
	}
	imageDelete = (idx) => {
		const {data} = this.state
		idx = idx === 0 ? '' : (idx + 1);
		data[`image${idx}`] = '';
		data[`imageFile${idx}`] = null;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		const sectionId = parseInt(this.state.data.sectionId), categoryId = parseInt(this.state.data.categoryId);
		const data = {
			sectionId:this.state.data.sectionId,
			sectionName:this.state.sections.filter(f => f.id === sectionId)[0].name,
			name:this.state.data.name,
			description:this.state.data.description,
			price:this.state.data.price,
			priceDiscount:this.state.data.priceDiscount,
			discount:this.state.data.discount,
			discountPercent:this.state.data.discountPercent,
			status:this.state.data.status,
			measure:this.state.data.measure,
			measureType:this.state.data.measureType,
			siteHide:this.state.data.siteHide,
			isNew:this.state.data.isNew,
			additive:this.state.data.additive,
			sortOrder:this.state.data.sortOrder,
			area1Off:0,
			area2Off:0,
			imageMain:this.state.data.imageMain||1
		};
		if (this.state.data.discount === 0) {
			data.discount = 0;
			data.priceDiscount = 0;
			data.discountPercent = 0;
		}
		if (this.state.data.areas === '1' || this.state.data.areas === '3') data.area1Off = 1;
		if (this.state.data.areas === '2' || this.state.data.areas === '3') data.area2Off = 1;
		if (categoryId) {
			data.categoryId = categoryId;
			data.categoryName = this.state.categories.filter(f => f.id === categoryId)[0].name;
		}
		if (id === 0) {
			Products.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.products.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.products.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.PRODUCT);
					for (let i = 0; i < 2; i++) {
						const idx = i === 0 ? '' : (i + 1);
						const image = this.state.data[`image${idx}`];
						const imageFile = this.state.data[`imageFile${idx}`];
						const imageName = this.state.data[`imageName${idx}`];
						this.imageSave(id, i, image, imageFile, imageName, false);
					}
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			Products.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.products.update,iserror:false});
					Events.add(this.state.user.id, Texts.products.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.PRODUCT);
					for (let i = 0; i < 2; i++) {
						const idx = i === 0 ? '' : (i + 1);
						const image = this.state.data[`image${idx}`];
						const imageFile = this.state.data[`imageFile${idx}`];
						const imageName = this.state.data[`imageName${idx}`];
						this.imageSave(id, i, image, imageFile, imageName, true);
					}
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	imageSave = (id, idx, image, file, filename, isupdate) => {
		if (image) {
			if (file) {
				Images.upload(id, 'product', idx+1, file);
				const data = {}
				data[`image${idx===0?'':(idx+1)}`] = `product_${id}_${idx+1}_${filename}`;
				Products.update(id, data);
			}
		} else {
			if (isupdate) {
				const data = {}
				data[`image${idx===0?'':(idx+1)}`] = '';
				Products.update(id, data);
			}
		}
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/products'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить позицию' : 'Редактирование позиции'}
								</span>
								<Link to={'/product'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<form onSubmit={this.save}>
									<div className={styles.formcontainer}>
										<div className={styles.oneline}>
											{[0,1].map((v,i) => <div key={i} className={styles.cell}>
												<label>
													<span>Изображение {v+1}</span>
													{this.state.data[`image${v===0?'':(v+1)}`] && <img src={this.state.data[`image${v===0?'':(v+1)}`]} alt="" className={parseInt(this.state.data.imageMain)===(i+1)?styles.imageMain:styles.image} />}
													<input onChange={(e) => this.imageChange(e, v)} name="image" className={styles.file} accept="image/jpeg,image/png,image/webp" type="file" />
													{empty(this.state.data[`image${v===0?'':(v+1)}`]) && <div className={styles.imageAdd}></div>}
												</label>
												{!empty(this.state.data[`image${v===0?'':(v+1)}`]) && <div className={styles.link} onClick={() => this.imageDelete(v)}>&times; удалить</div>}
											</div>)}
										</div>
										<div className={styles.cell}>
											<label>
												<span>Основное изображение</span>
												<select name="imageMain" onChange={this.handleForm} defaultValue={this.state.data.imageMain}>
													{[0,1].map((v,i) => <option key={i} value={v+1}>{v+1}</option>)}
												</select>
											</label>
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Раздел</span>
													<select name="sectionId" onChange={this.handleForm} defaultValue={this.state.data.sectionId} required>
														<option value=""></option>
														{this.state.sections.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
													</select>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Категория</span>
													<select name="categoryId" onChange={this.handleForm} defaultValue={this.state.data.categoryId}>
														<option value=""></option>
														{this.state.categories.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
													</select>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Добавка / соус</span>
													<select name="additive" onChange={this.handleForm} defaultValue={this.state.data.additive}>
														<option value={1}>да</option>
														<option value={0}>нет</option>
													</select>
												</label>
											</div>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Название</span>
												<input type="text" name="name" value={this.state.data.name} className={styles.double} placeholder="Название" onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Описание</span>
												<textarea name="description" placeholder="Описание" className={styles.desc} value={this.state.data.description} onChange={this.handleForm}></textarea>
											</label>
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Цена, ₽</span>
													<input type="text" name="price" value={this.state.data.price} onChange={this.handleForm} required />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Количество, размерность</span>
													<input type="text" name="measure" value={this.state.data.measure} onChange={this.handleForm} />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Единица измерения</span>
													<select name="measureType" onChange={this.handleForm} defaultValue={this.state.data.measureType}>
														<option value=""></option>
														{measureTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</label>
											</div>
										</div>
										<div className={styles.discounts}>
											<b>Скидка</b>
											<div className={styles.oneline}>
												<div className={styles.cell}>
													<label>
														<span>Скидка, %</span>
														<input type="number" name="discountPercent" min={0} max={50} value={this.state.data.discountPercent} placeholder="15" onChange={this.handleForm} />
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Сумма скидки, ₽</span>
														<div className={styles.cellblock}>{this.state.data.discount || '—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Цена со скидкой, ₽</span>
														<div className={styles.cellblock}>{this.state.data.priceDiscount || '—'}</div>
													</label>
												</div>
											</div>
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Порядок сортировки</span>
													<input type="text" name="sortOrder" value={this.state.data.sortOrder} onChange={this.handleForm} />
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Показывать на сайте</span>
													<select name="siteHide" onChange={this.handleForm} defaultValue={this.state.data.siteHide}>
														<option value={0}>да</option>
														<option value={1}>нет</option>
													</select>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Новинка</span>
													<select name="isNew" onChange={this.handleForm} defaultValue={this.state.data.isNew}>
														<option value={0}>нет</option>
														<option value={1}>да</option>
													</select>
												</label>
											</div>
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Статус</span>
													<select name="status" onChange={this.handleForm} defaultValue={this.state.data.status}>
														{comonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Наличие на точках</span>
													<select name="areas" onChange={this.handleForm} defaultValue={this.state.data.areas}>
														<option value={0}>Есть</option>
														<option value={1}>Нет на Чекистов</option>
														<option value={2}>Нет на Московском ш.</option>
														<option value={3}>Нет нигде</option>
													</select>
												</label>
											</div>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Product);