import Http from '../../Globals/Http';

const getAll		= (callback)		=> Http.get('settings').then((res) => callback(res.data));
const getAllAsync	= async ()			=> await Http.get('settings');
const get			= (id, callback)	=> Http.get('settings', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const getCode		= (code, callback)	=> Http.get('settings', {conditions:[{k:'code',v:code}]}).then((res) => callback(res.data[0]));

const add			= (data, callback, error)		=> Http.post('settings', {data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update		= (id, data, callback, error)	=> Http.put('settings', {data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

const getByKey		= (code, settings)	=> settings.find(f => f.code === code);

export {
	getAll,
	getAllAsync,
	get,
	getCode,
	add,
	update,
	getByKey
}