import Http from '../../Globals/Http';
import {requestConditionType} from "../../Globals/Constants";

const get					= async (id)			=> await Http.get('ordermoneyreports', {conditions:[{k:'id',v:id}]});
const getByOrderId 			= async (orderId)		=> await Http.get('ordermoneyreports', {conditions:[{k:'orderId',v:orderId}]});
const inWorkGet				= async (areaId)		=> await Http.request('ordermoneyreports', 'inWorkGet', areaId);

const add					= async (data)			=> await Http.post('ordermoneyreports', {data});
const update				= (id, data, callback)	=> Http.put('ordermoneyreports', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res));

const updateByOrderId		= (orderId, data)		=> Http.put('ordermoneyreports', {data:data,conditions:[{k:'orderId',v:orderId}]});

const removeByOrder			= (orderId, callback)	=> Http.remove('ordermoneyreports', {conditions:[{k:'orderId',v:orderId}]}).then((res) => callback ? callback(res) : {});
const removeExceptCourier	= (data, callback)		=> Http.remove('ordermoneyreports', {conditions:[{k:'courierId',v:data.courierId,op:requestConditionType.NOT_EQUAL},{k:'orderId',v:data.orderId,op:requestConditionType.EQUAL}]}).then((res) => callback ? callback(res) : {});


export {
	get,
	getByOrderId,
	inWorkGet,
	add,
	update,
	updateByOrderId,
	removeByOrder,
	removeExceptCourier
}