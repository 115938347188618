import React,{Component} from 'react';

// plug-ins
import classNames from 'classnames';

// styles
import styles from './Keyboard.module.css';
const cx = classNames.bind(styles);

class Keyboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			input:this.props.value||'',
			show:this.props.autoFocus === true ? true : false,
			hide:this.props.autoHide === true ? true : false,
			autosend:this.props.autoSend === undefined || this.props.autoSend
		};
	}
	keyboard = null;
	input = null;
	data = [
		['ё','1','2','3','4','5','6','7','8','9','0','-','backspace'],
		['й','ц','у','к','е','н','г','ш','щ','з','х','ъ','\\'],
		['ф','ы','в','а','п','р','о','л','д','ж','э','enter'],
		['/','я','ч','с','м','и','т','ь','б','ю','.',','],
		['space','close']
	];
	componentDidMount = () => {
		document.addEventListener('keydown', this.handleKeyDown);
		document.addEventListener('click', this.handleContainerClick);
	}
	componentWillReceiveProps = (p) => p && p.value ? this.setState({input:p.value}) : {};
	componentWillUnmount = () => {
		document.removeEventListener('keydown', this.handleKeyDown);
		document.removeEventListener('click', this.handleContainerClick);
	}
	charSet = (char) => {
		let {input} = this.state;
		if (char === 'backspace') {
			input = input.slice(0,-1);
			char = '';
		}
		if (char === 'space') char = ' ';
		if (char === 'enter') {
			this.onEnter();
			return;
		}
		if (char === 'close') {
			this.onClose();
			return;
		}
		input = `${input}${char}`;
		this.setState({input});
		if (this.props.autosend)
			this.props.onChange(input);
	}
	handleKeyDown = (e) => this.charSet(e.keyCode === 8 ? 'backspace' : String.fromCharCode(e.keyCode));
	charRender = (char) => {
		if (char === 'backspace') return '⌫';
		if (char === 'enter') return '⏎ ввод';
		if (char === 'space') return ' ';
		if (char === 'close') return '⊗';
		return char;
	}
	onFocus = () => this.setState({show:true});
	onEnter = () => {
		if (!this.props.autosend) this.props.onChange(this.state.input);
		this.setState({show:false});
	}
	onBlur = () => this.state.hide ? this.onClose() : {};
	onClose = () => this.setState({show:false});
	handleContainerClick = (e) => {
		if (this.keyboard && this.input) {
			const keyboard = this.keyboard, input = this.input;
			if (input.contains(e.target)) return;
			if (!keyboard.contains(e.target)) this.onBlur();
		}
	}
	render() {
		return (
			<>
				<input ref={(input) => this.input = input} type={this.props.type} value={this.state.input} placeholder={this.props.placeholder} onChange={this.handleInput} onFocus={this.onFocus} autoFocus={this.props.autoFocus} autoComplete="off" />
				{this.state.show ?
					<div ref={(keyboard) => this.keyboard = keyboard} className={cx(styles.keyboardcontainer,this.props.className)}>
						{this.data.map((row,i) => <div key={i} className={styles.row}>
							{row.map((v,j) => <div key={j} onClick={() => this.charSet(v)} className={cx(styles.cell,v==='space'?styles.space:null)}>{this.charRender(v)}</div>)}
						</div>
						)}
					</div> : null}
			</>
		);
	};
}

export default Keyboard;