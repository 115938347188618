import Http from '../../Globals/Http';
import {requestOderType,userStatus} from '../../Globals/Constants';

const login			= (phone, callback)			=> Http.request('clients', 'login', {conditions:[{k:'phone',v:phone}]}).then((res) => callback(res.data[0]));
const smsCheck		= (phone, code, callback)	=> Http.request('clients', 'smsCheck', {conditions:[{k:'phone',v:phone},{k:'code',v:code}],orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data[0]));

const registerCheck	= (phone, callback)			=> Http.request('clients', 'registerCheck', {conditions:[{k:'phone',v:phone}]}).then((res) => callback(res.data));
const register		= (data, callback)			=> Http.post('clients', {data:data}).then((res) => callback(res));

const getAll		= (callback)				=> Http.get('clients', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getAllAsync	= async ()					=> await Http.get('clients', {orders:[{k:'id',isd:requestOderType.DESC}]});

const getPage		= (limits, callback)		=> Http.get('clients', {orders:[{k:'id',isd:requestOderType.DESC}],limits}).then((res) => callback(res.data));
const get			= (id, callback)			=> Http.get('clients', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));
const getAsync		= async (id)				=> await Http.get('clients', {conditions:[{k:'id',v:id}]});
const getAllActive	= (callback)				=> Http.get('clients', {conditions:[{k:'status',v:userStatus.ACTIVE}],orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));

const find			= (text, callback)			=> Http.request('clients', 'find', {text}).then((res) => callback(res.data));

const byPhoneGet	= (phone, callback)			=> Http.request('clients', 'byPhoneGet', {phone}).then((res) => callback(res.data));
const byIdGet		= (id, callback)			=> Http.request('clients', 'byIdGet', {id}).then((res) => callback(res.data));

const byIdsGet		= (ids, callback)			=> Http.request('clients', 'byIdsGet', {ids}).then((res) => callback(res.data));
const byIdsGetAsync	= async (ids)				=> await Http.request('clients', 'byIdsGet', {ids});

const byVariosGet	= (id, phone, callback) => {
	if (id) {
		byIdGet(id, callback);
		return;
	}
	if (phone) {
		byPhoneGet(phone, callback);
		return;
	}
	callback(null);
}

const add			= (data, callback, error)		=> Http.post('clients', {data:data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update		= (id, data, callback, error)	=> Http.put('clients', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

const stats			= {
	get				: async ()					=> await Http.request('clients', 'newGet'),
	getToday		: async ()					=> await Http.request('clients', 'newTodayGet')
}

const load			= async (ctx, orders, callback)	=> {
	const {clients} = ctx.state;
	const ids = orders.map(m => m.clientId).filter(f => f !== 0);
	const c = await byIdsGetAsync(ids);
	c.data.forEach((v) => {
		const client = clients.find(f => f.id === v.id);
		if (client === undefined) clients.push(v);
	});
	ctx.setState({clients}, () => {
		if (callback) callback();
	});
}

export {
	getAll,
	getAllAsync,
	getPage,
	get,
	getAsync,
	getAllActive,
	byPhoneGet,
	byIdGet,
	byIdsGet,
	byIdsGetAsync,
	byVariosGet,
	find,
	login,
	smsCheck,
	registerCheck,
	register,
	add,
	update,
	stats,
	load
}