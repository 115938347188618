import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const get			= async (id)		=> await Http.get('courierroutes', {conditions:[{k:'orderId',v:id}]});
const getLastRoute	= async (userId)	=> await Http.get('courierroutes', {conditions:[{k:'userId',v:userId}],orders:[{k:'id',isd:requestOderType.DESC}],limits:[0,1]});

const add			= (data)			=> Http.post('courierroutes', {data});

export {
	get,
	getLastRoute,
	add,
}