import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import SaveStatus from '../../Components/SaveStatus';

// models
import {Pages,Events,Logs} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';
import errorsParse from '../../Globals/Errors';

// texts
import Texts from '../../Globals/Texts';

// globals
import {eventType,errorType} from '../../Globals/Constants';

// styles
import styles from './Page.module.css';

class Page extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = () => {
		if (this.state.id !== 0) Pages.get(this.state.id, (data) => this.setState({data,dataOld:{...data},notfound:empty(data),loading:false}));
		else this.dataInit();
	}
	dataInit = () => this.setState({data:{link:'',title:'',description:'',keywords:'',content:''},id:0,loading:false});
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.value;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		const data = {
			title:this.state.data.title,
			link:this.state.data.link,
			description:this.state.data.description,
			keywords:this.state.data.keywords,
			content:this.state.data.content
		}
		if (id === 0) {
			Pages.add(data, (res) => {
				this.stop(() => {
					const id = res.data.id;
					this.setState({saveStatus:Texts.pages.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.pages.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.PAGE);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicateLink:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			Pages.update(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.pages.update,iserror:false});
					Events.add(this.state.user.id, Texts.pages.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.PAGE);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicateLink:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
	   }
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/pages'}><img src={require('../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить страницу' : 'Редактирование страницы'}
								</span>
								<Link to={'/page'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
							<>
								<form onSubmit={this.save} className={styles.form}>
									<div className={styles.formcontainer}>
										<div className={styles.cell}>
											<label>
												<span>Ссылка</span>
												<input type="text" name="link" value={this.state.data.link} placeholder="Ссылка" onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Заголовок, название</span>
												<input type="text" name="title" value={this.state.data.title} placeholder="Заголовок" className={styles.double} onChange={this.handleForm} required />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Контент</span>
												<textarea name="content" placeholder="Описание, контент страницы" value={this.state.data.content} onChange={this.handleForm} required></textarea>
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Описание</span>
												<input type="text" name="description" value={this.state.data.description} placeholder="Описание" className={styles.double} onChange={this.handleForm} />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Ключевые слова, теги</span>
												<input type="text" name="keywords" value={this.state.data.keywords} placeholder="Ключевые слова, теги" className={styles.double} onChange={this.handleForm} />
											</label>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							</>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(Page);