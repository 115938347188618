import Http from '../../Globals/Http';

const getAll	= (callback)					=> Http.get('clientranktypes').then((res) => callback(res.data));
const get		= (id, callback)				=> Http.get('clientranktypes', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const add		= (data, callback, error)		=> Http.post('clientranktypes', {data:data}).then((res) => callback ? callback(res) : {}).catch((err) => error ? error(err) : {});
const update	= (id, data, callback, error)	=> Http.put('clientranktypes', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback ? callback(res) : {}).catch((err) => error ? error(err) : {});

export {
	getAll,
	get,
	add,
	update
}