import React,{Component} from 'react';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import Loading from '../../Components/Loading';

// models
import {Areas,DayShifts,Orders} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {dateGet,moneyFormat,tsDay} from '../../Globals/Utils';

// styles
import styles from './StatisticSections.module.css';

class StatisticSectionsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			data:[],
			areas:[],
			dayshiftsAll:[],
			dayshifts:[],
			date:null,
			areaId:0,
			dayShiftId:0,
			progress:10,
			loading:true,
			loadingdata:false
		};
	}
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		const areas = await Areas.getAllActive();
		const ds = await DayShifts.getAll();
		this.setState({user,areas:areas.data,dayshiftsAll:ds.data,loading:false});
	}
	handleArea = (e) => {
		const areaId = parseInt(e.target.value);
		const dayshifts = areaId === 0 ? [] : this.state.dayshiftsAll.filter(f => f.areaId === areaId);
		this.setState({areaId,dayShiftId:0,dayshifts:[],report:null}, () => this.setState({dayshifts}));
	}
	handleDayshift = (e) => {
		const t = e.target;
		const date = t.options[t.selectedIndex].getAttribute('date');
		this.setState({dayShiftId:parseInt(t.value),date,loadingdata:true}, () => this.dataGet());
	}
	dataGet = async () => {
		const {areaId,dayShiftId} = this.state;
		if (areaId === 0 || dayShiftId === 0) return;
		const d = await Orders.stats.products(areaId, dayShiftId);
		const data = d.data;
		const date = parseInt(this.state.date);
		const old = await Orders.stats.productsold(areaId, date + tsDay, date + tsDay + tsDay);
		data.forEach((v) => {
			const item = old.data.find(f => f.productId === v.productId);
			if (item) v.count = v.count + item.count;
		});
		old.data.forEach((v) => {
			const item = data.find(f => f.productId === v.productId);
			if (item === undefined) data.push(v);
		});
		const group = data.reduce((section, item) => {
			const {sectionId} = item;
			section[sectionId] = section[sectionId] ?? [];
			section[sectionId].push(item);
			return section;
		}, {});
		const out = [];
		Object.entries(group).forEach((v) => {
			out.push({
				name:v[1][0].sectionName,
				count:v[1].reduce((a, v) => a + (v.count), 0),
				amount:v[1].reduce((a, v) => a + (v.priceDiscount||v.price)*v.count, 0)
			});
		});
		this.setState({data:out,loadingdata:false});
	}
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>Отчет по станциям</h4>
							<div className={styles.controls}>
								<select onChange={this.handleArea}>
									<option value="0">Выберите точку</option>
									{this.state.areas.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
								</select>
								<select onChange={this.handleDayshift}>
									<option value="0">Выберите смену</option>
									{this.state.dayshifts.map((v,i) => <option key={i} date={v.date} value={v.id}>{dateGet(v.dateStart+tsDay/2)} - №{v.id} ({dateGet(v.dateStart+tsDay/2, {weekDayOnly:true})})</option>)}
								</select>
							</div>
							<div className={styles.formcontainer}>
								{this.state.areaId === 0 || this.state.dayShiftId === 0 ? <p className={styles.empty}>Для просмотра отчета выберите точку и смену</p> :
									this.state.loadingdata ? <Loading className={styles.loading} /> :
										<div className={styles.list}>
											{this.state.data.length === 0 ? <p>Нет данных</p> :
												<ul>
													{this.state.data.map((v,i) => <li key={i}><div className={styles.num}>{i+1}</div><div>{v.name}</div><div className={styles.count}>{moneyFormat(v.amount, false)} ₽</div></li>)}
												</ul>
											}
										</div>
								}
							</div>
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default StatisticSectionsList;