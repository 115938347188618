import React,{Component} from 'react';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import classNames from 'classnames';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';

// models
import {Areas,Clients,Orders} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';

// globals
import {orderStatusName} from '../../Globals/Constants';

// styles
import styles from './OrdersSearch.module.css';
const cx = classNames.bind(styles);

class OrdersSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:null,
			user:null,
			areas:[],
			clients:[],
			filterSearch:'',
			filterDateStart:'',
			filterDateEnd:'',
			filterStatus:undefined,
			filterAreaId:0,
			filterDayShiftCurrent:null,
			filterDate:null,
			notfound:false,
			progress:10,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		const areas = await Areas.getAllActive();
		this.setState({progress:30,user,areas:areas.data,loading:false});
	}
	handleForm = (e) => this.setState({[e.target.name]:e.target.value});
	handleFilterDateForm = (e) => this.setState({[e.target.name]:e.target.value});
	handleFilterDayShiftCurrentForm = (e) => this.setState({filterDayShiftCurrent:e.target.checked?1:0});
	handleFilterDateFutureForm = (e) => this.setState({filterDate:e.target.checked?1:0});
	dateFilterClear = (field) => this.setState({[field]:''});
	handleFilterStatusSelect = (e) => {
		const filterStatus = parseInt(e.target.value);
		this.setState({filterStatus:isNaN(filterStatus)?undefined:filterStatus});
	}
	handleFilterAreaSelect = (e) => {
		const filterAreaId = parseInt(e.target.value);
		this.setState({filterAreaId:isNaN(filterAreaId)?0:filterAreaId});
	}
	search = () => {
		const {filterSearch,filterAreaId,filterDateStart,filterDateEnd,filterStatus,filterDayShiftCurrent,filterDate} = this.state;
		if ((filterAreaId === 0 && !filterDayShiftCurrent) && empty(filterDateStart) && empty(filterDateEnd) && filterStatus === undefined && !filterDate) {
			if (filterSearch.length < 2) {
				this.setState({orderssearch:[]});
				return;
			}
		}
		const dateStart = filterDateStart ? Math.round(new Date(filterDateStart).getTime() / 1000) : null;
		const dateEnd = filterDateEnd ? Math.round(new Date(filterDateEnd).getTime() / 1000) : null;
		Orders.find({text:filterSearch,dateStart,dateEnd,status:filterStatus,areaId:filterAreaId,dayShift:filterDayShiftCurrent,date:filterDate}, (data) => {
			data.sort((a,b) => a.dateCreate > b.dateCreate ? -1 : 1);
			Clients.load(this, data, () => this.setState({data:this.dataSet(data),notfound:data.length===0}));
		});
	}
	dataSet = (data) => dgDataPrepare(data, 'orders', ['id','dateCreate','price','clientId','status'], this.state.clients);
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>Поиск заказов</h4>
							<div className={styles.searchblock}>
								<input type="search" name="filterSearch" placeholder="Поиск заказа по номеру, адресу доставки, телефону или имени клиента" value={this.state.filterSearch} onChange={this.handleForm} autoFocus={true} autoComplete="off" />
								<button onClick={() => this.search()}>Найти</button>
							</div>
							<div className={styles.formcontainerinputs}>
								<div className={styles.cell}>
									<span>Точка</span>
									<select onChange={this.handleFilterAreaSelect} value={this.state.filterAreaId}>
										<option></option>
										{this.state.areas.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
									</select>
								</div>
								<div className={cx(styles.cell,styles.cellsmall)}>
									<span>Смена</span>
									<div>
										<input type="checkbox" defaultChecked={this.state.filterDayShiftCurrent} onChange={this.handleFilterDayShiftCurrentForm} />
									</div>
								</div>
								<div className={styles.cell}>
									<span>Статус</span>
									<select onChange={this.handleFilterStatusSelect} value={this.state.filterStatus}>
										<option></option>
										{orderStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
									</select>
								</div>
								<div className={styles.cell}>
									<span>Дата от</span>
									<div>
										<input type="datetime-local" name="filterDateStart" value={this.state.filterDateStart} onChange={this.handleFilterDateForm} />
										<img src={require('../../Images/icons/close_black.svg')} alt='Сбросить' onClick={() => this.dateFilterClear('filterDateStart')} />
									</div>
								</div>
								<div className={styles.cell}>
									<span>Дата до</span>
									<div>
										<input type="datetime-local" name="filterDateEnd" value={this.state.filterDateEnd} onChange={this.handleFilterDateForm} />
										<img src={require('../../Images/icons/close_black.svg')} alt='Сбросить' onClick={() => this.dateFilterClear('filterDateEnd')} />
									</div>
								</div>
								<div className={cx(styles.cell,styles.cellsmall)}>
									<span>На дату</span>
									<div>
										<input type="checkbox" defaultChecked={this.state.filterDate} onChange={this.handleFilterDateFutureForm} />
									</div>
								</div>
							</div>
							{this.state.notfound ? <NotFound /> :
								<DataGrid
									tableName={'orders'}
									keys={['ID','Дата','Цена, ₽','Клиент','Статус']}
									link={'order'}
									data={this.state.data}
									dataFull={this.state.data} />}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(OrdersSearch);