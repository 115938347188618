import React from 'react';

// styles
import styles from './Overlay.module.css';

const Overlay = (props) => {
	return (
		<div className={styles.overlay}></div>
	);
}
export default Overlay;