import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';
import SaveStatus from '../../../Components/SaveStatus';

// models
import {FortuneWheelPrizes,Events,Logs,Products} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {empty} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {eventType} from '../../../Globals/Constants';

// styles
import styles from './FortunePrize.module.css';

class FortunePrize extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			products:[],
			user:null,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	dataGet = () => {
		FortuneWheelPrizes.get(this.state.id, async (data) => {
			const products = await Products.getZero();
			this.setState({data,dataOld:{...data},products:products.data,notfound:empty(data),loading:false});
		});
	}
	handleForm = (e) => {
		const {data,products} = this.state;
		data[e.target.name] = e.target.value;
		if (e.target.name === 'productId' && e.target.value !== '') {
			const product = products.find(p => p.id === parseInt(e.target.value));
			data.productName = product.name;
		} else data.productName = null;
		this.setState({data});
	}
	handleCheckbox = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.checked ? 1 : 0;
		this.setState({data});
	}
	save = (e) => {
		e.preventDefault();
		this.setState({issave:true});
		const {id} = this.state;
		const data = {
			discount:this.state.data.discount,
			offline:this.state.data.offline,
			isEmpty:this.state.data.isEmpty,
			freeDelivery:this.state.data.freeDelivery
		};
		if (this.state.data.productId) {
			data.productId = this.state.data.productId;
			data.productName = this.state.data.productName;
		}
		FortuneWheelPrizes.update(id, data, () => {
			this.stop(() => {
				this.setState({saveStatus:Texts.fortunewheelprize.update,iserror:false});
				Events.add(this.state.user.id, Texts.fortunewheelprize.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.FORTUNE_WHEEL_PRIZE);
			});
		}, (err) => {
			this.stop(() => {
				this.setState({saveStatus:Texts.errors.internalError,iserror:true});
				Logs.add(err);
			});
		});
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isLoyalty={true}>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/loyalty/wofprizes'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
									{'Редактирование приза'}
								</span>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<div className={styles.formcontainer}>
									<form onSubmit={this.save}>
										<div className={styles.cell}>
											<label>
												<span>Процент скидки</span>
												<input type="number" name="discount" min={0} max={99} value={this.state.data.discount} placeholder="5" required onChange={this.handleForm} />
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Товар</span>
												<select name="productId" onChange={this.handleForm} defaultValue={parseInt(this.state.data.productId)}>
													<option value=""></option>
													{this.state.products.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
												</select>
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Бесплатная доставка</span>
												<div><input type="checkbox" name="freeDelivery" defaultChecked={this.state.data.freeDelivery} onChange={this.handleCheckbox} /></div>
											</label>
										</div>
										<div className={styles.cell}>
											<label>
												<span>Оффлайн приз</span>
												<input type="text" className={styles.double} name="offline" value={this.state.data.offline} placeholder="Оффлайн" onChange={this.handleForm} />
											</label>
										</div>
										<div className={styles.conditions}>
											<div className={styles.cell}>
												<label>
													<span className={styles.fortunewheelIsempty}>Проигрыш</span>
													<div><input type="checkbox" name="isEmpty" defaultChecked={this.state.data.isEmpty} onChange={this.handleCheckbox} /></div>
												</label>
											</div>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</form>
								</div>
							}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(FortunePrize);