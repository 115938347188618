import Http from '../../Globals/Http';

const get		= async (areaId, dayShiftId)	=> await Http.get('dayshiftreports', {conditions:[{k:'areaId',v:areaId},{k:'dayShiftId',v:dayShiftId}]});

const add		= async (data)					=> await Http.post('dayshiftreports', {data});

const update	= async (id, data)				=> await Http.put('dayshiftreports', {data:data,conditions:[{k:'id',v:id}]});


export {
	get,
	add,
	update
}