import React,{Component} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {ProductRelationItems,ProductRelations} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

class ProductRealationsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:[],
			dataFull:[],
			dataShow:[],
			user:null,
			progress:10,
			loading:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		ProductRelations.getAll((data) => {
			ProductRelationItems.getAll((relations) => {
				const products = [];
				data.forEach((v,i) => products.push({id:v.id,name:v.sectionName,relations:relations.filter(f => f.productRelationId === v.id).map((p) => p.productName).join(',')}));
				this.setState({dataShow:this.dataSet(products),data:products,dataFull:products,user,loading:false})
			});
		});
	}
	dataSet = (data) => dgDataPrepare(data, 'productrelations', ['id','name','relations']);
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<DataGrid
							title={'Связанные позиции'}
							keys={['ID','Раздел','Связанные позиции']}
							link={'productrelation'}
							data={this.state.dataShow}
							dataFull={this.state.dataFull}
							controlAdd={<Link to={'/productrelation'}>Добавить</Link>}
							notFound={<NotFound />} />
					</MainTemplate>
				}
			</>
		);
	}
}

export default ProductRealationsList;