import Http from '../../Globals/Http';
import {requestOderType,requestConditionType,transactionStatus} from '../../Globals/Constants';

const getAll	= (callback)			=> Http.get('transactions', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getPage	= (limits, callback)	=> Http.get('transactions', {orders:[{k:'id',isd:requestOderType.DESC}],limits}).then((res) => callback(res.data));
const get		= (id, callback)		=> Http.get('transactions', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const getOthers	= async (start,end)		=> await Http.get('transactions', {conditions:[{k:'dateCreate',v:start,op:requestConditionType.MORE},{k:'dateCreate',v:end,op:requestConditionType.LESS},{k:'targetType',v:null,op:requestConditionType.NOT_NULL},{k:'status',v:transactionStatus.FINISH}]});

const add		= (data, callback)		=> Http.post('transactions', {data}).then((res) => callback ? callback(res) : {});

export {
	getAll,
	getPage,
	get,
	getOthers,
	add
}