import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import Modal from 'react-awesome-modal';
import classNames from 'classnames';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';
import SaveStatus from '../../../Components/SaveStatus';
import Close from '../../../Components/Close';
import Alert,{alertShow} from '../../../Components/Alert';

// models
import {Stores,Events,Logs} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {empty} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {eventType,measureTypeName, requestStatus} from '../../../Globals/Constants';

// styles
import styles from './StoreIncomeRequest.module.css';
const cx = classNames.bind(styles);

class StoreIncomeRequest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			store:null,
			ingredients:[],
			ingredientsFull:[],
			semifinish:[],
			semifinishFull:[],
			items:[],
			tab:1,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false,
			modalshow:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	dataGet = async () => {
		const ingredients = await Stores.ingredientsGetAsync();
		this.setState({ingredients:ingredients.data,ingredientsFull:ingredients.data});
		const items = await Stores.requestItemsGet(this.state.id);
		const semifinish = await Stores.semifinishesGetAsync();
		Stores.requestGet(this.state.id, async (data) => {
			const store = await Stores.getAsync(data.storeId);
			items.data.forEach((v) => {
				v.price = parseFloat(v.price / 100);
			});
			this.setState({data,dataOld:{...data},notfound:empty(data),items:items.data,store:store.data[0],semifinish:semifinish.data,semifinishFull:semifinish.data,loading:false});
		});
	}
	handleItemForm = (e, idx) => {
		const {items} = this.state;
		items[idx][e.target.name] = parseFloat(e.target.value);
		this.setState({items});
	}
	save = (e) => {
		e.preventDefault();
		const {items,user,store} = this.state;
		if (items.length === 0) {
			alertShow('<b>Ошибка!</b><br/>Вы не добавили ни одной позиции', true);
			this.modalShow();
			return;
		}
		this.setState({issave:true});
		const data = {
			requestId:this.state.id,
			storeId:store.id,
			userId:user.id,
			userName:user.name
		};
		if (this.state.data.provisorId) {
			data.provisorId = this.state.data.provisorId;
			data.provisorName = this.state.data.provisorName;
		}
		if (this.state.data.sectionId) {
			data.sectionId = this.state.data.sectionId;
			data.sectionName = this.state.data.sectionName;
		}
		Stores.incomeAdd(data, (res) => {
			this.stop(() => {
				const id = res.data.id;
				Events.add(this.state.user.id, Texts.income.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.STORE_INCOME);
				items.forEach(async (v) => {
					v.incomeId = id;
					v.price = v.price * 100;
					if (v.ingredientId === 0) {
						delete v.ingredientId;
						delete v.ingredientName;
					}
					if (v.extCardId === 0) {
						delete v.extCardId;
						delete v.extCardName;
					} else {
						v.cardId = v.extCardId;
						v.cardName = v.extCardName;
					}
					if (v.provisorId === 0) {
						delete v.provisorId;
						delete v.provisorName;
					}
					await Stores.incomeItemsAdd(v);
				});
				Stores.requestUpdate(this.state.id, {status:requestStatus.FINISH});
				Stores.balanceUpdate({storeId:data.storeId,items});
				this.setState({saveStatus:Texts.income.add,iserror:false});
				setTimeout(() => window.location.href=`/store/income/${id}`, 2000);
			});
		}, (err) => {
			this.stop(() => {
				this.setState({saveStatus:Texts.errors.internalError,iserror:true});
				Logs.add(err);
			});
		});
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	modalHide = () => this.setState({modalshow:false});
	modalShow = () => this.setState({modalshow:true});
	ingredientSelect = (e) => {
		const {items,ingredients} = this.state;
		const ingredient = ingredients.find(f => f.id === parseInt(e.target.value));
		const item = {
			ingredientId:ingredient.id,
			ingredientName:ingredient.name,
			measureType:ingredient.measureType,
			price:ingredient.price,
			quantity:ingredient.defaultCount
		};
		if (ingredient.provisorId !== 0) {
			item.provisorId = ingredient.provisorId;
			item.provisorName = ingredient.provisorName;
		}
		items.push(item);
		this.setState({items}, this.modalHide);
	}
	semifininishSelect = (e) => {
		const {items,semifinish} = this.state;
		const sf = semifinish.find(f => f.id === parseInt(e.target.value));
		const item = {
			extCardId:sf.id,
			extCardName:sf.productName,
			measureType:sf.measureType,
			quantity:sf.defaultCount
		};
		items.push(item);
		this.setState({items}, this.modalHide);
	}
	itemRemove = (idx) => {
		const items = [];
		this.state.items.forEach((v,i) => {
			if (i !== idx) items.push(v);
		});
		this.setState({items});
	}
	searchIngredients = (e) => {
		const ss = e.target.value;
		if (empty(ss)) {
			this.setState({ingredients:this.state.ingredientsFull});
			return;
		}
		const ingredients = this.state.ingredientsFull.filter(f => f.name.toLowerCase().indexOf(ss.toLowerCase()) !== -1);
		this.setState({ingredients});
	}
	totalGet = () => this.state.items.reduce((a, b) => a + (b.price / 100 * b.quantity), 0);
	tabSet = (tab) => this.setState({tab});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<>
						<MainTemplate isStore={true}>
							<div className={styles.container}>
								<h4>
									<span className={styles.oneline}>
										<Link to={'/store/incomes'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
										Созадать поступление из заявки
									</span>
								</h4>
								{this.state.notfound ? <NotFound /> :
									<form onSubmit={this.save}>
										<div className={styles.formcontainer}>
											<div className={styles.onelinefull}>
												<div className={styles.cell}>
													<label>
														<span>Склад</span>
														<div>{this.state.store.name}</div>
													</label>
												</div>
												{this.state.data.sectionId ?
													<div className={styles.cell}>
														<label>
															<span>Раздел</span>
															<div>{this.state.data.sectionName}</div>
														</label>
													</div> : null}
												{this.state.data.provisorId ?
													<div className={styles.cell}>
														<label>
															<span>Поставщик</span>
															<div>{this.state.data.provisorName}</div>
														</label>
													</div> : null}
											</div>
											<div className={styles.linepositions}>
												<h4>
													Позиции заявки
													<a className={styles.plus} onClick={() => this.modalShow()}></a>
												</h4>
													{this.state.items.length > 0 ?
													<table>
														<thead>
															<tr>
																<th>Название</th>
																<th>Количество</th>
																<th>Единица измерения</th>
																<th>Цена за единицу, ₽</th>
																<th>&nbsp;</th>
															</tr>
														</thead>
														<tbody>
															{this.state.items.map((v,i) => <tr key={i}>
																<td>{v.extCardId ? <><b>п/ф</b> {v.extCardName}</> : v.ingredientName}<span>{v.provisorName}</span></td>
																<td><input type="number" name="quantity" placeholder="Количество" step={0.1} value={v.quantity||''} onChange={(e) => this.handleItemForm(e, i)} required /></td>
																<td>
																	<select name="measureType" onChange={(e) => this.handleItemForm(e, i)} defaultValue={v.measureType} required>
																		<option value=""></option>
																		{measureTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
																	</select>
																</td>
																<td><input type="number" name="price" placeholder="Цена, за единицу" step={0.1} value={v.price||''} onChange={(e) => this.handleItemForm(e, i)} required /></td>
																<td><img src={require('../../../Images/icons/close_black.svg')} alt="Удалить" onClick={() => this.itemRemove(i)} /></td>
															</tr>)}
														</tbody>
													</table> : null}
											</div>
											<div className={styles.buttons}>
												{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
												{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
											</div>
										</div>
									</form>
								}
							</div>
						</MainTemplate>
						<Modal visible={this.state.modalshow} width={'90%'} height={'700'} effect={'fadeInUp'}>
							<div className={styles.modalcontainer}>
								<h4 className={styles.title}>Выбрать продукт, ингредиент</h4>
								<div className={styles.tabs}>
									<div className={cx(styles.tab,this.state.tab===1?styles.tabactive:null)} onClick={() => this.tabSet(1)}>Продукт/ингредиент</div>
									<div className={cx(styles.tab,this.state.tab===2?styles.tabactive:null)} onClick={() => this.tabSet(2)}>Полуфабрикат</div>
								</div>
								<div className={styles.search}>
									<input type="search" onChange={this.searchIngredients} />
								</div>
								<div className={styles.products}>
									<ul>
										<>
											{this.state.tab === 1 ?
												this.state.ingredients.map((v,i) => <li key={i}><label><input type="radio" name="ingredientId" value={v.id} onChange={this.ingredientSelect} /> {v.name}</label></li>)
												: null}
											{this.state.tab === 2 ?
												this.state.semifinish.map((v,i) => <li key={i}><label><input type="radio" name="cardId" value={v.id} onChange={this.semifininishSelect} /> {v.productName}</label></li>)
												: null}
										</>
									</ul>
								</div>
							</div>
							<Close close={this.modalHide.bind(this)} />
						</Modal>
						<Alert />
					</>
				}
			</>
		);
	}
}

export default withRouter(StoreIncomeRequest);