import Http from '../../Globals/Http';

const getAll		= (callback)					=> Http.get('deliveryareas').then((res) => callback(res.data));

const get			= async (id)					=> await Http.get('deliveryareas', {conditions:[{k:'id',v:id}]});
const getSync		= (id, callback)				=> Http.get('deliveryareas', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

//const getByArea		= async (latitude, longitude)	=> await Http.request('deliveryareas', 'byAreaGet', {latitude,longitude});
const getByArea		= async (latitude, longitude)	=> await Http.request('deliveryareas', 'byAreaDefaultGet', {latitude,longitude});

const add			= (data, callback, error)		=> Http.post('deliveryareas', {data:data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update		= (id, data, callback, error)	=> Http.put('deliveryareas', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export {
	getAll,
	get,
	getSync,
	getByArea,
	add,
	update
}