import Http from '../../Globals/Http';
import {requestOderType,comonStatus} from '../../Globals/Constants';

const getAll			= (callback) => Http.get('sections', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getAllSync		= async () => await Http.get('sections', {orders:[{k:'id',isd:requestOderType.DESC}]});

const getActiveAll		= (callback) => Http.get('sections', {conditions:[{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const getActiveAllSync	= async () => await Http.get('sections', {conditions:[{k:'status',v:comonStatus.ACTIVE}],orders:[{k:'id',isd:requestOderType.DESC}]});

const get = (id, callback) => Http.get('sections', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));

const add = (data, callback, error) => Http.post('sections', {data:data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update = (id, data, callback, error) => Http.put('sections', {data:data,conditions:[{k:'id',v:id}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export {
	getAll,
	getAllSync,
	getActiveAll,
	getActiveAllSync,
	get,
	add,
	update
}