import {API,orderPaymentType,PRINTER,orderType} from '../Constants';
import {dateGet,dateNow,guid,phoneFormatter,quantityFormat} from '../Utils';

const request = async (data) => {
	const options = {
		method:'POST',
		timeout:PRINTER.timeout,
		mode:'cors',
		cache:'no-cache',
		credentials:'same-origin',
		headers:new Headers({'Authorization':'Basic ' + btoa(PRINTER.username + ':' + PRINTER.password),'Content-type':'application/json; charset=UTF-8'}),
		body:JSON.stringify(data)
	};
	const response = await fetch(`${PRINTER.url}/Execute`, options);
	if (response.status === 200) {
		const json = await response.json();
		return json;
	}
}

const exec = async (data) => await request(data);

const row = (text, font) => ({PrintText:{Text:text,Font:font||2,Intensity:15}});
const header = (text, font) => row(`>#0#<${text}`, font||1);
const position = (left, right, font) => row(`${left}<#0#>${right}`, font);
const total = (left, right, font) => row(`${left}<#8#>>${right}`, font);
const separator = () => row('<<->>');
const empty = () => row(' ');
const qr = () => ({BarCode:{BarcodeType:'QR',Barcode:API.siteURL}});

const splitsections = (items) => {
	return items.reduce((r, a) => {
		r[a.sectionId] = [...r[a.sectionId] || [], a];
		return r;
	}, {});
}

const print = (CheckStrings, printer) => {
	const data = {
		Command:'PrintDocument',
		NumDevice:printer,
		IdCommand:guid()
	};
	exec({...data,CheckStrings});
}

const printfiscal = (CheckStrings, printer) => {
	const data = {
		Command:'RegisterCheck',
		NumDevice:printer,
		IsFiscalCheck:false,
		NotPrint:false,
		IdCommand:guid(),
	};
	exec({...data,CheckStrings});
}

const checkfiscal = async (printer) => {
	const data = {Command:'List'};
	const res = await exec(data);
	return res.ListUnit.filter(f => f.NumDevice === printer && f.IsRegisterCheck).length;
}

const cook = (order, items) => Object.values(splitsections(items)).map((items) => {
	const CheckStrings = [
		header(`Заказ №${order.id}`),
		header(items[0].sectionName),
		empty(),
		row(`Дата: ${dateGet(order.dateCreate)}`),
		row(`Время: ${dateGet(order.dateCreate, {onlyTime:true})}`),
		row(order.isPickup?'Самовывоз':'Доставка'),
		empty(),
		row(`Коммент: ${order.comment}`),
		empty(),
		order.date ? row(`Заказ на дату: ${dateGet(order.date, {showTime:true})}`) : null,
		order.cutlery ? row(`Приборы: ${order.cutlery} шт.`) : null,
		empty(),
		separator(),
		row('Название<#0#>Кол-во'),
		separator()
	];
	items.forEach((v) => CheckStrings.push(position(v.productName, quantityFormat(v.quantity, 2))));
	CheckStrings.push(empty());
	CheckStrings.push(empty());
	CheckStrings.push(empty());
	CheckStrings.push(empty());
	CheckStrings.push(separator());
	print(CheckStrings, PRINTER.printers.printer);
	// потом как-то поменять чтобы не печатать там где нет принтера
	print(CheckStrings, PRINTER.printers.printer_add);
	print(CheckStrings, PRINTER.printers.printer_add2);
});

const collectcombine = (order, items, printer) => {
	const f = 3;
	const CheckStrings = [
		header('Общий чек', 2),
		empty(),
		header(`Заказ №${order.id}`, 2),
		order.type === orderType.OPERATOR_HALL ? header('Заказ в зале', 2) : null,
		empty(),
		empty(),
		row(order.isPickup?'Самовывоз':'С доставкой', f),
		order.isPickup ? null : row(`Адрес: ${order.address}`, f),
		order.commentAddress ? row(`Коммент: ${order.commentAddress}`, f) : null,
		order.isPaid ? row('Оплачен', f) : null,
		empty(),
		order.clientName ? row(`Клиент: ${order.clientName}`, f) : null,
		order.phone ? row(`Телефон: ${phoneFormatter(order.phone)}`, f) : null,
		order.contactPhone ? row(`Доп.телефон: ${phoneFormatter(order.contactPhone)}`, f) : null,
		empty(),
		row(`Коммент: ${order.comment}`, f),
		order.cutlery ? row(`Приборы: ${order.cutlery} шт.`, f) : null,
		order.surrender ? row(`Сдача с: ${order.surrender} ₽`, f) : null,
		order.isTerminal ? row('Нужен терминал для оплаты', f) : null,
		position(`Дата: ${dateGet(order.dateCreate)}`, `Время: ${dateGet(order.dateCreate, {onlyTime:true})}`, 3),
		row(`Принят в работу: ${dateGet(dateNow(), {onlyTime:true})}`, 3),
		empty(),
		order.date ? row(`Заказ на дату: ${dateGet(order.date, {showTime:true})}`, f) : null,
		empty(),
		separator(),
		row('Название<#0#>Кол-во/Сумма', f),
		separator()
	];
	Object.values(splitsections(items)).map((items) => {
		items.forEach((v) => {
			CheckStrings.push(position(v.productName, `${quantityFormat(v.quantity, 2)} x ${(v.priceDiscount || v.price).toFixed(2)}`, f));
			if (v.quantity > 1) CheckStrings.push(total('=', ((v.priceDiscount || v.price) * v.quantity).toFixed(2), 3));
		});
		CheckStrings.push(separator());
	});
	if (!order.deliveryIsFree) {
		if (!order.isPickup) {
			CheckStrings.push(position('Доставка', order.deliveryCost.toFixed(2), f));
			CheckStrings.push(empty());
			CheckStrings.push(separator());
		}
	}
	CheckStrings.push(total('Итого:', order.priceFull.toFixed(2), f));
	CheckStrings.push(empty());
	CheckStrings.push(empty());
	CheckStrings.push(qr());
	CheckStrings.push(header(API.siteURL, 3));
	CheckStrings.push(empty());
	CheckStrings.push(empty());
	CheckStrings.push(separator());
	CheckStrings.push(header('Спасибо!', 2));
	print(CheckStrings, printer);
}

const collect = (order, items) => {
	collectcombine(order, items, PRINTER.printers.printer);
	// потом как-то поменять чтобы не печатать там где нет принтера
	collectcombine(order, items, PRINTER.printers.printer_add);
	collectcombine(order, items, PRINTER.printers.printer_add2);
}
const collectfiscal = (order, items) => collectcombine(order, items, PRINTER.printers.fiscal);

const shift = (name, inn, isopen) => {
	const data = {
		Command:isopen?'OpenShift':'CloseShift',
		NumDevice:PRINTER.printers.fiscal,
		CashierName:name,
		CashierVATIN:inn,
		NotPrint:false,
		IdCommand:guid()
	};
	exec(data);
}

const xreport = () => {
	const data = {
		Command:'XReport',
		NumDevice:PRINTER.printers.fiscal,
		IdCommand:guid()
	};
	exec(data);
}

const checkItemsPrepare = (order, items, needfiscal) => {
	const CheckStrings = [];
	if (!needfiscal) {
		CheckStrings.push(header(`Заказ №${order.id}`, 1));
		if (order.type === orderType.OPERATOR_HALL) CheckStrings.push(header('Заказ в зале', 1));
		CheckStrings.push(empty());
		CheckStrings.push(empty());
		CheckStrings.push(row(order.isPickup?'Самовывоз':'С доставкой'));
		if (!order.isPickup) {
			CheckStrings.push(row(`Адрес: ${order.address}`));
			CheckStrings.push(row(`Коммент: ${order.commentAddress}`));
		}
		if (order.isPaid) CheckStrings.push(row('Оплачен'));
		CheckStrings.push(empty());
		if (order.clientName) CheckStrings.push(row(`Клиент: ${order.clientName}`));
		if (order.phone) CheckStrings.push(row(`Телефон: ${phoneFormatter(order.phone)}`));
		if (order.contactPhone) CheckStrings.push(row(`Доп.телефон: ${phoneFormatter(order.contactPhone)}`));
		CheckStrings.push(empty());
		CheckStrings.push(empty());
		CheckStrings.push(row(`Коммент: ${order.comment}`));
		if (order.cutlery) CheckStrings.push(row(`Приборы: ${order.cutlery} шт.`));
		if (order.surrender) CheckStrings.push(row(`Сдача с: ${order.surrender} ₽`));
		if (order.isTerminal) CheckStrings.push(row('Нужен терминал для оплаты'));
		CheckStrings.push(position(`Дата: ${dateGet(order.dateCreate)}`, `Время: ${dateGet(order.dateCreate, {onlyTime:true})}`));
		CheckStrings.push(row(`Принят в работу: ${dateGet(dateNow(), {onlyTime:true})}`));
		CheckStrings.push(empty());
		if (order.date) CheckStrings.push(row(`Заказ на дату: ${dateGet(order.date, {showTime:true})}`));
		CheckStrings.push(empty());
		CheckStrings.push(empty());
		CheckStrings.push(separator());
	}
	if (needfiscal) {
		if (!order.deliveryIsFree) {
			if (!order.isPickup) items.push({productName:'Доставка',quantity:1,price:order.deliveryCost,isdelivery:true});
		}
	}
	items.forEach((v,i) => {
		if (needfiscal) {
			CheckStrings.push(
				{
					Register: {
						Name:v.productName,
						Quantity:v.quantity||1,
						Price:v.price,
						// Конечная сумма строки с учетом всех скидок/наценок; (2 знака после запятой), Тег 1043, Из нее расчет тега 1079
						Amount:(v.priceDiscount || v.price) * (v.quantity||1),
						Department:0,
						// НДС в процентах или ТЕГ НДС: 0 (НДС 0%), 10 (НДС 10%), 20 (НДС 20%), -1 (НДС не облагается), 120 (НДС 20/120), 110 (НДС 10/110), Тег 1199
						//Tax:20,
						//Штрих-код EAN13 для передачи в ОФД (не печатется)
						//EAN13: "1254789547853",
						CountryOfOrigin:'643', 
						SignMethodCalculation:4,
						SignCalculationObject:v.isdelivery?4:1,
					}
				}
			);
		} else {
			CheckStrings.push(position(v.productName, `${quantityFormat(v.quantity, 2)} x ${(v.priceDiscount || v.price).toFixed(2)}`));
			if (v.quantity > 1) CheckStrings.push(total('=', ((v.priceDiscount || v.price) * v.quantity).toFixed(2)));
		}
		CheckStrings.push(empty());
	});
	CheckStrings.push(separator());
	if (!needfiscal) {
		if (!order.deliveryIsFree) {
			if (!order.isPickup) {
				CheckStrings.push(position('Доставка', order.deliveryCost.toFixed(2)));
				CheckStrings.push(empty());
				CheckStrings.push(separator());
			}
		}
		CheckStrings.push(total('Итого:', order.priceFull.toFixed(2)));
		CheckStrings.push(empty());
		CheckStrings.push(empty());
		CheckStrings.push(qr());
		CheckStrings.push(empty());
		CheckStrings.push(header(API.siteURL, 1));
		CheckStrings.push(empty());
		CheckStrings.push(header('Спасибо!', 1));
		CheckStrings.push(empty());
		CheckStrings.push(separator());
	}
	return CheckStrings;
}

const check = async (name, inn, order, items, needfiscal) => {
	const printer = PRINTER.printers.fiscal;
	const isfiscal = await checkfiscal(printer);
	if (isfiscal) {
		const data = {
			Command:'RegisterCheck',
			NumDevice:printer,
			//InnKkm:'',
			//KktNumber:'',
			IsFiscalCheck:needfiscal,
			TypeCheck:0,
			NotPrint:false,
			NumberCopies:0,
			CashierName:name,
			CashierVATIN:inn,
			ClientAddress:order.phone?`+${order.phone}`:'+78462020225',
			// Печатать Слип-чек после чека (а не в чеке) (Null - из настроек на сервере)
			//PrintSlipAfterCheck:null,
			// Печатать Слип-чек дополнительно для кассира (основной слип-чек уже будет печататся в составе чека) (Null - из настроек на сервере)
			//PrintSlipForCashier:null,
			CheckStrings:checkItemsPrepare(order, items, needfiscal),
			IdCommand:guid(),
			Cash:order.paymentType === orderPaymentType.CASH ? order.priceFull : 0,
			ElectronicPayment:order.paymentType === orderPaymentType.CARD ? order.priceFull : 0,
			AdvancePayment:0,
			Credit:0,
			CashProvision:0
		};
		exec(data);
	}
	else collectfiscal(order, items);
}

const cancel = async (order) => {
	const printer = PRINTER.printers.fiscal;
	const isfiscal = await checkfiscal(printer);
	const CheckStrings = [
		header(`Заказ №${order.id}`, 1),
		empty(),
		header('Отмена'),
		empty(),
		separator(),
		empty()
	];
	if (isfiscal) {
		const data = {
			Command:'RegisterCheck',
			NumDevice:printer,
			IsFiscalCheck:false,
			TypeCheck:0,
			NotPrint:false,
			NumberCopies:0,
			CheckStrings:CheckStrings,
			IdCommand:guid()
		};
		exec(data);
	}
	else print(CheckStrings, printer);
}

const calc = async (moneyTotal) => {
	const printer = PRINTER.printers.fiscal;
	const isfiscal = await checkfiscal(printer);
	const CheckStrings = [
		header('Результат сверки', 2),
		empty(),
		header(dateGet(dateNow()), 2),
		empty(),
		empty(),
		position('В кассе', moneyTotal.cash.toFixed(2)),
		position('Терминалы', moneyTotal.terminal.toFixed(2)),
		position('Онлайн', moneyTotal.online.toFixed(2)),
		position('Прошлые заказы', moneyTotal.old.toFixed(2)),
		position('Всего за день', moneyTotal.all.toFixed(2)),
		separator(),
		position('Результат', moneyTotal.result.toFixed(2)),
		empty(),
		separator()
	];
	if (isfiscal) printfiscal(CheckStrings, printer);
	else print(CheckStrings, printer);
}

const report = async (dailyReport) => {
	const printer = PRINTER.printers.fiscal;
	const isfiscal = await checkfiscal(printer);
	const CheckStrings = [
		header('Результат отчета', 2),
		empty(),
		header(dateGet(dateNow()), 2),
		empty(),
		header(dailyReport.area, 2),
		empty(),
		empty(),
		row('В зале'),
		position('Кол-во', dailyReport.hall.count),
		position('Сумма', dailyReport.hall.amount.toFixed(2)),
		separator(),
		row('Онлайн. Оплачено'),
		position('Кол-во', dailyReport.onlinepaid.count),
		position('Сумма', dailyReport.onlinepaid.amount.toFixed(2)),
		separator(),
		row('Онлайн. Оплачено. Доставка'),
		position('Кол-во', dailyReport.onlinepaiddelivery.count),
		position('Сумма', dailyReport.onlinepaiddelivery.amount.toFixed(2)),
		separator(),
		row('Онлайн. Оплачено. Самовывоз'),
		position('Кол-во', dailyReport.onlinepaidpickup.count),
		position('Сумма', dailyReport.onlinepaidpickup.amount.toFixed(2)),
		separator(),
		row('Онлайн. QR'),
		position('Кол-во', dailyReport.onlineqrdelivery.count),
		position('Сумма', dailyReport.onlineqrdelivery.amount.toFixed(2)),
		separator(),
		row('Онлайн. Курьеру. Доставка'),
		position('Кол-во', dailyReport.onlinecashdelivery.count),
		position('Сумма', dailyReport.onlinecashdelivery.amount.toFixed(2)),
		separator(),
		row('Онлайн. Не оплачено. Самовывоз'),
		position('Кол-во', dailyReport.onlinecashpickup.count),
		position('Сумма', dailyReport.onlinecashpickup.amount.toFixed(2)),
		separator(),
		row('Оператор. Доставка'),
		position('Кол-во', dailyReport.operatordelivery.count),
		position('Сумма', dailyReport.operatordelivery.amount.toFixed(2)),
		separator(),
		row('Оператор. Самовывоз'),
		position('Кол-во', dailyReport.operatorpickup.count),
		position('Сумма', dailyReport.operatorpickup.amount.toFixed(2)),
		separator(),
		row('Прошлые заказы на сегодня'),
		position('Кол-во', dailyReport.old.count),
		position('Сумма', dailyReport.old.amount.toFixed(2)),
		separator(),
		row('Отмена'),
		position('Кол-во', dailyReport.cancel.count),
		position('Сумма', dailyReport.cancel.amount.toFixed(2)),
		separator(),
		row('Возврат'),
		position('Кол-во', dailyReport.refund.count),
		position('Сумма', dailyReport.refund.amount.toFixed(2)),
		separator(),
		row('Наличные в терминале'),
		position('Сумма', dailyReport.terminal.amount.toFixed(2)),
		separator(),
		row('Долг курьеров'),
		position('Сумма', dailyReport.terminalInWork.amount.toFixed(2)),
		separator(),
		row('Наличные в кассе'),
		position('Сумма', dailyReport.kassa.amount.toFixed(2)),
		separator(),
		row('Прочие продукты'),
		position('Кол-во', dailyReport.others.count),
		position('Сумма', dailyReport.others.amount.toFixed(2)),
		separator(),
		row('ИТОГО'),
		position('Кол-во', dailyReport.all.count),
		position('Сумма', dailyReport.all.amount.toFixed(2)),
		separator(),
		empty(),
		empty(),
	];
	if (isfiscal) printfiscal(CheckStrings, printer);
	else print(CheckStrings, printer);
}

const logist = async (dailyReport) => {
	const printer = PRINTER.printers.fiscal;
	const isfiscal = await checkfiscal(printer);
	const CheckStrings = [
		header('Отчет логиста', 2),
		empty(),
		header(dateGet(dateNow()), 2),
		empty(),
		header(dailyReport.area, 2),
		empty(),
		empty(),
		row('Все доставки'),
		position('Кол-во', dailyReport.delivery.count),
		position('Сумма', dailyReport.delivery.amount.toFixed(2)),
		separator(),
		row('Оплачены'),
		position('Кол-во', dailyReport.paid.count),
		position('Сумма', dailyReport.paid.amount.toFixed(2)),
		separator(),
		row('QR'),
		position('Кол-во', dailyReport.qr.count),
		position('Сумма', dailyReport.qr.amount.toFixed(2)),
		separator(),
		row('Наличные в терминале'),
		position('Сумма', dailyReport.terminal.amount.toFixed(2)),
		separator(),
		row('Текущие долги'),
		position('Сумма', dailyReport.couriersDebt.amount.toFixed(2)),
		separator(),
		row('Наличные в терминале за другую смену'),
		position('Сумма', dailyReport.terminalOld.amount.toFixed(2)),
		separator(),
		row('Прошлые долги'),
		position('Сумма', dailyReport.couriersDebtOld.amount.toFixed(2)),
		separator(),
		row('Ручное закрытие долгов'),
		position('Сумма', dailyReport.logistClose.amount.toFixed(2)),
		separator(),
		row('Наличные для размена'),
		position('Сумма', dailyReport.kassa.amount.toFixed(2)),
		separator(),
		row('Наличные от кассира'),
		position('Сумма', dailyReport.kassaLogist.amount.toFixed(2)),
		separator(),
		row('ИТОГО'),
		position('Сумма', dailyReport.logist.amount.toFixed(2)),
		separator(),
		empty(),
		empty(),
	];
	if (isfiscal) printfiscal(CheckStrings, printer);
	else print(CheckStrings, printer);
}

const couriersDebt = async (debts) => {
	const printer = PRINTER.printers.fiscal;
	const isfiscal = await checkfiscal(printer);
	const CheckStrings = [
		header('Долги курьеров', 2),
		empty(),
		header(dateGet(dateNow()), 2),
		empty(),
		header(debts.area, 2),
		empty(),
		empty(),
	];
	debts.couriers.map((v) => {
		CheckStrings.push(position(v.name, v.debt.toFixed, 3));
		CheckStrings.push(separator());
	});
	CheckStrings.push(empty());
	CheckStrings.push(empty());
	if (isfiscal) printfiscal(CheckStrings, printer);
	else print(CheckStrings, printer);
}

const sections = async (sectionsreport) => {
	//const printer = PRINTER.printers.printer;
	const printer = PRINTER.printers.fiscal;
	const isfiscal = await checkfiscal(printer);
	const items = [];
	sectionsreport.forEach((v) => {
		items.push(position(v.name, v.amount.toFixed(2)));
		items.push(separator());
	})
	items.push(empty());
	const CheckStrings = [
		header('Отчет по станициям', 2),
		empty(),
		header(dateGet(dateNow()), 2),
		empty(),
		empty()
	];
	const data =[...CheckStrings, ...items];
	if (isfiscal) printfiscal(data, printer);
	else print(data, printer);
}

const cashClose = async (report) => {
	//const printer = PRINTER.printers.printer;
	const printer = PRINTER.printers.fiscal;
	const isfiscal = await checkfiscal(printer);
	const CheckStrings = [
		header('Касса закрыта', 2),
		empty(),
		header(dateGet(dateNow()), 2),
		empty(),
		header(report.userName, 2),
		empty(),
		empty(),
		row('Заказов за день'),
		row(report.cashOrders),
		empty(),
		row('Сумма оплат картой'),
		row(report.cashCard),
		empty(),
		// row('Передано логисту'),
		// row(report.casherLogist),
		// empty(),
		row('Сумма от логиста'),
		row(report.cashLogist),
		empty(),
		row('Сумма размена'),
		row(report.cashExchange),
		empty(),
		row('Итого'),
		row(report.amount),
		empty(),
	];
	if (isfiscal) printfiscal(CheckStrings, printer);
	else print(CheckStrings, printer);
}

const Printer = {
	cook,
	collect,
	shift,
	xreport,
	check,
	cancel,
	report: {
		calc,
		report,
		logist,
		couriersDebt,
		sections,
		cashClose
	}
};

export default Printer;