import Http from '../../Globals/Http';
import {comonStatus,requestOderType} from '../../Globals/Constants';

const get			= async (areaId)	=> await Http.get('dayshifts', {conditions:[{k:'areaId',v:areaId},{k:'status',v:comonStatus.ACTIVE}]});
const getAll		= async () 			=> await Http.get('dayshifts', {orders:[{k:'id',isd:requestOderType.DESC}]});

const getCurrent	= async (areaId)	=> {
	const ds = await get(areaId);
	if (ds.data.length === 0) return null;
	const current = ds.data[0];
	return current.status === comonStatus.ACTIVE ? current : null;
}

const add			= async (data)		=> await Http.post('dayshifts', {data});
const update		= async (id, data)	=> await Http.put('dayshifts', {data:data,conditions:[{k:'id',v:id}]});

export {
	getAll,
	getCurrent,
	add,
	update
}