import Http from '../../Globals/Http';
import {requestOderType} from '../../Globals/Constants';

const getAll		= (callback)				=> Http.get('clientblacklists', {orders:[{k:'id',isd:requestOderType.DESC}]}).then((res) => callback(res.data));
const get			= (id, callback)			=> Http.get('clientblacklists', {conditions:[{k:'id',v:id}]}).then((res) => callback(res.data[0]));
const byPhoneGet	= (phone, callback)			=> Http.request('clientblacklists', 'byPhoneGet', {phone}).then((res) => callback(res.data));

const add			= (data, callback, error)		=> Http.post('clientblacklists', {data:data}).then((res) => callback(res)).catch((err) => error ? error(err) : {});
const update		= (phone, data, callback, error)	=> Http.put('clientblacklists', {data:data,conditions:[{k:'phone',v:phone}]}).then((res) => callback(res)).catch((err) => error ? error(err) : {});

export { getAll, get, byPhoneGet, add, update, }