import React,{Component} from 'react';
import {withRouter} from 'react-router';

// plug-ins
import Modal from 'react-awesome-modal';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import {YMaps,Map,ZoomControl} from 'react-yandex-maps';

// components
import Loading from '../../Loading';
import Error from '../../Error';
import Close from '../../Close';
import NotFound from '../../NotFound';
import Alert,{alertShow} from '../../Alert';
import OrderInfo from '../../OrderInfo';
import Domru from '../../Domru/Domru';

// models
import {Areas,DispatcherCalls,Clients,Orders,Events,OrderStatuses,Settings,Maps,ClientAddresses,Logs,OrderItems,Products,Discounts,DayShifts,DeliveryAreas,Sections,PromoCodes,OrderPrints,CourierRoutes,Users,CourierOrders,Pushes,Stores,ClientBlacklist,ClientPromoCodes,ClientRanks,ClientRankTypes,DomruEvents,Feedbacks,FortuneWheelClients,CourierStatuses} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {dateGet,phoneFormatter,phoneNormalize,dateSegmentsGet,moneyFormat,dateNow,empty,zeroPad,quantityFormat,todayGet} from '../../../Globals/Utils';
import errorsParse from '../../../Globals/Errors';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {MAP,orderStatus,userStatus,orderStatusName,comonStatus,eventType,errorType,clientStatus,measureTypeName,orderType,timers,transactionPaymentType,targetType,orderPaymentType,orderPaid,userType,courierStatus} from '../../../Globals/Constants';

// styles
import styles from './DashboardDispatcher.module.css';
const cx = classNames.bind(styles);

class DashboardDispatcher extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user:null,
			data:null,
			orderproducts:[],
			orders:null,
			orderssearch:[],
			productquantity:[],
			ordersall:[],
			clientOrders:[],
			couriers:[],
			courierorders:[],
			courierstatuses:[],
			orderid:0,
			orderisbusy:false,
			call:null,
			client:null,
			areas:null,
			activearea:null,
			noOperator:false,
			noCook:false,
			noCollector:false,
			noLogist:false,
			addresses:null,
			addressfull:null,
			addressClient:null,
			addressundefined:false,
			clients:[],
			sections:null,
			products:null,
			discounts:null,
			orderitems:[],
			sectionId:0,
			sectionLink:null,
			sectionName:null,
			categoryId:0,
			categoryName:null,
			categories:[],
			price:0,
			priceDiscount:0,
			discountId:0,
			discountName:'',
			discount:0,
			discountPercent:0,
			manualDiscount:'',
			manualDiscountPercent:'',
			promocodes:null,
			promoCode:'',
			promoCodeDiscount:0,
			promoCodeDiscountPercent:0,
			promoCodeName:'',
			delivery:null,
			deliveryCost:0,
			deliveryFreeAmount:0,
			deliveryCostManual:undefined,
			deliveryTrafficFactor:1,
			deliveryIsFree:false,
			deliveryIsAlwaysPaid:false,
			isPickup:null,
			isPaid:null,
			isTerminal:null,
			surrender:'',
			cutlery:'',
			date:'',
			room:'',
			entrance:'',
			intercom:'',
			floor:'',
			status:comonStatus.ACTIVE,
			commentSystem:'',
			commentAddress:'',
			courierroute:[],
			clientinblacklist: false,
			clientsblacklist: [],
			clientblacklistdesc: '',
			clientaddblacklist: false,
			clientName:null,
			clientPhone:null,
			clientContactPhone:null,
			clientAddress:null,
			clientAddressId:0,
			clientComment:'',
			clientRank:null,
			clientRankTypes:[],
			rank:null,
			clientBalance:0,
			filterSearch:'',
			filterDateStart:'',
			filterDateEnd:'',
			filterStatus:undefined,
			filterAreaId:0,
			filterDayShiftCurrent:null,
			filterDate:null,
			isordershow:false,
			isnewcall:false,
			isfullscreen:false,
			isordersearch:false,
			isordersearchinfo:false,
			isorderedit:false,
			isorderchange:false,
			isorderfinish:false,
			orderaddstep:1,
			showaddresssearch:false,
			showclientsearch:false,
			showallcouriers:false,
			showallorders:false,
			modalshoworderarea:false,
			modalshoworderareachange:false,
			modalshowprompt:false,
			modalshowpromptclose:false,
			modalshowpromptrefund:false,
			modalshowpromptcancel:false,
			modalshowcouriermap:false,
			modalshowcourier:false,
			modalshowclientorders:false,
			isordersave:false,
			iserror:false,
			loading:true,
			loadinginner:true,
			changecname:false,
			changecnumber:false,
			isWasPaid:false,
			addingPromo:false,
			newPromoCode:'',
			discountValue:'',
			discountType:'amount',
			promos:[],
			domrucalls:[],
			ismessage:false,
			messages:[],
			dialog:null,
			message:null,
			fortunewheelwins:null,
			showmessages:false
		};
	}
	timerId = null;
	messagesTimerId = null;
	//callTimerId = null;
	promoCodeTimerId = null;
	map = null;
	ymaps = null;
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.dataGet();
	}
	componentWillUnmount = () => {
		clearInterval(this.timerId);
		this.timerId = null;
		//clearInterval(this.callTimerId);
		//this.callTimerId = null;
		clearInterval(this.messagesTimerId);
		this.messagesTimerId = null;
		clearTimeout(this.promoCodeTimerId);
		this.promoCodeTimerId = null;
	}
	dataGet = async () => {
		this.setState({loading:true,iserror:false});
		const user = ls('user');
		try {
			const settings = ls('settings');
			const users = await Users.getByType([userType.COURIER]);
			const noCouriersOnline = parseInt(Settings.getByKey('couriers-no-online-status', settings).value) === 1;
			//const couriers = users.data.filter(f => f.status === userStatus.ACTIVE && (noCouriersOnline ? (f.online === 0 || f.online === 1) : f.online === 1));
			//const couriersList = users.data.filter(f => f.status === userStatus.ACTIVE && (noCouriersOnline ? (f.online === 0 || f.online === 1) : f.online === 1) && (f.areaId === 0 || (f.areaId !== 0 && f.areaId === activearea.id)));
			const couriersList = users.data.filter(f => f.status === userStatus.ACTIVE && (noCouriersOnline ? (f.online === 0 || f.online === 1) : f.online === 1));
			const areas = await Areas.getAllActive();
			const couriers = [];
			for (const courier of couriersList) {
				if(!courier.online) {
					couriers.push({ ...courier, position: { lat: null, lon: null }, rideData: { duration: null, distance: null } });
					continue;
				}
				try {
					const positions = await CourierRoutes.getLastRoute(courier.id);
					if (positions.data && positions.data.length > 0) {
						const validPositions = positions.data.filter((pos) => pos.latitude && pos.longitude);
						if (validPositions.length > 0) {
							const { latitude, longitude } = validPositions[0];
							couriers.push({ ...courier, position: { lat: latitude, lon: longitude }, area: areas.data.find(item => item.id === courier.areaId), rideData: { duration: null, distance: null } });
							continue;
						}
					}
				} catch (error) {
					console.error(`Error fetching positions for courier ${courier.id}: ${error}`);
				}
				couriers.push({ ...courier, position: { lat: null, lon: null } });
			}

			console.log(couriers);
	
			this.feedbackMessagesGet();
			ClientRankTypes.getAll((res) => {
				const ranksTypes = res.reduce((acc, item) => {
					acc[item.id] = item.name;
					return acc;
				}, {});
				this.setState({clientRankTypes:ranksTypes});
			});
			this.setState({user,
				noOperator:parseInt(Settings.getByKey('no-operator', settings).value) === 1,
				noCook:parseInt(Settings.getByKey('no-cook', settings).value) === 1,
				noCollector:parseInt(Settings.getByKey('no-collector', settings).value) === 1,
				noLogist:parseInt(Settings.getByKey('no-logist', settings).value) === 1,
				deliveryCost:parseInt(Settings.getByKey('delivery-cost', settings).value),
				deliveryFreeAmount:parseInt(Settings.getByKey('delivery-amount-free', settings).value),
				deliveryTrafficFactor:parseFloat(Settings.getByKey('delivery-traffic-factor', settings).value),
				couriers,
				areas:areas.data,
				loading:true
			}, async () => {
				await this.ordersPanelsUpdate();
				this.timerId = setInterval(this.ordersPanelsUpdate, timers.NEW_MESSAGES);
				this.messagesTimerId = setInterval(this.feedbackMessagesGet, timers.NEW_ORDERS_GET_DISPATCHER);
			});
			//this.callTimerId = setInterval(this.orderCallUpdate, timers.DISPATCHER_CALL);
			Products.getAllActive((data) => {
				const categories = [];
				data.forEach((v,i) => {
					if (v.categoryId !== 0) {
						const c = categories.find(f => f.categoryId === v.categoryId);
						if (c === undefined) categories.push(v);
					}
				});
				this.setState({products:data,categories});
			});
			Sections.getAll((data) => this.setState({sections:data.sort((a) => a.sortOrder ? -1 : 1)}));
			Discounts.getAll((data) => this.setState({discounts:data}));
		} catch (ex) {
			this.setState({loading:false,iserror:true});
		}
	}
	feedbackMessagesGet = () => {
		Feedbacks.getAll(async (res) => {
			const {ismessage,messages} = this.state;
			if (!ismessage && messages.length !== 0 && res.length > messages.length) this.setState({ismessage:true});
			this.setState({messages:res});
		});
	}
	ordersPanelsUpdate = async () => {
		const now = todayGet();
		const o = await Orders.getDispatcherActivce();
		const orders = o.data;
		const o2 = await Orders.getDayOrders(now);
		const ordersall = o2.data.filter(f => f.status !== orderStatus.HIDE && f.status !== orderStatus.ROBOT_PREPARE && f.status !== orderStatus.KIOSK_PREPARE);
		ordersall.sort((a,b) => a.dateCreate > b.dateCreate ? -1 : 1);
		if (orders) {
			const settings = ls('settings'), now = dateNow(), timeDelay = parseInt(Settings.getByKey('new-order-dispatcher', settings).value);
			orders.sort((a) => a.status === orderStatus.USER_PAID ? -1 : 1);
			orders.sort((a) => (a.dateCreate + timeDelay) < now ? -1 : 1);
			await this.couriersUpdate();
			this.setState({orders,ordersall,loading:false});
		}
		else this.setState({loading:false,iserror:true});
	}
	ordersClientUpdate = async () => {
		const clientPhone = this.state.clientPhone;
		Orders.getByPhone(clientPhone, (clientOrders) => this.setState({clientOrders, loadinginner: false}));
	}
	changeClientPhone = () => this.setState({changecphone: !this.state.changecphone});
	onChangeClientPhone = (e) => this.setState({clientPhone: e.target.value});
	saveClientPhone = () => {
		const clientPhone = phoneNormalize(this.state.clientPhone);
		this.setState({changecphone: !this.state.changecphone, clientPhone});
		const name = this.state.client.name;
		Clients.byPhoneGet(clientPhone, (client) => {
			if (client === null) {
				const name = 'Новый клиент';
				this.setState({client: {...client, name: name, addresses: [], phone: clientPhone, status:clientStatus.ACTIVE}, clientName: name, });
				this.clientSave();
			} else {
				this.setState({
					client: {
						...client,
						name: client.name ?? name,
						addresses: client.addresses ?? [],
						phone: clientPhone,
						status: clientStatus.ACTIVE
					}, clientName: name
				});
			}
		}).then(() => Orders.update(this.state.orderid, {phone: clientPhone, clientId: this.state.client.id, clientName: this.state.client.name}));
		this.checkBlacklist();
	}
	changeClientName = () => this.setState({changecname: !this.state.changecname});
	onChangeClientName = (e) => {
		const client = this.state.client;
		this.setState({client: {...client, name: e.target.value}});
	}
	saveClientName = () => {
		const name = this.state.client.name;
		Orders.update(this.state.orderid, {clientName: name});
		Clients.update(this.state.client.id, {name: name});
		this.setState({changecname: !this.state.changecname, clientName: name, name: {...this.state.client, name}});
	}
	couriersUpdate = async () => {
		// const settings = ls('settings');
		// const users = await Users.getByType([userType.COURIER]);
		// const noCouriersOnline = parseInt(Settings.getByKey('couriers-no-online-status', settings).value) === 1;
		// const couriersList = users.data.filter(f => f.status === userStatus.ACTIVE && (noCouriersOnline ? (f.online === 0 || f.online === 1) : f.online === 1));
		// const couriers = [];
		// const areas = await Areas.getAllActive();
		// for (const courier of couriersList) {
		// 	if(!courier.online) {
		// 		couriers.push({ ...courier, position: { lat: null, lon: null }, rideData: { duration: null, distance: null } });
		// 		continue;
		// 	}
		// 	try {
		// 		const positions = await CourierRoutes.getLastRoute(courier.id);
		// 		if (positions.data && positions.data.length > 0) {
		// 			const validPositions = positions.data.filter((pos) => pos.latitude && pos.longitude);
		// 			if (validPositions.length > 0) {
		// 				const { latitude, longitude } = validPositions[0];
		// 				couriers.push({ ...courier, position: { lat: latitude, lon: longitude }, area: areas.data.find(item => item.id === courier.areaId), rideData: { duration: null, distance: null } });
		// 				continue;
		// 			}
		// 		}
		// 	} catch (error) {
		// 		console.error(`Error fetching positions for courier ${courier.id}: ${error}`);
		// 	}
		// 	couriers.push({ ...courier, position: { lat: null, lon: null } });
		// }

		const {couriers} = this.state;
		const ids = [];
		for (let i = 0, j = couriers.length; i < j; i++) ids.push(couriers[i].id);
		const orders = await CourierOrders.getAllByCourierAsync(ids);
		if (orders.data) {
			for (let i = 0, j = couriers.length; i < j; i++) {
				const courierId = couriers[i].id;
				const ordersArray = Object.values(orders.data[courierId].data[0]);
				couriers[i].orders = ordersArray;
			}
		}
		couriers.sort((a,b) => a.orders.length > + b.orders.length ? -1 : 1);
		couriers.sort((a,b) => {
			if (a.online === b.online) return a.orders.length > b.orders.length ? -1 : 1;
			return a.online ? -1 : 1;
		});
		this.setState({couriers});
	}
	orderCallUpdate = async () => {
		const call = await DispatcherCalls.get(this.state.user.id);
		if (call.data && call.data.length > 0) this.setState({call:call.data[0]});
		else this.setState({call:null});
	}
	amountGet = (order) => `${moneyFormat(order.priceFull, false)} ₽`;
	additionalStatus = (order) => {
		const settings = ls('settings'), now = dateNow(), timeDelay = parseInt(Settings.getByKey('new-order-dispatcher', settings).value);
		return (order.dateCreate + timeDelay) < now ? styles.alarm : (order.status === orderStatus.USER_PAID ? styles.paid : null);
	}
	nextStatusGet = () => {
		let status = orderStatus.OPERATOR_WAIT;
		if (this.state.noOperator) {
			status = orderStatus.COOK_WAIT;
			if (this.state.noCook) {
				status = orderStatus.COLLECTOR_WAIT;
				if (this.state.noCollector) {
					status = orderStatus.LOGIST_WAIT;
					if (this.state.noLogist) status = orderStatus.COURIER_WAIT;
				}
			}
		}
		return status;
	}
	orderSelect = async (order) => {
		const products = await OrderItems.get(order.id);
		ClientPromoCodes.getByClientId(order.clientId, (promos) => {
			const newArray = promos.map(item => {
				return {id:item.id,code:item.code,discount:item.discount,type:item.type,status:item.status};
			});
			this.setState({promos:newArray})
		})
		this.setState({data:order,orderproducts:products.data,orderid:order.id,isordershow:false}, () => this.setState({isordershow:true}));
	}
	orderShowClose = () => this.setState({isordershow:false,data:null,orderid:0});

	/*
	orderSelect = async (order) => {
		const {user} = this.state;
		Orders.get(order.id, async (res) => {
			order.userId = res.userId ? parseInt(res.userId) : 0;
			if (order.userId === 0) {
				order.userId = user.id;
				const data = {userId:user.id,userName:user.name};
				Orders.update(order.id, data, () => Events.add(user.id, Texts.order.update, null, JSON.stringify(data), order.id, eventType.ORDER));
				this.ordersPanelsUpdate();
			}
			const products = await OrderItems.get(order.id);
			this.setState({data:order,orderproducts:products.data,orderid:order.id,orderisbusy:order.userId!==user.id,isordershow:false}, () => this.setState({isordershow:true}));
		});
	}
	orderShowClose = () => {
		const {user,orderid,data} = this.state;
		this.setState({isordershow:false,data:null,orderid:0,orderisbusy:false}, () => {
			if (data.userId === user.id) {
				const olddata = {userId:user.id,userName:user.name}, data = {userId:'',userName:''};
				Orders.update(orderid, data, () => Events.add(user.id, Texts.order.update, JSON.stringify(olddata), JSON.stringify(data), orderid, eventType.ORDER));
				this.ordersPanelsUpdate();
			}
		});
	}
	*/

	orderAreaSelect = async (order, isorderfinish) => {
		const {areas} = this.state;
		if (areas.length > 1) {
			const activearea = await Areas.getByArea(order.latitude,order.longitude);
			this.setState({activearea:activearea.data ? activearea.data : areas[0],modalshoworderarea:true,isorderfinish});
		} else this.setState({activearea:areas[0]}, () => this.operatorSelect(areas[0].id, isorderfinish));
	}
	operatorSelect = async (areaId, isorderfinish) => {
		const ds = await this.orderDayShiftSet(areaId);
		if (ds === null) return;
		if (isorderfinish) this.orderSaveFinish(areaId);
		else this.operatorSelectOrderUpdate(areaId, ds.id);
	}
	operatorSelectOrderUpdate = async (areaId, dayShiftId) => {
		const ds = await DayShifts.getCurrent(areaId);
		if (ds === null) {
			this.setState({isordersave:false});
			alertShow('<b>Невозможно принять заказ!</b><br/>Смена не открыта! Обратитесь к вашему управляющему', true);
			return;
		}
		dayShiftId = ds.id;
		const {user,data,isorderchange} = this.state;
		const status = isorderchange ? data.status : this.nextStatusGet();
		Orders.update(data.id, {areaId}, () => {
			Events.add(user.id, Texts.order.update, JSON.stringify({areaId:0}), JSON.stringify({areaId}), data.id, eventType.ORDER);
			if (this.state.noOperator) OrderPrints.add({areaId,dayShiftId,orderId:data.id,status:comonStatus.ACTIVE});
			const statuses = {
				orderId:data.id,
				userId:user.id,
				userName:user.name,
				status
			};
			OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
			const order = {status,dayShiftId};
			Orders.update(data.id, order, () => {
				Events.add(user.id, Texts.order.update, JSON.stringify({status:data.status}), JSON.stringify(order), data.id, eventType.ORDER);
				Pushes.client.prepare(data.clientId);
				this.modalHide();
				this.ordersPanelsUpdate();
				this.orderCallClose();
				this.orderShowClose();
				this.successMessageShow();
			});
			Stores.balanceSell(data.id);
		});
	}
	orderDayShiftSet = async (areaId) => {
		const ds = await DayShifts.getCurrent(areaId);
		if (ds === null) {
			alertShow('<b>Невозможно принять заказ!</b><br/>Смена не открыта! Обратитесь к вашему управляющему', true);
			return null;
		}
		return ds;
	}
	orderAccept = () => this.orderAreaSelect(this.state.data, false);
	orderCall = () => {
		const {call} = this.state;
		if (call === null) return;
		DispatcherCalls.update(call.id, {status:comonStatus.IN_ACTIVE});
		const clientPhone = call.phone;
		this.setState({clientPhone});
		Clients.byPhoneGet(clientPhone, (client) => {
			if (client === null) this.clientSave();
			else this.setState({client});
			const addressfull = client !== null && client.addresses.length ? client.addresses[0] : null;
			this.setState({isfullscreen:true,isnewcall:true,
				orderaddstep:3,
				addresses:client === null ? null : client.addresses,
				addressfull,
			}, () => addressfull ? this.clientAddressSet(addressfull.id) : {});
		});
	}
	showClientOrdersForm = () => {
		this.ordersClientUpdate();
		this.setState({modalshowclientorders:true});
	}
	checkBlacklist = () => {
		this.setState({clientinblacklist: false});
		let clientPhone = this.state.clientPhone ?? this.state.client ? this.state.client.phone ?? null : null;
		if (clientPhone !== null) {
			clientPhone = phoneNormalize(clientPhone);
			ClientBlacklist.byPhoneGet(clientPhone, (response) => {
				if (response == null || response.dateCreate == null) {
					response = {
						clientinblacklist: false,
						clientblacklistdesc: '',
						status: false,
						description: '—',
						phone: clientPhone,
						dateCreate: new Date().valueOf() / 1000,
						clientId: this.state.client.id
					}
					ClientBlacklist.add(response);
				}
				this.setState({
					clientinblacklist: response.status,
					clientblacklistdesc: response.description || '—',
					clientblacklists: response
				});
			});
		} else this.setState({clientinblacklist:false,clientblacklistdesc:null,clientblacklists:null});
	}
	addBlacklist = () => {
		if (this.state.clientaddblacklist) {
			this.updateBlacklist();
		}
		this.setState({clientaddblacklist: !this.state.clientaddblacklist});
	}
	updateBlacklist = () => {
		const clientPhone = this.state.clientPhone;
		ClientBlacklist.byPhoneGet(clientPhone, (response) => {
			if (response) {
				if (response.status) {
					response.clientinblacklist = false;
					response.status = false;
					response.description = this.state.clientblacklistdesc || '—';
					response.commentSystem = this.state.user.name + ' (' + this.state.user.id + ')';
					ClientBlacklist.update(clientPhone, response);
				} else {
					response.clientinblacklist = true;
					response.status = true;
					response.description = this.state.clientblacklistdesc || '—';
					response.dateCreate = new Date().valueOf() / 1000;
					response.commentSystem = this.state.user.name + ' (' + this.state.user.id + ')';
					ClientBlacklist.update(clientPhone, response);
				}
				this.setState({
					clientinblacklist: response.clientinblacklist,
					clientblacklists: response
				});
			} else {
				this.checkBlacklist();
			}
		});
	}
	orderClose = () => this.setState({modalshowpromptclose:true});
	orderCancel = () => this.setState({modalshowpromptcancel:true});
	orderRefund = () => this.setState({modalshowpromptrefund:true});
	orderCallClose = () => {
		this.setState({orderaddstep:1,isordershow:false,isnewcall:false,isfullscreen:false,
			isordersearch:false,isordersearchinfo:false,isorderedit:false,isorderchange:false,isrepeat:false,isorderfinish:false,
			orderitems:[],sectionId:0,sectionLink:null,sectionName:null,categoryId:0,categoryName:null,client:null,
			showaddresssearch:false,showclientsearch:false,
			orderproducts:[],productquantity:[],
			clientName:null,clientPhone:null,clientContactPhone:null,clientAddress:null,clientAddressId:0,clientComment:null,
			addresses:null,addressfull:null,addressClient:null,addressundefined:false,
			room:null,entrance:null,intercom:null,floor:null,status:comonStatus.ACTIVE,
			clients:[],promocodes:null,promoCode:'',rank:null,clientRank:null,
			delivery:null,deliveryCostManual:undefined,
			manualDiscount:null,manualDiscountPercent:null,
			surrender:null,cutlery:null,date:null,commentSystem:null,commentAddress:null,
			deliveryTrafficFactor:1,isPickup:null,isPaid:null,isTerminal:null,
			isordersave:false,
			filterDateEnd:null,filterDateStart:null,filterStatus:undefined,filterAreaId:0,filterSearch:null,filterDayShiftCurrent:null,filterDate:null,
			orderid:0,isWasPaid:false,
			addingPromo:false,
			newPromoCode:'',
			discountValue:'',
			discountType:'amount',
			promos:[],
			fortunewheelwins:null
		});
		this.modalHide();
		this.ordersPanelsUpdate();
	}
	clientSave = () => {
		const phone = phoneNormalize(this.state.clientPhone);
		const client = {
			name:this.state.clientName,
			phone,
			addresses:[],
			status:clientStatus.ACTIVE
		}
		Clients.add(client, (res) => {
			client.id = res.data.id;
			Events.add(this.state.user.id, Texts.client.add, null, JSON.stringify(client), client.id, eventType.CLIENT);
			this.setState({client,orderaddstep:3});
		}, (err) => {
			alertShow(errorsParse(err) === errorType.DUPLICATE?Texts.errors.duplicatePhone:Texts.errors.internalError, true);
			Logs.add(err);
		});
	}
	deliveryInfoGet = async (latitude, longitude) => {
		const delivery = await DeliveryAreas.getByArea(latitude, longitude);
		if (delivery) this.setState({delivery:delivery.data,deliveryCost:delivery.data.price,deliveryFreeAmount:delivery.data.priceFree,deliveryIsAlwaysPaid:delivery.data.alwaysPaid});
	}
	handleStreet = () => {
		const {addressClient} = this.state;
		if (addressClient && addressClient.length > 5) {
			this.setState({showaddresssearch:true,addresses:[]});
			const q = `${MAP.defaultCity}, ${addressClient}`;
			Maps.locationGetAddress(q, (res) => {
				const addresses = [];
				if (res.response) {
					res.response.GeoObjectCollection.featureMember.forEach((v) => {
						const name = v.GeoObject.name;
						const description = v.GeoObject.description;
						addresses.push({
							name,
							description,
							latitude:parseFloat(v.GeoObject.Point.pos.split(' ')[1]),
							longitude:parseFloat(v.GeoObject.Point.pos.split(' ')[0])
						});
					});
				}
				this.setState({addresses});
			});
		} else this.setState({showaddresssearch:false});
	}
	handleStreetAddress = (e) => {
		const addressClient = e.target.value;
		this.setState({addressClient,showaddresssearch:false});
	}
	addressCombine = (address) => {
		const addressfull = [this.addressStreetClean(address.address)];
		if (address.room) addressfull.push(`кв. ${address.room}`);
		if (address.entrance) addressfull.push(`подъезд ${address.entrance}`);
		if (address.floor) addressfull.push(`этаж ${address.floor}`);
		if (address.intercom) addressfull.push(`домофон ${address.intercom}`);
		return addressfull.join(', ').trim();
	}
	addressStreetClean = (address) => address.replace('ул.', '').trim();
	searchAddressSelect = (item) => {
		const addressfull = {
			clientId:this.state.client.id,
			address:item.name,
			latitude:item.latitude,
			longitude:item.longitude,
			status:comonStatus.ACTIVE
		};
		const clientAddress = addressfull.address;
		this.setState({addressfull,showaddresssearch:false,addressClient:clientAddress,clientAddress});
	}
	handleClient = () => {
		let clientPhone = this.state.clientPhone;
		if (clientPhone === null || clientPhone.length === 1) this.setState({showclientsearch:false,clients:null,clientPhone,fortunewheelwins:null});
		if (clientPhone && clientPhone.length > 1) {
			if (clientPhone[0] === '8') clientPhone = clientPhone.substring(1);
			Clients.find(clientPhone, (data) => {
				this.setState({showclientsearch:true,clients:data});
				if (clientPhone.length === 11) {
					this.setState({clientPhone});
				}
			});
		}
	}
	searchClientSelectDomru = (client, callId) => {
		this.orderAdd();
		this.searchClientSelect(client);
		this.domruCallClose(callId);
	}
	domruCallClose = (callId) => {
		DomruEvents.update(callId, {status: 6})
	}
	searchClientSelect = (client) => {
		if (client.phone.length === 11) {
			const addresses = client.addresses, addressfull = client.addresses && client.addresses.length ? client.addresses[0] : null;
			ClientPromoCodes.getByClientId(client.id, (promos) => {
				const newArray = promos.map(item => {
					return { id: item.id, code: item.code, discount: item.discount, type: item.type, status: item.status };
				});
				this.setState({promos: newArray})
			});
			ClientRanks.checkExt(client.id, (res) => {
				if (res) this.setState({rank:res,clientRank:res.name}, () => this.priceCalc());
			});
			FortuneWheelClients.getActiveByClientId(client.id, (res) => {
				if (res) this.setState({fortunewheelwins:res});
			});
			this.setState({
				addresses,
				addressfull,
				clientBalance:client.balance,
				client,
				clientPhone:client.phone,
				room:addressfull ? addressfull.room : '',
				entrance:addressfull ? addressfull.entrance : '',
				intercom:addressfull ? addressfull.intercom : '',
				floor:addressfull ? addressfull.floor : '',
				address:addressfull ? addressfull.address : '',
				addressClient:addressfull ? addressfull.address : '',
				clientAddressId:addressfull ? addressfull.id : '',
				showclientsearch:false,orderaddstep:3}, () => {
					if (addressfull) this.clientAddressSet(addressfull.id);
					this.checkBlacklist();
			});
		}
	}
	clientCurrentAddressGet = (addresses) => {
		if (addresses === null || addresses.length === 0) return null;
		const address = addresses.find(f => f.status === comonStatus.ACTIVE);
		return address ? address.address : addresses[addresses.length-1].address;
	}
	stepNameGet = (step) => {
		switch (step) {
			case 1: default: return 'Поиск клиента по телефону';
			case 2: return 'Новый клиент';
			case 3: return 'Адрес доставки';
			case 4: return `Меню${this.state.sectionName ? ' / ' + this.state.sectionName : ''}${this.state.categoryName ? ' / ' + this.state.categoryName : ''}`;
			case 5: return 'Скидки, промокоды, комментарий';
		}
	}
	orderNext = (e) => {
		const {orderaddstep,orderitems,clientPhone} = this.state;
		switch (orderaddstep) {
			case 1:
				break;
			case 2:
				if (phoneNormalize(clientPhone) === null) return;
				this.clientSave();
				break;
			case 3:
				this.addressSave();
				break;
			case 4:
				if (orderitems.length === 0) return;
				this.setState({orderaddstep:5});
				break;
			case 5:
				this.setState({modalshowprompt:true});
				break;
			default:
				return;
		}
	}
	clientAdd = () => this.setState({orderaddstep:2});
	clientAddDomru = (phone, callId) => {
		this.orderAdd();
		this.clientAdd();
		this.setState({clientPhone:phone});
		this.domruCallClose(callId);
	}
	quantityGet = (id) => this.state.productquantity[id] || 1;
	orderSave = async (ishold) => {
		this.modalHide();
		const {user,client,promocodes,orderitems,addressfull,clientComment,date,isorderedit,isorderchange,rank,isrepeat,deliveryIsFree,deliveryIsAlwaysPaid} = this.state, status = ishold ? orderStatus.HOLD : isorderchange ? this.state.data.status : this.nextStatusGet();
		const olddata = (isorderedit || isorderchange) ? this.state.data : null;
		orderitems.forEach((v,i) => v.quantity = v.quantity || this.quantityGet(v.id));
		const isDeliveryFreeStatus = rank && rank.deliveryFree;
		const data = {
			clientId:client.id,
			clientName:client.name,
			phone:client.phone,
			address:this.addressCombine(this.state.addressfull),
			latitude:addressfull.latitude,
			longitude:addressfull.longitude,
			price:this.state.price,
			priceFull:this.priceFullGet(),
			products:orderitems.map((v,i) => `${i+1}. ${v.name||v.productName}: ${v.priceDiscount||v.price} ₽ (${v.quantity} шт.)`).join('\r\n'),
			deliveryCost:this.state.deliveryCost,
			deliveryIsFree:deliveryIsAlwaysPaid ? false : (deliveryIsFree || isDeliveryFreeStatus || (this.state.price >= this.state.deliveryFreeAmount)),
			surrender:this.state.surrender,
			cutlery:this.state.cutlery,
			type:olddata ? olddata.type : orderType.DISPATCHER,
			isPickup:this.state.isPickup,
			isPaid:this.state.isPaid,
			isTerminal:this.state.isTerminal,
			status,
			transactionPaymentType:olddata ? olddata.transactionPaymentType : transactionPaymentType.OFFLINE,
			comment:clientComment || (olddata ? olddata.clientComment : ''),
			commentAddress:this.state.commentAddress,
			dateCreate: olddata ? olddata.dateCreate : new Date().valueOf() / 1000,
			target:olddata ? olddata.target : targetType.DISPATCHER
		};
		if (this.state.deliveryCostManual !== undefined) {
			data.deliveryCostManual = this.state.deliveryCostManual;
			data.deliveryIsFree = isDeliveryFreeStatus || (data.deliveryCostManual === 0);
		}
		if (olddata) {
			data.paymentType = olddata.paymentType;
			data.commentSystem = isorderchange && !ishold ? olddata.commentSystem : olddata.id;
		}
		const contactPhone = phoneNormalize(this.state.clientContactPhone);
		if (contactPhone) data.contactPhone = contactPhone;
		if (client.discount || rank) {
			data.discount = this.state.discount;
			data.priceDiscount = this.state.priceDiscount;
		}
		if (client.discount) data.discountPercent = client.discount;
		if (rank) data.discountPercent = rank.discount;
		if (promocodes) {
			data.promoCode = promocodes.code;
			if (promocodes.type === undefined || promocodes.type === null) {
				data.promoCodeId = promocodes.id;
				data.promoCodeDiscount = promocodes.discount;
				data.promoCodeDiscountPercent = promocodes.discountPercent;
			} else {
				if (promocodes.type === 1) {
					data.promoCodeDiscountPercent = promocodes.discount;
					data.promoCodeDiscount = Math.ceil((data.price * data.promoCodeDiscountPercent) / 100);
				}
				else if (promocodes.type === 0) data.promoCodeDiscount = promocodes.discount;
			}
		} else {
			if (this.state.manualDiscount || this.state.manualDiscountPercent) {
				data.manualDiscount = this.state.manualDiscount;
				data.manualDiscountPercent = this.state.manualDiscountPercent;
				data.priceDiscount = this.state.priceDiscount;
			}
			if (this.state.discountId) {
				data.discountId = this.state.discountId;
				data.priceDiscount = this.state.priceDiscount;
				data.discount = this.state.discount;
				data.discountPercent = this.state.discountPercent;
			}
		}
		if (!empty(date)) data.date = Math.round(new Date(date).getTime() / 1000);
		if (isrepeat) {
			data.isPaid = false;
			data.isTerminal = false;
			data.paymentType = orderPaymentType.CASH;
			data.target = targetType.DISPATCHER;
			data.type = orderType.DISPATCHER;
			data.transactionPaymentType = transactionPaymentType.OFFLINE;
		}
		if (ishold) {
			Orders.add(data, (res) => {
				Events.add(user.id, Texts.order.add, null, JSON.stringify(data), res.data.id, eventType.ORDER);
				const statuses = {
					orderId:res.data.id,
					userId:user.id,
					userName:user.name,
					status
				};
				OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
			});
			this.modalHide();
			this.ordersPanelsUpdate();
			this.orderCallClose();
			this.orderShowClose();
			this.successMessageShow();
			this.pushSend(data.clientId);
		} else this.setState({data}, () => this.orderAreaSelect(data, true));
		return false;
	}
	orderSaveFinish = async (areaId) => {
		if (this.state.isordersave) return;
		this.setState({isordersave:true});
		const {user,data,promocodes,orderitems,isorderedit,isorderchange} = this.state;
		const status = data.status;
		const ds = await this.orderDayShiftSet(areaId);
		if (ds === null) {
			this.setState({isordersave:false});
			return;
		}
		data.dayShiftId = ds.id;
		if (isorderedit && !isorderchange) {
			const order = {status:orderStatus.HIDE};
			Orders.update(this.state.orderid, order, () => {
				Events.add(user.id, Texts.order.update, JSON.stringify({status}), JSON.stringify(data), this.state.orderid, eventType.ORDER);
				if (this.state.orderid) {
					const statuses = {
						orderId:this.state.orderid,
						userId:user.id,
						userName:user.name,
						status:order.status
					};
					OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
				}
			});
		}
		const items = [];
		for (let i = 0, j = orderitems.length; i < j; i++) {
			const v = orderitems[i];
			items.push({
				sectionId:v.sectionId,
				sectionName:v.sectionName,
				productId:isorderedit ? (v.productId || v.id) : v.id,
				productName:v.name||v.productName,
				price:v.price,
				priceDiscount:v.priceDiscount,
				discount:v.discount,
				discountPercent:v.discountPercent,
				measure:v.measure,
				measureType:v.measureType,
				quantity:v.quantity
			});
		}
		data.userId = user.id;
		data.userName = user.name;
		if (!isorderchange) {
			Orders.addFull({order:data, items}, (res) => {
				const id = res.data.id;
				this.setState({data: {...data, id}});
				if (promocodes) {
					if (!isorderedit) {
						if (promocodes.count > 0) {
							const promo = {total: promocodes.total + 1};
							PromoCodes.update(promocodes.id, promo, () => Events.add(user.id, Texts.promocodes.update, JSON.stringify(promocodes), JSON.stringify(promo), promocodes.id, eventType.PROMOCODE));
						}
					}
				}
				this.operatorSelectOrderUpdate(areaId, data.dayShiftId);
			}, (err) => {
				alertShow(Texts.errors.internalError, true);
				Logs.add(err);
			});
		} else {
			Orders.update(this.state.orderid, data, (res) => {
				const id = this.state.orderid;
				this.setState({data: {...data, id}});
				this.operatorSelectOrderUpdate(areaId, data.dayShiftId);
				OrderItems.remove(this.state.orderid).then(() => {
					for (let i = 0; i < items.length; i++) items[i].orderId = id;
					Orders.updateItems({items});
				});
			}, (err) => {
				alertShow(Texts.errors.internalError, true);
				Logs.add(err);
			});
		}
	}
	addressSave = () => {
		const {room,entrance,floor,intercom,clientAddressId,client,user,addressundefined} = this.state, old = this.state.addressfull;
		const addressfull = {...this.state.addressfull,room,entrance,floor,intercom,status:comonStatus.ACTIVE,comment:this.state.commentAddress};
		if (addressfull.room) addressfull.room = addressfull.room.replaceAll(",",";");
		if (addressfull.entrance) addressfull.entrance = addressfull.entrance.replaceAll(",",";");
		if (addressfull.floor) addressfull.floor = addressfull.floor.replaceAll(",",";");
		if (addressfull.intercom) addressfull.intercom = addressfull.intercom.replaceAll(",",";");
		if (clientAddressId === 0) {
			if (addressundefined) {
				addressfull.clientId = client.id;
			}
			ClientAddresses.add(addressfull, (res) => {
				const id = parseInt(res.data.id);
				addressfull.id = id;
				client.addresses.push(addressfull);
				this.setState({
					client,clientAddressId:id,addressfull,orderaddstep:4,addressundefined:false,
					room:addressfull.room,entrance:addressfull.entrance,floor:addressfull.floor,intercom:addressfull.intercom
				});
				Events.add(user.id, Texts.client_address.add, null, JSON.stringify(addressfull), id, eventType.CLIENT_ADDRESS);
				this.deliveryInfoGet(addressfull.latitude, addressfull.longitude);
			});
		} else {
			ClientAddresses.update(clientAddressId, addressfull, () => {
				client.addresses = this.state.addresses.map((v, i) => {
					if (v.id === clientAddressId) v = addressfull;
					return v;
				});
				this.setState({
					client,clientAddressId,addressfull,orderaddstep:4,
					room:addressfull.room,entrance:addressfull.entrance,floor:addressfull.floor,intercom:addressfull.intercom
				});
				Events.add(user.id, Texts.client_address.update, JSON.stringify(old), JSON.stringify(addressfull), clientAddressId, eventType.CLIENT_ADDRESS);
				this.deliveryInfoGet(addressfull.latitude, addressfull.longitude);
			});
		}
	}
	clientAddressSet = (clientAddressId, address) => {
		address = address || this.state.client.addresses.find(f => f.id === clientAddressId);
		if (address) {
			this.setState({clientAddressId,addressfull:address,addressClient:address.address??'',room:address.room??'',floor:address.floor??'',entrance:address.entrance??'',intercom:address.intercom??''});
			this.deliveryInfoGet(address.latitude, address.longitude);
		}
	}
	handleForm = (e) => {
		if (e.target.name === 'clientPhone') {
			this.setState({[e.target.name]:phoneNormalize(e.target.value)});
		} else {
			this.setState({[e.target.name]:e.target.value});
		}
	}
	handleFormCommentSysytem = (e) => this.setState({commentSystem:e.target.value});
	handleCommentAddressForm = (e) => this.setState({commentAddress:e.target.value});
	handleAddressSelect = (e) => {
		const clientAddressId = parseInt(e.target.value);
		this.setState({addressundefined:false});
		if (isNaN(clientAddressId)) {
			this.addressAdd();
			return;
		}
		this.clientAddressSet(clientAddressId);
	}
	handleDiscountForm = (e, maxvalue, nocalc) => {
		let value = parseInt(e.target.value);
		value = value > maxvalue ? maxvalue : value;
		this.setState({[e.target.name]:isNaN(value)?'':value}, () => nocalc ? {} : this.priceCalc());
	}
	handleSurrenderForm = (e) => {
		const value = parseInt(e.target.value);
		if (isNaN(value) || value === 0) return;
		const price = this.priceFullGet();
		if (value < price) return;
		this.setState({[e.target.name]:value});
	}
	/*handleCommentForm = (e) => {
		const clientComment = e.target.value;
		this.setState({clientComment});
		const addresses = this.state.client.addresses.map((v,i) => {
			if (this.state.clientAddressId === v.id) v.comment = clientComment;
		});
		this.setState({addresses});
	};*/
	handleDeliveryForm = (e) => {
		const maxvalue = 500;
		let value = parseInt(e.target.value);
		value = value > maxvalue ? maxvalue : value;
		if (empty(value) || isNaN(value)) value = undefined;
		this.setState({deliveryCostManual:value});
	}
	handlePromoCodeForm = (e) => {
		const promoCode = e.target.value;
		clearTimeout(this.promoCodeTimerId);
		this.promoCodeTimerId = null;
		this.promoCodeTimerId = setTimeout(() => {
			PromoCodes.check(promoCode, (data) => {
				if(data?.data) {
					this.setState({promocodes:data}, () => this.priceCalc())
				}
			});
			ClientPromoCodes.check(promoCode, (data) => {
				if(data?.data) {
					this.setState({promocodes:data}, () => this.priceCalc())
				}
			})
		}, 2000);
		this.setState({promoCode:promoCode ? promoCode.toUpperCase() : promoCode,promocodes:null});
	}
	handlePickupForm = (e) => this.setState({isPickup:e.target.checked?1:0}, () => this.priceCalc());
	handlePaidForm = (e) => this.setState({isPaid:e.target.checked?1:0});
	handleTerminalForm = (e) => this.setState({isTerminal:e.target.checked?1:0});
	handleDateForm = (e) => this.setState({date:e.target.value});
	dateClear = () => this.setState({date:''});
	handleFilterDateForm = (e) => this.setState({[e.target.name]:e.target.value});
	handleFilterDayShiftCurrentForm = (e) => this.setState({filterDayShiftCurrent:e.target.checked?1:0});
	handleFilterDateFutureForm = (e) => this.setState({filterDate:e.target.checked?1:0});
	dateFilterClear = (field) => this.setState({[field]:''});
	handleFilterStatusSelect = (e) => {
		const filterStatus = parseInt(e.target.value);
		this.setState({filterStatus:isNaN(filterStatus)?undefined:filterStatus});
	}
	handleFilterAreaSelect = (e) => {
		const filterAreaId = parseInt(e.target.value);
		this.setState({filterAreaId:isNaN(filterAreaId)?0:filterAreaId});
	}
	handleQuantity = (e, id) => {
		if ((!this.state.isWasPaid) || [orderStatus.USER_ADDED,orderStatus.OPERATOR_WAIT].includes(this.state.data.status)) {
			const {productquantity,orderitems} = this.state;
			let q = parseInt(e.target.value);
			q = isNaN(q) || q < 1 ? 1 : q;
			productquantity[id] = q;
			orderitems.forEach((v,i) => {
				if (v.id === id) v.quantity = q;
			});
			this.setState({productquantity,orderitems}, () => this.priceCalc());
		} else {
			alertShow('Невозможно изменить состав заказа! Заказ был оплачен.', true);
		}
	}
	sectionSelect = (id, name, link, categoryName) => this.setState({sectionId:id,sectionName:name,sectionLink:link,categoryName});
	categorySelect = (id, sectionId, name, sectionName) => this.setState({categoryId:id,sectionId,categoryName:name,sectionName});
	itemsShow = () => {
		const {sectionId,categoryId,sectionLink,categories} = this.state;
		const cats = categories.filter(f => f.sectionId === sectionId);
		if (categoryId === 0 && cats.length > 0) {
			return <>
				<div className={styles.categories}>
					<div className={cx(styles.catitem,styles.back)} onClick={() => this.categorySelect(0, 0, null, null)}><b>Назад</b></div>
					{cats.map((v,i) => <div key={i} className={cx(styles.catitem,styles.cat)} onClick={() => this.categorySelect(v.categoryId, sectionId, v.categoryName, v.sectionName)}>
						<img src={require(`../../../Images/icons/sections/${sectionLink}.svg`)} alt={this.state.sectionName} />
						<div>{v.categoryName}</div>
					</div>)}
				</div>
				{this.productsShow(0, sectionId, false)}
			</>
		}
		return this.productsShow(categoryId, sectionId, true);
	}
	productsShow = (categoryId, sectionId, isback) => {
		const data = this.state.products.filter(f => f.sectionId === sectionId && f.categoryId === categoryId);
		return (
			<div className={styles.products}>
				{isback ? <div className={styles.productitem} onClick={() => categoryId === 0 ? this.sectionSelect(0, null, null, null) : this.categorySelect(0, sectionId, null, this.state.sectionName)}><b>Назад</b></div> : null}
				{data.map((v,i) => <div key={i} className={cx(styles.productitem,styles[`cat${v.categoryId}`])} onClick={() => this.productSelect(v)}>
						{v.name}
						<span><b>{moneyFormat(v.priceDiscount ? v.priceDiscount : v.price, false)} ₽</b> / {v.measure} {measureTypeName[v.measureType]}</span>
					</div>)}
			</div>
		);
	}
	productSelect = (v) => {
		if ((!this.state.isWasPaid) || [orderStatus.USER_ADDED,orderStatus.OPERATOR_WAIT].includes(this.state.data.status)) {
			this.setState({orderitems:[...this.state.orderitems,v]}, () => this.handleQuantity({target:{value:1}}, v.id));
		} else {
			alertShow('Невозможно изменить состав заказа! Заказ был оплачен.', true);
		}
	}
	productRemove = (idx) => {
		if ((!this.state.isWasPaid) || [orderStatus.USER_ADDED,orderStatus.OPERATOR_WAIT].includes(this.state.data.status)) {
			const orderitems = [];
			this.state.orderitems.forEach((v, i) => {
				if (i !== idx) orderitems.push(v);
			});
			this.setState({orderitems}, () => this.priceCalc());
		} else {
			alertShow('Невозможно изменить состав заказа! Заказ был оплачен.', true);
		}
	}
	priceCalc = async () => {
		const {orderitems,discounts,promocodes,rank,client} = this.state;
		const price = orderitems.reduce((a, b) => a + (b.priceDiscount||b.price) * (b.quantity || 1), 0);
		const discountGet = (v, price) => {
			if (v.discount) return v.discount;
			else if (v.discountPercent) return Math.ceil((price * v.discountPercent) / 100);
			return 0;
		};
		const priceDiscountGet = (v, price) => {
			if (v.discount) return price - v.discount;
			else if (v.discountPercent) return price - discountGet(v, price);
			return 0;
		};
		const timeCheck = (v) => {
			const start = parseInt(v.conditionTimeStart.replace(':', '')), end = parseInt(v.conditionTimeEnd.replace(':', '')),
				time = dateSegmentsGet().hour * 100 + dateSegmentsGet().minutes;
			return time >= start && time < end;
		};
		let discountId = 0, priceDiscount = 0, discountName, discount = 0, prefix = '';
		if (client.discount)  {
			discount = Math.ceil((price * client.discount) / 100);
			priceDiscount = price - discount;
			discountName = `Скидка клиента -${client.discount}%`;
		}
		if (rank) {
			discount = Math.ceil((price * rank.discount) / 100);
			priceDiscount = price - discount;
			discountName = `Скидка по статусу -${rank.discount}%`;
		}
		if (promocodes) {
			if (promocodes.type === undefined || promocodes.type === null) {
				if (promocodes.discount && promocodes.discount < price) {
					discount = promocodes.discount;
					priceDiscount = price - discount;
					prefix = ` -${promocodes.discount} ₽`
				}
				else if (promocodes.discountPercent) {
					discount = Math.ceil((price * promocodes.discountPercent) / 100);
					priceDiscount = price - discount;
					prefix = ` -${promocodes.discountPercent}% (${discount} ₽)`
				}
			} else {
				if (promocodes.type === 1) {
					discount = Math.ceil((price * promocodes.discount) / 100);
					priceDiscount = price - discount;
					prefix = ` -${promocodes.discount}% (${discount} ₽)`
				}
				else if (promocodes.type === 0) {
					discount = promocodes.discount;
					priceDiscount = price - discount;
					prefix = ` -${promocodes.discount} ₽`
				}
			}
			if (discount !== 0) {
				discountName = `промокод ${prefix}`;
				this.setState({price,priceDiscount,discountName,discount});
				return;
			}
		}
		const {manualDiscount,manualDiscountPercent} = this.state;
		if (manualDiscount || manualDiscountPercent) {
			if (manualDiscount && manualDiscount < price) {
				discount = manualDiscount;
				priceDiscount = price - discount;
				prefix = `-${manualDiscount} ₽`
			}
			else if (manualDiscountPercent) {
				discount = Math.ceil((price * manualDiscountPercent) / 100);
				priceDiscount = price - discount;
				prefix = `-${manualDiscountPercent}% (${discount} ₽)`
			}
			if (discount !== 0) discountName = `персональная ${prefix}`;
		}
		else {
			discounts.forEach((v) => {
				let pd = 0, d = 0;
				if (v.conditionTimeStart && v.conditionTimeEnd && (v.conditionAmount !== 0 && price >= v.conditionAmount)) {
					if (timeCheck(v)) {
						pd = priceDiscountGet(v, price);
						d = discountGet(v, price);
					}
				}
				else if (v.conditionAmount !== 0 && price >= v.conditionAmount) {
					pd = priceDiscountGet(v, price);
					d = discountGet(v, price);
				}
				else if (v.conditionTimeStart && v.conditionTimeEnd) {
					if (timeCheck(v)) {
						pd = priceDiscountGet(v, price);
						d = discountGet(v, price);
					}
				}
				if ((priceDiscount === 0 && pd > priceDiscount) || (pd > 0 && pd < priceDiscount)) {
					priceDiscount = pd;
					discount = d;
					discountId = v.id;
					discountName = v.name;
				}
			});
		}
		this.setState({price,priceDiscount,discountId,discountName,discount});
	}
	priceFullGet = () => {
		const {price,priceDiscount,deliveryIsFree,deliveryCost,deliveryCostManual,deliveryFreeAmount,deliveryIsAlwaysPaid,isPickup,rank} = this.state;
		const p = priceDiscount ? priceDiscount : price;
		if (rank && rank.deliveryFree) return p + 0;
		if (isPickup) return p + 0;
		if (!deliveryIsAlwaysPaid && deliveryIsFree) return p + 0;
		if (deliveryCostManual !== undefined) return p + deliveryCostManual;
		return p + (deliveryIsAlwaysPaid ? deliveryCost : (price >= deliveryFreeAmount ? 0 : deliveryCost));
	}
	orderAdd = () => this.setState({isfullscreen:true,isnewcall:true});
	addressAdd = () => this.setState({clientAddress:'',clientAddressId:0,addressfull:null,addressClient:'',room:'',entrance:'',intercom:'',floor:'',status:comonStatus.ACTIVE});
	addressChange = () => this.setState({orderaddstep:3});
	orderChange = () => this.setState({orderaddstep:4});
	orderSearch = () => this.setState({isordersearch:true,isordersearchinfo:false});
	holdShow = () => this.setState({isordersearch:true,isordersearchinfo:false,filterStatus:orderStatus.HOLD}, () => this.search());
	orderSearchHide = () => this.setState({isordersearch:false,isordersearchinfo:false,orderssearch:[],filterSearch:null,filterAreaId:0,filterDateStart:null,filterDateEnd:null,filterStatus:undefined,filterDayShiftCurrent:null,filterDate:null});
	search = () => {
		const {filterSearch,filterAreaId,filterDateStart,filterDateEnd,filterStatus,filterDayShiftCurrent,filterDate,clientPhone} = this.state;
		if ((filterAreaId === 0 && !filterDayShiftCurrent) && empty(filterDateStart) && empty(filterDateEnd) && filterStatus === undefined && !filterDate) {
			if (filterSearch && filterSearch.length < 2) {
				this.setState({orderssearch:[]});
				return;
			}
		}
		const dateStart = filterDateStart ? Math.round(new Date(filterDateStart).getTime() / 1000) : null;
		const dateEnd = filterDateEnd ? Math.round(new Date(filterDateEnd).getTime() / 1000) : null;
		Orders.find({text:filterSearch,dateStart,dateEnd,status:filterStatus,areaId:filterAreaId,dayShift:filterDayShiftCurrent,date:filterDate,clientPhone}, (data) => this.setState({orderssearch:data.reverse(),clientOrders:data.reverse()}));
	}
	orderSearchInfoShow = async (order) => {
		const products = await OrderItems.get(order.id);
		order.orderproducts = products.data;
		this.setState({data:order,isordersearchinfo:true});
	}
	orderAcceptChange = async () => {
		const {data} = this.state;
		const products = await OrderItems.get(data.id);
		data.orderproducts = products.data;
		this.setState({data,isordersearchinfo:true,isordershow:false,isfullscreen:true,isnewcall:true}, () => this.orderEdit(false));
	}
	orderStatusSave = (status) => {
		const {user,data,commentSystem} = this.state, order = {status,commentSystem};
		if (empty(commentSystem)) return;
		Orders.update(data.id, order, () => {
			Events.add(user.id, Texts.order.update, JSON.stringify({status:data.status}), JSON.stringify(order), data.id, eventType.ORDER);
			const statuses = {
				orderId:data.id,
				userId:user.id,
				userName:user.name,
				status
			};
			OrderStatuses.add(statuses, (res) => Events.add(user.id, Texts.order_status.add, null, JSON.stringify(statuses), res.data.id, eventType.ORDER_STATUS));
			if (status === orderStatus.CANCEL) {
				if (this.state.noOperator && (data.areaId !== 0 && data.dayShiftId !== 0))
					OrderPrints.add({areaId:data.areaId,dayShiftId:data.dayShiftId,orderId:data.id,status:comonStatus.ACTIVE});
			}
		});
		this.orderCallClose();
		this.ordersPanelsUpdate();
		this.successMessageShow();
	}
	orderSearchInfoHide = () => this.setState({isordersearchinfo:false,data:null});
	mapShow = async () => {
		const courierroute = await this.courierRoutGet();
		if (courierroute) this.setState({modalshowcouriermap:true,courierroute});
		else alertShow('Местоположение курьера не найдено!', true);
	}
	courierRoutUpdate = async () => {
		const courierroute = await this.courierRoutGet();
		if (courierroute) this.setState({courierroute}, () => this.mapRouteSet());
	}
	courierRoutGet = async () => {
		const {data} = this.state, route = await CourierRoutes.get(data.id);
		return route && route.data && route.data.length ? [route.data[route.data.length-1], {latitude:data.latitude,longitude:data.longitude}] : null;
	}
	onOtherMapLoad = async (ymaps) => {
		this.ymaps = ymaps;

		try {
			const newCouriers = this.state.couriers.map(async (v) => {
				if(!v.online) {
					return { ...v, rideData: { duration: null, distance: null } };
				}

				const points = [];

				points.push({ lat: v.position.lat, lon: v.position.lon });

				v.orders.forEach((order) => {
					if(order.order.status !== 18) {
						points.push({ lat: order.order.latitude, lon: order.order.longitude });
					}
				});

				points.push({ lat: v.area.latitude, lon: v.area.longitude });

				const rideData = await this.checkTime(points);

				return { ...v, rideData };
			});
			await Promise.all(newCouriers).then(async (res) => {
				this.setState({ couriers: res }, () => this.couriersUpdate());
			});
		} catch (err) {
			console.error(err);
		}
	}
	courierStatGet = (orders, isactive) => orders?.filter(f => isactive ? f.status === comonStatus.ACTIVE : f.status === comonStatus.IN_ACTIVE).length;
	onMapLoad = (ymaps) => {
		this.ymaps = ymaps;
		this.mapRouteSet();
	}
	mapRouteSet = (ymaps) => {
		ymaps = ymaps || this.ymaps;
		this.map.geoObjects.removeAll();
		ymaps.ready([]).then(() => {
			const coords = this.state.courierroute.map((v,i) => ({type:'wayPoint',point:[v.latitude,v.longitude]}));
			ymaps.route(coords, {mapStateAutoApply:true,reverseGeocoding:true}).then((route) => this.map.geoObjects.add(route));
		});
	}
	orderEdit = (isorderchange) => this.setState({isorderedit:true,isorderchange:isorderchange,isrepeat:false}, () => this.orderRepeat(false));
	similarity = (s1, s2) => {
		if (s1.length && s2.length) {
			let longer = s1;
			let shorter = s2;
			if (s1.length < s2.length) { longer = s2; shorter = s1; }
			let longerLength = longer.length;
			if (longerLength === 0) { return 1.0; }
			return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength) * 100;
		}
		return 0;
	}
	editDistance = (s1, s2) => {
		s1 = s1.toLowerCase();
		s2 = s2.toLowerCase();
		var costs = new Array();
		for (var i = 0; i <= s1.length; i++) {
			var lastValue = i;
			for (var j = 0; j <= s2.length; j++) {
				if (i == 0) costs[j] = j;
				else {
					if (j > 0) {
						var newValue = costs[j - 1];
						if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
						costs[j - 1] = lastValue;
						lastValue = newValue;
					}
				}
			}
			if (i > 0) costs[s2.length] = lastValue;
		}
		return costs[s2.length];
	}
	checkTime = async (points) => {
		const ymaps = this.ymaps;
		if (!ymaps) {
			console.error('Yandex Maps API не доступен.');
			return;
		}
		if(points.length < 2) {
			console.error('Для расчета времени необходимо не менее 2-ух точек');
			return;
		}
		const route = ymaps.route(points.map((point) => point.lat + ',' + point.lon));
		try {
			const res = await route;
			const distance = res.getHumanLength().replace('&#160;', ' ');
			const duration = res.getHumanTime().replace('&#160;', ' ');
			return { distance, duration };
		} catch (error) {
			console.error('Ошибка при вычислении маршрута:', error);
			throw error;
		}
	}
	parseMeasurementString = (str) => {
		if (!str) return { value: null, unit: null }
		const regex = /(\d+\.\d+|\d+)\s*([a-zA-Zа-яА-Я]+)/;
    	const match = str.match(regex);
    	if (match) {
			const value = parseFloat(match[1]);
			const unit = match[2].toLowerCase();
			if (unit === 'см') return { value, unit: 'см' };
			else if (unit === 'км') return { value, unit: 'км' };
			else if (unit === 'м') return { value, unit: 'м' };
			else return { value, unit: 'неизвестно' };
		}
		return { value: null, unit: null };
	}
	getStatusRide = (courier) => {
		if (courier.orders) {
			const hasActiveOrders = courier.orders.find((order) => order.order.status !== orderStatus.FINISH);
			const laststatus = this.state.courierstatuses.find(f => f.userId === courier.id);
			if (laststatus) {
				let distanceStr = null;
				const distance = this.parseMeasurementString(courier.rideData ? courier.rideData.distance : '');
				if (laststatus.status === courierStatus.ON_BASE) distanceStr = 'Сейчас на складе';
				if (laststatus.status === courierStatus.ON_BACK) {
					distanceStr = 'Едет на склад';
					if (distance.unit === 'см' || distance.unit === 'м') {
						if(distance.value < 200) distanceStr = 'Сейчас на складе (не отметился)';
						else distanceStr = `${distanceStr}. ${distance.value} ${distance.unit === 'см' ? 'м' : distance.unit} от `;
					}
				}
				distanceStr = distanceStr === null ? null : distanceStr.replace('&#160;', ' ');
				if (!courier.orders.length)
					return `Не выполняет заказы${distanceStr ? ` (${distanceStr})` : ''}`;
				if (distanceStr !== null) return distanceStr;
				if (laststatus.status !== courierStatus.UNKNOWN) {
					const time = courier.rideData && courier.rideData.duration ? courier.rideData?.duration?.replace('&#160;', ' ') : null;
					if (hasActiveOrders) return `Есть заказы в работе${time ? `. Вернется через: ~${time}` : ''}`;
					else return `Нет активных заказов${time ? `. Вернется через: ${time}` : ''}`;
				}
			} else console.log('courier status not found', courier.id);

			// !!! временно закомментил подсчет, пока толька инфа из статуса - едет на базу, на базе, на заказе
			/*
			const hasActiveOrders = courier.orders.find((order) => order.order.status !== 18);
			const distance = this.parseMeasurementString(courier.rideData ? courier.rideData.distance : '');
			let distanceStr = '';
			if(distance.unit === 'см' || distance.unit === 'м') {
				if(distance.value < 200) {
					distanceStr = `Сейчас на складе`;
				} else {
					distanceStr = `${distance.value} ${distance.unit === 'см' ? 'м' : distance.unit} от склада`;
				}
			} else {
				distanceStr = `${distance.value} ${distance.unit === 'см' ? 'м' : distance.unit} от склада`;
			}
			if (!courier.orders.length) {
				return `Не выполняет заказы (${distanceStr})`;
			}
			if (courier.rideData) {
				if(hasActiveOrders) {
					return 'Есть заказы в работе. Вернется через: ~' + courier.rideData.duration;
				} else {
					return 'Нет активных заказов. Вернется через: ' + courier.rideData.duration + ` (${distanceStr})`;
				}
			} else return hasActiveOrders ? 'Есть заказы в работе' : 'Нет активных заказов';
			*/
		}
	}
	addressPrepare = (order) => {
		const adr = order.address.split(', '), address = {};
		adr.forEach((v,i) => {
			if (v.indexOf('кв. ') !== -1) address.room = v.replace('кв. ', '');
			if (v.indexOf('подъезд ') !== -1) address.entrance = v.replace('подъезд ', '');
			if (v.indexOf('этаж ') !== -1) address.floor = v.replace('этаж ', '');
			if (v.indexOf('домофон ') !== -1) address.intercom = v.replace('домофон ', '');
		});
		if (adr[0].endsWith(' ')) {
			adr[0] = adr[0].slice(0, -1);
		}
		address.address = `${adr[0]}, ${adr[1]}`;
		address.latitude = order.latitude;
		address.longitude = order.longitude;
		address.id = 0;
		return address;
	}
	orderRepeat = (isrepeat) => {
		const order = this.state.data, clientName = order.clientName, clientComment = order.comment, clientPhone = order.phone, orderproducts = order.orderproducts, productquantity = [];
		const clientContactPhone = order.contactPhone;
		const {deliveryIsAlwaysPaid} = this.state;
		Clients.byVariosGet(order.clientId, clientPhone, (data) => {
			const client = data || {};
			if (data === null && clientPhone === null) {
				alertShow('Заказ был сделан из зала, его нельзя повторить!', true);
				return;
			}
			const orderitems = [];
			orderproducts.forEach((v,i) => {
				const item = orderitems.find(f => f.id === v.id);
				if (item) item.quantity = item.quantity + (v.quantity || 1);
				else {
					v.quantity = v.quantity || 1;
					orderitems.push(v);
				}
			});
			orderitems.forEach((v,i) => {
				productquantity[v.id] = v.quantity;
			});
			let addressfull;
			let isaddressfull = false;
			if (client && order) {
				ClientPromoCodes.getByClientId(order.clientId, (promos) => {
					const newArray = promos.map(item => {
						return { id: item.id, code: item.code, discount: item.discount, type: item.type, status: item.status };
					});
					this.setState({promos: newArray})
				})
				ClientRanks.checkExt(client.id, (res) => {
					if (res) this.setState({rank:res,clientRank:res.name}, () => this.priceCalc());
				});
				this.setState({clientBalance: client.balance})
				const oaddr = this.addressPrepare(order);
				if (client.addresses) {
					client.addresses.sort(function (a, b) { if (a.length >= b.length) { if (a.length > b.length) { return -1; } return 0; } return 1; });
					client.addresses.map((address) => {
						if (this.similarity(address.address, oaddr.address) >= 80 && !isaddressfull) {
							addressfull = address;
							if (address.room === oaddr.room || (!oaddr.room && !address.room)) {
								addressfull = address;
								if (address.floor === oaddr.floor || (!oaddr.floor && !address.floor)) {
									addressfull = address;
									if (address.entrance === oaddr.entrance || (!oaddr.entrance && !address.entrance)) {
										addressfull = address;
										isaddressfull = true;
									}
								}
							}
						}
					});
					client.addresses.sort(function(a, b){ let addrA = a.address.toLowerCase(), addrB = b.address.toLowerCase(); if (addrA >= addrB) { if (addrA > addrB) { return 1; } return 0; } return -1; });
				}
				if (!isaddressfull) {
					addressfull = oaddr;
					this.setState({addressundefined: true});
				}
			}
			if (!addressfull) {
				client.addresses.sort(function (a, b) { if (a.id >= b.id) { if (a.id > b.id) { return 1; } return 0; } return -1; });
				addressfull = data && client.addresses ? (client.addresses[0] ?? this.addressPrepare(order)) : this.addressPrepare(order);
			}
			const d = order.date ? dateSegmentsGet(new Date(order.date*1000)) : null;
			this.setState({isfullscreen:true,isnewcall:true,
				orderaddstep:data ? 3 : 2,
				addresses:data === null ? [] : client.addresses,
				addressfull,
				client,
				clientName,
				clientPhone,
				clientComment,
				orderitems,
				productquantity,
				orderid:this.state.isorderedit||this.state.isorderchange ? order.id : 0,
				deliveryCostManual:order.deliveryCostManual || undefined,
				deliveryIsFree:deliveryIsAlwaysPaid ? 0 : order.deliveryIsFree,
				manualDiscount:0,
				manualDiscountPercent:0,
				promoCode:'',
				commentAddress:order.commentAddress??'',
				contactPhone:order.contactPhone,
				clientContactPhone,
				isPickup:order.isPickup,
				isPaid:order.isPaid,
				isTerminal:order.isTerminal,
				surrender:order.surrender,
				cutlery:order.cutlery,
				date:d ? `${d.year}-${zeroPad(d.month,2)}-${zeroPad(d.day,2)}T${zeroPad(d.hour,2)}:${zeroPad(d.minutes,2)}` : null,
				isrepeat
			}, () => {
				if (addressfull) this.clientAddressSet(addressfull.id, addressfull);
				this.deliveryInfoGet(addressfull.latitude, addressfull.longitude);
				PromoCodes.getByCode(order.promoCode, (data) => {
					let promocodes = data.filter(f => f.code === order.promoCode);
					if (!promocodes.length) {
						ClientPromoCodes.getByCode(order.promoCode, (data) => {
							promocodes = data.filter(f => f.code === order.promoCode)
							if (!promocodes.length) {
							} else {
								promocodes = promocodes[0]||null;
								this.setState({promocodes,promoCode:promocodes?promocodes.code:''}, () => this.priceCalc())
							}
						});
					} else {
						promocodes = promocodes[0]||null;
						this.setState({promocodes,promoCode:promocodes?promocodes.code:''}, () => this.priceCalc())
					}
				});
				this.orderSearchHide();
				this.priceCalc();
			});
			if (!isrepeat)
				this.setState({isWasPaid:this.state.isPaid});
			this.checkBlacklist();
		});
	}
	orderAreaChange = async () => {
		const {data,areas} = this.state;
		if (areas.length > 1) {
			const activearea = await Areas.getByArea(data.latitude,data.longitude);
			this.setState({activearea:activearea.data ? activearea.data : areas[0],modalshoworderareachange:true});
		} else this.setState({activearea:areas[0]}, () => this.orderAreaChangeUpdate(areas[0].id));
	}
	orderAreaChangeUpdate = async (areaId) => {
		const ds = await DayShifts.getCurrent(areaId);
		if (ds === null) {
			alertShow('<b>Невозможно принять заказ!</b><br/>Смена не открыта! Обратитесь к вашему управляющему', true);
			return;
		}
		const {user,data} = this.state, order = {areaId,dayShiftId:ds.id};
		Orders.update(data.id, order, () => {
			Events.add(user.id, Texts.order.update, JSON.stringify({areaId:data.areaId,dayShiftId:data.dayShiftId}), JSON.stringify(order), data.id, eventType.ORDER);
		});
		data.areaId = areaId;
		this.setState({modalshoworderareachange:false,data});
		this.ordersPanelsUpdate();
		this.successMessageShow();
	}
	showAllCouriers = async (showallcouriers) => {
		if (!showallcouriers) this.setState({showallcouriers});
		const {couriers} = this.state;
		const courierstatuses = [];
		for (const c of couriers) {
			if (!c.online) continue;
			const cs = await CourierStatuses.getLastManual(c.id);
			if (cs.data && cs.data.length > 0) 
				courierstatuses.push(cs.data[0]);
		}
		this.setState({courierstatuses,showallcouriers});
		if (showallcouriers) {
			this.setState({loadinginner:true});
			this.couriersUpdate();
		}
		this.setState({loadinginner:false});
	}
	showMessages = (showmessages) => this.setState({showmessages,ismessage:false}, () => this.feedbackMessagesGet());
	courierOrderShow = async (data) => {
		const products = await OrderItems.get(data.id);
		data.orderproducts = products.data;
		this.setState({modalshowcourier:true,data});
	}
	showAllOrders = (showallorders) => this.setState({showallorders});
	onMapLoadCouriers = (ymaps) => {
		this.ymaps = ymaps;
	}
	modalMapHide = () => this.setState({modalshowcouriermap:false});
	modalHide = () => this.setState({modalshoworderarea:false,modalshoworderareachange:false,modalshowprompt:false,modalshowpromptclose:false,modalshowpromptcancel:false,modalshowpromptrefund:false,modalshowcouriermap:false,modalshowcourier:false,modalshowmessages:false,modalshowclientorders:false});
	successMessageShow = () => alertShow(Texts.success.simple);
	handleAddPromo = () => this.setState({ addingPromo: true });
	handlePromoCodeChange = (event) => this.setState({ newPromoCode: event.target.value });
	handleDiscountChange = (event) => this.setState({ discountValue: event.target.value });
	handleDiscountTypeChange = (event) => {
		const value = event.target.value;
		const discountValue = value === 'amount' ? '' : '1'; // clear the input value
		this.setState({ discountType: value, discountValue });
	}
	handleDeletePromo = (promo) => {
		ClientPromoCodes.remove(promo.id)
		alertShow(`Промокод ${promo.code} был удален`)
		this.setState((prevState) => ({
			promos: prevState.promos.filter(filter => filter.id !== promo.id)
		}));
	}
	handleSelectPromo = (promo) => {
		let promocodes;
		let promoCodeDiscount = 0
		let promoCodeDiscountPercent = 0
		if (promo.type === 1) {
			promoCodeDiscount = 0
			promoCodeDiscountPercent = promo.discount
		} else {
			promoCodeDiscount = promo.discount
			promoCodeDiscountPercent = 0
		}
		ClientPromoCodes.getByCode(this.state.promoCode, (data) => {
			if (data[0] && data[0].status === 0) {
				alertShow('К этому заказу был применен промокод клиентом, его нельзя изменить', true)
			} else {
				this.setState({promoCode:promo.code, promoCodeName:promo.code, promoCodeDiscount, promoCodeDiscountPercent}, () => {
					ClientPromoCodes.getByCode(promo.code, (data) => {
						promocodes = data.filter(f => f.code === promo.code)
						promocodes = promocodes[0];
						promocodes = {...promocodes, discount: promoCodeDiscount, discountPercent: promoCodeDiscountPercent}
						const priceDiscount = this.state.price - (promo.type === 1 ? Math.ceil((this.state.price * promocodes.discountPercent) / 100) : promoCodeDiscount)
						const priceFull = (priceDiscount + (
							this.state.isPickup ? 0 : 
								(this.state.deliveryCostManual !== undefined ? this.state.deliveryCostManual : (this.state.price >= this.state.deliveryFreeAmount ? 0 : this.state.deliveryCost))));
						this.setState({promocodes,promoCode:promocodes?promocodes.code:''}, () => {
							this.priceCalc()
							//Orders.update(this.state.orderid, {promoCode: promo.code, promoCodeDiscount, promoCodeDiscountPercent, priceDiscount, priceFull});
						})
						alertShow(`Промокод ${promo.code} применен к текущему заказу`)
					});
				});
			}
		})
	}
	handleSavePromo = () => {
		const { newPromoCode, discountValue, discountType } = this.state;
		if (typeof discountType !== 'string' || discountType === '' || !newPromoCode.trim()) {
			alertShow('Ошибка: Вы не ввели промокод', true);
			return;
		}
		if (typeof discountType !== 'string' || (discountType !== 'percent' && discountType !== 'amount')) {
			alertShow('Ошибка: Неверное значение поля "Тип скидки"', true);
			return;
		}
		let minValue = 0;
		let maxValue = 0;
		if (discountType === 'percent') {
			minValue = 1;
			maxValue = 50;
		}
		else { minValue = 10; maxValue = 1000; }
		if (isNaN(parseFloat(discountValue)) || !isFinite(discountValue) || discountValue < minValue || discountValue > maxValue) {
			alertShow('Ошибка: Неверное значение поля "Размер скидки" (1-50% / 10-1000₽)', true);
			return;
		}
		const discount = discountType === 'percent' ? parseFloat(discountValue) : parseFloat(discountValue);
		if (this.state.promos.some(promo => promo.code === newPromoCode)) {
			alertShow('Ошибка: Промокод уже существует', true)
			return;
		}
		if (this.state.client.id) {
			console.log({
				code: newPromoCode,
				clientId: this.state.client.id,
				discount: discountValue,
				type: discountType === 'percent' ? 1 : 0
			});
			ClientPromoCodes.add({
				code: newPromoCode,
				clientId: this.state.client.id,
				discount: discountValue,
				status: 1,
				type: discountType === 'percent' ? 1 : 0
			}).then(res => {
				this.setState((prevState) => ({
					promos: [...prevState.promos, {
						id: res.id,
						code: newPromoCode,
						discount,
						type: discountType === 'percent' ? 1 : 0
					}],
					newPromoCode: '',
					discountValue: '',
					addingPromo: false
				}));
				alertShow('Промокод был сохранен', false)
			}).catch(error => {
				alertShow('Ошибка при сохранении', true)
			});
		} else {
			alertShow('Не выбран клиент', true)
		}
	}
	generatePromoCode = () => {
		let promoCode = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const codeLength = 8;
		for (let i = 0; i < codeLength; i++) {
		  	promoCode += characters.charAt(Math.floor(Math.random() * characters.length));
		}
		return promoCode;
	}
	dialogSet = (clientId) => {
		const dialog = this.state.messages.filter(f => f.clientId === clientId);
		this.setState({dialog});
	}
	dialogClose = () => this.setState({dialog:null,message:null});
	handleMesssageForm = (e) => this.setState({message:e.target.value});
	messageSave = () => {
		const {message,user,dialog} = this.state;
		const f = dialog.find(f => !f.isAdmin)
		if (f === null) {
			this.dialogClose();
			return;
		}
		const data = {
			message,
			clientId:f.clientId,
			contact:user.phone,
			name:user.name,
			isAdmin:true
		};
		Feedbacks.add(data, (res) => {
			data.id = res.data.id;
			data.dateCreate = dateNow();
			Events.add(user.id, Texts.feedback.add, null, JSON.stringify(data), data.id, eventType.FEEDBACK);
			this.dialogClose();
		});
	}
	render() {
		if (this.state.loading) return <div className={styles.main}><Loading className={styles.loading} /></div>;
		if (this.state.iserror) return <div className={styles.main}><Error refresh={this.dataGet} /></div>;
		const { promos, addingPromo, newPromoCode, discountValue, discountType } = this.state;
		if (this.state.showmessages) {
			if (this.state.dialog) {
				return <div className={styles.container}>
					<div className={styles.mainfull}>
						<div className={styles.header}>
							<h4>Просмотр обращения</h4>
							<Close close={() => this.dialogClose()} />
						</div>
						<div className={styles.dialogcontainer}>
							<div className={styles.inner}>
								{this.state.dialog.map((v,i) => <div key={i} className={styles.row}>
									<div className={styles.messageinfo}>
										<div className={styles.date}>{dateGet(v.dateCreate, {showTime:true})}</div>
										<div className={styles.name}>{v.isAdmin ? <b style={{color:'green'}}>Поддержка</b> : <><b>{v.name}</b><div>{phoneFormatter(v.contact)}</div></>}</div>
									</div>
									<div className={styles.message}>{v.message}</div>
								</div>)}
							</div>
							<div className={styles.messagecontrol}>
								<textarea name="message" placeholder="Напишите сообщение" onChange={this.handleMesssageForm}></textarea>
								<div className={styles.buttons}>
									<button onClick={() => this.messageSave()}>Отправить</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
			return <div className={styles.container}>
				<div className={styles.mainfull}>
					<div className={styles.header}>
						<h4>Сообщения</h4>
						<Close close={() => this.showMessages(false)} />
					</div>
					<div className={cx(styles.inner,styles.messagescontainer)}>
						{this.state.messages.map((v,i) => <div key={i} className={styles.row} onClick={() => this.dialogSet(v.clientId)}>
							<div className={styles.messageinfo}>
								<div className={styles.date}>{dateGet(v.dateCreate, {showTime:true})}</div>
								<div className={styles.name}>{v.isAdmin ? <b style={{color:'green'}}>Поддержка</b> : <><b>{v.name}</b><div>{phoneFormatter(v.contact)}</div></>}</div>
							</div>
							<div className={styles.message}>{v.message}</div>
						</div>)}
					</div>
				</div>
			</div>
		}
		if (this.state.showallcouriers) {
			return <div className={styles.container}>
				{this.state.loadinginner ? <div className={styles.main}><Loading className={styles.loading} /></div> :
					<div className={styles.mainfull}>
						<div className={styles.header}>
							<h4>Курьеры</h4>
							<Close close={() => this.showAllCouriers(false)} />
						</div>
						<div className={styles.inner}>
							{this.state.couriers.map((v,i) => <div key={i} className={styles.row}>
								<div className={styles.name}>
									<b>{v.name}</b>{v.online?<span className={styles.statusOnline}>Онлайн</span>:<span className={styles.statusOffline}>Оффлайн</span>}
									{phoneFormatter(v.phone)}
									<div className={cx(styles.stat,styles.statlast)} style={{width:'200px'}}>{this.getStatusRide(v)}</div>
								</div>
								<div className={styles.orderslist}>
									{v.orders.filter(f => f.status === comonStatus.ACTIVE).map((o,j) => <div key={j} className={styles.orderblock} onClick={() => this.courierOrderShow(o.order)}>
										Заказ №{o.orderId}
										<span>{o.order.address}</span>
									</div>)}
								</div>
								<div className={styles.mainfull}>
									<div className={styles.mapContainer}>
										<YMaps query={{ apikey: MAP.keyYandex }}>
											<Map
												instanceRef={(map) => this.map = map}
												onLoad={(ymaps) => this.onOtherMapLoad(ymaps)}
												modules={['route']}
												state={{ center: MAP.defaultCoordinates, zoom: 12 }}
												width={'1px'} height={'1px'}
											>
												<ZoomControl />
											</Map>
										</YMaps>
									</div>
								</div>
							</div>)}
						</div>
					</div>
				}
				{this.state.modalshowcourier && <Modal visible={this.state.modalshowcourier} width={'900'} height={'680'} effect={'fadeInUp'}>
					<div className={styles.modalcontainermodal}>
						<div className={styles.header}>
							<h4>Состав заказа №{this.state.data.id}<span className={cx(styles.status,this.state.data.status===orderStatus.COURIER_DONE?styles.statusdone:styles.statuswork)}>{orderStatusName[this.state.data.status]}</span></h4>
							<span>{dateGet(this.state.data.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
							{this.state.data.type === orderType.OPERATOR_HALL || this.state.data.isPickup || this.state.data.status === orderStatus.FINISH ? null :
								<div className={styles.mapbutton} onClick={() => this.mapShow()}>
									<img src={require('../../../Images/icons/map.svg')} alt="Карта" />
									Курьер на карте
								</div>}
						</div>
						<div className={styles.productslist}>
							<OrderInfo data={this.state.data} orderproducts={this.state.data.orderproducts} areas={this.state.areas} />
						</div>
					</div>
					<Close close={this.modalHide.bind(this)} />
				</Modal>}
				{this.state.modalshowcouriermap && <Modal visible={this.state.modalshowcouriermap} width={'900'} height={'700'} effect={'fadeInUp'}>
					<div className={styles.modalcontainer}>
						<div className={styles.header}>
							<h4>Маршрут</h4>
						</div>
						<div className={styles.mapcontainer}>
							<div className={styles.map}>
								<YMaps query={{apikey:MAP.keyYandex}}>
									<Map
										instanceRef={(map) => this.map = map}
										onLoad={(ymaps) => this.onMapLoad(ymaps)}
										modules={['route']}
										state={{center:MAP.defaultCoordinates,zoom:16}}
										width={'auto'} height={'100%'}>
											<ZoomControl />
										</Map>
								</YMaps>
							</div>
							<div className={styles.orderaddress}>
								{this.state.data.address}
								<img src={require('../../../Images/icons/reload.svg')} className={styles.update} alt="Обновить" onClick={() => this.courierRoutUpdate()} />
							</div>
						</div>
					</div>
					<Close close={this.modalMapHide.bind(this)} />
				</Modal>}
			</div>
		}
		if (this.state.showallorders) {
			return <div className={styles.container}>
				<div className={styles.mainfull}>
					<div className={styles.header}>
						<h4>Все заказы</h4>
						<Close close={() => this.showAllOrders(false)} />
					</div>
					<div className={styles.inner}>
						{this.state.ordersall.map((v,i) => <div key={i} className={styles.row}>
							<div className={styles.numdate}>
								<span>{i+1}. <b>№{v.id}</b></span>
								{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}
							</div>
							<div className={styles.status}>
								{v.status === orderStatus.WAITING_USER_ADDED || v.status === orderStatus.WAITING_USER_PAID ? <div className={styles.statusWait}>{orderStatusName[v.status]}</div> : orderStatusName[v.status]}
								{v.status === orderStatus.FINISH ? <div>{dateGet(v.dateFinish, {yearHide:true,showMonthFullName:true,showTime:true})}</div> : null}
							</div>
							<div className={styles.couriername}>
								{v.isPickup ?
									<b>Самовывоз</b>
								:
									<>
										<b>{v.courierName}</b>
										{phoneFormatter(v.courierPhone)}
									</>
								}
							</div>
							<button className={styles.infobutton} onClick={() => this.courierOrderShow(v)}>Подробности</button>
						</div>)}
					</div>
				</div>
				{this.state.modalshowcourier && <Modal visible={this.state.modalshowcourier} width={'900'} height={'680'} effect={'fadeInUp'}>
					<div className={styles.modalcontainermodal}>
						<div className={styles.header}>
							<h4>Состав заказа №{this.state.data.id}<span className={cx(styles.status,this.state.data.status===orderStatus.COURIER_DONE?styles.statusdone:styles.statuswork)}>{orderStatusName[this.state.data.status]}</span></h4>
							<span>{dateGet(this.state.data.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
						</div>
						<div className={styles.productslist}>
							<OrderInfo data={this.state.data} orderproducts={this.state.data.orderproducts} areas={this.state.areas} />
						</div>
					</div>
					<Close close={this.modalHide.bind(this)} />
				</Modal>}
			</div>
		}
		return <div className={styles.container}>
		<Domru searchClientSelectDomru={this.searchClientSelectDomru} clientAddDomru={this.clientAddDomru} />
			<div className={styles.main}>
				{this.state.isfullscreen ?
					(this.state.isnewcall ?
						<>
							<div className={styles.ordercallcontainer}>
								<div className={styles.header}>
									<h4>{this.stepNameGet(this.state.orderaddstep)}</h4>
									<Close close={this.orderClose.bind(this)} />
								</div>
								<div className={cx(styles.orderbody,this.state.orderaddstep===1?styles.orderbodyclient:null)}>
									{this.state.orderaddstep === 1 && <div className={cx(styles.clientcontainer,styles.formcontainer)}>
											<div className={styles.phoneblock}>
												<input type="text" placeholder="Номер телефона клиента" name="clientPhone" value={this.state.clientPhone} onChange={this.handleForm} autoFocus={true} autoComplete="off" />
												<button className={styles.button} style={{marginLeft: 10}} onClick={() => this.handleClient()}>
													<img style={{filter: "invert(1)"}} src={require('../../../Images/icons/statuses/user_search.svg')} alt="" />
													Поиск
												</button>
												<button className={styles.button} style={{marginLeft: 10}} onClick={() => this.clientAdd()}>
													<img src={require('../../../Images/icons/add_white.svg')} alt="" />
													Новый клиент
												</button>
											</div>
											{this.state.showclientsearch &&
												(this.state.clients === null ? null :
													(this.state.clients.length === 0 ?
														<NotFound message={<>
															<h4>Клиент не найден!</h4>
															<p>Смягчите условия поиска или добавьте нового клиента</p>
														</>} />
														:
														<div className={styles.addresseslist}>
															{this.state.clients.map((v, i) => <div key={i} className={styles.container}>
																<div>
																	<b>{phoneFormatter(v.phone)}</b>
																	<span>{v.name ? `${v.name}, `: ''}{this.clientCurrentAddressGet(v.addresses)}</span>
																</div>
																<div className={styles.button} onClick={() => this.searchClientSelect(v)}>Выбрать</div>
															</div>)}
														</div>
													)
												)
											}
										</div>}
									{this.state.orderaddstep === 2 && <div className={cx(styles.clientcontainer,styles.formcontainer)}>
											<div className={styles.block}>
												<div className={styles.cell}>
													<label>
														<span>Телефон</span>
														<InputMask type="text" name="clientPhone" value={phoneNormalize(this.state.clientPhone)} mask="+7 (999) 999-99-99" maskChar={null} placeholder="Телефон" autoFocus={true} onChange={this.handleForm} />
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Имя</span>
														<input type="text" name="clientName" placeholder="Имя" onChange={this.handleForm} />
													</label>
												</div>
											</div>
										</div>}
									{this.state.orderaddstep === 3 && <div className={cx(styles.addresscontainer,styles.formcontainer)}>
											<div className={styles.addressblock}>
												<input type="text" placeholder="Введите адрес доставки" value={this.state.addressClient} onChange={this.handleStreetAddress} autoFocus={true} autoComplete="off" />
												<button className={styles.button} onClick={() => this.handleStreet()}>
													<img src={require('../../../Images/icons/search_white.svg')} alt="" />
													Найти
												</button>
											</div>
											{this.state.showaddresssearch ?
													<div className={styles.addresseslist}>
														{this.state.addresses.map((v, i) => <div key={i} className={styles.container}>
																<div>
																	<b>{v.name}</b>
																	<span>{v.description}</span>
																</div>
																<div className={styles.button} onClick={() => this.searchAddressSelect(v)}>Выбрать</div>
															</div>)}
													</div>
												: null}
											{this.state.showaddresssearch ? null :
												<div className={styles.inputs}>
													<div className={styles.row}>
														<label>
															Комната/Офис
															<input type="text" name="room" placeholder="Комната/Офис" value={this.state.room} onChange={this.handleForm} required />
														</label>
													</div>
													<div className={styles.row}>
														<label>
															Подъезд
															<input type="text" name="entrance" placeholder="Подъезд" value={this.state.entrance} onChange={this.handleForm} />
														</label>
													</div>
													<div className={styles.row}>
														<label>
															Этаж
															<input type="text" name="floor" placeholder="Этаж" value={this.state.floor} onChange={this.handleForm} />
														</label>
													</div>
													<div className={styles.row}>
														<label>
															Домофон
															<input type="text" name="intercom" placeholder="Домофон" value={this.state.intercom} onChange={this.handleForm} />
														</label>
													</div>
												</div>}
												<div className={cx(styles.inputs,styles.inputswide)}>
													<div className={styles.row}>
														<label>
															Самовывоз
															<input type="checkbox" name="isPickup" defaultChecked={this.state.isPickup} onChange={this.handlePickupForm} />
														</label>
													</div>
													<div className={styles.row}>
														<label>
															Дополнительный телефон для связи
															<InputMask type="text" name="clientContactPhone" mask="+7 (999) 999-99-99" maskChar={null} value={this.state.clientContactPhone} placeholder="Телефон" onChange={this.handleForm} />
														</label>
													</div>
												</div>
												<div className={cx(styles.inputs,styles.inputswide,styles.inputswidecomment)}>
													<div className={styles.row}>
														<label>
															Комментарий к адресу в текущем заказе
															<input type="text" name="commentAddress" value={this.state.commentAddress} placeholder="Комментарий к адресу" onChange={this.handleForm} />
														</label>
													</div>
												</div>
										</div>}
									{this.state.orderaddstep === 4 && <>
											{this.state.sectionId === 0 && this.state.categoryId === 0 ?
												<div className={styles.sections}>
													{this.state.sections.map((v,i) => <div key={i} className={cx(styles.section,styles[`${v.link}`])} onClick={() => this.sectionSelect(v.id, v.name, v.link)}>
															<img src={require(`../../../Images/icons/sections/${v.link}.svg`)} alt={v.name} />
															<div>{v.name}</div>
														</div>)}
												</div>
											:
												this.itemsShow()}
										</>}
									{this.state.orderaddstep === 5 && <div className={cx(styles.orderadditionals,styles.formcontainer)}>
											<div className={styles.block}>
												<div className={styles.cell}>
													<label>
														<span>Персональная скидка, %</span>
														<input type="text" name="manualDiscountPercent" maxLength={2} value={this.state.manualDiscountPercent} onChange={(e) => this.handleDiscountForm(e, 50)} />
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Персональная скидка, ₽</span>
														<input type="text" name="manualDiscount" maxLength={4} value={this.state.manualDiscount} onChange={(e) => this.handleDiscountForm(e, 1000)} />
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Промокод</span>
														<input type="text" name="promoCode" maxLength={10} value={this.state.promoCode} onChange={this.handlePromoCodeForm} />
													</label>
												</div>
											</div>
											<div className={styles.commentblock}>
												<div className={styles.cell}>
													<label>
														<span>Комментарий клиента</span>
														<input type="text" name="clientComment" value={this.state.clientComment} placeholder="Комментарий клиента" onChange={this.handleForm} />
													</label>
												</div>
											</div>
											<div className={styles.block}>
												{!this.state.isWasPaid?<div className={styles.cell}>
													<label>
														<span>Цена доставки</span>
														<input type="text" name="deliveryCostManual" maxLength={3} value={this.state.deliveryCostManual} disabled={this.state.isWasPaid} onChange={this.handleDeliveryForm} />
													</label>
												</div>
												:null}
												{!this.state.isWasPaid?<div className={styles.cell}>
													<label>
														<span>Сдача с, ₽</span>
														<input type="text" name="surrender" maxLength={5} value={this.state.surrender} disabled={this.state.isWasPaid} onChange={(e) => this.handleDiscountForm(e, 50000, true)} onBlur={this.handleSurrenderForm} />
													</label>
												</div>
												:null}
												<div className={styles.cell}>
													<label>
														<span>Количество приборов</span>
														<input type="text" name="cutlery" maxLength={2} value={this.state.cutlery || 0} onChange={(e) => this.handleDiscountForm(e, 99, true)} />
													</label>
												</div>
											</div>
											<div className={cx(styles.block,styles.blockdate)}>
												<div className={styles.cell}>
													<label>
														<span>Заказ на дату и время</span>
														<div>
															<input type="datetime-local" name="date" value={this.state.date || ''} onChange={this.handleDateForm} />
															<img src={require('../../../Images/icons/close_black.svg')} alt='Сбросить' onClick={() => this.dateClear()} />
														</div>
													</label>
												</div>
												{!this.state.isWasPaid?<div className={styles.cell}>
													<label>
														<span>Заказ оплачен</span>
														<input type="checkbox" name="isPaid" defaultChecked={this.state.isPaid} onChange={this.handlePaidForm} />
													</label>
												</div>
												:null}
												{!this.state.isWasPaid?<div className={styles.cell}>
													<label>
														<span>Нужен терминал</span>
														<input type="checkbox" name="isTerminal" defaultChecked={this.state.isTerminal} disabled={this.state.isWasPaid} onChange={this.handleTerminalForm} />
													</label>
												</div>
												:null}
											</div>
										</div>}
								</div>
								{this.state.orderaddstep !== 1 ?
									<div className={styles.controlpanel}>
										<button className={cx(styles.button,
											this.state.orderaddstep === 2 && phoneNormalize(this.state.clientPhone) === null ? styles.disabled :
												(this.state.orderaddstep === 4 && this.state.orderitems.length === 0 ? styles.disabled : null)
											)} onClick={() => this.orderNext()}>
											{this.state.orderaddstep === 5 ?
													<>
														<img src={require('../../../Images/icons/mark.svg')} alt="Создать заказ" />
														{!this.state.isorderchange && !this.state.isorderedit ? 'Создать заказ' : 'Сохранить'}
													</>
												:
													<>
														Далее
														<img src={require('../../../Images/icons/next.svg')} className={styles.right} alt="Далее" />
													</>
											}
											</button>
											<div>
												{this.state.orderaddstep === 4 || this.state.orderaddstep === 5 ?
													<button className={cx(styles.button,styles.secondary)} onClick={() => this.addressChange()}>
														<img src={require('../../../Images/icons/marker_black.svg')} alt="Изменить адрес" />
														Изменить адрес
													</button>
												: null}
												{this.state.orderaddstep === 5 ?
													<button className={cx(styles.button,styles.secondary)} onClick={() => this.orderChange()}>
														<img src={require('../../../Images/icons/order.svg')} alt="Изменить заказ" />
														Изменить заказ
													</button>
												: null}
											</div>
									</div> : null}
							</div>
							<div className={styles.column}>
								{this.state.orderaddstep === 1 || this.state.orderaddstep === 2 ?
									<>
										<div className={cx(styles.header,styles.info)}>
											Информация о клиенте
											<img src={require('../../../Images/icons/statuses/user_search.svg')} alt="" />
										</div>
										<div className={cx(styles.container,styles.containerinfo)}>
											<div className={cx(styles.item,styles.itemwide,styles.clientinfo)}>
												<div className={styles.cell}>
													<label>
														<span>Клиент</span>
														<div>—</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Телефон</span>
														<div>—</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Адрес доставки</span>
														<div>—</div>
													</label>
												</div>
											</div>
										</div>
									</>
								: null}
								{this.state.orderaddstep === 3 ?
									<>
										<div className={cx(styles.header,styles.info)}>
											Информация о клиенте
											<img src={require('../../../Images/icons/statuses/user.svg')} alt="" />
										</div>
										<div className={cx(styles.container,styles.containerinfo)}>
											<div className={cx(styles.item,styles.itemwide,styles.clientinfo)}>
												<div className={styles.cell}>
													<label>
														<span>Клиент</span>
														<div>{!this.state.changecname ?
															this.state.client ? (this.state.client.name || '—') : ''
															:
															<input type='text' onChange={this.onChangeClientName} placeholder='Введите имя клиента' className={styles.input} value={this.state.client ? (this.state.client.name || '') : ''} />
														}</div>
														<div>
															{this.state.changecname ?
																	<a onClick={this.saveClientName}>Сохранить</a>
																:
																	<a onClick={this.changeClientName}>Изменить имя клиента</a>
															}
														</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Телефон</span>
														<div>{!this.state.changecphone ?
															this.state.clientPhone ? phoneFormatter(this.state.clientPhone) : '—'
															:
															<input type='text' onChange={this.onChangeClientPhone} placeholder='Введите номер телефона' className={styles.input} value={this.state.clientPhone ? phoneFormatter(this.state.clientPhone) : ''} />
														}</div>
														{this.state.changecphone ?
															<a onClick={this.saveClientPhone}>Сохранить</a>
															:
															<a onClick={this.changeClientPhone}>Изменить номер телефона</a>
														}
													</label>
												</div>
												{this.state.client && this.state.client.addresses.length ?
													<div className={styles.cell}>
														<label>
															<span>Комментарий клиента</span>
															<div>{this.state.clientComment || '—'}</div>
														</label>
													</div>
													: null}
												<div className={styles.cell}>
													<label>
														<span>Адрес доставки</span>
														{this.state.client && this.state.client.addresses.length ?
															<select onChange={this.handleAddressSelect} value={this.state.clientAddressId}>
																{this.state.addressundefined?<option value="-1">Адрес не сохранен</option>:null}
																<option>Новый адрес</option>
																{this.state.client.addresses.sort((a,b) => a.address - b.address).map((v,i) => v.status === 1 ? <option key={i} value={v.id}>{v.address}{v.room?(', кв. '+v.room):''}</option>:null)}
															</select> : <div>—</div>}
													</label>
												</div>
												{this.state.client && this.state.client.addresses.length ?
													<div className={styles.cell}>
														<label>
															<span>Комментарий к адресу</span>
															<div>{this.state.commentAddress || '—'}</div>
														</label>
													</div>
													: null}
											</div>
											{this.state.fortunewheelwins && this.state.fortunewheelwins.length ?
												<div className={cx(styles.item,styles.itemwide,styles.clientinfo)}>
													<div className={styles.cell}>
														<label>
															<span>Выйгрыши в колесе фортуны</span>
															<div>
																{this.state.fortunewheelwins.map((v,i) => <div key={i}>{dateGet(v.dateCreate,{yearHide:true})} – {v.discount ? `скидка ${v.discount}%`:''}{v.freeDelivery?'бесплатная доставка':''}{v.productName||''} – до {dateGet(v.dateTill,{yearHide:true})} – {v.isUsed?'использован':<b>активен</b>}</div>)}
															</div>
														</label>
													</div>
												</div> : null}
											<div className={cx(styles.item,styles.itemwide,styles.clientinfo)}>
												{this.state.clientRank ?
													<div className={styles.cell}>
														<label>
															<span>Статус клиента</span>
															<div>{this.state.clientRank}</div>
														</label>
													</div> : null}
												{this.state.client && this.state.client.discount ?
													<div className={styles.cell}>
														<label>
															<span>Постоянная скидка</span>
															<div>{this.state.client.discount}%</div>
														</label>
													</div> : null}
												<div className={styles.cell}>
													<label>
														<span>Бонусы клиента</span>
														<div>{this.state.clientBalance}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Доступ</span>
														<div>{this.state.clientinblacklist ? <p style={{color: '#e74c3c'}}>В чeрном списке</p> : <p style={{color: '#27ae60'}}>Активен</p>}</div>
													</label>
												</div>
												{this.state.clientinblacklist ?
													<div className={styles.cell}>
														<label>
															<span>Комментарий</span>
															<div>{this.state.clientblacklists.description || '-'}</div>
														</label>
													</div>
												: null}
													<div className={styles.cell}>
														<label>
															<span><b>Действия</b></span>
															<div>
																{this.state.clientinblacklist && !this.state.clientaddblacklist ?
																	<button onClick={() => this.updateBlacklist()}>Убрать из чeрного списка</button>
																	: !this.state.clientinblacklist && this.state.clientaddblacklist ?
																		<>
																			<span>Напишите причину блокировки</span>
																			<input className={styles.inputs} type='text' name='clientblacklistdesc' placeholder='Причина блокировки...' onChange={this.handleForm} value={this.state.clientblacklistdesc} />
																			<button onClick={this.addBlacklist}>Подтвердить</button>
																		</>
																		: !this.state.clientinblacklist && !this.state.clientaddblacklist ?
																		<button onClick={this.addBlacklist}>Добавить в чeрный список</button>
																			: <button onClick={this.addBlacklist}>Убрать из чeрного списка</button>
																}
															</div>
														</label>
														<label>
															<div>
																<button onClick={this.showClientOrdersForm}>Последние заказы клиента</button>
															</div>
														</label>
													</div>
											</div>
											<div className={cx(styles.item,styles.itemwide,styles.clientinfo)}>
												<div className={styles.promoSection}>
  													<label>
    													<span>Промокоды клиента</span>
														<div className={styles.promoborder}></div>
  													</label>
  													<div>
													  <ul>
														{promos.length ? promos.map((promo, index) => (
															<li key={index}>
															<span className={styles.discountCode} style={promo.status === 0 ? {textDecoration: "line-through"} : {}}>{promo.code}</span> (
																{promo.type === 0 ? (
																<>сумма <span className={styles.discountValue}>{promo.discount}₽</span></>
																) : (
																<>процент <span className={styles.discountValue}>{promo.discount}%</span></>
																)}
																{/* {promo.status === 0 ? <span> - использован</span> : <span> - активен</span>} */}
																{/* {console.log(this.state.promoCode)} */}
																{promo.code === this.state.promoCode && (<span> - применен</span>)}
															)
															<div className={styles.promoButtons}>
																<button className={styles.promoDelete} onClick={() => this.handleDeletePromo(promo)}>Удалить</button>
																<button className={classNames(styles.promoApply, !promo.status && (styles.notactive))} onClick={() => promo.status === 1 ? this.handleSelectPromo(promo) : alertShow('Данный промокод уже был использован клиентом', 1)}>Применить</button>
															</div>
															</li>
														)) : <li>Нет промокодов</li>}
														</ul>
														{addingPromo ? (
															<div className={styles.promoAdd}>
																<label htmlFor="promoCode">Промокод:</label>
																<input type="text" id="promoCode" value={newPromoCode} onChange={this.handlePromoCodeChange} />
																<label htmlFor="discountType">Тип скидки:</label>
																<select id="discountType" value={discountType} onChange={this.handleDiscountTypeChange}>
																	<option value="amount">Фиксированная сумма</option>
																	<option value="percent">Процент от суммы заказа</option>
																</select>
																<label htmlFor="discountValue">Размер скидки:</label>
																{discountType === "percent" ? (
																	<div className={styles.sliderContainer}>
																		<input
																			type="range"
																			min="1"
																			max="100"
																			value={discountValue}
																			onChange={this.handleDiscountChange}
																			className={styles.slider}
																		/>
																		<span className={styles.discountValue}>{discountValue}%</span>
																	</div>
																) : (
																	<input className={styles.discountInput} type="number" id="discountValue" value={discountValue} onChange={this.handleDiscountChange} />
																)}
																<button onClick={() => this.setState({newPromoCode:this.generatePromoCode()})}>Сгенерировать</button>
																<button onClick={this.handleSavePromo}>Сохранить</button>
															</div>
														) : (
															<button className={styles.handleAddPromo} onClick={this.handleAddPromo}>Добавить новый промокод</button>
														)}
													</div>
												</div>
											</div>
										</div>
									</>
								: null}
								{this.state.orderaddstep === 4 || this.state.orderaddstep === 5 ?
									<>
										<div className={cx(styles.header,styles.info,styles.order)}>
											Позиции заказа{this.state.orderitems && this.state.orderitems.length > 0 ? ` (${this.state.orderitems.length})` : null}
											<img src={require('../../../Images/icons/statuses/order.svg')} alt="" />
										</div>
										<div className={cx(styles.item,styles.itemwide,styles.productinfo)}>
											{this.state.orderitems.length ?
												<>
													<div className={styles.orderlist}>
														{this.state.orderitems.map((v,i) => <div key={i} className={styles.row}>
																<div className={styles.name}>
																	<div>{i+1}. {v.name||v.productName}</div>
																	<div className={styles.measure}>{v.measure} {measureTypeName[v.measureType]}</div>
																</div>
																{this.state.orderaddstep === 5 ?
																	<div className={styles.quantitytext}>x {quantityFormat(this.state.productquantity[v.id])}</div>
																	:
																	<input type="number" min={1} max={999} className={styles.quantity} value={this.state.productquantity[v.id]} onChange={(e) => this.handleQuantity(e, v.id)} />
																}
																<div className={styles.price}>{moneyFormat(v.priceDiscount ? v.priceDiscount : v.price, true)} ₽</div>
																{this.state.orderaddstep === 4 && <div className={styles.delete} onClick={() => this.productRemove(i)}>
																		<img src={require('../../../Images/icons/close_black.svg')} alt='Удалить' />
																	</div>}
															</div>)}
													</div>
													<div className={styles.summary}>
														<div className={styles.row}><span>Сумма заказа</span><span><b>{this.state.price} ₽</b></span></div>
														<div className={styles.row}><span>Сумма со скидкой:</span><span>{this.state.priceDiscount} ₽</span></div>
														<div className={styles.row}><span>Сумма к оплате:</span><span><b>{this.priceFullGet()} ₽</b></span></div>
														<div className={styles.row}><span>Скидка</span><span>{this.state.discount ? `${this.state.discount} ₽` : 'нет'}</span></div>
														{this.state.discountName && <div className={styles.hint} title={this.state.discountName}>{this.state.discountName}</div>}
														{this.state.isPickup ?
																<div className={styles.row}><span>Доставка</span><span>Самовывоз</span></div>
															:
																this.state.rank && this.state.rank.deliveryFree ?
																		<div className={styles.row}><span>Доставка</span><span>0 ₽ (по статусу)</span></div>
																	:
																		(this.state.deliveryIsAlwaysPaid ?
																				<div className={styles.row}><span>Платная доставка</span><span>{this.state.deliveryCost} ₽</span></div>
																			:
																				(this.state.deliveryIsFree ?
																						<div className={styles.row}><span>Доставка</span><span>0 ₽</span></div>
																					:
																						<>
																							<div className={styles.row}><span>Доставка</span><span>{this.state.deliveryCostManual !== undefined ? this.state.deliveryCostManual : (this.state.price >= this.state.deliveryFreeAmount ? '0': this.state.deliveryCost)} ₽</span></div>
																							<div className={styles.row}><span>Бесплатная доставка от</span><span>{this.state.deliveryFreeAmount} ₽</span></div>
																						</>
																				)
																		)
														}
													</div>
												</>
											:
												<div>Выберите блюда и добавьте их к заказу</div>
											}
										</div>
									</>
								: null}
							</div>
						</> : null)
				:
					this.state.isordersearch ?
						(this.state.isordersearchinfo ?
								<div className={cx(styles.ordersearchcontainer,styles.orderinfocontainer)}>
									<div className={styles.header}>
										<h4>Состав заказа №{this.state.data.id}</h4>
										<Close close={this.orderSearchInfoHide.bind(this)} />
									</div>
									<div className={styles.orderbody}>
										<OrderInfo data={this.state.data} orderproducts={this.state.data.orderproducts} areas={this.state.areas} />
									</div>
									<div className={styles.controlpanel}>
										<div></div>
										<div>
											{![orderStatus.CANCEL,orderStatus.REFUND,orderStatus.FINISH,orderStatus.HIDE,orderStatus.ARCHIVE,orderStatus.ROBOT_PREPARE,orderStatus.KIOSK_PREPARE].includes(this.state.data.status) ?
												<button className={cx(styles.button,styles.secondary)} onClick={() => this.orderAreaChange()}>
													<img src={require('../../../Images/icons/marker_black.svg')} alt="Точка" />
													Точка
												</button> : null}
											<button className={cx(styles.button,styles.secondary)} onClick={() => this.orderRepeat(true)}>
												<img src={require('../../../Images/icons/reload.svg')} alt="Повторить" />
												Повторить
											</button>
											{[orderStatus.COURIER_ACCEPTED,orderStatus.COURIER_IN_ROUTE,orderStatus.COURIER_DONE].includes(this.state.data.status) ?
												<button className={cx(styles.button,styles.secondary)} onClick={() => this.mapShow()}>
													<img src={require('../../../Images/icons/map.svg')} alt="Курьер на карте" />
													Курьер на карте
												</button> : null}
											{[
												orderStatus.USER_ADDED,
												orderStatus.USER_PAID,
												orderStatus.OPERATOR_WAIT,
												orderStatus.COOK_WAIT,
												orderStatus.COOK_ACCEPTED,
												orderStatus.COOK_DONE,
												orderStatus.COLLECTOR_WAIT,
												orderStatus.COLLECTOR_ACCEPTED,
												orderStatus.COLLECTOR_DONE,
												orderStatus.LOGIST_WAIT,
												orderStatus.LOGIST_ACCEPTED,
												orderStatus.LOGIST_DONE,
												orderStatus.COURIER_WAIT,
												orderStatus.COURIER_ACCEPTED,
												orderStatus.COURIER_IN_ROUTE
											].includes(this.state.data.status) && this.state.data.phone ?
												<button className={cx(styles.button,styles.secondary)} onClick={() => this.orderEdit(true)}>
													<img src={require('../../../Images/icons/edit.svg')} alt="Изменить" />
													Изменить
												</button> : null}
											{![orderStatus.CANCEL,orderStatus.REFUND,orderStatus.FINISH,orderStatus.HIDE,orderStatus.ARCHIVE,orderStatus.ROBOT_PREPARE,orderStatus.KIOSK_PREPARE].includes(this.state.data.status) ?
												<button className={cx(styles.button,styles.secondary,styles.cancel)} onClick={() => this.orderCancel()}>
													<img src={require('../../../Images/icons/cancel.svg')} alt="Отменить" />
													Отменить
												</button> : null}
											{![orderStatus.USER_ADDED,orderStatus.CANCEL,orderStatus.REFUND,orderStatus.HOLD,orderStatus.HIDE,orderStatus.ARCHIVE,orderStatus.ROBOT_PREPARE,orderStatus.KIOSK_PREPARE].includes(this.state.data.status) && this.state.data.isPaid === orderPaid.PAID ?
												<button className={cx(styles.button,styles.secondary,styles.cancel)} onClick={() => this.orderRefund()}>
													<img src={require('../../../Images/icons/reload.svg')} alt="Возврат" />
													Возврат
												</button> : null}
										</div>
									</div>
								</div>
							:
								<div className={styles.ordersearchcontainer}>
									<div className={styles.header}>
										<h4>Поиск заказа</h4>
										<Close close={this.orderSearchHide.bind(this)} />
									</div>
									<div className={styles.orderbody}>
										<div className={styles.formcontainer}>
											<div className={styles.searchblock}>
												<input type="text" name="filterSearch" placeholder="Поиск заказа по номеру, адресу доставки, телефону или имени клиента" value={this.state.filterSearch} onChange={this.handleForm} autoFocus={true} autoComplete="off" />
												<button className={styles.button} onClick={() => this.search()}>
													<img src={require('../../../Images/icons/search_white.svg')} alt="" />
													Найти
												</button>
											</div>
											<div className={styles.formcontainerinputs}>
												<div className={styles.cell}>
													<span>Точка</span>
													<select onChange={this.handleFilterAreaSelect} value={this.state.filterAreaId}>
														<option></option>
														{this.state.areas.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
													</select>
												</div>
												<div className={cx(styles.cell,styles.cellsmall)}>
													<span>Смена</span>
													<div>
														<input type="checkbox" defaultChecked={this.state.filterDayShiftCurrent} onChange={this.handleFilterDayShiftCurrentForm} />
													</div>
												</div>
												<div className={styles.cell}>
													<span>Статус</span>
													<select onChange={this.handleFilterStatusSelect} value={this.state.filterStatus}>
														<option></option>
														{orderStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
													</select>
												</div>
												<div className={styles.cell}>
													<span>Дата от</span>
													<div>
														<input type="datetime-local" name="filterDateStart" value={this.state.filterDateStart} onChange={this.handleFilterDateForm} />
														<img src={require('../../../Images/icons/close_black.svg')} alt='Сбросить' onClick={() => this.dateFilterClear('filterDateStart')} />
													</div>
												</div>
												<div className={styles.cell}>
													<span>Дата до</span>
													<div>
														<input type="datetime-local" name="filterDateEnd" value={this.state.filterDateEnd} onChange={this.handleFilterDateForm} />
														<img src={require('../../../Images/icons/close_black.svg')} alt='Сбросить' onClick={() => this.dateFilterClear('filterDateEnd')} />
													</div>
												</div>
												<div className={cx(styles.cell,styles.cellsmall)}>
													<span>На дату</span>
													<div>
														<input type="checkbox" defaultChecked={this.state.filterDate} onChange={this.handleFilterDateFutureForm} />
													</div>
												</div>
											</div>
										</div>
										<div className={styles.orderscontainer}>
											{this.state.orderssearch.map((v,i) => <div key={i} className={styles.orderitem} onClick={() => this.orderSearchInfoShow(v)}>
													<div className={styles.orderiteminner}>
														<div className={styles.number}>Заказ №{v.id} {v.commentSystem?<span className={styles.status}>Ред.</span>:null}</div>
														<div className={styles.block}>
															<span>{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
															<span className={styles.status}>{orderStatusName[v.status]}</span>
														</div>
														<div className={styles.block}>
															<div><b>{v.address}</b></div>
															<div className={styles.amount}>{this.amountGet(v)}</div>
														</div>
														<div className={styles.products}>
															{v.products.split('\r\n').map((v,i) => <div key={i}>{v}</div>)}
														</div>
													</div>
												</div>)}
										</div>
									</div>
								</div>
						)
					:
						(this.state.isordershow ?
							<div className={styles.orderinfocontainer}>
								<div className={styles.header}>
									<h4>Состав заказа №{this.state.data.id}<span className={cx(styles.status,this.state.data.status===orderStatus.USER_PAID?styles.statuspaid:styles.statusnew)}>{orderStatusName[this.state.data.status]}</span></h4>
									<Close close={this.orderShowClose.bind(this)} />
								</div>
								<div className={styles.orderbody}>
									<OrderInfo data={this.state.data} orderproducts={this.state.orderproducts} areas={this.state.areas} />
								</div>
								{this.state.orderisbusy ?
										<div className={styles.busyuser}>Заказ занят и принят в работу{this.state.data.userName ? `. Пользователь: ${this.state.data.userName}` : null}</div>
									:
										([orderStatus.USER_ADDED,orderStatus.USER_PAID].includes(this.state.data.status) ?
											<div className={styles.controlpanel}>
												<button className={styles.button} onClick={() => this.orderAccept()}>
													<img src={require('../../../Images/icons/mark.svg')} alt="Принять" />
													Принять
												</button>
												<div>
													<button className={cx(styles.button,styles.secondary)} onClick={() => this.orderAcceptChange()}>
														<img src={require('../../../Images/icons/order.svg')} alt="Изменить" />
														Изменить
													</button>
													<button className={cx(styles.button,styles.secondary,styles.cancel)} onClick={() => this.orderCancel()}>
														<img src={require('../../../Images/icons/cancel.svg')} alt="Отменить" />
														Отменить
													</button>
													{this.state.data.status === orderStatus.USER_PAID && this.state.data.isPaid === orderPaid.PAID ?
														<button className={cx(styles.button,styles.secondary,styles.cancel)} onClick={() => this.orderRefund()}>
															<img src={require('../../../Images/icons/reload.svg')} alt="Возврат" />
															Возврат
														</button> : null}
												</div>
											</div> : null)
								}
							</div>
						:
							<>
								<div className={styles.column}>
									{/* <div className={cx(styles.button,this.state.call?styles.ordercall:null)} onClick={() => this.orderCall()}>
										<div>
											<img src={require('../../../Images/icons/call_white.svg')} alt="" />
											{this.state.call ? <img src={require('../../../Images/icons/one_plus_white.svg')} className={styles.icomingcount} alt="" /> : null}
											Входящий звонок
										</div>
									</div> */}
									<div className={cx(styles.button,styles.orders)} onClick={() => this.showAllOrders(true)}>
										<div>
											<img src={require('../../../Images/icons/order_white.svg')} alt="" />
											Заказы
										</div>
									</div>
									{[0].map((v,i) => <div key={i} className={cx(styles.button,styles.empty)}></div>)}
									<div className={cx(styles.button,styles.orderhold)} onClick={() => this.holdShow()}>
										<div>
											<img src={require('../../../Images/icons/clock_white.svg')} alt="" />
											Отложенные заказы
										</div>
									</div>
									<div className={cx(styles.button,styles.couriers)} onClick={() => this.showAllCouriers(true)}>
										<div>
											<img src={require('../../../Images/icons/user_white.svg')} alt="" />
											Курьеры
										</div>
									</div>
									{[0,1,2].map((v,i) => <div key={i} className={cx(styles.button,styles.empty)}></div>)}
								</div>
								<div className={styles.column}>
									<div className={cx(styles.button,styles.orderadd)} onClick={() => this.orderAdd()}>
										<div>
											<img src={require('../../../Images/icons/add_white.svg')} alt="" />
											Новый заказ
										</div>
									</div>
									<div className={cx(styles.button,styles.ordersearch)} onClick={() => this.orderSearch()}>
										<div>
											<img src={require('../../../Images/icons/search_white.svg')} alt="" />
											Поиск заказа
										</div>
									</div>
									{[0].map((v,i) => <div key={i} className={cx(styles.button,styles.empty)}></div>)}
									<div className={cx(styles.button,styles.ordercall,this.state.ismessage?styles.ordercallactive:null)} onClick={() => this.showMessages(true)}>
										<div>
											<img src={require('../../../Images/icons/email_white.svg')} alt="" />
											Сообщения
										</div>
									</div>
									{[0,1,2,3].map((v,i) => <div key={i} className={cx(styles.button,styles.empty)}></div>)}
								</div>
							</>
						)
				}
				{!this.state.isordersearch && !this.state.isfullscreen &&
					<div className={styles.column}>
						<div className={styles.header}>
							Заказы
							<img src={require('../../../Images/icons/statuses/alert.svg')} alt="" />
						</div>
						{this.state.orders === null || this.state.orders.length === 0 ? <div className={styles.empty}>Пусто</div> :
							<div className={styles.container}>
								{this.state.orders.map((v,i) => <div key={i} className={cx(styles.item,this.additionalStatus(v),v.id===this.state.orderid?styles.selected:null)} onClick={() => this.orderSelect(v)}>
									<div className={cx(styles.oneline,styles.num)}>
										<div>Заказ <b>№{v.id}</b></div>
										{/*
										{v.userId && parseInt(v.userId) !== this.state.user.id ? <span className={cx(styles.status,styles.busy)}>занят</span> : null}
										*/}
									</div>
									<div className={styles.oneline}>
										<div><b>{this.addressStreetClean(v.address)}</b></div>
										<div className={styles.amount}>{this.amountGet(v)}</div>
									</div>
									<div className={styles.products}>
										{v.products.split('\r\n').map((v,i) => <div key={i}>{v}</div>)}
									</div>
									<div className={styles.oneline}>
										<span className={styles.date}>{dateGet(v.dateCreate, {yearHide:true,showMonthFullName:true,showTime:true})}</span>
										<span className={styles.status}>{orderStatusName[v.status]}</span>
									</div>
								</div>)}
							</div>
						}
					</div>}
			</div>
			{this.state.modalshoworderarea && <Modal visible={this.state.modalshoworderarea} width={'600'} height={'400'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Выбор точки выполнения заказа</h4>
					</div>
					<div className={styles.areaselectcontainer}>
						<p>Адрес доставки: <b>{this.state.data.address}</b></p>
						{this.state.activearea ?
							<div className={cx(styles.row,styles.rowfirst)} onClick={() => this.operatorSelect(this.state.activearea.id, this.state.isorderfinish)}>
								<div>
									<b>{this.state.activearea.name}</b>
									<span>{this.state.activearea.address}</span>
								</div>
								<div className={cx(styles.button,styles.selected)}>Выбранная точка</div>
							</div> : null}
						{this.state.areas.filter(f => this.state.activearea === null || (f.id !== this.state.activearea.id)).map((v, i) => <div key={i} className={styles.row} onClick={() => this.operatorSelect(v.id, this.state.isorderfinish)}>
							<div>
								<b>{v.name}</b>
								<span>{v.address}</span>
							</div>
							<div className={styles.button}>Выбрать эту точку</div>
						</div>)}
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshoworderareachange && <Modal visible={this.state.modalshoworderareachange} width={'600'} height={'400'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Смена точки выполнения заказа</h4>
					</div>
					<div className={styles.areaselectcontainer}>
						<p>Адрес доставки: <b>{this.state.data.address}</b></p>
						{this.state.activearea ?
							<div className={cx(styles.row,styles.rowfirst)} onClick={() => this.orderAreaChangeUpdate(this.state.activearea.id)}>
								<div>
									<b>{this.state.activearea.name}</b>
									<span>{this.state.activearea.address}</span>
								</div>
								<div className={cx(styles.button,styles.selected)}>Выбранная точка</div>
							</div> : null}
						{this.state.areas.filter(f => this.state.activearea === null || (f.id !== this.state.activearea.id)).map((v, i) => <div key={i} className={styles.row} onClick={() => this.orderAreaChangeUpdate(v.id)}>
							<div>
								<b>{v.name}</b>
								<span>{v.address}</span>
							</div>
							<div className={styles.button}>Выбрать эту точку</div>
						</div>)}
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowprompt && <Modal visible={this.state.modalshowprompt} width={'540'} height={'155'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Вы уверены что хотите {this.state.isorderchange ? 'сохранить' : 'создать'} заказ?</h4>
					</div>
					<div className={styles.promptcontainer}>
						<div>
							<button className={styles.button} onClick={() => this.orderSave()}>Да</button>
							<button className={cx(styles.button,styles.secondary)} onClick={() => this.modalHide()}>Нет</button>
							<button className={cx(styles.button,styles.secondary,styles.hold)} onClick={() => this.orderSave(true)}>Отложить</button>
						</div>
					</div>
				</div>
			</Modal>}
			{this.state.modalshowpromptclose && <Modal visible={this.state.modalshowpromptclose} width={'540'} height={'205'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Отменить {!this.state.isorderchange?'создание':'редактирование'} заказа?</h4>
					</div>
					<div className={styles.promptcontainer}>
						<p>Если вы нажмете <b>Да</b>, то данные по заказу не сохранятся!</p>
						<div>
							<button className={styles.button} onClick={() => this.orderCallClose()}>Да</button>
							<button className={cx(styles.button,styles.secondary)} onClick={() => this.modalHide()}>Нет</button>
						</div>
					</div>
				</div>
			</Modal>}
			{this.state.modalshowpromptcancel && <Modal visible={this.state.modalshowpromptcancel} width={'600'} height={'300'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Отменить заказ?</h4>
					</div>
					<div className={styles.promptcontainer}>
						<div className={styles.cancelinput}>
							<p>Опишите причину отмены заказа</p>
							<input type="text" placeholder="Причина отмены" autoFocus={true} onChange={this.handleFormCommentSysytem} />
						</div>
						<div>
							<button className={styles.button} onClick={() => this.orderStatusSave(orderStatus.CANCEL)}>Да</button>
							<button className={cx(styles.button,styles.secondary)} onClick={() => this.modalHide()}>Нет</button>
						</div>
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowpromptrefund && <Modal visible={this.state.modalshowpromptrefund} width={'600'} height={'300'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Вернуть деньги за заказ?</h4>
					</div>
					<div className={styles.promptcontainer}>
						<div className={styles.cancelinput}>
							<p>Опишите причину возврата денег</p>
							<input type="text" placeholder="Причина возврата денег" autoFocus={true} onChange={this.handleFormCommentSysytem} />
						</div>
						<div>
							<button className={styles.button} onClick={() => this.orderStatusSave(orderStatus.REFUND)}>Да</button>
							<button className={cx(styles.button,styles.secondary)} onClick={() => this.modalHide()}>Нет</button>
						</div>
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowcouriermap && <Modal visible={this.state.modalshowcouriermap} width={'900'} height={'700'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Местоположение курьера</h4>
					</div>
					<div className={styles.mapcontainer}>
						<div className={styles.map}>
							<YMaps query={{apikey:MAP.keyYandex}}>
								<Map
									instanceRef={(map) => this.map = map}
									onLoad={(ymaps) => this.onMapLoad(ymaps)}
									modules={['route']}
									state={{center:MAP.defaultCoordinates,zoom:16}}
									width={'auto'} height={'100%'}>
										<ZoomControl />
									</Map>
							</YMaps>
						</div>
						<div className={styles.orderaddress}>
							{this.state.data.address}
							<img src={require('../../../Images/icons/reload.svg')} className={styles.update} alt="Обновить" onClick={() => this.courierRoutUpdate()} />
						</div>
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			{this.state.modalshowclientorders && <Modal visible={this.state.modalshowclientorders} width={'980'} height={'640'} effect={'fadeInUp'}>
				<div className={styles.modalcontainer}>
					<div className={styles.header}>
						<h4>Заказы клиента</h4>
					</div>
					<div className={cx(styles.item,styles.itemwide,styles.clientinfo)}>
						<div className={styles.cell}>
								{this.state.loadinginner ?
										<div className={styles.main} style={{margin:'0 20px 50px 0'}}><Loading className={styles.loading}/></div>
									:
										(!this.state.isordersearchinfo ?
											<div className={styles.ordershowcontainer} style={{overflow:'hidden'}}>
												<div className={styles.orderbody}>
													<div className={styles.formcontainer}>
														<div className={styles.formcontainerinputs}>
															<div className={styles.cell}>
																<span>Точка</span>
																<select onChange={this.handleFilterAreaSelect} value={this.state.filterAreaId}>
																	<option></option>
																	{this.state.areas.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
																</select>
															</div>
															<div className={styles.cell}>
																<span>Статус</span>
																<select onChange={this.handleFilterStatusSelect} value={this.state.filterStatus}>
																	<option></option>
																	{orderStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
																</select>
															</div>
															<div className={styles.cell}>
																<span>Дата от</span>
																<div>
																	<input type="datetime-local" name="filterDateStart" value={this.state.filterDateStart} onChange={this.handleFilterDateForm} />
																	<img src={require('../../../Images/icons/close_black.svg')} alt='Сбросить' onClick={() => this.dateFilterClear('filterDateStart')} />
																</div>
															</div>
															<div className={styles.cell}>
																<span>Дата до</span>
																<div>
																	<input type="datetime-local" name="filterDateEnd" value={this.state.filterDateEnd} onChange={this.handleFilterDateForm} />
																	<img src={require('../../../Images/icons/close_black.svg')} alt='Сбросить' onClick={() => this.dateFilterClear('filterDateEnd')} />
																</div>
															</div>
															<button onClick={() => this.search()}>
																<img src={require('../../../Images/icons/search_white.svg')} style={{width: '20px'}} alt="" />
															</button>
														</div>
													</div>
													<div className={cx(styles.orderscontainer)} style={{overflow:'auto'}}>
														{this.state.clientOrders.map((order) =>
															<div className={styles.orderitem} key={order.id} onClick={() => this.orderSearchInfoShow(order)}>
																<div className={styles.orderiteminner}>
																	<div className={styles.number}>Заказ №{order.id}</div>
																	<div className={styles.block}>
																		<span>{dateGet(order.dateCreate, {
																			yearHide: true,
																			showMonthFullName: true,
																			showTime: true
																		})}</span>
																		<span className={styles.status}>{orderStatusName[order.status]}</span>
																	</div>
																	<div className={styles.block}>
																		<div><b>{order.address}</b></div>
																		<div className={styles.amount}>{this.amountGet(order)}</div>
																	</div>
																	<div className={styles.products}>
																		{order.products?.split('\r\n').map((v, i) => <div key={i}>{v}</div>)}
																	</div>
																</div>
															</div>
														)}
													</div>
												</div>
											</div>
										:
											<div className={cx(styles.ordershowcontainer)}>
												<div className={styles.header}>
													<h4>Состав заказа №{this.state.data.id}</h4>
													<Close close={this.orderSearchInfoHide.bind(this)} />
												</div>
												<div className={styles.orderbody}>
													<OrderInfo data={this.state.data} orderproducts={this.state.data.orderproducts} areas={this.state.areas} />
												</div>
											</div>
										)
								}
							</div>
					</div>
				</div>
				<Close close={this.modalHide.bind(this)} />
			</Modal>}
			<Alert />
		</div>
	}
}

export default withRouter(DashboardDispatcher);