import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import Modal from 'react-awesome-modal';
import classNames from 'classnames';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';
import SaveStatus from '../../../Components/SaveStatus';
import Close from '../../../Components/Close';
import Alert,{alertShow} from '../../../Components/Alert';

// models
import {Stores,Events,Logs} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {dateGet,dateNow,empty,moneyFormat} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {eventType,measureTypeName} from '../../../Globals/Constants';

// styles
import styles from './StoreIncome.module.css';
const cx = classNames.bind(styles);

class StoreIncome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			user:null,
			stores:[],
			ingredients:[],
			ingredientsFull:[],
			semifinish:[],
			semifinishFull:[],
			items:[],
			sections:[],
			provisors:[],
			tab:1,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false,
			modalshow:false
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	dataGet = async () => {
		const ingredients = await Stores.ingredientsGetAsync();
		const stores = await Stores.getAllAsync();
		const provisors = await Stores.provisorsGetAsync();
		const sections = await Stores.sectionsGetAsync();
		const semifinish = await Stores.semifinishesGetAsync();
		this.setState({ingredients:ingredients.data,ingredientsFull:ingredients.data,stores:stores.data,semifinish:semifinish.data,semifinishFull:semifinish.data,sections:sections.data,provisors:provisors.data});
		if (this.state.id !== 0) {
			const items = await Stores.incomeItemsGet(this.state.id);
			Stores.incomeGet(this.state.id, (data) => this.setState({data,dataOld:{...data},notfound:empty(data),items:items.data,loading:false}));
		}
		else this.dataInit();
	}
	dataInit = () => this.setState({data:{storeId:0},id:0,loading:false});
	isMainStore = (id) => this.state.stores.find(f => f.id === parseInt(id))?.areaId === 0;
	handleForm = (e) => {
		const {data} = this.state;
		data[e.target.name] = parseInt(e.target.value);
		if (e.target.name === 'storeId') {
			data.sectionId = null;
			data.sectionName = null;
			data.provisorId = null;
			data.provisorName = null;
		}
		this.setState({data});
	}
	handleItemForm = (e, idx) => {
		const {items} = this.state;
		items[idx][e.target.name] = parseFloat(e.target.value);
		this.setState({items});
	}
	save = (e) => {
		e.preventDefault();
		const {items,user} = this.state;
		if (items.length === 0) {
			alertShow('<b>Ошибка!</b><br/>Вы не добавили ни одной позиции', true);
			this.modalShow();
			return;
		}
		this.setState({issave:true});
		const data = {
			storeId:this.state.data.storeId,
			userId:user.id,
			userName:user.name
		};
		if (this.state.data.provisorId) {
			data.provisorId = parseInt(this.state.data.provisorId);
			data.provisorName = this.nameGet(this.state.provisors, data.provisorId);
		}
		if (this.state.data.sectionId) {
			data.sectionId = parseInt(this.state.data.sectionId);
			data.sectionName = this.nameGet(this.state.sections, data.sectionId);
		}
		Stores.incomeAdd(data, (res) => {
			this.stop(() => {
				const id = res.data.id;
				this.setState({saveStatus:Texts.income.add,iserror:false,id});
				Events.add(this.state.user.id, Texts.income.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.STORE_INCOME);
				items.forEach(async (v) => {
					v.incomeId = id;
					v.price = v.price * 100;
					if (v.extCardId) {
						v.cardId = v.extCardId;
						v.cardName = v.extCardName;
					}
					await Stores.incomeItemsAdd(v);
				});
				data.id = id;
				data.dateCreate = dateNow();
				this.setState({data});
				Stores.balanceUpdate({storeId:data.storeId,items});
			});
		}, (err) => {
			this.stop(() => {
				this.setState({saveStatus:Texts.errors.internalError,iserror:true});
				Logs.add(err);
			});
		});
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	modalHide = () => this.setState({modalshow:false});
	modalShow = () => this.setState({modalshow:true});
	ingredientSelect = (e) => {
		const {items,ingredients} = this.state;
		const ingredient = ingredients.find(f => f.id === parseInt(e.target.value));
		const item = {
			ingredientId:ingredient.id,
			ingredientName:ingredient.name,
			measureType:ingredient.measureType,
			price:ingredient.price,
			quantity:ingredient.defaultCount
		};
		if (ingredient.provisorId !== 0) {
			item.provisorId = ingredient.provisorId;
			item.provisorName = ingredient.provisorName;
		}
		items.push(item);
		this.setState({items}, this.modalHide);
	}
	semifininishSelect = (e) => {
		const {items,semifinish} = this.state;
		const sf = semifinish.find(f => f.id === parseInt(e.target.value));
		const item = {
			extCardId:sf.id,
			extCardName:sf.productName,
			measureType:sf.measureType,
			quantity:sf.defaultCount
		};
		items.push(item);
		this.setState({items}, this.modalHide);
	}
	itemRemove = (idx) => {
		const items = [];
		this.state.items.forEach((v,i) => {
			if (i !== idx) items.push(v);
		});
		this.setState({items});
	}
	searchIngredients = (e) => {
		const ss = e.target.value;
		if (empty(ss)) {
			this.setState({ingredients:this.state.ingredientsFull,semifinish:this.state.semifinishFull});
			return;
		}
		if (this.state.tab === 1) {
			const ingredients = this.state.ingredientsFull.filter(f => f.name.toLowerCase().indexOf(ss.toLowerCase()) !== -1);
			this.setState({ingredients});
		} else {
			const semifinish = this.state.semifinishFull.filter(f => f.productName.toLowerCase().indexOf(ss.toLowerCase()) !== -1);
			this.setState({semifinish});
		}
	}
	totalGet = () => this.state.items.reduce((a, b) => a + (b.price / 100 * b.quantity), 0);
	incomeCreate = () => window.location.href=`/store/income/${this.state.data.id}`;
	nameGet = (data, id) => data.find(f => f.id === id)?.name;
	tabSet = (tab) => this.setState({tab});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<>
						<MainTemplate isStore={true}>
							<div className={styles.container}>
								<h4>
									<span className={styles.oneline}>
										<Link to={'/store/incomes'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
										{this.state.id === 0 ? 'Добавить поступление' : 'Просмотр поступления'}
									</span>
								</h4>
								{this.state.notfound ? <NotFound /> :
									this.state.id === 0 ?
										<form onSubmit={this.save}>
											<div className={styles.formcontainer}>
												<div className={styles.onelinefull}>
													<div className={styles.cell}>
														<label>
															<span>Склад</span>
															<select name="storeId" onChange={this.handleForm} defaultValue={parseInt(this.state.data.storeId)} required>
																<option></option>
																{this.state.stores.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
															</select>
														</label>
													</div>
													{this.isMainStore(this.state.data.storeId) ?
														<div className={styles.cell}>
															<label>
																<span>Поставщик</span>
																<select name="provisorId" onChange={this.handleForm} defaultValue={parseInt(this.state.data.provisorId)}>
																	<option value={0}>—</option>
																	{this.state.provisors.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
																</select>
															</label>
														</div>
													:
														<div className={styles.cell}>
															<label>
																<span>Раздел</span>
																<select name="sectionId" onChange={this.handleForm} defaultValue={parseInt(this.state.data.sectionId)}>
																	<option value={0}>—</option>
																	{this.state.sections.map((v,i) => <option key={i} value={v.id}>{v.name}</option>)}
																</select>
															</label>
														</div>
													}
												</div>
												<div className={styles.linepositions}>
													<h4>
														Позиции заявки
														<a className={styles.plus} onClick={() => this.modalShow()}></a>
													</h4>
														{this.state.items.length > 0 ?
														<table>
															<thead>
																<tr>
																	<th>Название</th>
																	<th>Количество</th>
																	<th>Единица измерения</th>
																	<th>Цена за единицу, ₽</th>
																	<th>&nbsp;</th>
																</tr>
															</thead>
															<tbody>
																{this.state.items.map((v,i) => <tr key={i}>
																	<td>{v.extCardId ? <><b>п/ф</b> {v.extCardName}</> : v.ingredientName}<span>{v.provisorName}</span></td>
																	<td><input type="number" name="quantity" placeholder="Количество" step={0.1} value={v.quantity||''} onChange={(e) => this.handleItemForm(e, i)} required /></td>
																	<td>
																		<select name="measureType" onChange={(e) => this.handleItemForm(e, i)} defaultValue={v.measureType} required>
																			<option value=""></option>
																			{measureTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
																		</select>
																	</td>
																	<td><input type="number" name="price" placeholder="Цена, за единицу" step={0.1} onChange={(e) => this.handleItemForm(e, i)} value={v.price||''} required /></td>
																	<td><img src={require('../../../Images/icons/close_black.svg')} alt="Удалить" onClick={() => this.itemRemove(i)} /></td>
																</tr>)}
															</tbody>
														</table> : null}
												</div>
												<div className={styles.buttons}>
													{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
													{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
												</div>
											</div>
										</form>
									:
										<div className={styles.formcontainer}>
											<div className={styles.onelinefull}>
												<div className={styles.cell}>
													<label>
														<span>Номер заявки</span>
														<div><b>{this.state.data.id}</b></div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Дата заявки</span>
														<div>{dateGet(this.state.data.dateCreate, {showTime:true,showSeconds:true})}</div>
													</label>
												</div>
												{this.state.data.requestId ?
													<div className={styles.cell}>
														<label>
															<span>Создано из заявки</span>
															<div><Link to={`/store/request/${this.state.data.requestId}`}>Заявка №{this.state.data.requestId}</Link></div>
														</label>
													</div> : null}
											</div>
											<div className={styles.onelinefull}>
												<div className={styles.cell}>
													<label>
														<span>Пользователь</span>
														<div><Link to={`/user/${this.state.data.userId}`}>{this.state.data.userName}</Link></div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Раздел</span>
														<div>{this.state.data.sectionId ? <Link to={`/store/section/${this.state.data.sectionId}`}>{this.state.data.sectionName}</Link> : '—'}</div>
													</label>
												</div>
												<div className={styles.cell}>
													<label>
														<span>Поставщик</span>
														<div>{this.state.data.provisorId ? <Link to={`/store/provisor/${this.state.data.provisorId}`}>{this.state.data.provisorName}</Link> : '—'}</div>
													</label>
												</div>
											</div>
											<div className={styles.oneline}>
												<div className={styles.cell}>
													<label>
														<span>Склад</span>
														<div><Link to={`/store/store/${this.state.data.storeId}`}>{this.nameGet(this.state.stores, this.state.data.storeId)}</Link></div>
													</label>
												</div>
											</div>
											<div className={styles.linepositions}>
												<h4>Позиции заявки</h4>
												<ul className={styles.productslist}>
													{this.state.items.map((v,i) => <li key={i}>
														<span className={styles.name}>{i+1}. {v.extCardId ? <><b>п/ф</b> {v.extCardName}</> : v.ingredientName}</span>
														<span className={styles.measure}>{v.quantity} {measureTypeName[v.measureType]}</span>
														<span className={styles.price}>{moneyFormat(v.price/100)} ₽</span>
														<span className={styles.price}>{moneyFormat(v.price/100*v.quantity)} ₽</span>
													</li>)}
												</ul>
												<div className={styles.summary}>
													<span>Итого</span>
													<b>{moneyFormat(this.totalGet())} ₽</b>
												</div>
											</div>
										</div>
								}
							</div>
						</MainTemplate>
						<Modal visible={this.state.modalshow} width={'90%'} height={'700'} effect={'fadeInUp'}>
							<div className={styles.modalcontainer}>
								<h4 className={styles.title}>Выбрать продукт, ингредиент</h4>
								<div className={styles.tabs}>
									<div className={cx(styles.tab,this.state.tab===1?styles.tabactive:null)} onClick={() => this.tabSet(1)}>Продукт/ингредиент</div>
									<div className={cx(styles.tab,this.state.tab===2?styles.tabactive:null)} onClick={() => this.tabSet(2)}>Полуфабрикат</div>
								</div>
								<div className={styles.search}>
									<input type="search" onChange={this.searchIngredients} />
								</div>
								<div className={styles.products}>
									<ul>
										<>
											{this.state.tab === 1 ?
												this.state.ingredients.map((v,i) => <li key={i}><label><input type="radio" name="ingredientId" value={v.id} onChange={this.ingredientSelect} /> {v.name}</label></li>)
												: null}
											{this.state.tab === 2 ?
												this.state.semifinish.map((v,i) => <li key={i}><label><input type="radio" name="cardId" value={v.id} onChange={this.semifininishSelect} /> {v.productName}</label></li>)
												: null}
										</>
									</ul>
								</div>

							</div>
							<Close close={this.modalHide.bind(this)} />
						</Modal>
						<Alert />
					</>
				}
			</>
		);
	}
}

export default withRouter(StoreIncome);