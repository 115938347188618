import Http from '../../Globals/Http';

const getAll	= (callback)		=> Http.get('productrelationitems').then((res) => callback(res.data));
const get		= (id, callback)	=> Http.get('productrelationitems', {conditions:[{k:'productRelationId',v:id}]}).then((res) => callback(res.data));

const add		= (data)			=> Http.post('productrelationitems', {data});
const remove	= (id)				=> Http.remove('productrelationitems', {conditions:[{k:'productRelationId',v:id}]});

export {
	getAll,
	get,
	add,
	remove
}