import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';
import Modal from 'react-awesome-modal';
import classNames from 'classnames';

// components
import MainTemplate from '../../../Components/MasterPages/MainTemplate';
import NotFound from '../../../Components/NotFound';
import SaveStatus from '../../../Components/SaveStatus';
import Close from '../../../Components/Close';
import Alert,{alertShow} from '../../../Components/Alert';

// models
import {Stores,Products,Events,Logs} from '../../../Models';

// helpers
import {ls} from '../../../Globals/Localstorage';
import {empty,moneyFormat,weightFormat} from '../../../Globals/Utils';

// texts
import Texts from '../../../Globals/Texts';

// globals
import {eventType,measureType,measureTypeName,semifinishType} from '../../../Globals/Constants';

// styles
import styles from './StoreCard.module.css';
const cx = classNames.bind(styles);

class StoreCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:parseInt(this.props.match.params.id||0),
			data:null,
			dataOld:null,
			products:[],
			productsFull:[],
			semifinish:[],
			semifinishFull:[],
			price:null,
			item:null,
			items:[],
			ingredientId:0,
			ingredients:[],
			ingredientsFull:[],
			cardId:0,
			user:null,
			tab:1,
			notfound:false,
			progress:10,
			loading:true,
			issave:false,
			saveStatus:null,
			iserror:false,
			modalitem:false,
			modalingredients:false,
			modalproducts:false,
			isitemnew:true
		};
	}
	componentDidMount = () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		this.setState({user}, () => this.dataGet());
	}
	componentWillReceiveProps = (p) => this.dataInit();
	dataGet = async () => {
		const products = await Products.getAllActiveAsync();
		const ingredients = await Stores.ingredientsGetAsync();
		const semifinish = await Stores.semifinishesGetAsync();
		this.setState({products:products.data,productsFull:products.data,semifinish:semifinish.data,semifinishFull:semifinish.data,ingredients:ingredients.data,ingredientsFull:ingredients.data});
		if (this.state.id !== 0) {
			const items = await Stores.cardItemsGet(this.state.id);
			Stores.cardGet(this.state.id, (data) => {
				this.setState({data,dataOld:{...data},notfound:empty(data),items:items.data,loading:false});
				const price = items.data.reduce((a, b) => a + (b.price / 100), 0);
				this.setState({price:(data.price?data.price/100:price).toFixed(2)});
			});
		}
		else this.dataInit();
	}
	dataInit = () => this.setState({data:{productName:'',productId:0,portion:0,quantity:0,brutto:0,netto:0},id:0,item:null,loading:false});
	handleForm = (e) => {
		let {item} = this.state;
		item = item || {};
		item[e.target.name] = e.target.name === 'priceKg' ? parseFloat(e.target.value) * 100 : parseInt(e.target.value);
		this.setState({item}, this.calc);
	}
	totalGet = () => {
		const price = this.state.items.reduce((a, b) => a + (b.price / 100), 0);
		this.setState({price:price.toFixed(2)});
	}
	handleFormMain = (e) => this.setState({[e.target.name]:e.target.value});
	handleQuantity = (e) => {
		const {data} = this.state;
		data[e.target.name] = e.target.checked ? 1 : 0;
		this.setState({data});
	}
	calc = () => {
		const {item} = this.state;
		if (item) {
			if (item.netto && item.priceKg) item.price = item.netto / 1000 * item.priceKg;
			if (item.netto) item.output = item.netto - (item.losses ? item.losses : 0);
			this.setState({item});
		}
	}
	saveIng = (e) => {
		e.preventDefault();
		const {item,data,isitemnew,tab} = this.state;
		if ((tab === 1 && !item.ingredientId) || (tab === 2 && !item.extCardId)) {
			alertShow('<b>Ошибка!</b><br/>Вы не выбрали продукт, ингредиент или полуфабрикат', true);
			this.modalIngredientShow();
			return;
		}
		let {items} = this.state;
		if (!isitemnew) {
			items = [];
			this.state.items.forEach((v) => {
				if (tab === 1) {
					if (v.ingredientId !== item.ingredientId) items.push(v);
				}
				if (tab === 2) {
					if (v.extCardId !== item.extCardId) items.push(v);
				}
			});
		}
		items.push(item);
		let brutto = 0, netto = 0;
		items.forEach((v) => {
			brutto += v.brutto;
			netto += v.netto;
		});
		data.netto = netto;
		data.brutto = brutto;
		data.type = semifinishType.NORMAL;
		this.setState({items,data}, () => {
			this.totalGet();
			this.modalHide();
		});
	}
	save = (e) => {
		e.preventDefault();
		const {id,data,items} = this.state;
		if (data.productId === 0) {
			alertShow('<b>Ошибка!</b><br/>Вы не выбрали позицию из меню', true);
			return;
		}
		if (items.length === 0) {
			alertShow('<b>Ошибка!</b><br/>Вы не добавили продукт, ингредиент', true);
			return;
		}
		if (data.quantity === 1 && items.length > 1) {
			alertShow('<b>Ошибка!</b><br/>Для штучного товара можно добавить только один продукт, ингредиент', true);
			return;
		}
		data.price = this.state.price*100;
		this.setState({issave:true});
		if (id === 0) {
			Stores.cardAdd(data, (res) => {
				this.stop(() => {
					const id = parseInt(res.data.id);
					this.setState({saveStatus:Texts.card.add,iserror:false,id});
					Events.add(this.state.user.id, Texts.card.add, JSON.stringify(this.state.dataOld), JSON.stringify(data), res.data.id, eventType.STORE_CARD);
					this.itemsAdd(id);
				});
		 	}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			 });
		} else {
			Stores.cardUpdate(id, data, () => {
				this.stop(() => {
					this.setState({saveStatus:Texts.card.update,iserror:false});
					Events.add(this.state.user.id, Texts.card.update, JSON.stringify(this.state.dataOld), JSON.stringify(data), id, eventType.STORE_CARD);
					this.itemsAdd(id);
				});
			}, (err) => {
				this.stop(() => {
					this.setState({saveStatus:Texts.errors.internalError,iserror:true});
					Logs.add(err);
				});
			});
		}
	}
	stop = (callback) => this.setState({issave:false}, () => callback ? callback() : {});
	itemsAdd = async (id) => {
		//this.state.items.forEach(async (v) => v.id && await Stores.cardItemDelete(v.id));
		await Stores.cardItemsDelete(id);
		setTimeout(() => {
			this.state.items.forEach(async (v) => {
				const d = {
					brutto:v.brutto,
					cardId:id,
					ingredientId:v.ingredientId,
					ingredientName:v.ingredientName,
					netto:v.netto,
					output:v.output,
					price:v.price,
					priceKg:v.priceKg,
					losses:v.losses
				};
				v.cardId = id;
				v.id = parseInt((await Stores.cardItemAdd(d)).data?.id);
			});
		}, 1000);
	}
	itemRemove = (e, idx) => {
		e.stopPropagation();
		const items = [];
		this.state.items.forEach((v,i) => {
			if (i !== idx) items.push(v);
		});
		this.setState({items}, this.totalGet);
	}
	searchProducts = (e) => {
		const ss = e.target.value;
		if (empty(ss)) {
			this.setState({products:this.state.productsFull});
			return;
		}
		const products = this.state.productsFull.filter(f => f.name.toLowerCase().indexOf(ss.toLowerCase()) !== -1);
		this.setState({products});
	}
	searchIngredients = (e) => {
		const ss = e.target.value;
		if (empty(ss)) {
			this.setState({ingredients:this.state.ingredientsFull,semifinish:this.state.semifinishFull});
			return;
		}
		if (this.state.tab === 1) {
			const ingredients = this.state.ingredientsFull.filter(f => f.name.toLowerCase().indexOf(ss.toLowerCase()) !== -1);
			this.setState({ingredients});
		} else {
			const semifinish = this.state.semifinishFull.filter(f => f.productName.toLowerCase().indexOf(ss.toLowerCase()) !== -1);
			this.setState({semifinish});
		}
	}
	modalHide = () => this.setState({modalitem:false,modalproducts:false,modalingredient:false,isitemnew:true});
	modalProductHide = () => this.setState({modalproducts:false});
	modalIngredientHide = () => this.setState({modalingredients:false});
	modalItemShow = (item) => this.setState({modalitem:true,item,isitemnew:item===null});
	modalIngredientShow = () => this.setState({modalingredients:true});
	modalProductShow = () => this.setState({modalproducts:true,productName:null,productId:0});
	productSelect = (e) => {
		const {data} = this.state;
		const product = this.objectGet(this.state.products, e.target.value);
		data.productId = product.id;
		data.productName = product.name;
		data.portion = this.measureGet(product.measure, product.measureType);
		this.setState({data}, this.modalHide);
	}
	ingredientSelect = (e) => {
		const id = parseInt(e.target.value);
		const ingredient = this.objectGet(this.state.ingredientsFull, id);
		let {item} = this.state;
		item = item || {};
		item.ingredientId = ingredient.id;
		item.ingredientName = ingredient.name;
		item.priceKg = ingredient.price*100;
		this.setState({item,ingredientId:id}, this.modalIngredientHide);
	}
	semifininishSelect = (e) => {
		const id = parseInt(e.target.value);
		const semifinish = this.objectGet(this.state.semifinishFull, id);
		let {item} = this.state;
		item = item || {};
		item.extCardId = id;
		item.extCardName = semifinish.productName;
		item.priceKg = semifinish.price;
		item.netto = semifinish.netto;
		item.brutto = semifinish.brutto;
		this.setState({item,cardId:id}, () => {
			this.calc();
			this.modalIngredientHide();
		});
	}
	objectGet = (data, id) => data.find(f => f.id === parseInt(id));
	measureGet = (value, type) => {
		switch (parseInt(type)) {
			case measureType.KILOGRAM: return parseFloat(value) * 1000;
			case measureType.GRAMS: return parseFloat(value);
			default: return value;
		}
	}
	tabSet = (tab) => this.setState({tab});
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate isStore={true}>
						<div className={styles.container}>
							<h4>
								<span className={styles.oneline}>
									<Link to={'/store/cards'}><img src={require('../../../Images/icons/back.svg')} alt="назад" /></Link>
									{this.state.id === 0 ? 'Добавить технологическую карту' : 'Редактирование технологической карты'}
								</span>
								<Link to={'/store/card'} className={styles.add}>Добавить</Link>
							</h4>
							{this.state.notfound ? <NotFound /> :
								<form onSubmit={this.save}>
									<div className={styles.formcontainer}>
										<div className={styles.onelinefull}>
											<div className={styles.cell}>
												<label>
													<span>Порция</span>
													<div>{this.state.data.portion ? <>{weightFormat(this.state.data.portion/1000)}, кг</> : '—'}</div>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Брутто</span>
													<div>{this.state.data.brutto ? <>{weightFormat(this.state.data.brutto/1000)}, кг</> : '—'}</div>
												</label>
											</div>
											<div className={styles.cell}>
												<label>
													<span>Нетто</span>
													<div>{this.state.data.netto ? <>{weightFormat(this.state.data.netto/1000)}, кг</> : '—'}</div>
												</label>
											</div>
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Штучный товар</span>
													<div><input type="checkbox" name="quantity" defaultChecked={this.state.data.quantity} onChange={this.handleQuantity} /> да</div>
												</label>
											</div>
										</div>
										<div className={styles.oneline}>
											<div className={styles.cell}>
												<label>
													<span>Расчетная цена, ₽</span>
													<input type="text" name="price" value={this.state.price} placeholder="Цена комплекта" onChange={this.handleFormMain} required />
													<div className={styles.notice}>Подсчитывается автоматически. При необходимости можно задать в ручную.</div>
												</label>
											</div>
										</div>
										<div className={styles.linepositions}>
											<h4>
												Позиции, продукты, полуфабрикаты
												{this.state.data.quantity === 1 && this.state.items.length === 1 ? null : <a className={styles.plus} onClick={() => this.modalItemShow(null)}></a>}
											</h4>
											{this.state.items.length > 0 ?
												<table>
													<thead>
														<tr>
															<th>Название</th>
															<th>Брутто, кг</th>
															<th>Нетто, кг</th>
															<th>Потери, кг</th>
															<th>Выход, кг</th>
															<th>Стоимость за кг, ₽</th>
															<th>Стоимость, ₽</th>
															<th>&nbsp;</th>
														</tr>
													</thead>
													<tbody>
														{this.state.items.map((v,i) => <tr key={i} onClick={() => this.modalItemShow(v)}>
															<td>{v.extCardId ? <><b>п/ф</b> {v.extCardName}</> : v.ingredientName}</td>
															<td>{weightFormat(v.brutto/1000)}</td>
															<td>{weightFormat(v.netto/1000)}</td>
															<td>{weightFormat(v.losses?v.losses/1000:0)}</td>
															<td>{weightFormat(v.output/1000)}</td>
															<td>{moneyFormat(v.priceKg/100)}</td>
															<td>{moneyFormat(v.price/100)}</td>
															<td><img src={require('../../../Images/icons/close_black.svg')} alt="Удалить" onClick={(e) => this.itemRemove(e, i)} /></td>
														</tr>)}
													</tbody>
												</table> : null}
										</div>
										<div className={styles.linepositions}>
											<h4>
												Применено к меню
												<a className={styles.plus} onClick={() => this.modalProductShow()}></a>
											</h4>
											<div>{this.state.data.productName}</div>
										</div>
										<div className={styles.buttons}>
											{this.state.issave ? <button disabled></button> : <button>Сохранить</button>}
											{this.state.saveStatus && <SaveStatus message={this.state.saveStatus} isError={this.state.iserror} onfinish={() => this.setState({saveStatus:null,iserror:false})} />}
										</div>
									</div>
								</form>
							}
						</div>
					</MainTemplate>
				}
				<Modal visible={this.state.modalitem} width={'80%'} height={'520'} effect={'fadeInUp'}>
					<div className={styles.modalcontainer}>
						<h4 className={styles.title}>{this.state.item ? 'Изменить продукт, ингредиент' : 'Добавить продукт, ингредиент'}</h4>
						<form onSubmit={this.saveIng}>
							<div className={cx(styles.formcontainer,styles.formcontainerclean)}>
								<div className={styles.cell}>
									<label>
										<span>Продукт, ингредиент, полуфабрикат</span>
										{this.state.item ? <div><b>{this.state.item.extCardId ? this.state.item.extCardName : this.state.item.ingredientName}</b></div> : null}
										<div className={styles.cellbutton}>
											<button type="button" className={styles.button} onClick={() => this.modalIngredientShow()}>выбрать</button>
										</div>
									</label>
								</div>
								<div className={styles.oneline}>
									<div className={styles.cell}>
										<label>
											<span>Стоимость, ₽ <sup>*</sup></span>
											<input type="text" name="priceKg" placeholder="Стоимость" value={this.state.item?.priceKg ? this.state.item.priceKg/100 : ''} onChange={this.handleForm} required />
										</label>
									</div>
								</div>
								<div className={styles.oneline}>
									<div className={styles.cell}>
										<label>
											<span>Брутто, гр.</span>
											<input type="text" name="brutto" placeholder="Брутто, вес с упаковкой в гр." value={this.state.item?.brutto ? this.state.item.brutto : ''} onChange={this.handleForm} required />
										</label>
									</div>
									<div className={styles.cell}>
										<label>
											<span>Нетто, гр.</span>
											<input type="text" name="netto" placeholder="Нетто, чистый вес в гр." value={this.state.item?.netto ? this.state.item.netto : ''} onChange={this.handleForm} required />
										</label>
									</div>
									<div className={styles.cell}>
										<label>
											<span>Потери, гр.</span>
											<input type="text" name="losses" placeholder="Потери в гр." value={this.state.item?.losses ? this.state.item.losses : 0} onChange={this.handleForm} required />
										</label>
									</div>
								</div>
								<div className={styles.onelinefull}>
									<div className={styles.cell}>
										<label>
											<span>Выход</span>
											<div>{this.state.item?.output ? <>{weightFormat(this.state.item.output/1000)} кг</> : '—'}</div>
										</label>
									</div>
									<div className={styles.cell}>
										<label>
											<span>Стоимость</span>
											<div>{this.state.item?.price ? <>{moneyFormat(this.state.item.price/100)} ₽</> : '—'}</div>
										</label>
									</div>
								</div>
								<div className={styles.oneline}>
									<p><sup>*</sup> для весовых продуктов стоимость за кг</p>
								</div>
								<div className={styles.control}>
									<button className={styles.button}>{this.state.item ? 'Сохранить' : 'Добавить'}</button>
								</div>
							</div>
						</form>
					</div>
					<Close close={this.modalHide.bind(this)} />
				</Modal>
				<Modal visible={this.state.modalingredients} width={'90%'} height={'700'} effect={'fadeInUp'}>
					<div className={styles.modalcontainer}>
						<h4 className={styles.title}>Выбрать продукт, ингредиент</h4>
						<div className={styles.tabs}>
							<div className={cx(styles.tab,this.state.tab===1?styles.tabactive:null)} onClick={() => this.tabSet(1)}>Продукт/ингредиент</div>
							<div className={cx(styles.tab,this.state.tab===2?styles.tabactive:null)} onClick={() => this.tabSet(2)}>Полуфабрикат</div>
						</div>
						<div className={styles.search}>
							<input type="search" onChange={this.searchIngredients} />
						</div>
						<div className={styles.products}>
							<ul>
								<>
									{this.state.tab === 1 ?
										this.state.ingredients.map((v,i) => <li key={i}><label><input type="radio" name="ingredientId" value={v.id} checked={this.state.ingredientId===v.id} onChange={this.ingredientSelect} /> {v.name}</label></li>)
										: null}
									{this.state.tab === 2 ?
										this.state.semifinish.map((v,i) => <li key={i}><label><input type="radio" name="cardId" value={v.id} checked={this.state.cardId===v.id} onChange={this.semifininishSelect} /> {v.productName}</label></li>)
										: null}
								</>
							</ul>
						</div>
					</div>
					<Close close={this.modalIngredientHide.bind(this)} />
				</Modal>
				<Modal visible={this.state.modalproducts} width={'90%'} height={'700'} effect={'fadeInUp'}>
					<div className={styles.modalcontainer}>
						<h4 className={styles.title}>Применить к позиции меню</h4>
						<div className={styles.search}>
							<input type="search" onChange={this.searchProducts} />
						</div>
						<div className={styles.products}>
							<ul>
								{this.state.products.map((v,i) => <li key={i}><label><input type="radio" name="productId" value={v.id} onChange={this.productSelect} /> {v.name}<span>{v.price} ₽ / {v.measure ? <>{v.measure} {measureTypeName[v.measureType]}</> : '—'}</span></label></li>)}
							</ul>
						</div>
					</div>
					<Close close={this.modalProductHide.bind(this)} />
				</Modal>
				<Alert />
			</>
		);
	}
}

export default withRouter(StoreCard);