import React,{Component} from 'react';
import {withRouter} from 'react-router';

// plug-ins
import LoadingBar from 'react-top-loading-bar';

// components
import MainTemplate from '../../Components/MasterPages/MainTemplate';
import NotFound from '../../Components/NotFound';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';

// models
import {Clients} from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// styles
import styles from './ClientsSearch.module.css';

class ClientsSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data:null,
			clients:[],
			user:null,
			search:'',
			notfound:false,
			progress:10,
			loading:true
		};
	}
	componentDidMount = async () => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		Clients.getAll((data) => this.setState({clients:data,progress:30,user,loading:false}));
	}
	handleForm = (e) => this.setState({[e.target.name]:e.target.value});
	search = () => {
		const {search} = this.state;
		if (search.length < 2) {
			this.setState({clients:[]});
			return;
		}
		const data = this.state.clients.filter(f => this.check(f.name, search) || this.check(f.email, search) || this.check(f.phone, search) || this.check(f.refCode, search));
		this.setState({data:this.dataSet(data),notfound:data.length===0});
	}
	check = (r, l) => r ? r.toString().toLowerCase().indexOf(l.toLowerCase()) !== -1 : false;
	dataSet = (data) => dgDataPrepare(data, 'clients', ['id','name','phone','status','dateCreate']);
	render() {
		return (
			<>
				<LoadingBar color='#f11946' progress={this.state.loading?this.state.progress:100} />
				{this.state.loading ? null :
					<MainTemplate>
						<div className={styles.container}>
							<h4>Поиск клиентов</h4>
							<div className={styles.searchblock}>
								<input type="search" name="search" placeholder="Поиск клиента по имени, телефону, эл.почте, реферальному коду" value={this.state.search} onChange={this.handleForm} autoFocus={true} autoComplete="off" />
								<button onClick={() => this.search()}>Найти</button>
							</div>
							{this.state.notfound ? <NotFound /> :
								<DataGrid
									tableName={'client'}
									keys={['ID','Имя','Телефон','Статус','Дата регистрации']}
									link={'client'}
									data={this.state.data}
									dataFull={this.state.data} />}
						</div>
					</MainTemplate>
				}
			</>
		);
	}
}

export default withRouter(ClientsSearch);